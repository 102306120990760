<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <svgicon
            class="modal__container__icon"
            name="large-remove"
            :original="true"
          ></svgicon>
          <h4 class="modal__container__heading">
            {{ $t("warnings.error_occurred_message") }}
          </h4>
          <p class="modal__container__warning">
            {{ apiError }}
          </p>
          <div class="modal__container__actions">
            <button-3 @click.native="handleModalToggle">{{ $t("actions.ok_action") }}</button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Button3 from '@/components/elements/buttons/Button3.vue';

export default {
  name: 'ErrorModal',
  components: {
    Button3,
  },
  computed: {
    ...mapGetters('meta', ['apiError']),
  },
  methods: {
    ...mapActions('meta', ['setApiError']),
    handleModalToggle(status) {
      if (status) this.setApiError(null);
      // this.$emit('handleErrorModal');
    },
  },
};

</script>
