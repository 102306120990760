<template>
  <div id="group-reflection" class="group-reflection" v-if="!loading">
    <member-reflection-header
      v-if="member"
      :member="member"
      :questionnaire="questionnaire"
    ></member-reflection-header>
    <group-reflection-filter
      @handleReflectionFilter="handleReflectionFilter"
    ></group-reflection-filter>
    <div class="group-reflection__list">
      <member-reflection-date
        v-for="(reflection, index) in memberReflections"
        :key="reflection.id"
        :reflection="reflection"
        :number="queries.offset + index + 1"
        @handleDeleteModal="handleDeleteModal"
      ></member-reflection-date>
    </div>
    <div v-if="!memberReflections.length" class="no-data-message-wrapper flex-global">
      <h3>{{ $t('messages.no_data_message_alt') }}</h3>
    </div>
    <pagination
      v-if="totalPages > 1"
      :activePage="activePage"
      :totalPages="totalPages"
      @selectPage="selectPage"
    ></pagination>
    <delete-modal
      v-if="deleteModal"
      type="reflection"
      @handleDeleteAction="handleReflectionDelete"
    />
  </div>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import {
    handleGetQuestionnaire,
    handleGetGroup,
    handleGetMembers,
    handleGetMember,
  } from '@/helpers/groups.helpers';

  import MemberReflectionHeader from '@/components/groups/member/MemberReflectionHeader.vue';
  import GroupReflectionFilter from '@/components/groups/GroupReflectionFilter.vue';
  import MemberReflectionDate from '@/components/groups/member/MemberReflectionDate.vue';
  import Loading from '@/components/base/Loading.vue';
  import Pagination from '@/components/base/Pagination.vue';
  import DeleteModal from '@/components/base/DeleteModal.vue';

  export default {
    name: 'GroupMemberReflection',
    components: {
      MemberReflectionHeader,
      GroupReflectionFilter,
      MemberReflectionDate,
      Loading,
      Pagination,
      DeleteModal,
    },
    created() {
      this.handlePageInit();
    },
    data() {
      return {
        totalCount: 0,
        filterDates: {
          from: null,
          to: null,
        },
        activePage: 1,
        reflectionToDelete: null,
      };
    },
    computed: {
      ...mapGetters('groups', ['group']),
      ...mapGetters('members', ['members', 'member', 'memberReflections']),
      ...mapGetters('reflections', ['currentReflection']),
      ...mapGetters('meta', ['loading', 'deleteModal']),
      questionnaire() {
        if (!this.memberReflections.length) return null;
        const reflection = this.memberReflections[0];
        return {
          ...reflection.questionnaire,
          id: reflection.questionnaireId,
        };
      },
      queries() {
        const {
          params: { memberId, questionnaireId },
        } = this.$route;
        return {
          clientId: +memberId,
          memberId: +memberId,
          questionnaireId: +questionnaireId,
          limit: 10,
          offset: 0,
          ...this.filterDates,
        };
      },
      currentMemberId() {
        return this.member.id;
      },
      totalPages() {
        return Math.ceil(this.totalCount / this.queries.limit) || 0;
      },
    },
    methods: {
      ...mapActions('groups', ['getGroup']),
      ...mapActions('reflections', ['setCurrentReflection', 'deleteReflectionMember']),
      ...mapActions('members', ['getMembers', 'getMember', 'getMemberReflections']),
      ...mapActions('meta', ['setLoading', 'toggleDeleteModal']),
      ...mapActions('questionnaire', ['getQuestionnaire']),
      handleGetGroup,
      handleGetMembers,
      handleGetMember,
      handleGetQuestionnaire,
      async handlePageInit() {
        this.setLoading(true);
        try {
          await this.handleGetGroup();
          await this.handleGetMembers();
          await this.handleGetMember();
          await this.handleGetQuestionnaire();
          await this.handleGetMemberReflections();
          await this.handleSetCurrentReflection();
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      async handleReflectionDelete() {
        this.toggleDeleteModal();
        this.setLoading(true);
        const result = await this.deleteReflectionMember({
          reflectionId: this.reflectionToDelete,
          clientId: this.currentMemberId,
        });
        if (result) {
          this.handleGetMemberReflections(this.queries);
        }
        this.setLoading(false);
        return Promise.resolve();
      },
      handleReflectionFilter({ fromDate, toDate }) {
        this.filterDates = {
          from: this.moment(fromDate).format('YYYY-MM-DD'),
          to: this.moment(toDate).format('YYYY-MM-DD'),
        };
        this.handleGetMemberReflections();
      },
      async handleGetMemberReflections() {
        const { totalCount } = await this.getMemberReflections(this.queries);
        this.totalCount = totalCount || null;
        return Promise.resolve();
      },
      handleSetCurrentReflection() {
        if (this.memberReflections.length) {
          this.setCurrentReflection(this.memberReflections[0]);
        }
        return Promise.resolve();
      },
      selectPage(page) {
        this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
        this.activePage = page;
        this.handleGetMemberReflections();
      },
      handleDeleteModal(id) {
        this.reflectionToDelete = id;
        this.toggleDeleteModal();
      },
    },
  };
</script>
<style lang="scss">
  .group-reflection {
    max-width: 940px;
    width: 940px;
    margin: 0 auto;
    position: relative;
    .no-data-message-wrapper {
      margin-top: 1rem;
      text-align: center;
      h3 {
        width: 350px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
  }
</style>
