<template>
  <div class="group__upper-header">
    <div class="group__upper-wrapper flex-global" v-if="currentGroup">
      <div class="spacer"></div>
      <header-h1>{{currentGroup.title}}</header-h1>
      <div class="group-delete-wrapper" @click="toggleModal">
        <svgicon name="bin" width="10" height="14" :original="true"></svgicon>
        <span>{{ $t("actions.group_delete_action") }}</span>
      </div>
    </div>
    <div class="warning-modal" v-show="isModalVisible" @click="toggleModal">
      <div class="warning-modal__bg">
        <div class="warning-modal__modal" @click.stop>
          <div class="warning-modal__container">
            <svgicon
              class="warning-modal__container__icon"
              name="large-remove"
              :original="true"
            ></svgicon>
            <h4 class="options-modal__container__heading">
              {{ $t("group.group_delete_confirmation_message") }}
            </h4>
            <div class="options-modal__container__actions">
              <button-3 @click.native="toggleModal">{{ $t("no_cap") }}</button-3>
              <button-4 @click.native="handleGroupDelete">{{ $t("yes_cap") }}</button-4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'GroupUpperHeader',
  components: {
    HeaderH1,
    Button3,
    Button4
  },
  props: ['currentGroup'],
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    ...mapActions('groups', ['updateGroup', 'removeGroup', 'getGroupList']),
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    handleGroupDelete() {
      this.removeGroup(this.currentGroup.id).then(({ success }) => {
        if (success) {
          this.toggleModal();
          this.$router.push({
            name: 'MyGroups',
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.group {
  &__upper-header {
    margin-bottom: 20px;
    .group-delete-wrapper{
      display: flex;
      align-items: center;
      margin-top: 5px;
      transition: all 0.3s;
      cursor: pointer;
      svg{
        g{
          stroke: $red-4;
        }
      }
      span{
        margin-top: 2px;
        margin-left: 5px;
        font-family: Nunito-Bold;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: $red-4;
      }
    }
  }
  &__upper-wrapper{
    justify-content: space-between;
    .spacer{
      width: 93.16px;
    }
  }
}
</style>
