<template>
  <div class="member-answer__answer-sentences">
    <span class="answer-date">{{displayDate}}</span>
    <span class="answer-sentence" v-html="questionAnswer"></span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'QuestionTextDisplay',
  props: ['date', 'answer'],
  computed: {
    questionAnswer() {
      const answer = this.answer;

      if(answer === this.$t("member_did_not_reflect") || answer === this.$t("member_skipped")){
        return `<span class="no-reflection">${answer}</span>`;
      }

      return `<span class="answer-sentence answer-sentence--answer">${answer || '_'}</span>`;
    },
    displayDate() {
      return moment(this.date).format('YYYY-MM-DD');
    },
  },
};
</script>
<style lang="scss" scoped>
.member-answer {
  &__answer-sentences {
    .answer-date {
      margin-right: 5px;
      font-size: 14px;
      color: #5a5a5a;
    }
    .answer-sentence{
      word-break: break-word;
    }
  }
}
</style>
