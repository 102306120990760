import axios from 'axios';
import { handleCommitResponse, handleResponseError } from './helpers/responseHelpers';
import i18n from '../../plugins/i18n';

const getDefaultState = () => ({
  licencesForPurchase: [],
  myAssignedLicences: [],
  licencesForm: {
    selectedLicence: null,
    selectedOrg: null,
    licenceType: null,
    quantity: 1,
  },
  checkoutForm: {
    companyTitle: null,
    companyCode: null,
    pvmCode: null,
    name: null,
    lastName: null,
    email: null,
    phone: null,
    address: null,
    city: null,
    postalCode: null,
    country: null,
    payerType: null,
  },
  paymentMethod: null,
  cartContent: {
    licence: null,
    services: [],
  },
  licenceOrdersHistory: [],
  licenceOrderInner: null,
  defaultPurchaseAmount: 1,
  defaultPurchaseAmountOrg: 5,
  orgLicences: [],
  isLoadingPurchase: false,
});

const state = getDefaultState();

const mutations = {
  GET_LICENCES_FOR_PURCHASE(state, value) {
    state.licencesForPurchase = value;
  },
  SET_LICENCE_FORM(state, { type, property }) {
    switch (type) {
      case 'selectedLicence':
        state.licencesForm.selectedLicence = property;
        break;
      case 'quantity':
        state.licencesForm.quantity = property;
        break;
      case 'licenceType':
        state.licencesForm.licenceType = property;
        break;
      case 'selectedOrg':
        state.licencesForm.selectedOrg = property;
        break;
      default:
        break;
    }
  },
  SET_PAYER_TYPE(state, value) {
    state.checkoutForm.payerType = value;
  },
  SET_PAYMENT_METHOD(state, value) {
    state.paymentMethod = value;
  },
  PREFILL_CHECKOUT_FORM(state, { user, currentActiveOrganization }) {
    const payload = {
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      companyTitle: currentActiveOrganization ? currentActiveOrganization.title : null,
      companyCode: currentActiveOrganization ? currentActiveOrganization.code : null,
    };
    state.checkoutForm.name = payload.name;
    state.checkoutForm.lastName = payload.lastName;
    state.checkoutForm.email = payload.email;
    state.checkoutForm.phone = payload.phone;
    state.checkoutForm.companyTitle = payload.companyTitle;
    state.checkoutForm.companyCode = payload.companyCode;
  },
  UPDATE_CHECKOUT_FORM(state, { type, property }) {
    switch (type) {
      case 'email':
        state.checkoutForm.email = property;
        break;
      case 'phone':
        state.checkoutForm.phone = property;
        break;
      default:
        break;
    }
  },
  RESET_LICENCES_FORM(state) {
    state.licencesForm = {
      selectedLicence: null,
      selectedOrg: null,
      quantity: 1,
      licenceType: null,
    };
  },
  RESET_CHECKOUT_FORM(state) {
    state.checkoutForm = {
      companyTitle: null,
      companyCode: null,
      pvmCode: null,
      name: null,
      lastName: null,
      email: null,
      phone: null,
      address: null,
      city: null,
      postalCode: null,
      country: null,
      payerType: null,
    };
  },
  ADD_LICENCE_TO_CART(state, value) {
    state.cartContent.licence = value;
  },
  GET_LICENCE_ORDER_HISTORY(state, value) {
    state.licenceOrdersHistory = value;
  },
  GET_INNER_LICENCE_ORDER(state, value) {
    state.licenceOrderInner = value;
  },
  GET_ORGANIZATION_LICENCES(state, value) {
    state.orgLicences = value;
  },
  SET_IS_LOADING(state, value) {
    state.isLoadingPurchase = value;
  },
  GET_MY_ASSIGNED_LICENCES(state, value) {
    state.myAssignedLicences = value;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getLicencesForPurchase({ commit }) {
    return axios
      .get('client/license/listForSale')
      .then((res) => handleCommitResponse(res, commit, 'GET_LICENCES_FOR_PURCHASE'))
      .catch(handleResponseError);
  },
  updateLicenceForm({ commit }, payload) {
    commit('SET_LICENCE_FORM', payload);
  },
  updateCheckoutForm({ commit }, payload) {
    commit('UPDATE_CHECKOUT_FORM', payload);
  },
  handlePayseraPurchase(context, payload) {
    return axios({
      method: 'post',
      url: '/client/payment/create',
      data: {
        ...payload,
      },
    })
      .then(({ data: { success, data } }) => {
        if (!success) return Promise.resolve({ success: false });
        const { payseraRedirectUrl } = data;
        window.location.replace(payseraRedirectUrl);

        return Promise.resolve({ success: true, data });
      })
      .catch(handleResponseError);
  },
  getLicenceOrdersHistory({ commit }) {
    return axios
      .get('client/payment/list')
      .then((res) => handleCommitResponse(res, commit, 'GET_LICENCE_ORDER_HISTORY'))
      .catch(handleResponseError);
  },
  getOrgLicenceOrdersHistory({ commit }, id) {
    return axios
      .get(`client/payment/list`, { params: { organizationId: id } })
      .then((res) => handleCommitResponse(res, commit, 'GET_LICENCE_ORDER_HISTORY'))
      .catch(handleResponseError);
  },
  getLicenceOrder({ commit }, id) {
    return axios
      .get(`client/payment/get/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_INNER_LICENCE_ORDER'))
      .catch(handleResponseError);
  },
  getOrganizationLicences({ commit }, id) {
    return axios
      .get(`client/license/listOrganizationLicenses/${id}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_ORGANIZATION_LICENCES'))
      .catch(handleResponseError);
  },
  assignLicenceToUser(context, payload) {
    const { relationId, clientId } = payload;
    return axios
      .put(`/client/license/assign/${relationId}/${clientId}`)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          return Promise.resolve({ success: true });
        }
        return Promise.resolve({
          success: false,
          msg: i18n.t('warnings.assign_licence_error_message'),
        });
      })
      .catch((error) => {
        const { data } = error.response.data;
        if (data) return Promise.resolve({ success: false, data });
        return Promise.reject(error);
      });
  },
  getMyAssignedLicences({ commit }) {
    return axios
      .get('client/license/listMyLicenses')
      .then((res) => handleCommitResponse(res, commit, 'GET_MY_ASSIGNED_LICENCES'))
      .catch(handleResponseError);
  },
  setPayerType({ commit }, payload) {
    commit('SET_PAYER_TYPE', payload);
  },
  setPaymentMethod({ commit }, payload) {
    commit('SET_PAYMENT_METHOD', payload);
  },
  addLicenceToCart({ commit }, payload) {
    commit('ADD_LICENCE_TO_CART', payload);
  },
  setIsLoading({ commit }, payload) {
    commit('SET_IS_LOADING', payload);
  },
  prefillCheckoutForm({ commit, rootState }) {
    const { user, currentActiveOrganization } = rootState.user;
    commit('PREFILL_CHECKOUT_FORM', { user, currentActiveOrganization });
  },
  resetLicencesForm({ commit }) {
    commit('RESET_LICENCES_FORM');
  },
  resetCheckoutForm({ commit }) {
    commit('RESET_CHECKOUT_FORM');
  },
  resetLicencesState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  licencesForPurchase(state) {
    return state.licencesForPurchase;
  },
  licencesForm(state) {
    return state.licencesForm;
  },
  checkoutForm(state) {
    return state.checkoutForm;
  },
  payerType(state) {
    return state.checkoutForm.payerType;
  },
  paymentMethod(state) {
    return state.paymentMethod;
  },
  cartContent(state) {
    return state.cartContent;
  },
  licenceOrdersHistory(state) {
    return state.licenceOrdersHistory;
  },
  licenceOrderInner(state) {
    return state.licenceOrderInner;
  },
  defaultPurchaseAmount(state) {
    return state.defaultPurchaseAmount;
  },
  defaultPurchaseAmountOrg(state) {
    return state.defaultPurchaseAmountOrg;
  },
  orgLicences(state) {
    return state.orgLicences;
  },
  isLoadingPurchase(state) {
    return state.isLoadingPurchase;
  },
  myAssignedLicences(state) {
    return state.myAssignedLicences;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
