<template>
  <div class="member-answer__answer-sentences">
    <span class="answer-sentence" v-html="incompleteSentence"></span>
    <!-- <input class="member-answer__input" type="text" placeholder="Įrašykite reikšmę" /> -->
  </div>
</template>
<script>
  export default {
    name: 'TemplateSentences',
    props: ['method', 'question', 'memberAnswer'],
    computed: {
      incompleteSentence() {
        if (this.question.methodId === 5 && this.memberAnswer[0].original) {
          let sentence = this.question.parts[0].title;
          const answerArray = this.memberAnswer[0].original;

          answerArray.forEach((phrase) => {
            sentence = sentence.replace(
              '@',
              `<span class="answer-sentence answer-sentence--answer">${phrase || '_'}</span>`
            );
          });
          return sentence;
        }
        if (typeof this.memberAnswer !== 'string') {
          return `<span class="no-reflection-sentence answer-sentence--answer">${this.$t(
            'member_skipped_alt'
          )}</span>`;
        }
        return `<span class="no-reflection-sentence answer-sentence--answer">${this.memberAnswer}</span>`;
      },
      // OLD
      // incompleteSentence() {
      //   if (this.method.template === 'incomplete_sentence') {
      //     const sentence = this.method.question;
      //     const answerArray = this.method.answers;
      //     const completeSentence = answerArray.map((answer, i) => {
      //       const sentenceArray = sentence.split('@');
      //       return sentenceArray[i].concat(
      //         `<span class="answer-sentence answer-sentence--answer">${answer.value}</span>`,
      //       );
      //     });
      //     return completeSentence.join('  ');
      //   }
      //   return null;
      // },
    },
  };
</script>
<style lang="scss">
  .member-answer {
    &__answer-sentences {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-left: 35px;
      .answer-sentence {
        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-2;
        max-width: 570px;
        &--answer {
          font-family: Roboto-Medium;
          color: $green-3;
        }
      }
    }
  }
</style>
