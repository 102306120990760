<template>
  <div class="dropdown__custom">
    <a>
      {{ fullName }}
      <span>&#9660;</span>
    </a>
    <ul>
      <li>
        <svgicon name="person" :original="true" color="#fff" :fill="false"></svgicon>
        <a @click="handleRouteToProfile">{{ $t('top_bar.my_profile') }}</a>
      </li>
      <li>
        <svgicon name="exit" :original="true"></svgicon>
        <a @click="logout">{{ $t('top_bar.sign_out') }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MenuDropdown',
  data() {
    return {
      fullName: '-',
    };
  },
  mounted() {
    this.setFullName();
  },
  computed: {
    ...mapGetters('user', ['user', 'isUserLicenceExpired']),
  },
  methods: {
    ...mapActions('user', ['logout', 'setError', 'toggleModal']),
    setFullName() {
      this.fullName = `${this.user.name} ${this.user.lastName}`;
    },
    handleRouteToProfile() {
      if (this.isUserLicenceExpired) {
        this.setError({ error: 'license_expired' });
        this.toggleModal({ type: 'login', status: true });
        return;
      }
      this.$router.push({ name: 'ProfileInfo' });
    }
  },
};
</script>
<style lang="scss" scoped>
.dropdown__custom {
  position: relative;
  background-color: #11ab99;
  color: #fff;
  width: 240px;
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    font-family: Roboto-Medium;
    font-size: 11px;
    text-decoration: none;
    color: #fff;
    padding-top: 10px;

    span {
      color: #fff;
      margin-left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    display: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    position: absolute;
    top: 32px;
    z-index: 2;
    margin-top: -2.5px;
    border-top: 1px solid #fff;

    li {
      list-style: none;
      width: 160px;
      height: 30px;
      background-color: #11ab99;
      border: 1px solid transparent;
      margin-top: 1px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
        margin-right: 23px;
        height: 16px;
        width: 16px;
      }

      a {
        padding-top: 0;
      }

      &:hover {
        background-color: #33ca97;
      }
    }
  }

  &:hover ul {
    display: flex;
  }

  &:hover a span {
    transform: rotate(180deg);
  }
}
</style>
