export const handleResponse = ({ data: { success, data, totalCount } }) => {
  if (!success) return Promise.resolve({ success: false });
  return Promise.resolve({ success, data, totalCount });
};

export const handleCommitResponse = (
  { data: { success, data, totalCount } },
  commit,
  commitString,
) => {
  if (!success) return Promise.resolve({ success: false });
  commit(commitString, data);
  return Promise.resolve({ success, data, totalCount });
};

export const handleResponseError = ({ response }) => Promise.resolve({ success: false, response });
