<template>
  <transition name="modal__fade">
    <div class="modal__backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="methodModal"
        aria-describedby="methodModal"
        @click.stop
      >
        <method-modal @close="close" :method="method"></method-modal>
      </div>
    </div>
  </transition>
</template>
<script>
import MethodModal from '@/components/methods/MethodModal.vue';

export default {
  name: 'Modal',
  components: {
    MethodModal,
  },
  props: ['method'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';
.modal__backdrop {
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
}

.modal__fade-enter,
.modal__fade-leave-active {
  opacity: 0;
}
.modal__fade-enter-active,
.modal__face-leave-active {
  transition: opacity 0.5s ease-in-out;
}
</style>
