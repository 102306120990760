<template>
  <div class="member-answer-component">
    <div class="member-answer-component" v-if="statsInTime && statsInTime.length">
      <div class="answer-component yes-no" v-if="method.id === 1">
        <div class="flex-global">
          <percentage-bar :percentage="percentage"></percentage-bar>
          <list-chart-filter
            :method="method.id"
            @checkStatus="checkStatus"
            :percentage="percentage"
            v-if="method.id !== 5 && method.id !== 8"
          ></list-chart-filter>
        </div>

        <stats-yes-no :method="filteredStats" :question="question.answerOptions.options"></stats-yes-no>
      </div>
      <div class="answer-component" v-else-if="method.id === 5">
        <!-- <list-chart :answers="filteredStats"></list-chart> -->

        <div class="sentence-display-wrapper">
          <sentence-display
            v-for="(value, index) in filteredStatsReversed"
            :answers="value.value"
            :date="value.date"
            :sentence="question.parts[0].title"
            :key="index"
          />
        </div>
      </div>
      <div class="answer-component" v-else-if="isTextQuestionMethod">
        <div class="sentence-display-wrapper">
          <question-text-display
            v-for="(value, index) in filteredStatsReversed"
            :answer="value.value"
            :date="value.date"
            :key="index"
          />
        </div>
      </div>
      <div class="answer-component" v-else-if="method.id === 8">
        <!-- <percentage-bar :percentage="percentage"></percentage-bar> -->
        <stats-indicator :question="question" :method="filteredStats"></stats-indicator>
      </div>
      <div class="answer-component" v-else>
        <div class="percentage-bar-wrapper"
          :class="{ 'active-calendar': isCalendarStat && showCalendar },"
        >
          <percentage-bar :percentage="percentage"></percentage-bar>
          <div v-if="isCalendarStat" :class="['calendar-switch-wrapper', { 'list': !showCalendar}]">
            <svgicon class="calendar-view-switch calendar-option" :name="showCalendar ? 'calendar-switch' : 'calendar-switch-inactive'" @click="handleCalendarToggle('calendar')" :original="true" :fill="false" height="25px"></svgicon>
            <svgicon class="calendar-view-switch list-option" name="list-switch" @click="handleCalendarToggle('list')" :original="true" :fill="false" height="27px"></svgicon>
          </div>
        </div>
        <calendar-chart v-if="isCalendarStat && showCalendar" :answers="filteredStatsReversed"/>
        <list-chart v-else :answers="filteredStatsReversed"></list-chart>
      </div>
      <list-chart-filter
        :method="method.id"
        @checkStatus="checkStatus"
        :percentage="percentage"
        v-if="method.id !== 5 && method.id !== 8 && method.id !== 1 && !isTextQuestionMethod"
      ></list-chart-filter>
    </div>
    <div v-else class="member-answer-component member-stat-no-data-message flex-global">
      <header-h4>{{ $t("messages.no_data_message")}}</header-h4>
    </div>
  </div>
</template>

<script>
import CalendarChart from '@/components/stats/chart-templates/CalendarChart.vue';
import ListChart from '@/components/stats/chart-templates/ListChart.vue';
import PercentageBar from '@/components/stats/chart-templates/PercentageBar.vue';
import ListChartFilter from '@/components/stats/chart-templates/ListChartFilter.vue';
import StatsYesNo from '@/components/groups/member/stat-templates/StatsYesNo.vue';
import StatsIndicator from '@/components/groups/member/stat-templates/StatsIndicator.vue';
import SentenceDisplay from './SentenceDisplay.vue';
import QuestionTextDisplay from './QuestionTextDisplay.vue';
import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

export default {
  name: 'MemberAnswerTimeItem',
  components: {
    CalendarChart,
    ListChart,
    ListChartFilter,
    PercentageBar,
    StatsYesNo,
    StatsIndicator,
    SentenceDisplay,
    QuestionTextDisplay,
    HeaderH4,
  },
  props: ['statsInTime', 'question', 'method', 'isCalendarStat', 'showCalendar', 'toggleCalendarView'],
  data() {
    return {
      filterTerms: []
    };
  },
  computed: {
    stats() {
      if (!this.statsInTime || !this.statsInTime.length) return false;
      const filteredData = this.statsInTime.map((stat) => {
        const {
          reflection: { date },
          data,
        } = stat;
        const answer = data.find((item) => item.count > 0);
        return {
          date,
          value: (answer ? answer.value : undefined) || 'Praleista',
          color: (answer ? answer.color : undefined) || '#ccd0d9',
          refId: stat.reflectionId,
        };
      });

      return filteredData;
    },
    percentage() {
      if (!this.stats) return false;
      const occurances = {};
      this.stats.forEach((stat) => {
        const amount = this.stats.filter((item) => item.value === stat.value);
        occurances[stat.value] = { count: amount.length, color: stat.color };
      });
      const statArray = Object.entries(occurances).map(([key, value]) => {
        return {
          value: key,
          count: value.count,
          color: value.color,
        };
      });

      const total = statArray.reduce((acc, value) => acc + value.count, 0);

      return statArray.map((stat) => ({
        ...stat,
        percentage: Math.floor((stat.count / total) * 100),
      }));
    },
    filteredStats() {
      if (!this.stats) return false;
      return this.stats.filter((stat) => !this.filterTerms.includes(stat.value));
    },
    filteredStatsReversed() {
      const stats = this.filteredStats;
      return stats.reverse();
    },
    isTextQuestionMethod(){
      return this.method.id === 4 && this.question.answerTemplate === 'text'
    }
  },
  methods: {
    checkStatus({ value, checked }) {
      if (checked) {
        const termIndex = this.filterTerms.findIndex((term) => term === value);
        this.filterTerms.splice(termIndex, 1);
      } else {
        this.filterTerms.push(value);
      }
    },
    handleCalendarToggle(type){
      this.$emit('toggleCalendarView', type);
    }
  },
};
</script>
<style lang="scss">
@import 'mixins';

.sentence-display-wrapper {
  width: 745px;
  margin-left: 20px;
  padding-right: 12px;
  max-height: 422px;
  overflow-y: auto;

  @include handleListChartScrollbar();
}

.percentage-bar-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .percentage-bar{
    width: 290px;
  }
  &.active-calendar{
    .percentage-bar{
      margin-left: 0;
      max-width: initial;
      width: 83%;
    }
  }
  .calendar-switch-wrapper{
    .calendar-view-switch{
      cursor: pointer;
      &.calendar-option{
        position: relative;
        left: 55px;
        stroke: none;
      }
    }
    &.list{
      .calendar-view-switch{
        &.calendar-option{
          rect[width="27"]{
            fill: #fff !important;
            stroke: $violet-3;
            stroke-width: 1.5;
          }
        }
        &.list-option{
          rect{
            fill: $violet-3;
          }
          path{
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>
