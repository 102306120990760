<template>
  <div class="modal" @click.native="toggleModal">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="toggleModal"
            color="#000000"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <div class="service-content">
            <p class="service-content__title">{{ service.title }}</p>
            <!-- <p class="service-content__description">{{ service.description }}</p> -->
            <p class="service-content__description"></p>
            <div
              class="service-content__image"
              :style="{ backgroundImage: `url(${service.imageUrl})` }"
            ></div>
            <div class="service-content__about">
              <p class="title">{{ $t('profile.about_service') }}</p>
              <div class="content">
                <p>{{ service.description }}</p>
              </div>
            </div>
          </div>
          <div class="action-bar">
            <div class="wrapper flex-global">
              <div class="action-bar__content flex-global">
                <div class="price flex-global">
                  <span class="current-price">
                    <span class="currency">€</span>
                    {{ service.isOnSale ? service.salePrice : service.priceBeforeDiscount }}
                  </span>
                  <span v-if="service.isOnSale" class="old-price">
                    <span class="currency">€</span>
                    {{ service.priceBeforeDiscount }}
                  </span>
                  <span class="discount-text flex-global" v-if="service.discountText">
                    {{ `✨${service.discountText}` }}
                  </span>
                </div>
                <!-- <p class="info-text">
                  Informacija, kad apmokėjimas tik vienkartinis. Adipiscing vel diam enim eget
                  lectus arcu nullam duis vitae.
                </p> -->
              </div>
              <div class="action-bar__button">
                <button-4 @click.native="handleAction">
                  {{ $t('actions.add_to_cart_action') }}
                </button-4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'ServiceModal',
    props: ['service'],
    components: {
      Button4,
    },
    data() {
      return {};
    },
    computed: {},
    methods: {
      ...mapActions('services', ['addServiceToCart']),
      toggleModal() {
        this.$emit('handleModalToggle');
      },
      handleAction() {
        this.addServiceToCart(this.service.id).then((res) => {
          const { success } = res;
          if (success) {
            this.toggleModal();
            this.$toast.success(this.$t('warnings.add_to_cart_success_message'));
          } else {
            this.$toast.error(this.$t('warnings.item_already_in_cart_message'));
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    &__modal {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    }
    &__container {
      padding: 0;
      width: 824px;
      height: 574px;
      border-radius: 5px;
    }
    &__close {
      top: 25px;
      right: 25px;
      width: 14px;
      height: 14px;
    }
    .service-content {
      padding: 25px;
      width: 100%;
      overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      color: $grey-9;
      &::-webkit-scrollbar {
        display: none;
      }
      &__title {
        margin-bottom: 57px;
        width: 700px;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
      }
      &__description {
        margin-bottom: 20px;
        width: 680px;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
      }
      &__image {
        margin-bottom: 30px;
        width: 100%;
        height: 281px;
        min-height: 280px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $grey-6;
      }
      &__about {
        .title {
          margin-bottom: 18px;
          font-size: 14px;
          font-weight: 600;
          text-align: left;
        }
        .content {
          text-align: left;
        }
      }
    }
    .action-bar {
      padding: 15px 25px 10px 25px;
      width: 100%;
      min-height: 74px;
      max-height: 74px;
      border-top: 1px solid #ccc;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
      .wrapper {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }
      &__content {
        height: 100%;
        text-align: left;
        .price {
          height: 100%;
          .current-price {
            font-size: 18px;
            font-weight: 600;
            color: #000;
            .currency {
              font-size: 16px;
            }
          }
          .old-price {
            margin-left: 4px;
            font-size: 12px;
            font-weight: 400;
            text-decoration: line-through;
            color: #919191;
            .currency {
              font-size: 10px;
            }
          }
          .discount-text {
            align-self: flex-start;
            margin-left: 5px;
            font-size: 14px;
            font-weight: 600;
            color: #ff2e5d;
          }
        }
        .info-text {
          font-size: 14px;
          font-weight: 400;
          font-style: italic;
          color: #6a6a6a;
        }
      }
      &__button {
        button {
          width: 175px;
          height: 39px;
          background-color: $violet-3;
          &:hover {
            background-color: $violet-4;
          }
        }
      }
    }
  }
</style>
