<template>
  <div class="profile-inner">
    <div class="header-wrapper flex-global">
      <div class="header-wrapper__info flex-global">
        <p class="title">{{ $t('profile.users') }}</p>
        <!-- <p class="info">
          {{ $t('profile.unassigned_licences_message_1') }}
          <span>12</span>
          {{ $t('profile.unassigned_licences_message_2') }}
        </p> -->
        <router-link class="link" :to="{ name: 'StepLicences', params: { isFromOrg: true } }">
          {{ $t('actions.buy_licences_action') }}
        </router-link>
      </div>
      <div class="header-wrapper__action">
        <!-- <button-3 class="import-button" @click.native="handleUserImport">
          {{ $t('actions.import_action') }}
        </button-3> -->
        <button-4 class="new-users-button" @click.native="handleAddNewUsers">
          <svgicon name="add-big" width="13px" height="12px"></svgicon>
          {{ $t('actions.add_users_action') }}
        </button-4>
      </div>
    </div>
    <Table :data="tableData" :columns="tableColumns" :isRowClickable="true" @rowAction="handleRowClick" />
    <user-modal v-if="isModal" :orgId="orgId" :user="activeUser" @handleModalToggle="handleModalToggle"
      @handleAction="handleRemoveUser" @handleEditAction="handleEditUser" />
    <add-users-modal v-if="isAddUsersModal" @handleModalToggle="handleAddUsersModalToggle"
      @handleAction="handleAddUsers" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/tables/Table.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import UserModal from '@/components/user/UserModal.vue';
import AddUsersModal from '@/components/user/AddUsersModal.vue';

export default {
  name: 'OrganizationUsers',
  components: {
    Table,
    Button4,
    UserModal,
    AddUsersModal,
  },
  data() {
    return {
      isModal: false,
      activeUserId: null,
      isAddUsersModal: false,
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('user', ['organizationUserList']),
    orgId() {
      return this.$route.params.orgId && parseInt(this.$route.params.orgId, 10);
    },
    tableData() {
      return this.organizationUserList && this.organizationUserList.length
        ? this.organizationUserList.map((user) => {
          const isValidDate = this.moment(user.lastLoginDate).isValid();
          return {
            id: user.id,
            firstName: user.name,
            lastName: user.lastName,
            email: user.email,
            lastLoggedIn: isValidDate ? user.lastLoginDate : '-',
            role: user.roleTitle,
            // licence: '-',
          };
        })
        : [];
    },
    tableColumns() {
      return [
        {
          label: this.$t('inputs.first_name_cap'),
          key: 'firstName',
        },
        {
          label: this.$t('inputs.last_name_cap'),
          key: 'lastName',
        },
        {
          label: this.$t('inputs.email_address_cap'),
          key: 'email',
        },
        {
          label: this.$t('last_logged_in'),
          key: 'lastLoggedIn',
        },
        {
          label: this.$t('role'),
          key: 'role',
        },
        // {
        //   label: this.$t('licences.assigned_licence'),
        //   key: 'licence',
        // },
      ];
    },
    activeUser() {
      return this.organizationUserList.find((user) => user.id === this.activeUserId) || null;
    },
  },
  methods: {
    ...mapActions('user', [
      'getOrganizationUserList',
      'addUsersToOrganization',
      'removeUserFromOrganization',
      'editOrganizationMember'
    ]),
    handleUserImport() {
      console.log('import');
    },
    handleAddNewUsers() {
      this.toggleAddUserModal();
    },
    handleRowClick(value) {
      this.activeUserId = value;
      this.toggleModal();
    },
    toggleModal() {
      this.isModal = !this.isModal;
    },
    toggleAddUserModal() {
      this.isAddUsersModal = !this.isAddUsersModal;
    },
    handleModalToggle() {
      this.activeUserId = null;
      this.toggleModal();
    },
    handleAddUsersModalToggle() {
      this.toggleAddUserModal();
    },
    handleGetOrganizationUsers() {
      this.getOrganizationUserList(this.orgId);
    },
    handlePageInit() {
      this.handleGetOrganizationUsers();
    },
    handleAddUsers(usersList) {
      const payload = {
        organizationId: this.orgId,
        emails: usersList,
        role: 'member',
      };
      this.addUsersToOrganization(payload).then((res) => {
        const { success, msg } = res;
        if (success) {
          this.toggleAddUserModal();
          this.handleGetOrganizationUsers();
          this.$toast.success(this.$t('warnings.add_users_success_message'));
        }
        if (msg) {
          this.$toast.error(msg);
        }
      });
    },
    handleRemoveUser(payload) {
      this.removeUserFromOrganization({
        organizationId: payload.organizationId,
        clientId: payload.clientId,
      }).then((res) => {
        const { success, msg } = res;
        if (success) {
          this.handleGetOrganizationUsers();
          this.isModal = false;
          this.$toast.success(this.$t('warnings.remove_user_success_message'));
        }
        if (msg) {
          this.$toast.error(msg);
        }
      });
    },
    handleEditUser(user) {
      const payload = {
        organizationId: this.orgId,
        emails: user.email,
        role: user.role,
      };
      this.editOrganizationMember(payload).then((res) => {
        const { success, msg } = res;
        if (success) {
          this.toggleModal();
          this.handleGetOrganizationUsers();
          this.$toast.success(this.$t('warnings.member_updated_success_message'));
        }
        if (msg) {
          this.$toast.error(msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 28px;

  &__info {
    .title {
      font-size: 16px;
      font-weight: 600;
      color: $grey-9;
    }

    .info {
      margin-left: 24px;
      font-size: 14px;
      font-weight: 500;
      color: #686868;

      span {
        font-weight: 600;
      }
    }

    .link {
      margin-left: 37px;
      font-size: 14px;
      font-weight: 400;
      color: $blue-5;
    }
  }

  &__action {
    margin-left: 5px;

    button {
      padding: 5px 38px;
      height: 40px;

      &.import-button {
        margin-right: 18px;
      }

      &.new-users-button {
        background-color: $violet-3;

        svg {
          margin-right: 5px;
          margin-bottom: 1px;
        }

        &:hover {
          background: $violet-5;
        }
      }
    }
  }

  @media screen and (max-width: 1226px) {
    &__action {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
</style>
