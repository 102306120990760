<template>
  <div class="group-confirm__page" v-if="!loading">
    <section class="group-confirm">
      <div v-if="creation" class="group-confirm__header">
        <svgicon name="certified" :original="true" height="2.2em"></svgicon>
        <header-h2>{{ $t("group.group_creation_success_message") }}</header-h2>
      </div>
      <div v-else class="group-confirm__header">
        <header-h1>{{ $t("group.group_login_settings") }}</header-h1>
      </div>
      <div class="group-confirm__code">
        <h4>{{ $t("group.login_token") }}</h4>
        <h1 v-if="currentGroup.token && tokenValid">{{ currentGroup.token }}</h1>
        <h1 v-else>{{ $t("group.group_inactive_token") }}</h1>
        <p v-if="currentGroup.token && tokenValid">{{ $t("group.group_share_message") }}</p>
      </div>
      <div class="group-confirm__toggle">
        <span>{{ $t("inactive") }}</span>
        <toggle
          class="group-confirm__toggle__switch"
          :boxValue="'activeToken'"
          :checked="active"
          @checkStatus="checkStatus"
        ></toggle>
        <span>{{ $t("active") }}</span>
      </div>
      <div class="group-confirm__info" v-if="active">
        <span>{{ $t("group.group_token_active") }}: </span>
        <vue-countdown class="group-confirm__info__time-remaining" :time="timeRemaining">
          <template
            slot-scope="props"
          >{{ props.days }}d {{ props.hours }}h {{ props.minutes }}min {{ props.seconds }}sec</template>
        </vue-countdown>
        <span> ({{ $t("until") }} {{ moment(currentGroup.tokenValidationDate).format('LLLL') }})</span>
        <p class="extra-info-paragraph">{{ $t("group.group_token_extra_info_active") }}</p>
      </div>
      <date-time-picker
        class="group-confirm__date-time-picker custom-position"
        v-if="active"
        v-model="selectedDate"
        :no-value-to-custom-elem="true"
        :locale="currentLocale"
        :overlay="true"
        :format="'YYYY-MM-DDTHH:mm'"
        :no-button-now="true"
        :color="'#11ac9a'"
        :min-date="moment(currentGroup.tokenValidationDate).format('YYYY-MM-DDTHH:mm')"
        @validate="handleDateSelect"
      >
        <button-5 class="group-confirm__action">{{ $t("group.group_change_token_date") }}</button-5>
      </date-time-picker>
      <p class="extra-info-paragraph not-active" v-else>{{ $t("group.group_token_extra_info_inactive") }}</p>
      <div class="group-confirm__actions">
        <button-3 @click.native="clickHandler">{{ $t("actions.cancel_action") }}</button-3>
        <button-4 @click.native="clickHandler">{{ $t("actions.save_action") }}</button-4>
      </div>
    </section>
    <div class="modal" @click="toggleModal" v-if="datepickerModal">
      <div class="modal__bg">
        <div class="modal__modal" @click.stop>
          <div class="modal__container">
            <svgicon
              @click="toggleModal"
              color="grey"
              :fill="false"
              name="multiply"
              class="modal__close"
            ></svgicon>
            <svgicon class="modal__container__icon" name="large-check" :original="true"></svgicon>
            <h4 class="modal__container__heading">{{ $t("group.group_token_update_success_message") }}</h4>
            <div class="modal__container__actions">
              <button-3 @click.native="toggleModal">{{ $t("actions.ok_action") }}</button-3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import VueCountdown from '@chenfengyuan/vue-countdown';
import { lt } from 'vuejs-datepicker/dist/locale';
import DateTimePicker from 'vue-ctk-date-time-picker';

import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Toggle from '@/components/elements/Toggle.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import Button5 from '@/components/elements/buttons/Button5.vue';

export default {
  name: 'GroupToken',
  components: {
    HeaderH1,
    HeaderH2,
    Toggle,
    Button3,
    Button4,
    Button5,
    VueCountdown,
    DateTimePicker,
  },
  data() {
    return {
      language: lt,
      datepickerModal: false,
      selectedDate: this.moment().format('YYYY-MM-DDTHH:mm'),
      loading: true,
      currentLocale: localStorage.getItem('locale')
    };
  },
  created() {
    const {
      params: { groupId },
    } = this.$route;
    this.getGroup(groupId).then((res) => {
      const { success } = res;
      this.loading = false;
      if (!success) {
        this.$router.push({ name: 'Group', params: { groupId } });
      }
    });
  },
  computed: {
    ...mapGetters('groups', ['groupList', 'currentGroup']),
    active() {
      return this.timeRemaining > 0;
    },
    timeRemaining() {
      const now = this.moment();
      const then = this.moment(this.currentGroup.tokenValidationDate);
      const difference = this.moment.utc(then).diff(now);
      return difference > 0 ? difference : 0;
    },
    creation() {
      const { creation } = this.$route.params;
      return creation || false;
    },
    tokenValid() {
      const { tokenValidationDate } = this.currentGroup;
      const today = this.moment();
      const expiryDate = this.moment(tokenValidationDate);
      return expiryDate.isAfter(today);
    },
  },
  methods: {
    ...mapActions('groups', ['getGroup', 'getToken', 'removeToken', 'updateGroup']),
    checkStatus(value) {
      const { checked } = value;
      if (checked) {
        this.getToken();
      } else {
        this.removeToken();
      }
    },
    clickHandler() {
      const { id } = this.currentGroup;
      this.$router.push({ name: 'Group', params: { groupId: id } });
    },
    handleTokenUpdate() {
      const { groupId: id } = this.$route.params;
      const payload = {
        id,
        tokenValidationDate: this.moment(this.selectedDate).format('YYYY-MM-DDTHH:mm:ss'),
      };
      this.updateGroup(payload).then(() => this.toggleModal());
    },
    handleDateSelect() {
      this.handleTokenUpdate();
    },
    toggleModal() {
      this.datepickerModal = !this.datepickerModal;
    },
    handleDate(value) {
      this.selectedDate = this.moment(value)
        .add(20, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss');
    },
  },
};
</script>
<style lang="scss">
.group-confirm {
  &__toggle {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
    }

    &__switch {
      margin: 0 10px;
    }
  }

  &__info {
    margin-top: 12px;
    font-size: 14px;

    &__time-remaining {
      font-family: Roboto-Medium;
    }
  }

  &__action {
    margin-top: 10px;
    margin: 10px auto 0 auto;
    display: block;
  }

  &__actions {
    margin-top: 20px;

    button {
      &:last-of-type {
        margin-left: 5px;
      }
    }
  }

  &__date-time-picker {
    max-width: 400px;
    margin: 0 auto;
    &.custom-position {
      .datetimepicker {
        .datepicker {
          top: -280px !important;
          min-height: 446px;
        }
      }
    }
  }
  .extra-info-paragraph{
    margin-top: 15px;
  }
}
</style>
