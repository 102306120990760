<template>
  <div class="modal" @click.native="toggleModal">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="toggleModal"
            color="#000000"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <div class="content">
            <div class="content__header flex-global">
              <p>{{ $t('actions.add_users_action') }}</p>
              <p class="subtitle">
                {{ $t('warnings.add_user_info_subtitle') }}
              </p>
            </div>
            <div class="content__input">
              <input
                :class="{ error }"
                type="email"
                multiple
                v-model="value"
                :placeholder="$t('inputs.user_emails_placeholder')"
              />
              <p>{{ errorMessage }}</p>
            </div>
            <div class="content__buttons flex-global">
              <button-3 class="cancel" @click.native="toggleModal">
                {{ $t('actions.cancel_action') }}
              </button-3>
              <button-4 class="save" @click.native="handleAction">
                {{ $t('actions.add_action') }}
              </button-4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'AddUsersModal',
    components: {
      Button3,
      Button4,
    },
    data() {
      return {
        value: '',
        error: false,
        errorMessage: '',
      };
    },
    created() {},
    computed: {},
    methods: {
      toggleModal() {
        this.$emit('handleModalToggle');
      },
      handleAction() {
        if (!this.value) {
          this.error = true;
          this.errorMessage = this.$t('warnings.field_is_required_warning');
        } else {
          this.error = false;
          this.errorMessage = '';
          this.$emit('handleAction', this.value);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    &__modal {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    }
    &__close {
      top: 25px;
      right: 25px;
      width: 14px;
      height: 14px;
    }
    &__container {
      padding: 22px 27px;
      padding-bottom: 44px;
      width: 674px;
      border-radius: 5px;
      .content {
        width: 100%;
        &__header {
          width: 100%;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 20px;
          p {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            color: $grey-9;
            &.subtitle {
              margin-top: 10px;
              width: 595px;
              font-size: 14px;
              font-weight: 300;
              line-height: 16px;
              color: #888888;
            }
          }
        }
        &__input {
          input {
            width: 100%;
            height: 33px;
            padding-left: 5px;
            font-family: 'Nunito-Regular';
            font-size: 14px;
            border: 1px solid $grey-3;
            border-radius: 2px;
            &.error {
              border: 1px solid $red-4;
            }
            &::placeholder {
              color: #999;
            }
          }
          p {
            margin-top: 2px;
            width: 100%;
            font-size: 14px;
            text-align: left;
            color: $red-4;
          }
        }
        &__buttons {
          margin-top: 30px;
          width: 100%;
          justify-content: flex-end;
          button {
            height: 40px;
            &.cancel {
              width: 145px;
            }
            &.save {
              width: 202px;
              margin-left: 18px;
            }
          }
        }
      }
    }
  }
</style>
