<template>
  <div
    :class="['answer-component__container', { hidden: !expandedAnswer }]"
    v-if="groupStatInTime.length"
  >
    <line-template
      :filteredQuestionInTime="preparedInTimeStatArray"
      :questionInTimeDates="questionInTimeDates"
      :specificChartOptions="scaleChartOptions"
    ></line-template>
    <stat-value-in-time
      @checkStats="handleInTimeFilter"
      :filteredQuestionInTime="parsedQuestionInTime"
    ></stat-value-in-time>
  </div>
  <div class="answer-component__container no-stats-message-wrapper" v-else>
    <h4 class="no-stats-message">{{ $t('messages.no_data_message') }}</h4>
  </div>
</template>

<script>
  import LineTemplate from '@/components/stats/group-stats/LineTemplate.vue';
  import StatValueInTime from '@/components/stats/chart-templates/StatValueInTime.vue';

  export default {
    name: 'StatComponentsInTime',
    components: {
      LineTemplate,
      StatValueInTime,
    },
    props: ['groupStatInTime', 'question', 'questionMethod', 'expanded'],
    data() {
      return {
        expandedAnswer: this.expanded,
        inTimeStatArray: [],
        inTimeStatArrayTooltip: [],
        filterTerms: [],
        // rf-373 disable median and mode by default
        valuesToFilter: [this.$t('median'), this.$t('mode')],
        labels: [
          { label: 'skipped', title: this.$t('member_skipped'), color: '#AC92ED' },
          { label: 'average', title: this.$t('average'), color: '#48CFAE' },
          { label: 'median', title: this.$t('median'), color: '#FB6E52' },
          { label: 'mode', title: this.$t('mode'), color: '#656D78' },
          { label: 'noReflection', title: this.$t('member_did_not_reflect'), color: '#EC87BF' },
        ],
        newData: [],
        array: [],
        numbers: [],
        dates: [],
        skipped: [],
        skippedEmpty: [],
        average: [],
        median: [],
        mode: [],
        noReflection: [],
        noReflectionEmpty: [],
        currentlyActiveFilters: [],
      };
    },
    computed: {
      computedLabels() {
        // rf-389 hide skipped and no reflection labels for scale stats in time
        if(this.isScaleMethod) {
          return this.labels.filter(label => label.label !== 'skipped' && label.label !== 'noReflection');
        }
        return this.labels;
      },
      preparedInTimeStatArray() {
        const preparedStatValues = this.inTimeStatArray.filter((statValue) => {
          return this.currentlyActiveFilters.length
            ? this.currentlyActiveFilters.some((filterValue) => {
                return filterValue === statValue.value;
              })
            : [];
        });
        return preparedStatValues;
      },
      filteredQuestionInTime() {
        if (!this.groupStatInTime.length) return false;
        const answerArray = this.groupStatInTime
          .map((reflection) =>
            reflection.data.map((data) => ({
              value: data.value,
              count: data.count,
              color: data.color,
            }))
          )
          .flat();

        return answerArray;
      },
      parsedQuestionInTime() {
        if (!this.filteredQuestionInTime.length) return false;
        const answerObject = {};
        this.filteredQuestionInTime.forEach((item) => {
          if (!answerObject[item.value]) answerObject[item.value] = [];
          answerObject[item.value].push(item.count);
        });

        return this.handleValueParse(answerObject);
      },
      questionInTimeDates() {
        if (!this.groupStatInTime.length) return false;
        return this.groupStatInTime.map((groupStat) => groupStat.reflection.date);
      },
      calculations() {
        return {
          skipped: this.skipped,
          average: this.average,
          median: this.median,
          mode: this.mode,
          noReflection: this.noReflection,
        };
      },
      calculationsEmpty() {
        return {
          skipped: this.skippedEmpty,
          average: this.average,
          median: this.median,
          mode: this.mode,
          noReflection: this.noReflectionEmpty,
        };
      },
      scaleChartOptions() {
        let options = null;
        const realData = this.inTimeStatArrayTooltip;
        if (this.questionMethod.id === 8) {
          options = {
            responsive: true,
            maintainAspectRatio: false,
            height: 'inherit',
            legend: false,
            tension: 0,
            tooltips: {
              callbacks: {
                label(t) {
                  const value = realData[t.datasetIndex].percentage[t.index];
                  return `${value}`;
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    color: 'transparent',
                    zeroLineColor: '#ccc',
                    zeroLineWidth: 1,
                  },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: parseInt(this.question.answerOptions.max, 10),
                  },
                },
              ],
            },
          };
        }
        return options;
      },
      isScaleMethod() {
        return this.question.methodId === 8
      },
      scaleMethodType() {
        return this.isScaleMethod ? this.question.answerOptions.numberType : null;
      },
    },
    methods: {
      getData() {
        this.groupStatInTime.forEach((stat) => {
          this.newData.push({ date: stat.reflection.date, values: stat.data });
        });

        let tempArray = [];

        this.newData.forEach((element) => {
          element.values.map((value) =>
            tempArray.push({ actualValue: value.value, actualCount: value.count })
          );

          this.array.push({ date: element.date, numbers: tempArray });
          tempArray = [];
        });
      },
      calculate() {
        let tempArray = [];

        this.array.forEach((element) => {
          element.numbers.map((number) => {
            if (Number.isNaN(number.actualValue.replace(',', '.') * number.actualCount)) {
              if (number.actualValue === 'Praleista') {
                this.skipped.push(number.actualCount);
                this.skippedEmpty.push(0);
              } else if (number.actualValue === 'Nereflektavo') {
                this.noReflection.push(number.actualCount);
                this.noReflectionEmpty.push(0);
              }
              //
            } else if (number.actualValue.replace(',', '.') * number.actualCount === 0) {
              //
            } else {
              for (let x = 0; x < number.actualCount; x += 1) {
                const dataValue = parseFloat(number.actualValue.replace(',', '.'), 10);
                tempArray.push(dataValue);
              }
            }
            return number;
          });
          this.numbers.push({ date: element.date, array: tempArray });
          tempArray = [];
        });
        this.getAverage();
        this.getMedian();
        this.getMode();
      },
      getAverage() {
        const roundType = this.scaleMethodType === 'hundredth' ? 100 : 10;
        this.numbers.forEach((element) => {
          const total = element.array.reduce((a, b) => a + b, 0);
          const average = Math.round((total / element.array.length) * roundType) / roundType;
          if (Number.isNaN(average)) {
            this.average.push(0);
          } else {
            this.average.push(average);
          }
          this.dates.push(element.date);
        });
      },
      getMedian() {
        this.numbers.forEach((element) => {
          const arr = element.array.slice().sort((a, b) => a - b);
          const half = Math.floor(arr.length / 2);

          if (arr.length % 2) {
            if (Number.isNaN(arr[half])) {
              this.median.push(0);
            } else {
              this.median.push(arr[half]);
            }
          } else if (Number.isNaN((arr[half - 1] + arr[half]) / 2.0)) {
            this.median.push(0);
          } else {
            this.median.push((arr[half - 1] + arr[half]) / 2.0);
          }
        });
      },
      getMode() {
        this.numbers.forEach((element) => {
          const arr = element.array.slice();
          this.mode.push(
            arr
              .sort((a, b) => arr.filter((v) => v === a).length - arr.filter((v) => v === b).length)
              .pop()
          );
        });
      },
      handleValueParse(answerObject) {
        let convertedArray = [];
        let convertedArrayTooltip = [];
        if (this.questionMethod.id === 8) {
          this.getData();
          this.calculate();

          convertedArray = this.computedLabels.map((label) => {
            return {
              value: label.title,
              count: this.calculationsEmpty[label.label],
              percentage: this.calculationsEmpty[label.label],
              color: label.color,
            };
          });

          convertedArrayTooltip = this.computedLabels.map((label) => {
            return {
              value: label.title,
              count: this.calculations[label.label],
              percentage: this.calculations[label.label],
              color: label.color,
            };
          });
        } else {
          convertedArray = Object.entries(answerObject).map(([key, value]) => {
            const question = this.filteredQuestionInTime.find((answer) => answer.value === key);
            return {
              value: key,
              count: value,
              percentage: value.map((count, index) =>
                Math.floor((100 / this.calcTotal(index)) * count)
              ),
              color: question ? question.color : 'orangered',
            };
          });
        }

        // rf-373 disable median and mode by default
        this.inTimeStatArray = convertedArray.filter(
          (value) => value.value !== this.$t('median') && value.value !== this.$t('mode')
        );
        this.inTimeStatArrayTooltip = convertedArrayTooltip.filter(
          (value) => value.value !== this.$t('median') && value.value !== this.$t('mode')
        );

        return convertedArray;
      },
      calcTotal(index) {
        // const calcTotal = this.groupStatInTime[index].data;

        const groupStatInTimeData = JSON.parse(JSON.stringify(this.groupStatInTime[index].data));

        // recalculate total member count with only current selected filters
        const calcTotal = groupStatInTimeData.filter((statValue) => {
          return this.currentlyActiveFilters.length
            ? this.currentlyActiveFilters.some((filterValue) => {
                return filterValue === statValue.value;
              })
            : [];
        });

        const total = calcTotal.reduce((sum, item) => sum + item.count, 0);
        return total;
      },
      handleInTimeFilter({ value }) {
        // recalculate total members here
        // this.handleValueParse(answerObject);

        // this.recalculateStats();

        // updates valuesToFilter
        const isFound = this.valuesToFilter.findIndex((inFilter) => inFilter === value);
        if (isFound > -1) {
          this.valuesToFilter.splice(isFound, 1);
        } else this.valuesToFilter.push(value);

        this.inTimeStatArray = this.parsedQuestionInTime.filter((stat) => {
          const valueIndex = this.valuesToFilter.findIndex((value) => value === stat.value);
          if (valueIndex > -1) return false;
          return true;
        });

        // update currently active filters here
        const currentSelectedStatValues = JSON.parse(JSON.stringify(this.inTimeStatArray));
        this.currentlyActiveFilters = currentSelectedStatValues.map((value) => value.value);

        if (this.questionMethod.id === 8) {
          const convertedArrayTooltip = this.computedLabels.map((label) => {
            return {
              value: label.title,
              count: this.calculations[label.label],
              percentage: this.calculations[label.label],
              color: label.color,
            };
          });
          this.inTimeStatArrayTooltip = convertedArrayTooltip.filter((stat) => {
            const valueIndex = this.valuesToFilter.findIndex((value) => value === stat.value);
            if (valueIndex > -1) return false;
            return true;
          });
        }
      },
      checkStatus({ value, checked }) {
        if (checked) {
          const termIndex = this.filterTerms.findIndex((term) => term.value);
          this.filterTerms.splice(termIndex, 1);
        } else {
          this.filterTerms.push(value);
        }
      },
      // recalculateStats() {
      //   if (!this.filteredQuestionInTime.length) return false;
      //   const answerObject = {};
      //   this.filteredQuestionInTime.forEach((item) => {
      //     if (!answerObject[item.value]) answerObject[item.value] = [];
      //     answerObject[item.value].push(item.count);
      //   });

      //   return this.handleValueParse(answerObject);
      // }
    },
    beforeDestroy() {
      this.expandedAnswer = true;
    },
    watch: {
      expanded(newVal) {
        this.expandedAnswer = newVal;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .hidden {
    display: none;
  }
</style>
