var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home__page"},[_c('calendar-header',{attrs:{"date":_vm.moment().format('MMMM YYYY')}}),_c('section',{staticClass:"home",attrs:{"id":"home"}},[(_vm.loading || _vm.welcome)?_c('div',{staticClass:"home__calendar"},[_c('transition-group',{attrs:{"tag":"div","name":"calendar-transition"}},_vm._l((5),function(n,i){return _c('calendar-day-empty',{key:_vm.moment(_vm.currentDate)
              .startOf('isoWeek')
              .add(i, 'days')
              .format('dddd DD'),attrs:{"day":_vm.moment(_vm.currentDate)
              .startOf('isoWeek')
              .add(i, 'days')
              .format('dddd DD'),"empty":true,"mockReflections":_vm.mockReflections[i]}})}),1),(!_vm.welcome)?_c('welcome',{attrs:{"loading":true}}):_c('welcome')],1):(!_vm.reflectionList.length)?_c('div',[_c('div',{staticClass:"home__calendar"},[_c('transition-group',{attrs:{"tag":"div","name":"calendar-transition"}},_vm._l((5),function(n,i){return _c('calendar-day-empty',{key:_vm.moment(_vm.currentDate)
                .startOf('isoWeek')
                .add(i, 'days')
                .format('dddd DD'),attrs:{"day":_vm.moment(_vm.currentDate)
                .startOf('isoWeek')
                .add(i, 'days')
                .format('dddd DD'),"empty":true,"mockReflections":_vm.mockReflections[i]}})}),1)],1),_c('welcome',{attrs:{"noReflections":_vm.reflectionsByDay.length}})],1):_c('div',{staticClass:"home__calendar"},[_c('transition-group',{attrs:{"tag":"div","name":"calendar-transition"}},_vm._l((_vm.reflectionsByDay),function(day,index){return _c('calendar-day',{key:_vm.moment(_vm.currentDate)
              .startOf('isoWeek')
              .add(index, 'days')
              .format('dddd DD'),attrs:{"weekend":day.weekend,"day":_vm.moment(_vm.currentDate)
              .startOf('isoWeek')
              .add(index, 'days')
              .format('dddd DD'),"reflections":day.reflections.length},scopedSlots:_vm._u([{key:"day",fn:function(){return [_vm._v(_vm._s(day.day))]},proxy:true},{key:"reflection",fn:function(){return _vm._l((day.reflections),function(reflection,index){return _c('calendar-reflection',{key:index,attrs:{"viewed":_vm.moment().isAfter(reflection.start, 'day'),"complete":reflection.status !== 'pending',"current":_vm.moment().isSame(reflection.start, 'day'),"data":reflection,"progress":reflection.progress,"reflectionData":reflection},scopedSlots:_vm._u([{key:"time",fn:function(){return [_vm._v(_vm._s(_vm.moment(reflection.start).format('HH:mm')))]},proxy:true},{key:"class",fn:function(){return [_vm._v(_vm._s(reflection.clientGroup.title))]},proxy:true}],null,true)})})},proxy:true}],null,true)})}),1),(_vm.loading)?_c('welcome',{attrs:{"loading":true}}):_vm._e()],1)]),_c('register-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.registerModal),expression:"registerModal"}]}),(_vm.welcome && _vm.showOnboarding && !_vm.registerModal)?_c('onboarding-modal',{on:{"handleOnboardingModal":function($event){return _vm.handleOnboardingModal()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }