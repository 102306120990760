<template>
  <div>
    <div class="reflection-options__option-header">
      <h3 class="reflection-options__option-date">
        {{ $t('schedule.repeat_type_recurrent_title_two') }}
      </h3>
      <div class="reflection-options__option-events flex-global">
        <div
          class="reflection-options__edit"
          v-if="(!schedule.active || (schedule.active && allowToDelete)) && !editStatus"
          @click="handleScheduleEdit"
        >
          <svgicon
            class="reflection-options__edit__icon"
            name="edit-button"
            :fill="false"
            :original="true"
            v-tooltip="$t('actions.edit_schedule_time_action')"
          ></svgicon>
        </div>
        <div
          class="reflection-options__delete"
          v-if="!schedule.active || (schedule.active && allowToDelete)"
          @click="handleScheduleRemove(i, schedule)"
        >
          <svgicon
            class="reflection-options__delete__icon"
            name="delete"
            :original="true"
            v-tooltip="$t('actions.remove_schedule_time_action')"
          ></svgicon>
        </div>
      </div>
    </div>
    <options-schedule-steps v-if="editStatus" :steps="editSteps" :currentStep="currentEditStep" />
    <div v-if="editStatus">
      <div v-if="currentEditStep === 0">
        <span class="grey-explanation-text">{{ $t('schedule.select_week_days_exp') }}</span>
        <div class="reflection-options__row">
          <div class="reflection-options__col">
            <div class="flex-global week-day-wrapper header">
              <div class="flex-global checkbox-wrapper">
                <span class="reflection-options__option-title">{{ $t('schedule.week_days') }}</span>
              </div>
              <div class="flex-global date-wrapper">
                <span class="reflection-options__option-title">{{ $t('schedule.times') }}</span>
              </div>
            </div>
            <div class="flex-global week-day-wrapper" v-for="(day, index) in weekDays" :key="index">
              <div class="flex-global checkbox-wrapper">
                <checkbox
                  :boxValue="day.value"
                  :checked="day.checked"
                  @checkStatus="checkAnswerType"
                ></checkbox>
                <span>{{ day.display }}</span>
              </div>
              <div class="flex-global date-wrapper">
                <span
                  class="week-time-picker-input-wrapper"
                  :class="['week-time-picker-input-wrapper', { 'no-margin': !schedule.time }]"
                  v-for="(schedule, index) in day.times"
                  :key="`${index}-time-${schedule.time}`"
                >
                  <vue-timepicker
                    v-if="schedule.time"
                    v-model="schedule.time"
                    :format="'HH:mm'"
                    class="week-time-picker-input"
                    auto-scroll
                    lazy
                  ></vue-timepicker>
                  <!-- <date-time-picker
                    class="reflection-options__input reflection-options__input--time-picker"
                    v-model="schedule.time"
                    :format="'HH:mm'"
                    :only-time="true"
                    :no-value-to-custom-elem="true"
                    :color="'#11ac9a'"
                    :overlay="false"
                    :class="{ 'input-error-active': !isValidDateTime && isScheduleChecked }"
                    :id="`${index}-time-${schedule.time}`"
                  >
                    <input
                      type="time"
                      class="reflection-options__input__time-picker"
                      v-model="schedule.time"
                      @input="handleTimeInput"
                      placeholder="HH:mm"
                      @click="(e) => e.preventDefault()"
                    />
                  </date-time-picker> -->
                </span>
                <div
                  class="add-schedule-button"
                  v-if="day.checked"
                  @click="handleAddOneChildSchedule(day.value)"
                >
                  + {{ $t('schedule.add_another_time') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reflection-options__row repeat-input-wrapper">
          <span class="grey-explanation-text">{{ $t('schedule.repeat_schedule_exp') }}</span>
          <div class="reflection-options__inputs reflection-options__inputs--weeks">
            <multi-select
              :id="`weekStepSelect${i}`"
              :key="`weekStepSelect${i}`"
              :options="weekStep"
              :placeholder="`${$t('select_option_placeholder')}`"
              :width="80"
              :selected="schedule.weekStep"
              @selectedValue="handleWeekStepSelect"
            ></multi-select>
          </div>
          <span class="grey-explanation-text weeks-text">
            {{ $t('schedule.schedule_repeat_display_week_exp') }}.
          </span>
        </div>
      </div>
      <div v-if="currentEditStep === 1">
        <span class="grey-explanation-text">{{ $t('schedule.select_duration_exp') }}</span>
        <div class="reflection-options__row">
          <div class="reflection-options__col">
            <span class="reflection-options__option-title">{{ $t('duration') }}</span>
            <div class="reflection-options__inputs">
              <input
                class="reflection-options__input reflection-options__input--small"
                type="number"
                :value="schedule.duration"
                :id="`duration${i}`"
                :name="`duration${i}`"
                @input="handleDuration($event, i)"
                min="1"
              />
              <multi-select
                :id="`durationTypeSelect${i}`"
                :key="`durationTypeSelect${i}`"
                :options="reflectionDuration"
                :placeholder="`- ${$t('select_option_placeholder')} -`"
                :width="180"
                :selected="durationType"
                @selectedValue="handleDurationSelect"
              ></multi-select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentEditStep === 2">
        <span class="grey-explanation-text">{{ $t('schedule.select_start_and_end_exp') }}</span>
        <div class="reflection-options__row duration-input-wrapper">
          <div class="reflection-options__col max-width-input">
            <span class="reflection-options__option-title">
              {{ $t('schedule.start_of_reflection') }}
            </span>
            <div class="reflection-options__inputs">
              <vue-datepicker
                :monday-first="true"
                :bootstrap-styling="true"
                :full-month-name="true"
                :language="currentCalendarLocale.locale"
                :placeholder="'YYYY-mm-dd'"
                :format="customFormatter"
                @selected="handleDate($event, i, 'fromDate')"
                :value="schedule.fromDate"
                :disabled-dates="disabledFromDates"
                :class="{ 'empty-date-input': !isValidDateTime && isScheduleChecked }"
              ></vue-datepicker>
            </div>
            <p class="schedule-error-message" v-if="!isValidDateTime && isScheduleChecked">
              {{ $t('modals.options_modal_start_time_past_warning') }}
            </p>
          </div>
          <div class="arrow-icon-wrapper flex-global">
            <svgicon :fill="false" name="arrow-right" width="15px" height="10px"></svgicon>
          </div>
          <div class="reflection-options__col max-width-input">
            <span class="reflection-options__option-title">
              {{ $t('schedule.end_of_reflection') }}
            </span>
            <div class="reflection-options__inputs">
              <vue-datepicker
                :monday-first="true"
                :bootstrap-styling="true"
                :full-month-name="true"
                :language="currentCalendarLocale.locale"
                :placeholder="'YYYY-mm-dd'"
                :format="customFormatter"
                @selected="handleDate($event, i, 'toDate')"
                :disabled-dates="disabledToDates"
                :value="schedule.toDate"
                :class="{ 'empty-date-input': schedule.toDateEmpty && isScheduleChecked }"
              ></vue-datepicker>
            </div>
            <p class="schedule-error-message" v-if="schedule.toDateEmpty && isScheduleChecked">
              {{ $t('modals.options_modal_no_end_time') }}
            </p>
            <p
              class="schedule-error-message"
              v-if="schedule.repeatType === 'custom' && noWeekDaysSelected && isScheduleChecked"
            >
              {{ $t('modals.options_modal_no_weekdays_warning') }}
            </p>
          </div>
        </div>
        <span class="grey-explanation-text flex-global duration-info-text">
          <svgicon name="blue-info-circle" :original="true" width="17px" height="18px"></svgicon>
          {{ $t('schedule.start_and_end_schedule_exp') }}
        </span>
      </div>
      <div v-if="currentEditStep === 3">
        <span class="grey-explanation-text">{{ $t('schedule.skip_dates_exp') }}</span>
        <div class="reflection-options__row">
          <div class="reflection-options__col">
            <div class="skip-calendar-wrapper">
              <vc-calendar
                is-expanded
                :locale="currentLocale"
                :attributes="skipAttributes"
                :min-date="today"
                :disabled-dates="{
                  weekdays: disabledSkipDates.days.map((day) => day + 1),
                }"
                @dayclick="onSkipDayClick"
              />
            </div>

            <div class="skip-dates-display-wrapper flex-global">
              <date-chip
                v-for="skipDate in disabledSkipDates.dates"
                :key="`skip--${skipDate}`"
                :value="skipDate"
                :isDate="true"
                @removeDate="handleSkipDateRemove"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reflection-options__step-buttons flex-global" v-if="editStatus">
      <button-3 v-if="currentEditStep > 0" @click.native="handleStepChange(-1)">
        {{ $t('back') }}
      </button-3>
      <button-4
        v-if="currentEditStep > 0 && currentEditStep === editSteps.length - 1"
        @click.native="handleScheduleConfirm"
      >
        {{ $t('actions.create_schedule_action') }}
      </button-4>
      <button-4 v-else @click.native="handleStepChange(1)" :disabled="disableNextStepButton">
        {{ $t('next') }}
      </button-4>
    </div>
    <options-data-display
      v-else
      :type="'custom'"
      :schedule="schedule"
      :durationValues="reflectionDuration"
      :weekDays="weekDays"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { lt, en } from 'vuejs-datepicker/dist/locale';
  import VueDatepicker from 'vuejs-datepicker';
  import VueTimepicker from 'vue2-timepicker';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Checkbox from '@/components/elements/Checkbox.vue';
  import DateChip from '@/components/elements/DateChip.vue';

  import MultiSelect from '@/components/elements/select/MultiSelect.vue';
  import OptionsDataDisplay from '@/components/reflections/options/OptionsDataDisplay.vue';
  import OptionsScheduleSteps from '@/components/reflections/options/OptionsScheduleSteps.vue';

  export default {
    name: 'OptionsInnerCustom',
    components: {
      VueDatepicker,
      MultiSelect,
      VueTimepicker,
      OptionsDataDisplay,
      OptionsScheduleSteps,
      Button3,
      Button4,
      Checkbox,
      DateChip,
    },
    props: ['i', 'currentScheduleType', 'isScheduleChecked'],
    created() {
      this.skipDaysValue = [];
      const { skip, repeatType, weekDays } = this.schedule;
      skip.forEach((date) => {
        this.disabledSkipDates.dates.push(this.moment(date).toDate());
        this.skipDaysValue.push({
          date,
          id: date,
        });
      });

      if (repeatType === 'once') {
        this.disabledToDates.days = [];
        this.disabledSkipDates.days = [];
        this.disabledToDates.from = null;
        this.disabledToDates.to = null;
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = null;
      } else if (this.schedule.repeatType === 'daily') {
        this.disabledToDates.days = [];
        this.disabledSkipDates.days = [];
        this.disabledToDates.from = null;
        this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
      } else if (this.schedule.repeatType === 'custom') {
        weekDays.forEach((day) => {
          if (day === 7) day = 0;
          const dayIndex = this.disabledToDates.days.findIndex((i) => i === day);
          this.disabledToDates.days.splice(dayIndex, 1);
          this.disabledSkipDates.days.splice(dayIndex, 1);
        });
        this.weekDays.forEach((day) => {
          this.childSchedules.forEach((childSchedule) => {
            const childScheduleFound = childSchedule.weekDays.find(
              (weekDay) => weekDay === day.value
            );
            if (childScheduleFound) {
              day.times.push(childSchedule);
              day.checked = true;
            }
          });
        });
        this.disabledToDates.from = null;
        this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
      }
    },
    data() {
      return {
        today: new Date(),
        time: null,
        reflectionDuration: [
          { value: this.$t('schedule.duration_minutes'), type: 'minutes' },
          { value: this.$t('schedule.duration_hours'), type: 'hours' },
          { value: this.$t('schedule.duration_days'), type: 'days' },
        ],
        reflectionRepeat: [
          { value: this.$t('schedule.repeat_type_once'), type: 'once' },
          { value: this.$t('schedule.repeat_type_daily'), type: 'daily' },
          { value: this.$t('schedule.repeat_type_custom'), type: 'custom' },
        ],
        weekStep: [1, 2, 3, 4],
        weekDays: [
          {
            value: 1,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_monday_cap'),
          },
          {
            value: 2,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_tuesday_cap'),
          },
          {
            value: 3,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_wednesday_cap'),
          },
          {
            value: 4,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_thursday_cap'),
          },
          {
            value: 5,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_friday_cap'),
          },
          {
            value: 6,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_saturday_cap'),
          },
          {
            value: 7,
            type: 'days',
            times: [],
            checked: false,
            display: this.$t('date_and_time.day_sunday_cap'),
          },
        ],
        durationToDisplay: null,
        disabledFromDates: {
          to: new Date(this.moment().subtract(1, 'day')),
          from: null,
        },
        disabledToDates: {
          to: new Date(this.moment().subtract(1, 'day')),
          from: null,
          days: [0, 1, 2, 3, 4, 5, 6],
          dates: [],
        },
        placeholderDate: null,
        disabledSkipDates: {
          to: null,
          from: null,
          days: [0, 1, 2, 3, 4, 5, 6],
          dates: [],
        },
        hello: null,
        calendarLocales: [
          { key: 'lt', locale: lt },
          { key: 'en', locale: en },
        ],
        currentLocale: localStorage.getItem('locale'),
        editMode: false,
        editSteps: [
          this.$t('time_plural'),
          this.$t('duration'),
          this.$t('period'),
          this.$t('exceptions'),
        ],
        currentEditStep: 0,
        skipDaysValue: [],
      };
    },
    computed: {
      ...mapGetters('questionnaire', ['questionnaire', 'scheduleIsEdit']),
      schedule() {
        return this.questionnaire.schedules[this.i];
      },
      scheduleId() {
        return this.schedule.id || null;
      },
      childSchedules() {
        return this.questionnaire.schedules.filter(
          (schedule) =>
            schedule.parentScheduleId === this.scheduleId || schedule.parentIndex === this.i
        );
      },
      todayDate() {
        return this.moment().format('YYYY-MM-DD');
      },
      todayDateTime() {
        return this.moment().format('YYYY-MM-DD HH:mm');
      },
      tomorrowDate() {
        return this.moment()
          .add(1, 'day')
          .format('YYYY-MM-DD');
      },
      currentWeekDayValue() {
        return this.moment().day();
      },
      editStatus() {
        return this.schedule.initialEditStatus && this.schedule.initialEditStatus !== undefined
          ? this.schedule.initialEditStatus
          : this.editMode;
      },
      durationType() {
        const durationType = { type: this.schedule.durationType || 'minutes', value: null };
        switch (durationType.type) {
          case 'minutes':
            durationType.value = this.$t('schedule.duration_minutes');
            break;
          case 'hours':
            durationType.value = this.$t('schedule.duration_hours');
            break;
          case 'days':
            durationType.value = this.$t('schedule.duration_days');
            break;
          default:
            break;
        }

        return durationType;
      },
      repeatType() {
        const repeatType = { type: this.schedule.repeatType || 'once', value: null };
        switch (repeatType.type) {
          case 'once':
            repeatType.value = this.$t('schedule.repeat_type_once');
            break;
          case 'daily':
            repeatType.value = this.$t('schedule.repeat_type_daily');
            break;
          case 'custom':
            repeatType.value = this.$t('schedule.repeat_type_custom');
            break;
          default:
            break;
        }

        return repeatType;
      },
      activeDay() {
        const { weekDays } = this.schedule;
        if (weekDays) {
          return weekDays;
        }
        return [];
      },
      currentCalendarLocale() {
        return this.calendarLocales.find((locale) => locale.key === this.currentLocale);
      },
      maxChildTime() {
        const childScheduleTimes = this.childSchedules.map((child) => {
          return this.moment(child.toDate + child.time, 'YYYY-MM-DD HH:mm');
        });
        const maxChildTime = this.moment.max(childScheduleTimes);
        return maxChildTime || null;
      },
      allowToDelete() {
        return (
          this.moment(this.maxChildTime).format('YYYY-MM-DD HH:mm') >
          this.moment().format('YYYY-MM-DD HH:mm')
        );
      },
      isValidDateTime() {
        let isValid = 0;
        if (!this.schedule.active) {
          const scheduleDate = this.moment(this.schedule.fromDate).format('YYYY-MM-DD');
          if (scheduleDate < this.moment().format('YYYY-MM-DD')) {
            isValid = 1;
          }
        }
        return isValid === 0;
      },
      noWeekDaysSelected() {
        return this.disabledToDates.days.length === 7 || false;
      },
      scheduleType() {
        return this.schedule.repeatType === 'custom' || this.schedule.repeatType === 'daily'
          ? 'recurrent'
          : 'once';
      },
      showScheduleOption() {
        return this.currentScheduleType === this.scheduleType;
      },
      disableNextStepButton() {
        return (
          !this.weekDays.filter((day) => day.times.length && day.checked).length ||
          (this.currentEditStep === 2 && this.schedule.toDateEmpty) ||
          false
        );
      },
      skipDates() {
        return this.disabledSkipDates.dates.map((day) => day);
      },
      skipAttributes() {
        return this.skipDates.map((date) => ({
          highlight: {
            style: {
              backgroundColor: '#ff3366',
              borderRadius: '50%',
            },
          },
          dates: date,
        }));
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'addChildSchedule',
        'removeChildSchedule',
        'editChildSchedule',
        'editActiveChildSchedule',
        'setQuestionnaireProperty',
        'setScheduleProperty',
        'setScheduleIsEdit',
      ]),
      handleSkipDateRemove(dayId) {
        const idx = this.skipDaysValue.findIndex((d) => d.id === dayId);
        this.schedule.skip.splice(idx, 1);
        this.disabledSkipDates.dates.splice(idx, 1);
        this.skipDaysValue.splice(idx, 1);
      },
      onSkipDayClick(day) {
        const idx = this.skipDaysValue.findIndex((d) => d.id === day.id);
        if (idx >= 0) {
          this.schedule.skip.splice(idx, 1);
          this.disabledSkipDates.dates.splice(idx, 1);
          this.skipDaysValue.splice(idx, 1);
        } else {
          this.handleSkip(day.date, this.schedule.skip.length || 0, this.i);
          this.skipDaysValue.push({
            id: day.id,
            date: day.date,
          });
        }
      },
      customFormatter(value) {
        return this.moment(value).format('YYYY-MM-DD');
      },
      handleScheduleRemove(i, schedule) {
        if (this.scheduleIsEdit && !this.editStatus) {
          this.handleIsEditModal();
        } else {
          this.$emit('handleScheduleRemove', { i, schedule });
        }
      },
      handleIsEditModal() {
        this.$emit('handleIsEditModal');
      },
      handleScheduleEdit() {
        if (this.scheduleIsEdit) {
          this.handleIsEditModal();
        } else {
          this.editMode = !this.editMode;
          this.setScheduleIsEdit(true);
        }
      },
      toggleIsEditModal() {
        this.isEditModal = !this.isEditModal;
      },
      timeChangeHandler(value, i) {
        const property = value;
        const payload = {
          type: 'time',
          index: i,
          property,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleDeleteChildSchedule(time) {
        this.removeChildSchedule(time);
      },
      handleAddChildSchedule() {
        this.weekDays.forEach((weekDay) => {
          const { value: weekDayValue } = weekDay;
          let newFromDate = this.schedule.fromDate;
          let newToDate = this.schedule.toDate;

          weekDay.times.forEach((time) => {
            // if current fromDate is today, change fromDate for all child schedules
            if (this.schedule.fromDate === this.todayDate && time.time) {
              const scheduleDateTime = this.moment(
                this.schedule.fromDate + time.time,
                'YYYY-MM-DD HH:mm'
              ).format('YYYY-MM-DD HH:mm');
              if (weekDayValue === this.currentWeekDayValue) {
                newFromDate =
                  scheduleDateTime > this.todayDateTime
                    ? this.schedule.fromDate
                    : this.tomorrowDate;

                // check if toDate is not before fromDate
                if (this.moment(newFromDate).diff(this.schedule.toDate, 'days') >= 1) {
                  newToDate = newFromDate;
                }
              } else {
                newFromDate = this.tomorrowDate;
              }
            }

            if (time.id !== undefined) {
              // schedule edit with id
              this.editActiveChildSchedule({
                ...time,
                duration: this.schedule.duration,
                durationType: this.schedule.durationType,
                fromDate: newFromDate,
                skip: this.schedule.skip,
                toDate: newToDate,
                toDateEmpty: this.schedule.toDateEmpty,
                weekStep: this.schedule.weekStep,
              });
              // handle schedule delete if active and removed
              if (!time.time) {
                this.handleDeleteChildSchedule(time);
              }
            } else if (time.parentIndex !== undefined) {
              // schedule edit without id
              this.editChildSchedule({
                ...time,
                duration: this.schedule.duration,
                durationType: this.schedule.durationType,
                fromDate: newFromDate,
                skip: this.schedule.skip,
                toDate: newToDate,
                toDateEmpty: this.schedule.toDateEmpty,
                weekStep: this.schedule.weekStep,
              });
            } else if (time.time) {
              this.addChildSchedule({
                ...this.schedule,
                fromDate: newFromDate,
                toDate: newToDate,
                weekDays: [weekDay.value],
                time: time.time,
                parentScheduleId: this.scheduleId,
                parentIndex: this.i,
                editId: time.editId,
              });
            }
            time.parentIndex = this.i;
          });
          const filteredTimes = weekDay.times.filter((time) => time.time);
          weekDay.times = filteredTimes;
        });
      },
      handleAddOneChildSchedule(weekDay) {
        this.weekDays.forEach((day) => {
          if (day.value === weekDay) {
            day.times.push({
              ...this.schedule,
              id: undefined,
              weekDays: [weekDay],
              time: this.moment()
                .add(5, 'minutes')
                .format('HH:mm'),
              parentScheduleId: this.scheduleId,
              isChild: true,
              editId: `${day.value}-${day.times.length}`,
            });
          }
        });
      },
      checkAnswerType(emitedValue) {
        const { checked, value } = emitedValue;
        const day = this.weekDays.find((day) => day.value === value);
        day.checked = checked;
        this.handleDaySelect(value);

        // add first time on weekday check
        if (checked) {
          this.handleAddOneChildSchedule(value);
        }

        if (!checked && day.times && day.times.length) {
          day.times.forEach((dayTime) => {
            dayTime.time = '';
          });
        }
      },
      handleDate(value, i, type, skipIndex) {
        const selectedDate = this.moment(value).format('YYYY-MM-DD');
        const payload = {
          type,
          index: i,
          property: selectedDate,
        };
        if (skipIndex > -1) {
          payload.skipIndex = skipIndex;
        }
        if (type !== 'skip') {
          this.setQuestionnaireProperty(payload);
        } else {
          this.setScheduleProperty(payload);
        }

        if (type === 'toDate') {
          // this.disabledToDates.from = this.moment(value).toDate();
          this.disabledSkipDates.from = this.moment(value).toDate();
        }
        if (type === 'fromDate') {
          this.disabledSkipDates.to = this.moment(value).toDate();
          this.disabledToDates.to = this.moment(value).toDate();
        }
      },
      handleSkip(value, skipIndex, index) {
        const selectedDate = this.moment(value).format('YYYY-MM-DD');
        this.setScheduleProperty({
          type: 'skip',
          index,
          skipIndex,
          property: selectedDate,
        });
        this.disabledSkipDates.dates.push(new Date(selectedDate));
        this.placeholderDate = null;
      },
      handleSkipDelete(skipIndex) {
        this.schedule.skip.splice(skipIndex, 1);
        this.disabledSkipDates.dates.splice(skipIndex, 1);
      },
      handleDuration(e, i) {
        const property = parseInt(e.target.value, 10);
        const payload = {
          type: 'duration',
          index: i,
          property,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleTimeInput(value) {
        console.log(value);
      },
      handleDurationSelect(value) {
        const {
          params: { type: routeType },
        } = this.$route;

        const payload = {
          type: 'durationType',
          index: this.i,
          property: value.type,
          routeType,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleRepeatSelect(value) {
        const type = value.type;
        const payload = {
          type: 'repeatType',
          index: this.i,
          property: type,
        };
        this.setQuestionnaireProperty(payload);
        if (type === 'once') {
          this.disabledToDates.days = [];
          this.disabledSkipDates.days = [];
          this.disabledToDates.from = null;
          this.disabledToDates.to = null;
          this.disabledSkipDates.from = null;
          this.disabledSkipDates.to = null;
        } else if (type === 'daily') {
          this.disabledToDates.days = [];
          this.disabledSkipDates.days = [];
          this.disabledToDates.from = null;
          this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
          this.disabledSkipDates.from = null;
          this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
        } else if (type === 'custom') {
          this.disabledToDates.days = [0, 1, 2, 3, 4, 5, 6];
          this.disabledSkipDates.days = [0, 1, 2, 3, 4, 5, 6];
          this.disabledToDates.from = null;
          this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
          this.disabledSkipDates.from = null;
          this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
        }
      },
      handleDaySelect(day) {
        const payload = {
          type: 'weekDays',
          index: this.i,
          property: day,
        };
        this.setQuestionnaireProperty(payload);
        if (day === 7) day = 0;
        const skipDays = this.disabledSkipDates.days;
        const toDays = this.disabledToDates.days;
        const dayIndex = skipDays.findIndex((skipDay) => skipDay === day);
        if (dayIndex > -1) {
          skipDays.splice(dayIndex, 1);
          toDays.splice(dayIndex, 1);
        } else {
          skipDays.push(day);
          toDays.push(day);
        }
      },
      handleWeekStepSelect(value) {
        const weekStep = value.value;
        const payload = {
          type: 'weekStep',
          index: this.i,
          property: weekStep,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleStepChange(value) {
        this.currentEditStep += value;
      },
      handleInitialEditStatus() {
        const payload = {
          type: 'editStatus',
          index: this.i,
        };
        this.setScheduleProperty(payload);
      },
      handleScheduleConfirm() {
        this.handleInitialEditStatus();
        this.editMode = false;
        this.currentEditStep = 0;
        this.handleAddChildSchedule();
        this.setScheduleIsEdit(false);
      },
    },
  };
</script>
<style lang="scss">
  .grey-explanation-text {
    font-family: Nunito-Regular;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.56px;
    color: $grey-5;
    &.duration-info-text {
      justify-content: flex-start;
      svg {
        margin-right: 10px;
      }
    }
  }
  .arrow-icon-wrapper {
    margin: 17px 30px 0 30px;
  }
  .week-day-wrapper {
    justify-content: flex-start;
    min-height: 30px;
    .checkbox-wrapper {
      width: 200px;
      justify-content: flex-start;
      span {
        margin-left: 15px;
        text-transform: capitalize;
      }
    }
    .date-wrapper {
      width: 645px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .week-time-picker-input-wrapper {
      margin-right: 20px;
      margin-bottom: 5px;
      .week-time-picker-input {
        width: 90px;
        input {
          width: 90px;
          height: 30px;
        }
      }
      &.no-margin {
        margin-right: 0;
      }
    }
    &.header {
      span {
        text-transform: uppercase;
      }
      .checkbox-wrapper {
        span {
          margin-left: 0;
        }
      }
    }
    .add-schedule-button {
      display: flex;
      align-items: center;
      height: 30px;
      margin-bottom: 5px;
      font-family: Nunito-Regular;
      font-weight: bold;
      font-size: 12px;
      color: $grey-3;
      cursor: pointer;
    }
    .reflection-options__input {
      &--time-picker {
        margin-right: 20px;
      }
    }
    &:not(:first-child):not(:last-child) {
      margin-bottom: 12px;
    }
  }
  .skip-calendar-wrapper {
    width: 445px;
    margin-top: 16px;
    margin-bottom: 20px;
    .vc-container {
      border: none;
      border-radius: 0;
      background-color: $grey-6;
      .vc-pane-container {
        .vc-arrows-container {
          padding: 8px 30px;
        }
      }
      .vc-day {
        .vc-day-content {
          &.is-disabled {
            pointer-events: none;
          }
        }
      }
    }
  }
  .skip-dates-display-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .date-chip-wrapper {
      margin-right: 14px;
      margin-bottom: 14px;
    }
  }
  .reflection-options {
    &__row {
      &.repeat-input-wrapper {
        margin-top: 30px;
        .reflection-options__inputs--weeks {
          margin: 0 20px;
        }
        .grey-explanation-text {
          &.weeks-text {
            text-transform: lowercase;
          }
        }
      }
      &.duration-input-wrapper {
        margin-bottom: 20px;
      }
    }
  }
  .reflection-options__inputs {
    .vdp-datepicker {
      &.empty-date-input {
        input {
          border: 1px solid $red-4;
        }
      }
    }
    .input-error-active {
      border: 1px solid $red-4;
    }
  }
</style>
