<template>
  <div class="about-us__page">
    
    <section id="about-us" class="about-us">
      <div class="about-us__header">
        <header-h1>Kas yra Reflectus</header-h1>
        <header-h1>Naudos mokytojams ir mokiniams</header-h1>
      </div>
    </section>
  </div>
</template>
<script>

import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';

export default {
  name: 'FAQ',
  components: {
    HeaderH1,
  },
};
</script>
<style lang="scss" scoped>
.about-us {
  max-width: 940px;
  margin: 30px auto 0 auto;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-around;
  }
}
</style>
