<template>
  <section id="methods" class="methods">
    <method-header></method-header>
    <method-filter></method-filter>
    <method-grid v-if="questionTypeList.length"></method-grid>
    <div class="methods__loading" v-else>
      <img class="methods__loading__gif" :src="loadingGif" alt="loading" />
    </div>
    <div class="contact">
      <div class="contact__text">
        <p>{{ $t("methods.methods_suggestion_question") }}</p>
        <p>{{ $t("contact_by_email_message") }}</p>
      </div>
      <div class="contact__email">
        <svgicon name="logos/mail-logo" :original="true" height="2em"></svgicon>
        <span>info@reflectus.lt</span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MethodHeader from '@/components/methods/MethodHeader.vue';
import MethodFilter from '@/components/methods/MethodFilter.vue';
import MethodGrid from '@/components/methods/MethodGrid.vue';
import loadingGif from '@/assets/gif/loading.gif';

export default {
  name: 'Methods',
  components: {
    MethodHeader,
    MethodFilter,
    MethodGrid,
  },
  data() {
    return {
      loadingGif,
    };
  },
  created() {
    this.handleGetMethods();
  },
  computed: {
    ...mapGetters('methods', ['questionTypeList']),
  },
  methods: {
    ...mapActions('methods', ['getQuestionList']),
    handleGetMethods() {
      if (!this.questionTypeList.length) this.getQuestionList();
    },
  },
};
</script>
<style lang="scss">
.methods {
  max-width: 940px;
  margin: 30px auto 0 auto;
  position: relative;
  .contact {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .contact__text {
      p {
        padding: 0;
        margin: 0;
        font-family: Roboto-Regular;
        color: $green-3;
        font-size: 14px;
      }
    }
    .contact__email {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      span {
        margin-top: 10px;
        font-family: Nunito-SemiBold;
        font-size: 12px;
        color: #3b328b;
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;

    &__gif {
      height: 160px;
    }
  }
}
.methods__page {
  footer {
    bottom: auto;
  }
}
</style>
