<template>
  <div class="image-modal" @click="toggleImageModal">
    <div class="image-modal__bg">
      <div class="image-modal__modal" @click.stop>
        <div
          :class="[
            'image-modal__container',
            { 'image-modal__container--question': type === 'question' },
          ]"
        >
          <svgicon
            @click="toggleImageModal"
            color="grey"
            :fill="false"
            name="multiply"
            class="image-modal__close"
          ></svgicon>
          <div class="image-modal__images" v-if="type === 'main'">
            <img
              :class="['image-modal__image', { 'image-modal__image--active': imageActive === n }]"
              v-for="(image, n) in imageArray"
              :src="image.url"
              :key="n"
              @click="setImage(n)"
            />
            <!-- @todo uncomment this custom image upload when needed -->
            <!-- <label class="image-modal__upload" :for="label">
              <input type="file" name="imageUpload" :id="label" @change="onFileChange" />
              <img class="image-modal__image" :src="require(`@/assets/image-picker/upload.png`)" />
            </label>-->
          </div>
          <div class="image-modal__images" v-if="type === 'question'">
            <img
              :class="[
                'image-modal__image',
                'image-modal__image--question',
                { 'image-modal__image--active': imageActive === n },
              ]"
              v-for="(image, n) in filteredImages"
              :src="image.url"
              :key="n"
              @click="setImage(n)"
            />
            <!-- @todo uncomment this custom image upload when needed -->
            <!-- <label class="image-modal__upload" :for="label">
              <input type="file" name="imageUpload" :id="label" @change="onFileChange" />
              <img class="image-modal__image" :src="require(`@/assets/image-picker/upload.png`)" />
            </label>-->
          </div>
          <div class="image-modal__actions">
            <button-3 @click.native="toggleImageModal">{{ $t("actions.cancel_action") }}</button-3>
            <button-4 @click.native="onImageSelect">{{ $t("actions.select_action") }}</button-4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'QuestionImagePicker',
  components: {
    Button3,
    Button4,
  },
  props: ['label', 'type', 'selectedImages'],
  data() {
    return {
      showImageModal: false,
      imageActive: null,
      selectedImage: null,
      customImages: [],
      imageArray: [],
    };
  },
  created() {
    this.handleGetGallery();
  },
  computed: {
    ...mapGetters('meta', ['animals', 'questionnaireIcons']),
    filteredImages() {
      if (this.type === 'question') {
        if (this.selectedImages.length) {
          return this.imageArray.filter(
            (image) =>
              this.selectedImages.filter((selectedImage) => selectedImage.id === image.id)
                .length === 0
          );
        }
        return this.imageArray;
      }
      return this.imageArray;
    },
  },
  methods: {
    ...mapActions('questionnaire', ['setQuestionnaireIcon']),
    ...mapActions('meta', ['getGallery']),
    toggleImageModal() {
      this.$emit('closeImageModal');
    },
    onImageSelect() {
      this.toggleImageModal();
      this.$emit('onImageSelect', this.selectedImage);
    },
    onFileChange(e) {
      const image = e.target.files[0];
      if (!image) return;
      this.selectedImage = { url: URL.createObjectURL(image) };
      this.imageArray.push(this.selectedImage);
      this.imageActive = this.imageArray.length - 1;
      this.$emit('onFileChange', this.selectedImage);
      this.setQuestionnaireIcon(image);
    },
    setImage(n) {
      this.imageActive = n;
      if (n || n === 0) {
        if (this.filteredImages[n] && this.type === 'question') {
          this.selectedImage = this.filteredImages[n];
        } else {
          this.selectedImage = this.imageArray[n];
        }
        this.$emit('onFileChange', this.selectedImage);
      } else {
        this.$emit('onFileChange', null);
      }
    },
    async handleGetGallery() {
      if (this.type === 'question') {
        if (this.imageArray.length) return;
        const { icons } = await this.getGallery('animals');
        this.imageArray = [...icons];
      } else {
        if (this.imageArray.length) return;
        const { icons } = await this.getGallery('questionnaire');
        // @todo remove this filter when custom image upload is back again
        this.imageArray = [...icons.filter((icon) => icon.id !== '96')];
      }
    },
  },
};
</script>
<style lang="scss">
@import 'mixins';
.image-modal {
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
  &__container {
    position: relative;
    padding: 20px;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--question {
      max-width: 490px !important;
    }
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    margin: 5px;

    border-radius: 50%;
    transition: all 0.3s;
    width: 60px;
    height: 60px;

    &:hover {
      cursor: pointer;
      border: 1px solid $violet-6;
      transform: translateY(-3px) scale(1.02);
    }

    &--active {
      border: 1px solid $violet-6;
    }

    &--question {
      width: 80px;
      height: 80px;
      border-radius: 0 !important;
    }
  }

  &__upload {
    input {
      display: none;
    }
  }

  &__actions {
    margin-top: 70px;
    display: flex;
    align-self: flex-start;

    button:last-of-type {
      margin-left: 10px;
    }
  }
}
</style>
