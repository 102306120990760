<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <div v-if="!loading">
            <slot name="name-change-form"></slot>
            <div class="modal__container__actions">
              <button-3 @click.native="handleModalToggle">
                {{ $t('actions.cancel_action') }}
              </button-3>
              <button-4 @click.native="handleModalAction" :disabled="disableButton">
                {{ $t('actions.save_action_2') }}
              </button-4>
            </div>
          </div>
          <loading v-else></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'NameChangeModal',
    components: {
      Button4,
      Button3,
      Loading,
    },
    props: ['loading', 'disableButton'],
    methods: {
      handleModalToggle() {
        this.$emit('toggleModal');
      },
      handleModalAction() {
        this.$emit('handleNameChangeAction');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    &__container {
      .info-field {
        flex-direction: column;
        align-items: flex-start;
        margin: 7px 0;
        input {
          margin-top: 5px;
          padding-left: 10px;
          width: 260px;
          height: 28px;
          font-size: 14px;
          font-family: Roboto-Regular;
          color: $grey-5;
          border: 1px solid $grey-2;
        }
      }
      &__warning {
        max-width: 430px;
      }
    }
  }
</style>
