<template>
  <div class="modal">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon class="modal__container__icon" name="large-check" :original="true"></svgicon>
          <h4 class="modal__container__heading">
            {{ $t("modals.register_modal_confirm_id") }}
          </h4>
          <p class="modal__container__warning">
            {{ $t("modals.register_modal_confirm_sent_message") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'RegisterModal'
};
</script>

<style lang="scss">
@import 'mixins';
.modal {
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  &__container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__icon {
      width: 120px;
      height: 82px;
    }

    &__heading {
      margin-top: 20px;
      font-family: Nunito-Bold;
      font-size: 16px;
      color: $violet-5;
    }

    &__warning {
      font-family: Nunito-Regular;
      font-size: 16px;
      color: $violet-5;
      max-width: 260px;
    }

    &__actions {
      margin-top: 20px;

      button {
        width: 102px;
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}
</style>
