<template>
  <div class="template__smileys" v-if="question">
    <div class="template__input-field" v-for="(option, i) in options" :key="i">
      <svgicon
        v-if="!option.show"
        class="template__color-toggle"
        name="methods/smiley-select-blank"
        :original="true"
        width="36px"
        height="18px"
        @click="toggleSmileysModal(i)"
        :disabled="!edit"
      ></svgicon>
      <div class="template__color-show" v-if="option.show" @click="toggleSmileysModal(i)">
        <div class="template__color-show__container">
          <img class="template__color-show__image" :src="option.show" />
        </div>
        <svgicon
          class="template__color-show-icon"
          name="methods/image-pick-close"
          :original="true"
        ></svgicon>
      </div>
      <input
        :class="[
          'template__input-text template__input-text--img',
          { 'template__input-text--error': option.error },
        ]"
        type="text"
        v-model="option.value"
        @input="
          setQuestionPropertyHandler($event, { type: 'value', index: number, optionIndex: i })
        "
        :placeholder="`- ${$t('actions.select_2_action')} -`"
        @click="toggleSmileysModal(i)"
        readonly="readonly"
        :disabled="!edit"
      />
      <svgicon
        v-if="options.length > 1 && option.value && edit"
        class="template__input-text__remove"
        name="multiply"
        :original="true"
        @click="handleRemoveOption(i)"
      ></svgicon>
    </div>
    <smileys-modal
      v-show="smileysModal"
      @close="toggleSmileysModal"
      :key="`smileysModal--${i}`"
      @selectedSmiley="handleSmileySelect"
      :selectedSmileys="selectedSmileys"
      :smileyOptions="options"
    ></smileys-modal>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import SmileysModal from '@/components/reflections/templates/SmileysModal.vue';

  export default {
    name: 'TemplateSmileys',
    components: {
      SmileysModal,
    },
    props: ['number', 'edit'],
    data() {
      return {
        i: 0,
        smileysModal: false,
        selectedSmileys: [],
      };
    },
    created() {
      this.handleOptionAssign();
    },
    computed: {
      ...mapGetters('questionnaire', ['questions']),
      question() {
        return this.questions[this.number];
      },
      options() {
        return this.question.answerOptions.options;
      },
      type() {
        return this.question.answerOptions.type;
      },
      view() {
        return this.question.answerOptions.view;
      },
    },
    methods: {
      ...mapActions('questionnaire', ['setQuestionProperty', 'setOptionError']),
      toggleSmileysModal(i) {
        if (!this.edit) return;
        this.i = i;
        this.smileysModal = !this.smileysModal;
      },
      handleSmileySelect(value) {
        const option = this.options[this.i];
        option.show = value.url;
        option.value = value.title;
        this.selectedSmileys.push({ value: option.value, show: option.show });
        if (!this.options[this.i + 1]) {
          this.options.push({ value: null, show: null });
        }
      },
      handleOptionInput(event, i) {
        const { value } = event.target;
        if (!value && this.options[i + 1]) {
          this.options.splice(i + 1, 1);
        }
      },
      setQuestionPropertyHandler($event, payload) {
        const { optionIndex } = payload;
        const { value } = $event.target;
        if (!value) {
          this.setOptionError({
            questionIndex: this.number,
            optionIndex,
            error: this.$t('warnings.finish_typing'),
          });
        }
        payload.property = value;
        this.setQuestionProperty(payload);
      },
      handleRemoveOption(i) {
        const smileyIndex = this.selectedSmileys.findIndex(
          (smiley) => smiley.value === this.options[i].value
        );
        this.options.splice(i, 1);
        this.selectedSmileys.splice(smileyIndex, 1);
      },
      handleOptionAssign() {
        if (this.options[this.options.length - 1].value) {
          this.options.push({ value: null, show: null });
        }
        this.options.forEach((option) => this.selectedSmileys.push(option));
      },
    },
  };
</script>
<style lang="scss">
  .template {
    &__smileys {
      .template__input-field {
        position: relative;

        .template__color-toggle {
          top: 6px;
          position: absolute;

          &:hover {
            cursor: pointer;
          }
        }

        .template__color-show {
          border-left: 0.5px solid $violet-6;
          border-bottom: 0.5px solid $violet-6;
          &__container {
            width: 18px;
            height: 18px;
            border: 0.5px solid $violet-6;
            border-bottom: 1px solid $violet-6;
            border-right: none;
            overflow: hidden;
          }

          &__image {
            width: 100%;
            height: 100%;
            transform: scale(1.5);
          }
        }
        .template__input-text {
          &--img {
            padding-left: 40px;
          }
        }
      }
    }
  }
</style>
