<template>
  <div class="template-modal" v-if="template" @click="close">
    <div class="template-modal__bg">
      <div class="template-modal__modal" @click.stop>
        <div class="template-modal__container">
          <svgicon
            @click="close"
            color="grey"
            :fill="false"
            name="multiply"
            class="template-modal__close"
          ></svgicon>
          <div
            class="template-modal__container__icon"
            v-if="type === 'copy' || success || type === 'copyFromQuestionnaire'"
          >
            <svgicon
              class="template-modal__container__icon__svg"
              name="large-check"
              :original="true"
            ></svgicon>
          </div>
          <div class="template-modal__container__icon" v-if="type === 'share' && !success">
            <svgicon
              class="template-modal__container__icon__svg template-modal__container__icon__svg--envelope"
              name="large-envelope-happy"
              :original="true"
            ></svgicon>
            <svgicon
              v-if="!template.public"
              class="template-modal__container__icon__svg template-modal__container__icon__svg--dialog"
              name="dialog-heart"
              :original="true"
            ></svgicon>
            <svgicon
              v-else
              class="template-modal__container__icon__svg template-modal__container__icon__svg--dialog"
              name="dialog-check"
              :original="true"
            ></svgicon>
          </div>
          <div class="template-modal__container__icon" v-if="type === 'delete'">
            <svgicon
              class="template-modal__container__icon__svg"
              name="large-question-mark"
              :original="true"
            ></svgicon>
          </div>
          <div class="template-modal__container__icon" v-if="type === 'deleteDraft'">
            <svgicon
              class="template-modal__container__icon__svg"
              name="large-question-mark"
              :original="true"
            ></svgicon>
          </div>
          <div class="template-modal__container__content" v-if="type === 'copy'">
            <h4 class="template-modal__container__content__header">
              {{ $t('templates.template') }}
            </h4>
            <h4 class="template-modal__container__content__header">„{{ template.title }}“</h4>
            <h4 class="template-modal__container__content__header">
              {{ $t('questionnaire.copied_to_questionnaire_list_message') }}
            </h4>
          </div>
          <div class="template-modal__container__content" v-if="type === 'share' && !success">
            <h4 class="template-modal__container__content__header" v-if="!template.public">
              {{ $t('modals.template_modal_share_quest_header') }}
            </h4>
            <h4
              class="template-modal__container__content__header"
              v-if="template.public && !success"
            >
              {{ $t('modals.template_modal_share_again_question') }}
            </h4>
          </div>
          <div class="template-modal__container__content" v-if="success">
            <h4 class="template-modal__container__content__header">
              {{ $t('modals.template_modal_template_share_success_message') }}
            </h4>
          </div>
          <div class="template-modal__container__content" v-if="type === 'delete'">
            <h4 class="template-modal__container__content__header">
              {{ $t('modals.template_modal_delete_questionnaire_check') }}
            </h4>
          </div>
          <div class="template-modal__container__content" v-if="type === 'deleteDraft'">
            <h4 class="template-modal__container__content__header">
              {{ $t('modals.template_modal_delete_draft_check') }}
            </h4>
          </div>
          <div class="template-modal__container__content" v-if="type === 'copyFromQuestionnaire'">
            <h4 class="template-modal__container__content__header">
              {{ $t('modals.template_modal_copy_questionnaire_success_message') }}
            </h4>
          </div>
          <div
            class="template-modal__container__edit"
            v-if="type === 'share' && !template.public && !success"
          >
            <div class="template-modal__container__edit__border"></div>
            <div class="template-modal__container__edit__header">
              <div class="template-modal__container__edit__header__title">
                <img
                  class="template-modal__container__edit__header__icon"
                  v-if="template.icon"
                  :src="template.icon"
                  alt="template-icon"
                />
                <svgicon
                  class="template-modal__container__edit__header__icon"
                  v-else
                  name="methods/logo-grey"
                  :original="true"
                ></svgicon>
                <p class="template-modal__container__edit__header__title__title">
                  {{ template.title }}
                </p>
              </div>
              <p class="template-modal__container__edit__header__info">
                <span>
                  <svgicon name="clock" :original="true"></svgicon>
                  {{ moment(template.duration * 1000).format('mm [min] ss [sec]') }}
                </span>
                <span>
                  <svgicon name="person" :original="true"></svgicon>
                  {{
                    `${questionnaireTemplate.owner.name} ${questionnaireTemplate.owner.lastName}`
                  }}
                </span>
              </p>

              <div class="template-modal__container__edit__description">
                <p class="template-modal__container__edit__description__title">
                  {{ $t('modals.template_modal_decription_for_users') }}
                </p>
                <textarea
                  class="template-modal__container__edit__description__input"
                  maxlength="145"
                  v-model="recommendation"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="template-modal__container__actions" v-if="type === 'copy'">
            <button-3 @click.native="close">{{ $t('actions.ok_action') }}</button-3>
          </div>
          <div class="template-modal__container__actions" v-if="type === 'share' && !success">
            <button-3 @click.native="close">{{ $t('actions.cancel_action') }}</button-3>
            <button-4 @click.native="updateHandler" v-if="!template.public">
              {{ $t('actions.share_action') }}
            </button-4>
            <button-4 @click.native="close" v-else>{{ $t('actions.share_action') }}</button-4>
          </div>
          <div class="template-modal__container__actions" v-if="type === 'delete'">
            <button-3 @click.native="close">{{ $t('no_cap') }}</button-3>
            <button-4 @click.native="deleteHandler">{{ $t('yes_cap') }}</button-4>
          </div>
          <div class="template-modal__container__actions" v-if="type === 'deleteDraft'">
            <button-3 @click.native="close">{{ $t('no_cap') }}</button-3>
            <button-4 @click.native="deleteHandler">{{ $t('yes_cap') }}</button-4>
          </div>
          <div
            class="template-modal__container__actions"
            v-if="success || type === 'copyFromQuestionnaire'"
          >
            <button-3 @click.native="close">{{ $t('actions.close_action') }}</button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'TemplateModal',
    components: {
      Button3,
      Button4,
    },
    props: ['template', 'type', 'templateType', 'questionnaireTemplate'],
    data() {
      return {
        recommendation: null,
        success: false,
      };
    },
    created() {
      this.recommendation = this.template.recommendation;
    },
    methods: {
      ...mapActions('reflections', ['cloneTemplate', 'deleteTemplate', 'resetTemplateDraftQuery']),
      ...mapActions('questionnaire', ['deleteDraft', 'deleteQuestionnaire']),
      close(success) {
        this.$emit('close', success);
        if (this.type === 'copyFromQuestionnaire') {
          window.location.reload();
          this.resetTemplateDraftQuery();
        }
      },
      updateHandler() {
        // const { id } = this.template;
        const { id: questionnaireId } = this.questionnaireTemplate;
        this.cloneTemplate({ id: questionnaireId, recommendations: this.recommendation }).then(
          (res) => {
            const { success } = res;
            if (success) {
              this.success = true;
            }
          }
        );
      },
      deleteHandler() {
        const { id, clientGroupId } = this.template;
        const type = this.templateType;
        if (type === 'draft') {
          if (clientGroupId) {
            this.deleteQuestionnaire(id).then(({ success }) => this.emitDelete(success));
          } else {
            this.deleteDraft(id).then(({ success }) => this.emitDelete(success));
          }
          return;
        }
        const payload = { id, type };
        this.deleteTemplate(payload).then(({ success }) => this.emitDelete(success));
      },
      emitDelete(success) {
        this.$emit('handleDelete', success);
        this.close();
      },
    },
  };
</script>
<style lang="scss">
  @import 'mixins';
  .template-modal {
    &__bg {
      margin: 0;
      z-index: 5;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__modal {
      @include hardBoxShadow();
      overflow-x: auto;
      display: flex;
      flex-direction: column;
    }

    &__container {
      max-width: 460px;
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      text-align: center;

      &__icon {
        margin-top: 10px;
        position: relative;

        &__svg {
          width: 120px;
          height: 82px;

          &--envelope {
            width: 70px;
            height: 40px;
          }

          &--dialog {
            width: 32px;
            height: 48px;
            position: absolute;
            top: -20px;
          }
        }
      }

      &__content {
        margin-top: 20px;

        &__header {
          font-family: Nunito-Bold;
          font-size: 16px;
          color: $violet-5;
          max-width: 320px;
        }
      }

      &__edit {
        margin-top: 20px;
        position: relative;
        width: 100%;

        &__border {
          position: absolute;
          height: 3px;
          width: calc(100% + 40px);
          left: -20px;
          background-color: rgba(0, 0, 0, 0.3);
        }

        &__header {
          margin-top: 20px;
          display: flex;
          flex-direction: column;

          &__title {
            display: flex;
            align-items: center;
            svg {
              width: 34px;
              height: 34px;
            }

            &__title {
              margin-left: 14px;
              font-family: Roboto-Medium;
              font-size: 14px;
              color: $grey-5;
            }
          }

          &__info {
            margin-top: 15px;
            text-align: left;

            span {
              svg {
                width: 14px;
                height: 14px;
                margin-right: 2px;
              }

              &:last-of-type {
                margin-left: 24px;
              }
            }
          }

          &__icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        &__description {
          display: flex;
          flex-direction: column;
          margin-top: 16px;

          &__title {
            text-transform: uppercase;
            font-family: Nunito-Bold;
            font-size: 9px;
            color: $grey-3;
            text-align: left;
          }

          &__input {
            margin-top: 5px;
            width: 100%;
            resize: none;
            border: 1px solid $grey-2;
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $grey-5;
            min-height: 110px;
            padding: 10px;

            &::placeholder {
              color: $grey-4;
            }

            &:active,
            &:focus,
            &:hover {
              border-color: $grey-3;
            }
          }
        }
      }

      &__actions {
        margin-top: 20px;

        button {
          padding: 5px 20px;
          min-width: 102px;
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }

    &__close {
      position: absolute;
      height: 10px;
      width: 10px;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
  }
</style>
