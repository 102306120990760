import { render, staticRenderFns } from "./OrderInner.vue?vue&type=template&id=79c13beb&scoped=true&"
import script from "./OrderInner.vue?vue&type=script&lang=js&"
export * from "./OrderInner.vue?vue&type=script&lang=js&"
import style0 from "./OrderInner.vue?vue&type=style&index=0&id=79c13beb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c13beb",
  null
  
)

export default component.exports