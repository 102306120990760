<template>
  <h3>
    <slot/>
  </h3>
</template>
<script>
export default {
  name: 'HeaderH3',
};
</script>
<style lang="scss" scoped>
h3 {
  margin: 0;
  padding: 0;
  color: $grey-5;
  font-weight: inherit;
  font-family: Nunito-Regular;
  font-size: 14px;
  text-transform: uppercase;
}
</style>
