<template>
  <div class="template__pie" v-if="question">
    <div class="template__input-field">
      <span class="uppercase-title">{{ $t('from_cap') }}</span>
      <input
        :class="['input-box', 'input-box--small', { 'input-box--error': questionErrors.partCount }]"
        type="number"
        :placeholder="$t('insert_value_placeholder')"
        v-model="options.partCount"
        :min="defaultMinValue"
        :max="defaultMaxValue"
        :disabled="!edit"
        @input="handleLengthInput($event)"
      />
      <span class="template__input-field__error" v-if="questionErrors.partCount">
        {{ questionErrors.partCount }}
      </span>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'TemplatePie',
    props: ['number', 'question', 'edit'],
    data() {
      return {
        i: 0,
        defaultMinValue: 2,
        defaultMaxValue: 8,
      };
    },
    created() {
      if (!this.options.partCount) {
        this.options.partCount = 3;
      }
    },
    computed: {
      ...mapGetters('questionnaire', ['questionnaire']),
      options() {
        return this.question.answerOptions;
      },
      type() {
        return this.question.answerOptions.type;
      },
      view() {
        return this.question.answerOptions.view;
      },
      questionErrors() {
        return this.questionnaire.questions[this.number].answerOptions.errors;
      },
    },
    methods: {
      ...mapActions('questionnaire', ['setAnswerOptionError']),
      handleLengthInput(event) {
        const { value } = event.target;
        if (value < this.defaultMinValue) {
          this.setAnswerOptionError({
            questionIndex: this.number,
            optionKey: 'partCount',
            error: this.$t('warnings.between_2_and_8_message'),
          });
        } else {
          this.setAnswerOptionError({
            questionIndex: this.number,
            optionKey: 'partCount',
            error: null,
          });
        }
        this.options.partCount = parseInt(value, 10);
      },
    },
  };
</script>
<style lang="scss">
  .template {
    &__pie {
      .uppercase-title {
        margin-right: 5px;
      }
    }
  }
</style>
