<template>
  <div class="sentence-chart__item">
    <div class="sentence-chart__item__left">
      <div class="sentence-chart__item__left__icon">{{ nameIcon }}</div>
    </div>
    <div class="sentence-chart__item__middle">
      <div class="sentence-chart__item__middle__member">
        <h4 @click="handleNavigation(answer.clientId)">{{ `${answer.name} ${answer.lastName}` }}</h4>
      </div>
      <div class="sentence-chart__item__middle__question">
        <p v-html="originalAnswer">{{ originalAnswer }}</p>
      </div>
    </div>
    <div class="sentence-chart__item__right">
      <div class="set-stat-value">
        <input
          v-if="!answer.noReflection && !answer.skipped"
          class="input-box"
          type="text"
          :placeholder='$t("insert_value_placeholder_2")'
          v-model="currentValue"
          v-debounce:500.lock="handleValueAssign"
          :debounce-events="'blur'"
          @focus="toggleDropdown(true)"
          @blur="toggleDropdown(false)"
        />
        <div class="answer-list-dropdown" v-if="isDropdown">
          <span v-for="(answer, index) in answerList" :key="index" @click="handleValueAssign(answer)">{{answer}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'QuestionMethodListItem',
  props: ['answer', 'question', 'answerList', 'addValue', 'handleNavigation'],
  data(){
    return{
      currentValue: this.answer.value,
      isDropdown: false,
    }
  },
  computed: {
    nameIcon() {
      const name = this.answer.name.charAt(0).toUpperCase();
      const lastName = this.answer.lastName.charAt(0).toUpperCase();
      return `${name}${lastName}`;
    },
    originalAnswer() {
      const { original, value, noReflection, skipped } = this.answer;

      if (noReflection)
        return `<span class="sentence-chart__item__answer sentence-chart__item__answer--null">${value}</span>`;

      if (skipped)
        return `<span class="sentence-chart__item__answer sentence-chart__item__answer--null">${original}</span>`;

      return `<span class="sentence-chart__item__answer">${original}</span>`;
    },
    computedAnswer() {
      return this.answer;
    }
  },
  methods: {
    ...mapActions('stats', ['setIndividualAnswer']),
    async handleValueAssign(value) {
      const { answerId } = this.answer;
      const payload = {
        id: answerId,
        answer: {
          value: value || null
        }
      };

      this.currentValue = value;
      this.computedAnswer.value = value;
      await this.setIndividualAnswer(payload);
      this.toggleDropdown(false);
      this.$emit('addValue', value);
    },
    toggleDropdown(value){
      setTimeout(() => {
        this.isDropdown = value
      }, 200);
    },
  },
};
</script>
