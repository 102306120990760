<template>
  <li class="list-chart__list__item">
    <span class="list-chart__list__item__date" v-if="!individual">{{ date }}</span>
    <div class="list-chart__list__item__name-container" v-else>
      <div class="list-chart-member-index">{{ memberIndex }}</div>
      <div class="list-chart__list__item__name-container__icon">{{ nameIcon }}</div>
      <span class="list-chart__list__item__name" @click="handleNavigation(memberId)">{{ `${name} ${lastName}` }}</span>
    </div>
    <span
      class="list-chart__list__item__value"
      :style="[!color && {'color': '#11ad9b'}]"
    >{{ value }}</span>
    <div class="list-chart__list__item__color" v-if="color" :style="{ 'background-color': color }"></div>
  </li>
</template>
<script>
export default {
  name: 'ListChartItem',
  props: ['date', 'value', 'color', 'name', 'lastName', 'memberId', 'index', 'handleNavigation'],
  computed: {
    individual() {
      return !!this.name;
    },
    nameIcon() {
      const name = this.name.charAt(0).toUpperCase();
      const lastName = this.lastName.charAt(0).toUpperCase();
      return `${name}${lastName}`;
    },
    memberIndex() {
      return `${this.index}.`;
    },
  },
};
</script>
