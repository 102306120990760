<template>
  <div class="example-question-category">
    <div class="category-header flex-global" @click="toggleCategory">
      <span>{{ category }}</span>
      <svgicon
        :class="{ 'active-icon': expanded }"
        name="chevron"
        :original="true"
        width="11px"
        height="7px"
        :fill="false"
      ></svgicon>
    </div>
    <div v-if="expanded" class="category-content">
      <div
        v-for="question in categoryQuestions"
        :key="`${question.id}-${question.position}`"
        :class="['example-question-card', { active: question.isActive }]"
        @click="handleQuestionSelect(question)"
      >
        <p>{{ question.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'QuestionCategory',
    props: ['category', 'questions'],
    data() {
      return {
        expanded: false,
      };
    },
    computed: {
      categoryQuestions() {
        return this.questions.filter((question) => question.category === this.category);
      },
    },
    methods: {
      toggleCategory() {
        this.expanded = !this.expanded;
      },
      handleQuestionSelect(selectedQuestion) {
        this.$emit('handleExampleQuestionSelect', selectedQuestion);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .example-question-category {
    padding: 15px;
    padding-top: 0;
    border-radius: 3px;
    background: $grey-6;
    .category-header {
      justify-content: space-between;
      padding-top: 15px;
      padding-left: 15px;
      cursor: pointer;
      span {
        font-family: 'Nunito-Bold';
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: $grey-3;
      }
      svg {
        &.active-icon {
          transform: rotate(180deg);
        }
      }
    }
    .category-content {
      margin-top: 15px;
      .example-question-card {
        padding: 15px;
        border-radius: 3px;
        background: #fff;
        cursor: pointer;
        transition: all 0.1s ease-in;
        p {
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          color: $grey-5;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &.active {
          background: $violet-3;
          p {
            color: #fff;
          }
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
</style>
