import axios from 'axios';

const state = {
  smileys: [],
  animals: [],
  questionnaireIcons: [],
  lessons: [],
  faq: [],
  loading: false,
  loadingWithKey: {},
  warningModal: false,
  deleteModal: false,
  examplesModal: false,
  templateSearch: '',
  groupSearch: '',
  groupQuestionnairePage: 1,
  questionnaireNavigation: {
    modal: false,
    route: null,
  },
  optionsNavigation: false,
  apiError: null,
  tac: null,
  showOnboarding: true,
  locales: [
    {
      key: 'lt',
      title: 'Lietuvių',
    },
    {
      key: 'en',
      title: 'English',
    },
  ],
};

const mutations = {
  GET_SMILEYS(state, smileys) {
    state.smileys = smileys;
  },
  GET_ANIMALS(state, animals) {
    state.animals = animals;
  },
  GET_QUESTIONNAIRE_ICONS(state, questionnaireIcons) {
    state.questionnaireIcons = questionnaireIcons;
  },
  GET_LESSONS(state, lessons) {
    state.lessons = lessons;
  },
  GET_FAQ(state, faq) {
    state.faq = faq;
  },
  GET_TERMS(state, tac) {
    state.tac = tac;
  },
  RESET_TERMS(state) {
    state.tac = null;
  },
  SET_LOADING(state, status) {
    state.loading = status;
  },
  SET_LOADING_WITH_KEY(state, { key, status }) {
    state.loadingWithKey[key] = status;
  },
  SET_TEMPLATE_SEARCH(state, term) {
    state.templateSearch = term;
  },
  SET_GROUP_SEARCH(state, term) {
    state.groupSearch = term;
  },
  SET_QUESTIONNAIRE_NAVIGATION(state, { route, modal }) {
    state.questionnaireNavigation = {
      route: route || state.questionnaireNavigation.route,
      modal,
    };
  },
  SET_OPTIONS_NAVIGATION(state, status) {
    state.optionsNavigation = status;
  },
  SET_API_ERROR(state, error) {
    if (!error) {
      state.apiError = null;
    } else if (error.password) {
      state.apiError = error.password;
    } else state.apiError = error;
  },
  TOGGLE_WARNING_MODAL(state) {
    state.warningModal = !state.warningModal;
  },
  TOGGLE_DELETE_MODAL(state) {
    state.deleteModal = !state.deleteModal;
  },
  TOGGLE_EXAMPLES_MODAL(state) {
    state.examplesModal = !state.examplesModal;
  },
  SHOW_ONBOARDING_MODAL(state) {
    state.showOnboarding = true;
  },
  HIDE_ONBOARDING_MODAL(state) {
    state.showOnboarding = false;
  },
  RESET_ONBORDING_MODAL_STATE(state) {
    state.showOnboarding = true;
  },
  SET_GROUP_QUESTIONNAIRE_PAGE(state, page) {
    state.groupQuestionnairePage = page;
  },
  RESET_GROUP_QUESTIONNAIRE_QUERY(state) {
    state.groupQuestionnairePage = 1;
  },
};

const actions = {
  getGallery({ commit }, term) {
    return axios.get(`/gallery/gallery/getByCode/${term}`).then((res) => {
      const { success, data } = res.data;
      if (success) {
        switch (term) {
          case 'faces':
            commit('GET_SMILEYS', data);
            return Promise.resolve({ success: true, icons: data });
          case 'questionnaire':
            commit('GET_QUESTIONNAIRE_ICONS', data);
            return Promise.resolve({ success: true, icons: data });
          case 'animals':
            commit('GET_ANIMALS', data);
            return Promise.resolve({ success: true, icons: data });
          default:
            break;
        }
      }
      return Promise.resolve({ success: false, icons: null });
    });
  },
  getLessons({ commit }) {
    return axios.get(`frontend/educationalmaterial/list`).then((res) => {
      const { success, data } = res.data;
      if (success) {
        commit('GET_LESSONS', data);
        return Promise.resolve({ success: true, lessons: data });
      }
      return Promise.resolve({ success: false, lessons: [] });
    });
  },
  getFaq({ commit }) {
    return axios.get(`frontend/faq/list`).then((res) => {
      const { success, data } = res.data;
      if (success) {
        commit('GET_FAQ', data);
        return Promise.resolve({ success: true, faq: data });
      }
      return Promise.resolve({ success: false, faq: [] });
    });
  },
  getTermsAndConditions({ commit }) {
    return axios.get(`page/page/getBySlug/terms-and-conditions`).then((res) => {
      const { success, data } = res.data;
      if (success) {
        commit('GET_TERMS', data);
        return Promise.resolve({ success: true, tac: data });
      }
      return Promise.resolve({ success: false, tac: null });
    });
  },
  resetTermsAndConditions({ commit }) {
    commit('RESET_TERMS');
  },
  setLoading({ commit }, payload) {
    commit('SET_LOADING', payload);
  },
  setLoadingWithKey({ commit }, payload) {
    commit('SET_LOADING_WITH_KEY', payload);
  },
  setTemplateSearch({ commit }, payload) {
    commit('SET_TEMPLATE_SEARCH', payload);
  },
  setGroupSearch({ commit }, payload) {
    commit('SET_GROUP_SEARCH', payload);
  },
  setQuestionnaireNavigation({ commit }, payload) {
    commit('SET_QUESTIONNAIRE_NAVIGATION', payload);
  },
  setOptionsNavigation({ commit }, payload) {
    commit('SET_OPTIONS_NAVIGATION', payload);
  },
  setApiError({ commit }, payload) {
    commit('SET_API_ERROR', payload);
  },
  toggleWarningModal({ commit }) {
    commit('TOGGLE_WARNING_MODAL');
  },
  toggleDeleteModal({ commit }) {
    commit('TOGGLE_DELETE_MODAL');
  },
  toggleExamplesModal({ commit }) {
    commit('TOGGLE_EXAMPLES_MODAL');
  },
  showOnboardingModal({ commit }) {
    commit('SHOW_ONBOARDING_MODAL');
  },
  hideOnboardingModal({ commit }) {
    commit('HIDE_ONBOARDING_MODAL');
  },
  resetOnboardingModal({ commit }) {
    commit('RESET_ONBORDING_MODAL_STATE');
  },
  setGroupQuestionnairePage({ commit }, payload) {
    commit('SET_GROUP_QUESTIONNAIRE_PAGE', payload);
  },
  resetGroupQuestionnaireQuery({ commit }) {
    commit('RESET_GROUP_QUESTIONNAIRE_QUERY');
  },
};

const getters = {
  smileys(state) {
    return state.smileys;
  },
  animals(state) {
    return state.animals;
  },
  questionnaireIcons(state) {
    return state.questionnaireIcons;
  },
  lessons(state) {
    return state.lessons;
  },
  faq(state) {
    return state.faq;
  },
  loading(state) {
    return state.loading;
  },
  loadingWithKey(state) {
    return state.loadingWithKey;
  },
  templateSearch(state) {
    return state.templateSearch;
  },
  groupSearch(state) {
    return state.groupSearch;
  },
  questionnaireNavigation(state) {
    return state.questionnaireNavigation;
  },
  optionsNavigation(state) {
    return state.optionsNavigation;
  },
  apiError(state) {
    return state.apiError;
  },
  termsAndConditions(state) {
    return state.tac;
  },
  warningModal(state) {
    return state.warningModal;
  },
  deleteModal(state) {
    return state.deleteModal;
  },
  examplesModal(state) {
    return state.examplesModal;
  },
  showOnboarding(state) {
    return state.showOnboarding;
  },
  locales(state) {
    return state.locales;
  },
  groupQuestionnairePage(state) {
    return state.groupQuestionnairePage;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
