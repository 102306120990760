<template>
  <div>
    <section id="create__reflection" class="create__reflection">
      <header-h1 class="create__main-header"
        >{{ $t("reflection.new_reflection_type_select_header") }}</header-h1
      >
      <div class="create__card-container">
        <div class="create__card">
          <svgicon name="methods/lego" :original="true"></svgicon>
          <header-h2>{{ $t("reflection.new_reflection_type_new") }}</header-h2>
          <paragraph>{{ $t("reflection.new_reflection_type_new_content") }}</paragraph>
          <router-link to="/reflections/new">
            <button-4 @click.native="startNewHandler">{{ $t("actions.begin_action") }}</button-4>
          </router-link>
        </div>
        <div class="create__card">
          <svgicon name="methods/paper" :original="true"></svgicon>
          <header-h2>{{ $t("reflection.new_reflection_type_template") }}</header-h2>
          <paragraph class="create__card__title">{{ $t("reflection.new_reflection_type_template_content") }}</paragraph>
          <router-link :to="{name: 'Templates'}">
            <button-4>{{ $t("actions.begin_action") }}</button-4>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';


import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Paragraph from '@/components/elements/typography/Paragraph.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'CreateReflection',
  components: {
    HeaderH1,
    HeaderH2,
    Paragraph,
    Button4,
  },
  mounted() {
    if(this.welcome){
      this.setWelcome(false);
    }
  },
   computed: {
    ...mapGetters('user', ['welcome']),
  },
  methods: {
    ...mapActions('methods', ['onMethodUnset']),
    ...mapActions('questionnaire', ['unsetQuestionnaire']),
    ...mapActions('reflections', ['setEditStatus']),
    ...mapActions('user', ['setWelcome']),
    startNewHandler() {
      this.onMethodUnset();
      this.unsetQuestionnaire().then((res) => {
        const { success } = res;
        if (success) {
          this.setEditStatus('edit');
          this.$router.push({ name: 'NewReflection' });
        }
      });
    },
  },
};
</script>
<style lang="scss">
#create__reflection {
  .create__main-header {
    text-align: center;
    margin-top: 20px;
  }
  .create__card-container {
    max-width: 640px;
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: space-between;
    .create__card {
      text-align: center;
      width: 300px;
      height: 254px;
      background-color: #fff;
      border-radius: 3px;
      padding: 0 20px 20px 20px;
      position: relative;

      h2 {
        margin-top: 10px;
      }
      svg {
        margin-top: -20px;
        background-color: #fff;
        border-radius: 50%;
        border: 3px solid #fff;
        height: 74px;
        width: 74px;
      }
      p {
        margin-top: 5px;
      }
      button {
        position: absolute;
        bottom: 8%;
        left: 34%;
      }
      a {
        color: inherit;
        text-decoration: none;
      }

      &__title {
        letter-spacing: -0.7px;
      }
    }
  }
}
</style>
