<template>
  <div class="member-answer__answer-3k">
    <div class="threek-question" v-for="(answer, index) in method.answers" :key="index">
      <span class="threek-question__question">
        {{ answer.question }}
      </span>
      <div class="threek-question__answer">
        <p class="threek-answer">{{ answer.answer }}</p>
        <input type="text" class="member-answer__input" value="Daug žino" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Template3k',
  props: ['method'],
};
</script>
<style lang="scss">
.member-answer {
  &__answer-3k {
    margin-top: 16px;
    font-family: Roboto-Regular;
    font-size: 14px;

    .threek-question {
      &:not(:first-of-type) {
        margin-top: 16px;
      }
      &__question {
        color: $grey-5;
      }

      &__answer {
        margin-top: 10px;
        display: flex;

        p {
          color: $green-3;
        }
      }
    }
  }
}
</style>
