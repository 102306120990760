<template>
  <header class="group-member__header">
    <div class="header-info-wrapper">
      <header-h1 class="header">{{ member.name }} {{member.lastName}}</header-h1>
      <p>{{member.email}}</p>
    </div>
    <div class="header__container">
      <p class="header__group-title">{{ $t("group.group_member_belongs_to_group") }}:</p>
      <div class="header__group-list">
        <router-link
          class="header__group-list__link"
          v-for="group in memberGroups"
          :to="{ name: 'Group', params: { groupId: group.id } }"
          :key="group.id"
        >
          <button-5>
            {{ group.title }}
            <svgicon name="blue-arrow" width="15px" :original="true"></svgicon>
          </button-5>
        </router-link>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';

import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import Button5 from '@/components/elements/buttons/Button5.vue';

export default {
  name: 'MemberHeader',
  components: {
    HeaderH1,
    Button5,
  },
  computed: {
    ...mapGetters('members', ['memberGroups', 'member']),
  },
};
</script>
<style lang="scss" scoped>
.header-info-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p{
    color: $grey-5;
  }
}
.header {
  h1 {
    text-align: center;
  }

  &__container {
    margin-top: 20px;
    background-color: #fff;

    width: 100%;
    display: flex;
    padding: 20px;
  }

  &__group-list {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 80px;

    &__link {
      &:nth-of-type(2n - 1) {
        margin-bottom: 12px;
      }
      margin-left: 20px;
    }
  }
}
</style>
