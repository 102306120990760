<template>
  <div class="step checkout-step flex-global">
    <div class="checkout__form">
      <div class="form-box__info">
        <div class="title">1. {{ $t('licences.user_payment_info') }}</div>
        <div class="payer-type-switch flex-global">
          <p>{{ $t('licences.payer') }}</p>
          <div class="switch-wrapper" @click="handlePayerTypeSwitch(payerTypes.physical)">
            <div class="radio flex-global">
              <div class="active" v-if="isPhysical"></div>
            </div>
            <span>{{ $t('licences.payer_physical') }}</span>
          </div>
          <div class="switch-wrapper" @click="handlePayerTypeSwitch(payerTypes.juridical)">
            <div class="radio flex-global">
              <div class="active" v-if="isJuridical"></div>
            </div>
            <span>{{ $t('licences.payer_juridical') }}</span>
          </div>
        </div>

        <div class="info__input-row" v-if="isJuridical">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.companyTitle.$error }]">
              {{ $t('inputs.org_name_alt') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.companyTitle.$error }"
              v-model="checkoutForm.companyTitle" @blur="$v.checkoutForm.companyTitle.$touch()" />
            <svgicon v-if="$v.checkoutForm.companyTitle.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.companyTitle.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.companyTitle.required">
                {{ $t('warnings.company_name_is_required') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.companyTitle.minLength">
                {{ $t('warnings.company_name_length_warning') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.companyTitle.maxLength">
                {{ $t('warnings.company_name_length_warning') }}
              </span>
            </div>
          </div>
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.companyCode.$error }]">
              {{ $t('inputs.org_code') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.companyCode.$error }" v-model="checkoutForm.companyCode"
              @blur="$v.checkoutForm.companyCode.$touch()" />
            <svgicon v-if="$v.checkoutForm.companyCode.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.companyCode.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.companyCode.required">
                {{ $t('warnings.org_code_is_required') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.companyCode.maxLength">
                {{ $t('warnings.org_code_length_warning') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.companyCode.numeric">
                {{ $t('warnings.org_code_numeric_warning') }}
              </span>
            </div>
          </div>
        </div>
        <div class="info__input-row" v-if="isJuridical">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.pvmCode.$error }]">
              {{ $t('inputs.vat_code') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.pvmCode.$error }" v-model="checkoutForm.pvmCode"
              @blur="$v.checkoutForm.pvmCode.$touch()" />
            <svgicon v-if="$v.checkoutForm.pvmCode.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.pvmCode.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.pvmCode.required">
                {{ $t('warnings.vat_is_required') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.pvmCode.numeric">
                {{ $t('warnings.vat_numeric_warning') }}
              </span>
            </div>
          </div>
          <div class="info__input-field flex-global"></div>
        </div>
        <div class="info__input-row">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.name.$error }]">
              {{ $t('inputs.first_name') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.name.$error }" v-model="checkoutForm.name"
              @blur="$v.checkoutForm.name.$touch()" />
            <svgicon v-if="$v.checkoutForm.name.$error" name="warning" class="error-icon" :original="true" width="16px"
              height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.name.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.name.required">
                {{ $t('warnings.first_name_is_required') }}
              </span>
            </div>
          </div>
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.lastName.$error }]">
              {{ $t('inputs.last_name') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.lastName.$error }" v-model="checkoutForm.lastName"
              @blur="$v.checkoutForm.lastName.$touch()" />
            <svgicon v-if="$v.checkoutForm.lastName.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.lastName.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.lastName.required">
                {{ $t('warnings.last_name_is_required') }}
              </span>
            </div>
          </div>
        </div>
        <div class="info__input-row">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.email.$error }]">
              {{ $t('inputs.email_address') }}
            </span>
            <input type="email" :class="{ error: $v.checkoutForm.email.$error }" v-model="checkoutForm.email"
              @blur="$v.checkoutForm.email.$touch()" />
            <svgicon v-if="$v.checkoutForm.email.$error" name="warning" class="error-icon" :original="true" width="16px"
              height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.email.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.email.required">
                {{ $t('warnings.email_is_required') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.email.email">
                {{ $t('warnings.invalid_email') }}
              </span>
            </div>
          </div>
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.phone.$error }]">
              {{ $t('inputs.org_phone_alt') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.phone.$error }" v-model="checkoutForm.phone"
              @blur="$v.checkoutForm.phone.$touch()" />
            <svgicon v-if="$v.checkoutForm.phone.$error" name="warning" class="error-icon" :original="true" width="16px"
              height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.phone.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.phone.required">
                {{ $t('warnings.phone_is_required') }}
              </span>
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.phone.phone">
                {{ $t('warnings.invalid_phone') }}
              </span>
            </div>
          </div>
        </div>
        <div class="info__input-row" v-if="isJuridical">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.address.$error }]">
              {{ $t('inputs.address') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.address.$error }" v-model="checkoutForm.address"
              @blur="$v.checkoutForm.address.$touch()" />
            <svgicon v-if="$v.checkoutForm.address.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.address.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.address.required">
                {{ $t('warnings.address_is_required') }}
              </span>
            </div>
          </div>
        </div>
        <div class="info__input-row" v-if="isJuridical">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.city.$error }]">
              {{ $t('inputs.city') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.city.$error }" v-model="checkoutForm.city"
              @blur="$v.checkoutForm.city.$touch()" />
            <svgicon v-if="$v.checkoutForm.city.$error" name="warning" class="error-icon" :original="true" width="16px"
              height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.city.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.city.required">
                {{ $t('warnings.city_is_required') }}
              </span>
            </div>
          </div>
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.postalCode.$error }]">
              {{ $t('inputs.postal_code') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.postalCode.$error }" v-model="checkoutForm.postalCode"
              @blur="$v.checkoutForm.postalCode.$touch()" />
            <svgicon v-if="$v.checkoutForm.postalCode.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.postalCode.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.postalCode.required">
                {{ $t('warnings.postal_code_is_required') }}
              </span>
              <span class="error-wrapper__message" v-if="
                !$v.checkoutForm.postalCode.minLength || !$v.checkoutForm.postalCode.maxLength
              ">
                {{ $t('warnings.postal_code_length_warning') }}
              </span>
            </div>
          </div>
        </div>
        <div class="info__input-row" v-if="isJuridical">
          <div class="info__input-field flex-global">
            <span :class="['input-title', { 'error-message': $v.checkoutForm.country.$error }]">
              {{ $t('inputs.country') }}
            </span>
            <input type="text" :class="{ error: $v.checkoutForm.country.$error }" v-model="checkoutForm.country"
              @blur="$v.checkoutForm.country.$touch()" />
            <svgicon v-if="$v.checkoutForm.country.$error" name="warning" class="error-icon" :original="true"
              width="16px" height="17px"></svgicon>
            <div class="error-wrapper" v-if="$v.checkoutForm.country.$error">
              <span class="error-wrapper__message" v-if="!$v.checkoutForm.country.required">
                {{ $t('warnings.country_is_required') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-box__payment">
        <div class="title">2. {{ $t('licences.user_payment_type') }}</div>
        <payment-method-switch />
      </div>
    </div>
    <div class="checkout__cart">
      <checkout-cart @handleNextStep="handleSubmit" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
  requiredIf,
} from 'vuelidate/lib/validators';
import {
  LICENCE_TYPE_TEACHER,
  LICENCE_TYPE_ORG,
  PAYER_TYPE_PHYSICAL,
  PAYER_TYPE_JURIDICAL,
  PAYMENT_METHOD_PAYSERA,
} from '@/helpers/constants/licence';
import PaymentMethodSwitch from '@/components/user/licences/PaymentMethodSwitch.vue';
import CheckoutCart from '@/components/user/licences/CheckoutCart.vue';

export default {
  name: 'StepCheckout',
  components: {
    PaymentMethodSwitch,
    CheckoutCart,
  },
  data() {
    return {
      purchaseTypes: {
        teacher: LICENCE_TYPE_TEACHER,
        org: LICENCE_TYPE_ORG,
      },
      payerTypes: {
        physical: PAYER_TYPE_PHYSICAL,
        juridical: PAYER_TYPE_JURIDICAL,
      },
      paymentMethods: {
        paysera: PAYMENT_METHOD_PAYSERA,
      },
      currentPayerType: null,
    };
  },
  validations: {
    checkoutForm: {
      companyTitle: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
        minLength: minLength(2),
        maxLength: maxLength(120),
      },
      companyCode: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
        numeric,
        maxLength: maxLength(9),
      },
      pvmCode: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
        numeric,
      },
      name: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        numeric,
        minLength: minLength(9),
      },
      address: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
      },
      city: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
      },
      postalCode: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      country: {
        required: requiredIf((form) => {
          return form.payerType === PAYER_TYPE_JURIDICAL;
        }),
      },
    },
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('licences', [
      'licencesForm',
      'checkoutForm',
      'paymentMethod',
      'defaultPurchaseAmount',
    ]),
    ...mapGetters('user', ['currentActiveOrganization']),
    isPhysical() {
      return this.currentPayerType === PAYER_TYPE_PHYSICAL;
    },
    isJuridical() {
      return this.currentPayerType === PAYER_TYPE_JURIDICAL;
    },
    formIsValid() {
      return !this.$v.checkoutForm.$invalid;
    },
  },
  methods: {
    ...mapActions('licences', [
      'setPayerType',
      'resetLicencesForm',
      'resetCheckoutForm',
      'handlePayseraPurchase',
      'setPaymentMethod',
      'setIsLoading',
      'prefillCheckoutForm',
      'updateCheckoutForm',
    ]),
    handlePayerTypeSwitch(value) {
      this.setPayerType(value);
      this.currentPayerType = value;
      if (value === this.payerTypes.juridical && this.currentActiveOrganization) {
        this.updateCheckoutForm({
          type: 'email',
          property: this.currentActiveOrganization.email,
        });
        this.updateCheckoutForm({
          type: 'phone',
          property: this.currentActiveOrganization.phone,
        });
      }
    },
    handlePaymentMethodChange(value) {
      this.setPaymentMethod(value);
    },
    handleSubmit() {
      this.$v.checkoutForm.$touch();

      if (
        this.formIsValid &&
        this.licencesForm &&
        this.licencesForm.selectedLicence &&
        this.paymentMethod
      ) {
        this.setIsLoading(true);
        const licenceId = this.licencesForm.selectedLicence.id;
        const licenceType = this.licencesForm.licenceType;
        const licenceQuantity = this.licencesForm.quantity;
        const licenceSelectedOrg = this.licencesForm.selectedOrg;
        const formData = {
          licenseId: licenceId,
          ...(licenceType === this.purchaseTypes.org && {
            organizationId: licenceSelectedOrg,
          }),
          quantity:
            licenceType === this.purchaseTypes.teacher
              ? this.defaultPurchaseAmount
              : licenceQuantity,
          isCompany: this.isJuridical,
          name: this.checkoutForm.name,
          lastName: this.checkoutForm.lastName,
          email: this.checkoutForm.email,
          phone: this.checkoutForm.phone,
          ...(this.isJuridical && {
            companyTitle: this.checkoutForm.companyTitle,
            companyCode: this.checkoutForm.companyCode,
            pvmCode: this.checkoutForm.pvmCode,
            address: this.checkoutForm.address,
            city: this.checkoutForm.city,
            postalCode: this.checkoutForm.postalCode,
            country: this.checkoutForm.country,
          }),
        };

        this.handlePayseraPurchase(formData).then(() => {
          setTimeout(() => {
            this.setIsLoading(false);
          }, 1000);
        });
      }
    },
    handlePageInit() {
      this.resetCheckoutForm();
      this.handlePayerTypeSwitch(PAYER_TYPE_PHYSICAL);
      this.handlePaymentMethodChange(PAYMENT_METHOD_PAYSERA);
      this.prefillCheckoutForm();
    },
  },
};
</script>
<style lang="scss" scoped>
.step {
  &.checkout-step {
    justify-content: space-between;
    align-items: flex-start;
    width: 1030px;

    .checkout {
      &__form {
        flex: 2;

        .form-box {

          &__info,
          &__payment {
            .title {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 23px;
            }
          }

          &__info {
            margin-bottom: 40px;
            padding-right: 56px;

            .payer-type-switch {
              margin-bottom: 42px;
              width: 100%;
              justify-content: flex-start;

              p {
                margin-right: 30px;
                font-size: 16px;
                font-weight: 600;
                color: #333;
              }

              .switch-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;

                &:first-of-type {
                  margin-right: 34px;
                }

                .radio {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  border: 2px solid #000;

                  .active {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #000;
                  }
                }

                span {
                  margin-left: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #666;
                }
              }
            }

            .info {
              &__input-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 20px;

                .info__input-field {
                  &:nth-child(even) {
                    margin-left: 30px;
                  }
                }
              }

              &__input-field {
                position: relative;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;

                span {
                  font-size: 10px;
                  font-weight: 700;

                  &.input-title {
                    margin-bottom: 5px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: $grey-3;
                  }

                  &.error-message {
                    color: $red-4;
                  }
                }

                input {
                  width: 100%;
                  height: 33px;
                  padding-left: 8px;
                  border: 1px solid $grey-3;
                  border-radius: 2px;

                  &.error {
                    border: 1px solid $red-4;
                  }
                }

                svg {
                  &.error-icon {
                    position: absolute;
                    right: 10px;
                    top: 27px;
                  }
                }

                .error-wrapper {
                  &__message {
                    font-size: 12px;
                    font-weight: 500;
                    color: $red-4;
                  }
                }
              }
            }
          }
        }
      }

      &__cart {
        flex: 1;
        padding-left: 32px;
        border-left: 1px solid $grey-1;
      }
    }
  }
}
</style>
