export default [
  '#AC92ED',
  '#48CFAE',
  '#FB6E52',
  '#656D78',
  '#EC87BF',
  '#E3C9AB',
  '#47ACB1',
  '#46856A',
  '#F4CCE8',
  '#A15B65',
  '#E5E29B',
  '#A1EBF9',
  '#B76EAB',
  '#FF9F55',
  '#B98D84',
  '#6786CE',
];
