<template>
  <div v-if="!loading" class="profile-inner">
    <p class="profile-inner__title">{{ $t('profile.settings') }}</p>
    <div class="advanced-input-wrapper flex-global">
      <div class="advanced-input-wrapper__title">
        <span class="title">{{ $t('profile.language') }}</span>
        <span class="action">
          <select
            :disabled="loading"
            name="locale-select"
            v-model="currentLocale"
            @change="onLanguageChange($event)"
          >
            <option v-for="locale in locales" :key="locale.key" :value="locale.key">
              <svgicon :original="true" :name="`flags/flag-${locale.key}`" height="19px"></svgicon>
              {{ locale.title }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- <div class="advanced-input-wrapper flex-global">
      <div class="advanced-input-wrapper__title">
        <span class="title">{{ $t('profile.newsletter') }}</span>
        <span class="action">Placeholder</span>
      </div>
    </div> -->
    <div class="advanced-input-wrapper flex-global">
      <div class="advanced-input-wrapper__title">
        <span class="title non-capitalized">{{ $t('terms_and_conditions_header') }}</span>
        <span class="action">
          <div class="profile-settings-toggle-wrapper flex-global">
            <toggle
              :disabled="loadingToggle"
              :checked="user.acceptNewsletter"
              :boxValue="'acceptNewsletter'"
              @checkStatus="toggleValue"
            ></toggle>
            <span>{{ $t('email_news') }}</span>
          </div>
        </span>
      </div>
    </div>
    <div class="advanced-input-wrapper flex-global">
      <div class="advanced-input-wrapper__title">
        <span class="title non-capitalized"></span>
        <span class="action">
          <div class="profile-settings-toggle-wrapper flex-global">
            <toggle
              :boxValue="'acceptPrivacy'"
              @checkStatus="toggleValue"
              :disabledPositive="true"
            ></toggle>
            <span>
              {{ $t('i_agree') }}
              <router-link :to="{ name: 'Terms' }">{{ $t('terms_and_conditions') }}</router-link>
            </span>
          </div>
        </span>
      </div>
    </div>
  </div>
  <loading v-else />
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import Toggle from '@/components/elements/Toggle.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'ProfileSettings',
    components: {
      Toggle,
      Loading,
    },
    data() {
      return {
        currentLocale: localStorage.getItem('locale'),
        loading: false,
        loadingToggle: false,
      };
    },
    computed: {
      ...mapGetters('meta', ['locales']),
      ...mapGetters('user', ['user']),
    },
    methods: {
      ...mapActions('user', ['updateUser']),
      ...mapActions('methods', ['resetQuestionList']),
      ...mapActions('questionnaire', ['resetQuestionnaireFilterData']),
      onLanguageChange(e) {
        this.handleLocaleSubmit(e.target.value);
      },
      handleLocaleSubmit(value) {
        this.loading = true;
        setTimeout(() => {
          this.$i18n.locale = value;
          localStorage.setItem('locale', value);

          // reset some of the data to fetch new data with updated language
          this.resetQuestionList();
          this.resetQuestionnaireFilterData();

          window.location.reload();
          this.loading = false;
          this.$toast.success(this.$t('profile.successful_profile_update'));
        }, 2000);
      },
      toggleValue(value) {
        this.user[value.value] = value.checked;
        this.handleUserUpdate(value);
      },
      handleUserUpdate(value) {
        const payload = {
          [value.value]: value.checked,
        };

        this.loadingToggle = true;
        setTimeout(() => {
          this.updateUser(payload).then((res) => {
            const { success, msg } = res;
            if (success) {
              this.loadingToggle = false;
              this.$toast.success(this.$t('profile.successful_profile_update'));
            }
            if (msg) {
              this.loadingToggle = false;
              this.$toast.error(msg);
            }
          });
        }, 1000);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .profile-inner {
    .advanced-input-wrapper {
      .action {
        select {
          border: none;
          font-family: Nunito-Regular;
          font-size: 16px;
          font-weight: 400;
          color: $grey-5;
          cursor: pointer;
        }
      }
    }
  }
</style>
