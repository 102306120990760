<template>
  <div class="modal onboarding-modal">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <div class="onboarding-step-wrapper" v-if="step === 0">
            <svgicon
              class="modal__container__icon"
              name="logos/reflectus-mirror"
              :original="true"
            ></svgicon>
            <h1 class="onboarding-header">{{ $t("onboarding.welcome_message_header")}}</h1>
            <p class="onboarding-content">{{ $t("onboarding.welcome_message_content_1")}}</p>
            <p class="onboarding-content">{{ $t("onboarding.welcome_message_content_2")}}</p>
            <p class="onboarding-content green">{{ $t("onboarding.welcome_message_content_3")}}</p>
            <div class="button-wrapper step-0">
              <button-3 @click.native="handleOnboardingModal">{{ $t("onboarding.skip_onboarding")}}</button-3>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next_first_step")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 1">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step1})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-1-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 2">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step2})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-2-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 3">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step3})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-3-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 4">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step4})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-4-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 5">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step5})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-5-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 6">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step6})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-6-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleStep('next')">{{ $t("onboarding.next")}}</button-4>
            </div>
          </div>
          <div class="onboarding-step-wrapper" v-if="step === 7">
            <div class="main-step-image" :class="'step-' + step" :style="`background-image: url(${stepImages.step7})`"></div>
            <p class="main-step-paragraph" :class="'step-' + step">{{ $t("onboarding.step-7-content")}}</p>
            <div class="button-wrapper main-step-button-wrapper">
              <button-3 @click.native="handleStep('prev')">{{ $t("onboarding.back")}}</button-3>
              <p class="step-indicator" :class="'step-' + step">{{step}}/{{totalSteps}}</p>
              <button-4 @click.native="handleOnboardingModal">{{ $t("onboarding.finish")}}</button-4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'GlobalModal',
  components: {
    Button3,
    Button4,
  },
  data() {
    return{
      stepImages:{
        step1: this.getImageSrc('step1', 'onb-1'),
        step2: this.getImageSrc('step2', 'onb-2'),
        step3: this.getImageSrc('step3', 'onb-3'),
        step4: this.getImageSrc('step4', 'onb-4'),
        step5: this.getImageSrc('step5', 'onb-5'),
        step6: this.getImageSrc('step6', 'onb-6'),
        step7: this.getImageSrc('step7', 'onb-7'),
      },
      step: 0,
      totalSteps: 7
    }
  },
  methods: {
    handleOnboardingModal() {
      this.$emit('handleOnboardingModal');
    },
    handleStep(value){
      if(value === 'next'){
        this.step += 1
      }else{
        this.step -= 1
      }
    },
    getImageSrc(key, name){
      import(`@/assets/img/${name}_${localStorage.getItem('locale')}.png`).then(src => {
        this.stepImages[key] = src.default
      })
    }
  },
};
</script>
<style lang="scss">
.onboarding-modal{
  .modal__modal{
    width: 960px;
    .modal__container{
      padding: 24px 40px 32px 40px;
      min-height: 512px;
      .modal__close{
        width: 15px;
        height: 15px;
      }
      .onboarding-step-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .modal__container__icon{
          width: 125px;
          height: 80px;
        }
        .onboarding-header{
          margin-top: 34px;
          margin-bottom: 16px;
          font-family: "Nunito-Bold";
          font-size: 24px;
          text-transform: none;
          color: $violet-5;
        }
        .onboarding-content{
          margin-bottom: 16px;
          width: 480px;
          text-align: center;
          font-size: 14px;
          color: $grey-5;
          &.green{
            margin-top: 16px;
            margin-bottom: 0;
            font-family: 'Nunito-Bold';
            font-weight: bold;
            font-size: 16px;
            color: $green-2;
          }
        }
        .button-wrapper{
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 44px;
          button{
            padding: 6px 31px;
            height: 33px;
            &:first-child{
              border: 2px solid $violet-5;
            }
          }
          .step-indicator{
            font-size: 14px;
            color: $grey-5;
            &.step-7{
            margin-left: 78.8px;
          }
          }
          &.step-0{
            margin-top: 32px;
            justify-content: center;
            button{
              &:first-child{
                margin-right: 8px;
                border: 2px solid $violet-5;
              }
            }
          }
          &.main-step-button-wrapper{
            width: 100%;
            justify-content: space-between;
          }
        }
        .main-step-image{
          width: 769px;
          height: 471px;
          &.step-2{
            width: 585px;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
          }
          &.step-6{
            height: 511px;
          }
        }
        .main-step-paragraph{
          margin-top: 40px;
          width: 769px;
          font-family: 'Nunito-Bold';
          font-weight: bold;
          font-size: 16px;
          color: $violet-5;
          &.step-7{
            width: 772px;
          }
        }
      }
    }
  }
}
</style>
