<template>
  <div class="profile-inner">
    <router-link class="back-link" tag="span" :to="{ name: 'ProfileHistory' }">
      <svgicon :fill="false" name="purple-arrow" width="12px" height="7px"></svgicon>
      {{ $t('orders.all_orders') }}
    </router-link>
    <div v-if="!isLoading" class="order-inner">
      <div class="order-inner__header flex-global">
        <span class="title">{{ $t('orders.order') }} #{{ orderId }}</span>
        <!-- <span class="download-link flex-global">
          <svgicon :fill="false" name="sim-card" width="8px" height="11px"></svgicon>
          {{ $t('actions.download_action') }}
        </span> -->
      </div>
      <div class="order-inner__content flex-global">
        <div class="content-item flex-global">
          <span class="title">{{ $t('orders.date') }}</span>
          <span class="value">{{ order.date }}</span>
        </div>
        <div class="content-item flex-global">
          <span class="title">{{ $t('orders.payment_type') }}</span>
          <span class="value">{{ order.paymentType }}</span>
        </div>
        <div class="content-item flex-global">
          <span class="title">{{ $t('orders.status') }}</span>
          <span :class="['value', { 'status-green': order.status === 'paid' }]">{{ order.statusTitle }}</span>
        </div>
      </div>
      <div class="order-inner__table">
        <Table :data="tableData" :columns="tableColumns" :isRowClickable="false" />
      </div>

      <div class="order-inner__totals">
        <div class="total-price flex-global">
          <span class="title">{{ $t('licences.cart_total_no_vat') }}</span>
          <span class="price">
            <span>€</span>
            {{ cartTotals.totalNoVAT }}
          </span>
        </div>
        <div class="total-price flex-global">
          <span class="title">{{ $t('licences.cart_total_vat') }}</span>
          <span class="price">
            <span>€</span>
            {{ cartTotals.vat }}
          </span>
        </div>
        <div class="total-price big flex-global">
          <span class="title">{{ $t('licences.cart_total_with_vat') }}</span>
          <span class="price">
            <span>€</span>
            {{ cartTotals.total }}
          </span>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Table from '@/components/tables/Table.vue';

export default {
  name: 'OrderInner',
  components: {
    Table,
  },
  data() {
    return {
      isLoading: false,
      vatPercent: 21,
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('licences', ['licenceOrderInner']),
    orderId() {
      return this.$route.params.orderId;
    },
    order() {
      return this.licenceOrderInner;
    },
    orderLicences() {
      return this.licenceOrderInner.license;
    },
    orderServices() {
      return this.licenceOrderInner.services;
    },
    cartTotals() {
      const { fullPrice } = this.order;

      const licencePrice = fullPrice;
      const vatOfTotalPrice = licencePrice * (this.vatPercent / 100);
      const licencePriceWithoutVAT = licencePrice - vatOfTotalPrice;

      return {
        totalNoVAT: (licencePriceWithoutVAT).toFixed(2),
        vat: (vatOfTotalPrice).toFixed(2),
        total: (licencePrice).toFixed(2),
      };
    },
    tableData() {
      const payload = this.orderLicences && this.orderLicences.length ? this.orderLicences : this.orderServices;

      return payload && payload.length ? payload.map((item) => {
        const totalPrice = parseFloat(item.price) * parseInt(item.quantity, 10);
        return {
          title: `<span>${item.title}</span>`,
          totalPerUnit: `€ ${item.price}`,
          amount: item.quantity,
          total: `€ ${(totalPrice).toFixed(2)}`,
        };
      }) : [];
    },
    tableColumns() {
      return [
        {
          label: this.$t('orders.purchase_items'),
          key: 'title',
          rawHTML: true,
        },
        {
          label: this.$t('orders.price_per_unit'),
          key: 'totalPerUnit',
          rightAlign: true,
        },
        {
          label: this.$t('orders.amount'),
          key: 'amount',
          rightAlign: true,
        },
        {
          label: this.$t('licences.licence_price'),
          key: 'total',
          rightAlign: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions('licences', ['getLicenceOrder']),
    handleGetOrder() {
      this.isLoading = true;
      this.getLicenceOrder(this.orderId).then(() => {
        this.isLoading = false;
      });
    },
    handlePageInit() {
      this.handleGetOrder();
    },
  },
};
</script>
<style lang="scss" scoped>
.back-link {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: $violet-3;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 5px;
    stroke: none;
  }
}

.order-inner {
  margin-top: 30px;
  color: $grey-9;

  &__header {
    justify-content: flex-start;

    .title {
      margin-right: 24px;
      font-size: 16px;
      font-weight: 600;
    }

    .download-link {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      color: $violet-3;

      &:hover {
        text-decoration: underline;
      }

      svg {
        margin-right: 12px;
        stroke: none;
      }
    }
  }

  &__content {
    margin-top: 28px;
    justify-content: flex-start;

    .content-item {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        font-size: 14px;
        font-weight: 300;
        color: #5f5f5f;
      }

      .value {
        font-size: 16px;
        font-weight: 400;

        &.status-green {
          color: #3CAC47;
        }
      }

      &:not(:last-child) {
        margin-right: 47px;
      }
    }
  }

  &__table {
    margin-top: 40px;
  }

  &__totals {
    margin-top: 26px;

    .total-price {
      margin-left: auto;
      padding-right: 7px;
      width: 335px;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 400;
      color: $grey-9;

      .title {
        flex: 2;
        text-align: right;
      }

      .price {
        flex: 1;
        text-align: right;
      }

      &.big {
        font-size: 18px;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-top: 14px;
      }
    }
  }
}
</style>
