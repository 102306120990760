<template>
  <div class="reflection-options__tabs flex-global">
    <div
      :class="[
        'reflection-options__tab-item flex-global',
        { 'tab-active ': currentScheduleType === 'recurrent' },
      ]"
      @click="handleTypeSwitch('recurrent')"
    >
      <span>
        <svgicon
          class="custom-schedule-icon"
          :fill="false"
          name="refresh-green"
          width="17px"
          height="17px"
        ></svgicon>
        {{ $t('schedule.repeat_type_recurrent_title') }}
        <div class="schedule-count-display flex-global">{{ scheduleCountValues.recurrent }}</div>
      </span>
      <div class="tab-border"></div>
    </div>
    <div
      :class="[
        'reflection-options__tab-item flex-global',
        { 'tab-active ': currentScheduleType === 'once' },
      ]"
      @click="handleTypeSwitch('once')"
    >
      <span>
        <svgicon :fill="false" name="arrow-right" width="15px" height="10px"></svgicon>
        {{ $t('schedule.repeat_type_once_title') }}
        <div class="schedule-count-display flex-global">{{ scheduleCountValues.once }}</div>
      </span>
      <div class="tab-border"></div>
    </div>
    <warning-modal
      v-if="isEditModal"
      @handleToggleAction="toggleIsEditModal"
      :message="$t('modals.finish_option_message')"
    />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import WarningModal from '@/components/base/WarningModal.vue';

  export default {
    name: 'OptionsTypeSwitch',
    components: {
      WarningModal,
    },
    props: ['scheduleCountValues'],
    data() {
      return {
        isEditModal: false,
      };
    },
    computed: {
      ...mapGetters('questionnaire', ['currentScheduleType', 'scheduleIsEdit']),
    },
    methods: {
      ...mapActions('questionnaire', ['setScheduleType']),
      toggleIsEditModal() {
        this.isEditModal = !this.isEditModal;
      },
      handleTypeSwitch(type) {
        if (this.scheduleIsEdit) {
          this.toggleIsEditModal();
        } else {
          this.setScheduleType(type);
        }
      },
    },
  };
</script>
