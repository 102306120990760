<template>
  <div class="not-found">
    <svgicon class="not-found__icon" name="large-search-2"></svgicon>
    <span class="not-found__title">{{ text }}</span>
    <button-4 class="not-found__btn" @click.native="handleNavigation">{{ $t("actions.back_action") }}</button-4>
  </div>
</template>

<script>
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'NotFound',
  components: {
    Button4,
  },
  props: ['text', 'routeName'],
  methods: {
    handleNavigation() {
      this.$router.push({ name: this.routeName });
    },
  },
};
</script>

<style lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    height: 80px;
    width: 100px;
  }

  &__title {
    font-family: Nunito-Bold;
    font-size: 16px;
    margin-top: 18px;
    color: $violet-4;
  }

  &__btn {
    margin-top: 18px;
  }
}
</style>
