<template>
  <div class="question-actions" :class="{ 'pull-left': currentView === 'list' }">
    <span class="question-actions__error" v-if="questionnaireErrors.maxQuestions || error">
      {{ questionnaireErrors.maxQuestions || error }}
    </span>
    <div class="question-actions__field">
      <svgicon
        class="question-actions__icon question-example"
        :name="'question-example'"
        :original="true"
        :fill="null"
        v-tooltip="$t('questionnaire.question_example')"
        @click="handleQuestionExample"
      ></svgicon>
    </div>
    <div class="question-actions__field">
      <svgicon
        class="question-actions__icon"
        :name="'duplicate_question'"
        :original="true"
        :fill="null"
        v-tooltip="$t('questionnaire.duplicate_question')"
        @click="handleQuestionDuplicate"
      ></svgicon>
    </div>
    <div class="question-actions__field">
      <svgicon
        class="question-actions__icon"
        :name="'delete-question'"
        v-tooltip="$t('questionnaire.delete_question')"
        @click="removeQuestion(number)"
        :original="true"
        :fill="null"
      ></svgicon>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'QuestionActions',
    props: ['number', 'currentView'],
    data() {
      return {
        error: null,
      };
    },
    computed: {
      ...mapGetters('questionnaire', ['questions', 'questionnaireErrors']),
    },
    methods: {
      ...mapActions('questionnaire', [
        'removeQuestion',
        'duplicateQuestion',
        'setQuestionError',
        'setQuestionnaireErrors',
        'setCurrentQuestion',
        'setCurrentTab',
      ]),
      handleQuestionDuplicate() {
        const question = this.questions[this.number];
        if (question.error) {
          this.setQuestionError({
            index: this.number,
            error: this.$t('questionnaire.add_question_first_message'),
          });
        } else if (this.questions.length >= 20) {
          this.error = this.$t('warnings.max_question_amount_warning');
          setTimeout(() => {
            this.error = null;
          }, 4000);
        } else {
          this.error = null;
          this.setCurrentQuestion(question).then(() => this.duplicateQuestion(this.number));
        }
      },
      handleQuestionExample() {
        const { methodId } = this.questions[this.number];

        this.setCurrentQuestion(this.questions[this.number]);
        this.setCurrentTab(this.number);
        this.$emit('handleDisplayExamplesModalAction', methodId);
      },
    },
  };
</script>
<style lang="scss">
  .question-actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 17px;
    top: 12px;

    &__field {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
      &:first-of-type {
        .question-actions__icon {
          height: 34px;
          width: 34px;
        }
      }
    }

    &__icon {
      height: 24px;
      width: 24px;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        transform: translateY(-3px) scale(1.25);
      }
      &.question-example {
        stroke: none;
      }
    }

    &__title {
      font-family: Nunito-Bold;
      font-size: 10px;
      text-transform: uppercase;
      color: $grey-3;
      margin-left: 5px;
      cursor: pointer;
    }

    &__error {
      position: absolute;
      color: $red-4;
      left: -210px;
      font-size: 12px;
    }

    &.pull-left {
      right: 50px;
    }
  }
</style>
