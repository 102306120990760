<template>
  <li class="method-list__item">
    <div class="item__left">
      <svgicon :name="methodIconName" :original="true" height="2.5em"></svgicon>
    </div>
    <div class="item__right">
      <div class="top">
        <header-h4>
          <slot name="name" />
        </header-h4>
      </div>
      <div class="bottom">
        <div>
          <svgicon class="bottom__icon" name="info" :original="true" height="1em"
            v-tooltip="{
              content: method.description,
              classes: ['info-tooltip'],
              placement: 'bottom-center',
              targetClasses: ['it-has-a-tooltip'],
              offset: 10,
              delay: {
                show: 100,
                hide: 300,
              },
            }"
          ></svgicon>
          <svgicon name="clock" :original="true" height="1em"></svgicon>
          <span>
            <slot name="time" />
          </span>
        </div>
        <div :class="['plus-icon', { 'plus-icon--pulse': iconPulse }]" @click="methodSelectHandler">
          <svgicon class="plus-icon__svg" name="plus"></svgicon>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

export default {
  name: 'MethodListItem',
  components: {
    HeaderH4,
  },
  props: ['iconName', 'method'],
  computed: {
    ...mapGetters('methods', ['questionTypeList', 'selectedMethod', 'iconPulse']),
    methodIconName(){
      if(this.method.id === 4){
        return `methods/question-text`
      }
      if(this.method.id === 16){
        return `methods/question-select`
      }
      return `methods/${this.method.template}`
    }
  },
  methods: {
    ...mapActions('methods', ['addMethod', 'onMethodSelect']),
    methodSelectHandler() {
      this.onMethodSelect(this.method);
      this.addMethod(this.method);
    },
  },
};
</script>

<style lang="scss" scoped>
.method-list__item {
  width: 262px;
  height: 62px;
  background-color: #fff;
  margin-top: 1px;
  display: flex;
  padding: 10px 20px;

  .item__left {
    display: flex;
    align-items: center;
  }
  .item__right {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    flex: 1;

    .top {
      h4:first-letter {
        text-transform: capitalize;
      }
    }

    .top,
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .bottom {
      margin-top: 6px;

      & > div {
        display: flex;
        align-items: center;

        svg:first-of-type {
          margin-right: 10px;
        }

      }
        &__icon {
          &:hover {
            cursor: pointer;
          }
        }

      span {
        margin-left: 5px;
        font-size: 12px;
        font-family: Roboto-regular;
      }

      .plus-icon {
        position: absolute;
        right: 0;
        top: 8%;
        transition: all 0.2s;

        &:hover,
        &:active {
          cursor: pointer;
          transform: translateY(-3px);
        }

       &__svg {
         height: 35px;
         width: 35px;
       }

        &--pulse {
          animation: pulse 1.5s infinite;
        }
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.25);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }
}
</style>
