<template>
  <div class="member-answer">
    <div class="member-answer__header">
      <div class="member-answer__top method-item">
        <span class="method-item__number">{{ number + 1 }}</span>
        <svgicon
          class="method-item__icon"
          :name="methodIconName"
          :original="true"
          width="2em"
        ></svgicon>
        <header-h2 class="method-item__title">{{ currentMethod.title.toLowerCase() }}</header-h2>
      </div>
      <div class="member-answer__question">
        <header-h4>{{ question.title }}</header-h4>
      </div>
    </div>
    <component
      v-if="component && !loading"
      :is="component"
      :method="method"
      :question="question"
      :memberAnswer="memberAnswer"
    ></component>
    <loading v-if="loading"></loading>
    <!-- <div class="member-answer__answer-null" v-if="!method.answers">
      <span class="answer-null">Nereflektavo</span>
    </div>-->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

  import TemplateYesNo from '@/components/groups/member/answer-templates/TemplateYesNo.vue';
  import TemplateTrafficLight from '@/components/groups/member/answer-templates/TemplateTrafficLight.vue';
  import TemplateThermometer from '@/components/groups/member/answer-templates/TemplateThermometer.vue';
  import TemplateSmileys from '@/components/groups/member/answer-templates/TemplateSmileys.vue';
  import TemplateSentences from '@/components/groups/member/answer-templates/TemplateSentences.vue';
  import TemplateMatrix from '@/components/groups/member/answer-templates/TemplateMatrix.vue';
  import Template321 from '@/components/groups/member/answer-templates/Template321.vue';
  import TemplateQuestion from '@/components/groups/member/answer-templates/TemplateQuestion.vue';
  import Template3k from '@/components/groups/member/answer-templates/Template3k.vue';
  import TemplatePie from '@/components/groups/member/answer-templates/TemplatePie.vue';
  import TemplateIndicator from '@/components/groups/member/answer-templates/TemplateIndicator.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'MemberAnswer',
    components: {
      HeaderH2,
      HeaderH4,
      yes_no: TemplateYesNo,
      traffic_light: TemplateTrafficLight,
      thermometer: TemplateThermometer,
      smileys: TemplateSmileys,
      incomplete_sentence: TemplateSentences,
      progress_matrix: TemplateMatrix,
      Template321,
      question: TemplateQuestion,
      Template3k,
      pie: TemplatePie,
      indicator: TemplateIndicator,
      Loading,
    },
    props: ['method', 'number', 'question', 'loading'],
    computed: {
      ...mapGetters('methods', ['questionTypeList']),
      ...mapGetters('members', ['memberAnswers']),
      currentMethod() {
        if (!this.questionTypeList.length) return false;
        if (!this.question) return false;
        return this.questionTypeList.find((method) => method.id === this.questionMethodId);
      },
      // this checks if question is old question method with select answer type
      questionMethodId() {
        return this.question.methodId === 4 && this.question.answerTemplate === 'select'
          ? 16
          : this.question.methodId;
      },
      component() {
        let component;
        const { template } = this.currentMethod;
        switch (template) {
          case '3-2-1':
            component = 'Template321';
            break;
          case '3k':
            component = 'Template3k';
            break;
          case 'choice':
            component = 'question';
            break;
          default:
            component = template;
            break;
        }
        return component;
      },
      methodIconName() {
        if (this.currentMethod.id === 4) {
          return `methods/question-text`;
        }
        if (this.currentMethod.id === 16) {
          return `methods/question-select`;
        }
        return `methods/${this.currentMethod.template}`;
      },
      memberAnswer() {
        if (!this.memberAnswers.length) return this.$t('member_did_not_reflect');
        const answer = this.memberAnswers.find((answer) => answer.questionId === this.question.id);
        if (answer.parts && answer.parts.length) return answer.parts;
        if (
          (this.questionAnswerType.methodId === 4 &&
            this.questionAnswerType.answerTemplate === 'text') ||
          this.questionAnswerType.methodId === 11
        )
          return answer.original;
        return answer.value || this.$t('member_skipped_alt');
      },
      questionAnswerType() {
        if (!this.question) return false;
        const { methodId, answerTemplate } = this.question;
        return { methodId, answerTemplate };
      },
    },
  };
</script>

<style lang="scss">
  .member-answer {
    width: 100%;
    background-color: #fff;
    min-height: 110px;
    padding: 20px;
    padding-left: 30px;
    position: relative;
    display: flex;
    flex-direction: column;

    &__header {
      .method-item {
        display: flex;
        align-items: center;
        position: relative;

        &__title {
          color: $violet-2;
          margin-left: 7px;
          text-transform: capitalize;
        }

        &__number {
          position: absolute;
          left: -18px;
          font-family: Nunito-SemiBold;
          color: $violet-2;
          font-size: 12px;
        }
      }
    }

    &__question {
      margin-top: 8px;

      h4 {
        font-size: 15px;
        max-width: 580px;
      }
    }

    &__answer-null {
      margin-top: 10px;
      .answer-null {
        font-family: Roboto-Regular;
        font-size: 14px;
        color: $grey-4;
      }
    }

    &__input {
      min-width: 190px;
      height: 28px;
      padding: 8px;
      border: 1px solid $grey-2;
      margin-left: 20px;

      font-family: Roboto-Regular;
      font-size: 14px;
      color: $grey-5;

      &::placeholder {
        color: $grey-3;
      }
    }

    &:not(:first-of-type) {
      margin-top: 3px;
    }
  }
</style>
