<template>
  <button>
    <slot/>
  </button>
</template>
<script>
export default {
  name: 'Button7',
};
</script>
<style lang="scss" scoped>
button {
  border: none;
  background: $orange-2;
  color: #fff;
  border-radius: 45px;
  padding: 5px 34px;
  font-size: 14px;
  font-family: Roboto-Medium;
  &:hover {
    background: $orange-1;
    cursor: pointer;
  }
  &:active {
    color: $grey-2;
  }
  &:disabled {
    background: $grey-2;
  }
}
</style>
