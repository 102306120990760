<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <svgicon class="modal__container__icon" name="large-remove" :original="true"></svgicon>
          <h4 class="modal__container__heading" v-if="type === 'questionnaire'">
            {{ $t('modals.delete_questionnaire_modal_header') }}
          </h4>
          <h4 class="modal__container__heading" v-if="type === 'reflection'">
            {{ $t('modals.delete_reflection_modal_header') }}
          </h4>
          <p class="modal__container__warning" v-if="type === 'questionnaire'">
            {{ $t('modals.delete_questionnaire_modal_warning') }}
          </p>
          <p class="modal__container__warning" v-if="type === 'reflection'">
            {{ $t('modals.delete_reflection_modal_warning') }}
          </p>
          <div class="modal__container__actions">
            <button-4 @click.native="handleModalAction">{{ $t('yes_cap') }}</button-4>
            <button-3 @click.native="handleModalToggle">{{ $t('no_cap') }}</button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';

  export default {
    name: 'DeleteModal',
    components: {
      Button4,
      Button3,
    },
    props: ['type'],
    methods: {
      ...mapActions('meta', ['toggleDeleteModal']),
      handleModalToggle() {
        this.toggleDeleteModal();
      },
      handleModalAction() {
        this.$emit('handleDeleteAction');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    &__container {
      &__warning {
        max-width: 430px;
      }
    }
  }
</style>
