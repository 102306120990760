<template>
  <div class="unassigned-values-message">
    <p>{{ $t("group_statistics.unassigned_values_header") }}</p>
    <p>{{ $t("group_statistics.unassigned_values_content") }}<br>„{{ $t("group_statistics.individual_answers") }}”</p>
  </div>
</template>

<script>

export default {
  name: 'UnassignedValuesMessage',
}
</script>
