<template>
  <div class="checkout-cart">
    <div class="checkout-cart__header flex-global">
      <svgicon name="shopping-cart" width="20" height="20"></svgicon>
      <p>{{ $t('cart') }}</p>
    </div>
    <div class="checkout-cart__table">
      <div class="header-row flex-global">
        <span>{{ $t('orders.purchase_item') }}</span>
        <span>{{ $t('licences.licence_price') }}</span>
      </div>
      <div class="item-row licence">
        <div class="item-row__content flex-global">
          <div class="title">
            <p>
              {{ licenceInCart.title }}
              <span class="no-bold">x {{ licenceQuantity }}</span>
            </p>
            <p class="small">{{ licenceInCartDuration[0] }}</p>
          </div>
          <div class="price">
            <span>€</span>
            {{ licencePrice }}
          </div>
        </div>
        <div class="item-row__actions">
          <span class="action" @click="handleCartEdit">
            {{ $t('actions.change_action') }}
          </span>
        </div>
      </div>
    </div>
    <div class="checkout-cart__total">
      <div class="total-price flex-global">
        <span class="title">{{ $t('licences.cart_total_no_vat') }}</span>
        <span class="price">
          <span>€</span>
          {{ cartTotals.totalNoVAT }}
        </span>
      </div>
      <div class="total-price flex-global">
        <span class="title">{{ $t('licences.cart_total_vat') }}</span>
        <span class="price">
          <span>€</span>
          {{ cartTotals.vat }}
        </span>
      </div>
      <div class="total-price big flex-global">
        <span class="title">{{ $t('licences.cart_total_with_vat') }}</span>
        <span class="price">
          <span>€</span>
          {{ cartTotals.total }}
        </span>
      </div>
    </div>
    <div class="checkout-cart__checkbox">
      <custom-checkbox
        :checked="agreement"
        :boxValue="'purchase-agreement-licence'"
        @checkStatus="handleAgreement"
      >
        <template #label>
          <span class="checkbox-label">
            {{ $t('licences.rule_agreement_label') }}
            <a href="https://reflectus.lt/pirkimo-taisykles/" target="_blank">
              {{ $t('licences.rule_agreement_link_label') }}
            </a>
          </span>
        </template>
      </custom-checkbox>
    </div>
    <div class="checkout-cart__main-action flex-global">
      <button-4 :disabled="isLoadingPurchase || !agreement" @click.native="handleNextStep">
        {{ $t('actions.continue_action') }}
      </button-4>
    </div>
    <!-- rf-403 hide repeated payment info -->
    <!-- <div class="checkout-cart__more-info">
      <div class="info-item flex-global">
        <div class="title">
          <p>{{ $t('licences.repeated_payment_total') }}</p>
          <p>{{ licenceInCartDuration[1] }}</p>
        </div>
        <div class="content">
          <span>
            <span>€</span>
            {{ licenceInCart.price }}
          </span>
        </div>
      </div>
      <div class="info-item flex-global">
        <div class="title">
          <p>{{ $t('licences.next_payment_due_date') }}</p>
        </div>
        <div class="content">
          <span>{{ nextPaymentDate }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import {
    LICENCE_DURATION_TYPE_MONTHLY,
    LICENCE_DURATION_TYPE_ANNUAL,
  } from '@/helpers/constants/licence';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import CustomCheckbox from '@/components/elements/CustomCheckbox.vue';

  export default {
    name: 'CheckoutCart',
    props: [],
    components: {
      Button4,
      CustomCheckbox,
    },
    data() {
      return {
        durationTypes: {
          monthly: LICENCE_DURATION_TYPE_MONTHLY,
          annual: LICENCE_DURATION_TYPE_ANNUAL,
        },
        vatPercent: 21,
        agreement: false,
      };
    },
    computed: {
      ...mapGetters('licences', ['cartContent', 'licencesForm', 'isLoadingPurchase']),
      licenceInCart() {
        return this.cartContent ? this.cartContent.licence : null;
      },
      licencePrice() {
        const priceValue = parseFloat(this.licenceInCart.price) * this.licenceQuantity;
        return priceValue.toFixed(2);
      },
      licenceInCartDuration() {
        const licenceDurationType = this.licenceInCart.licenseDuration;
        switch (licenceDurationType) {
          case this.durationTypes.monthly:
            return [
              `(${this.$t('licences.monthly_licence')})`,
              `(${this.$t('licences.monthly_licence_short')})`,
            ];
          case this.durationTypes.annual:
            return [
              `(${this.$t('licences.yearly_licence')})`,
              `(${this.$t('licences.yearly_licence_short')})`,
            ];
          default:
            return '-';
        }
      },
      licenceQuantity() {
        return this.licencesForm.quantity;
      },
      cartTotals() {
        const { price, priceWithoutVAT } = this.licenceInCart;
        const licencePrice = parseFloat(price, 10);
        const licencePriceWithoutVAT = parseFloat(priceWithoutVAT, 10);
        const vatOfTotalPrice = licencePrice - licencePriceWithoutVAT;

        return {
          totalNoVAT: (this.licenceQuantity * licencePriceWithoutVAT).toFixed(2),
          vat: (this.licenceQuantity * vatOfTotalPrice).toFixed(2),
          total: (this.licenceQuantity * licencePrice).toFixed(2),
        };
      },
      nextPaymentDate() {
        const licenceDurationType = this.licenceInCart.licenseDuration;
        const today = this.moment().format('YYYY-MM-DD');

        if (licenceDurationType === this.durationTypes.monthly) {
          const futureMonth = this.moment(today).add(1, 'M');
          const futureMonthEnd = this.moment(futureMonth).endOf('month');
          return this.moment(today).date() !== this.moment(futureMonth).date() &&
            this.moment(futureMonth).isSame(futureMonthEnd.format('YYYY-MM-DD'))
            ? futureMonth.add(1, 'd').format('MMMM Do, YYYY')
            : futureMonth.format('MMMM Do, YYYY');
        }
        if (licenceDurationType === this.durationTypes.annual) {
          return this.moment(today)
            .add(1, 'y')
            .format('MMMM Do, YYYY');
        }
        return '-';
      },
    },
    methods: {
      handleCartEdit() {
        this.$router.push({ name: 'StepLicences' });
      },
      handleNextStep() {
        this.$emit('handleNextStep');
      },
      handleAgreement() {
        this.agreement = !this.agreement;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .checkout-cart {
    color: #000;
    &__header {
      margin-bottom: 20px;
      justify-content: flex-start;
      svg {
        margin-right: 12px;
      }
      p {
        font-size: 16px;
        font-weight: 600;
      }
    }
    &__table {
      margin-bottom: 20px;
      .header-row {
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        span {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 300;
          color: #5f5f5f;
        }
      }
      .item-row {
        padding: 10px 6px 20px 0;
        border-bottom: 1px dashed #cccccc;
        &__content {
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 10px;
          .title {
            font-size: 14px;
            font-weight: 600;
            .small {
              text-transform: lowercase;
              font-size: 12px;
              font-weight: 500;
              color: #5a5a5a;
            }
            .no-bold {
              font-weight: 400;
            }
          }
          .price {
            font-size: 14px;
            font-weight: 600;
            .span {
              margin-right: 4px;
            }
          }
        }
        &__actions {
          .action {
            font-size: 14px;
            font-weight: 700;
            text-decoration-line: underline;
            cursor: pointer;
            color: $blue-5;
          }
        }
      }
    }
    &__total {
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #cccccc;
      .total-price {
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 400;
        color: $grey-9;
        .title {
          flex: 2;
          text-align: right;
        }
        .price {
          flex: 1;
          text-align: right;
        }
        &.big {
          font-size: 18px;
          font-weight: 700;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        &:last-child {
          margin-top: 14px;
        }
      }
    }
    &__checkbox {
      margin-bottom: 20px;
      .checkbox-label {
        width: 305px;
        font-size: 14px;
      }
    }
    &__main-action {
      button {
        width: 100%;
        height: 39px;
        font-size: 16px;
        font-weight: 500;
        background-color: $violet-3;
        &:disabled {
          background-color: $grey-2;
        }
      }
    }
    &__more-info {
      margin-top: 23px;
      padding: 18px 0 30px 20px;
      border-top: 1px dashed #d1d1d1;
      .info-item {
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        color: $grey-9;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
</style>
