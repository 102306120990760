<template>
  <div>

    <section id="reflection__options" class="reflection__options">
      <header-h1>REFLEKSIJOS KLAUSIMYNO SIUNTIMO NUSTATYMAI</header-h1>
      <div class="options__container">
        <options-form-old :key="`options-form--${scheduleLength}`" :questionnaire="questionnaire" :scheduleLength="scheduleLength"></options-form-old>
        <div class="options__add">
          <span>Kitas Laikas</span>
          <button-5 @click.native="addScheduleHandler">+ Pridėti</button-5>
        </div>
        <div class="options__actions">
          <create-reflection-buttons :submit="true" :type="type"></create-reflection-buttons>
        </div>
      </div>
    </section>
    <div class="options__errors" v-if="questionnaireErrors.length">
      <div class="options__error" v-for="(error, index) in questionnaireErrors" :key="index">
        {{ index + 1 !== questionnaireErrors.length ? `${error},` : `${error}` }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';


import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import CreateReflectionButtons from '@/components/reflections/CreateReflectionButtons.vue';
import Button5 from '@/components/elements/buttons/Button5.vue';
import OptionsFormOld from '@/components/reflections/options/OptionsFormOld.vue';

export default {
  name: 'TemplateOptions',
  components: {
    HeaderH1,
    CreateReflectionButtons,
    Button5,
    OptionsFormOld,
  },
  data() {
    return {
      type: null,
      scheduleLength: 0,
    };
  },
  created() {
    this.setEditStatus('edit');
    const { id, type } = this.$route.params;
    this.type = type;
    if (!id) {
      this.loading = false;
      return;
    }
    if (!this.questionnaire.id) {
      this.getQuestionnaire({questionnaireId: id});
      this.getQuestionnaireSchedule(id);
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire', 'questionnaireErrors']),
  },
  methods: {
    ...mapActions('questionnaire', ['addSchedule', 'getQuestionnaireSchedule', 'getQuestionnaire']),
    ...mapActions('reflections', ['getTemplateById', 'setEditStatus']),
    addScheduleHandler() {
      this.addSchedule();
      this.scheduleLength = this.questionnaire.schedules.length;
    },
  },
};
</script>
