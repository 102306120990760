<template>
  <div class="modal" @click="handleModalToggle">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div class="modal__container">
          <svgicon
            @click="handleModalToggle"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__close"
          ></svgicon>
          <slot name="modal-main-icon"></slot>
          <h4 class="modal__container__heading">
            <slot name="modal-header"></slot>
          </h4>
          <p class="modal__container__warning">
            <slot name="modal-warning"></slot>
          </p>
          <div class="modal__container__actions">
            <slot name="modal-actions"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalModal',
  methods: {
    handleModalToggle() {
      this.$emit('handleModalToggle');
    },
  },
};
</script>
