<template>
  <figure class="bar-chart__container" v-if="groupStatQuestion">
    <chart-arith-values :average="average" :median="median" :mode="mode" />
    <bar-chart class="bar-chart" :chart-data="barChartData" :options="barChartOptions"></bar-chart>
  </figure>
</template>
<script>
import BarChart from '@/components/stats/chart-templates/BarChart.vue';
import ChartArithValues from '@/components/charts/ChartArithValues.vue';

export default {
  name: 'BarTemplate',
  components: {
    BarChart,
    ChartArithValues,
  },
  props: ['groupStatQuestion'],
  mounted() {
    this.getValues();
  },
  data() {
    return {
      arrayOfValues: [],
      total: 0,
      average: null,
    };
  },
  methods: {
    getValues() {
      this.arrayOfValues = [];
      if (this.groupStatQuestion) {
        this.groupStatQuestion.forEach((element) => {
          if (!Number.isNaN(element.percentage)) this.arrayOfValues.push(element.percentage);
        });
      }

      // sorting values from min to max
      this.arrayOfValues.sort((a, b) => a - b);

      this.getAverage();
    },
    getAverage() {
      this.total = this.arrayOfValues.reduce((a, b) => a + b, 0);
      this.average = this.arrayOfValues.length ? Math.round((this.total / this.arrayOfValues.length) * 100) / 100 : '-'
    },
  },
  watch: {
    groupStatQuestion() {
      this.getValues();
    },
  },
  computed: {
    median() {
      if (this.arrayOfValues.length === 0) return '-';

      const half = Math.floor(this.arrayOfValues.length / 2);

      if (this.arrayOfValues.length % 2) return this.arrayOfValues[half];

      return (this.arrayOfValues[half - 1] + this.arrayOfValues[half]) / 2.0;
    },
    mode() {
      const modes = [];
      const count = [];
      let i;
      let number;
      let maxIndex = 0;

      for (i = 0; i < this.arrayOfValues.length; i += 1) {
        number = this.arrayOfValues[i];
        count[number] = (count[number] || 0) + 1;
        if (count[number] > maxIndex) {
          maxIndex = count[number];
        }
      }

      for (i in count)
        if (Object.prototype.hasOwnProperty.call(count, i)) {
          if (count[i] === maxIndex) {
            modes.push(Number(i));
          }
        }

      if (modes.length === this.arrayOfValues.length) {
        if (this.arrayOfValues.length === 1) {
          return modes;
        }
        return '-';
      }
      return modes;
    },
    barChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const indice = tooltipItem.index;
              return `${data.labels[indice]}: ${data.datasets[0].data[indice]}`;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: this.groupStatQuestion[0].min,
                suggestedMax: this.groupStatQuestion[0].max,
              },
            },
          ],
        },
      };
    },
    barChartData() {
      return {
        labels: this.groupStatQuestion.map((item) => item.value),
        datasets: [
          {
            data: this.groupStatQuestion.map((item) => item.percentage),
            backgroundColor: '#48CFAE',
            borderColor: 'transparent',
            borderWidth: 0,
            barThickness: 13,
          },
        ],
      };
    },
  },
};
</script>
