<template>
  <section id="create__custom" class="create__custom" v-if="!loading">
    <template-header
      @handleTemplateSearch="handleTemplateSearch"
      @handleTypeSelect="handleTypeSelect"
    ></template-header>
    <template-list-actions
      @handleTemplateSearch="handleTemplateSearch"
      @handleApplyFilter="handleApplyFilter"
      @handleApplySort="handleApplySort"
    ></template-list-actions>
    <transition-group class="custom__container" v-if="templatesPublic" name="template-transition">
      <template-card
        v-for="template in templatesPublic"
        :key="template.id"
        :personal="false"
        :draft="false"
        :template="template"
        :type="'public'"
        @handleDelete="handleDelete"
      >
        <template v-slot:header>{{ template.title }}</template>
        <template v-slot:time>{{ template.duration }}</template>
        <template v-if="template.owner" v-slot:author>
          {{ template.owner.name }} {{ template.owner.lastName }}
        </template>
        <template v-slot:content><p :title="template.recommendations">{{ template.recommendations }}</p></template>
      </template-card>
    </transition-group>
    <div class="custom__list-container" v-if="!templatesPublic.length">
      <svgicon class="custom__list-container__icon" name="large-search"></svgicon>
      <p class="custom__list-container__title">{{ $t('messages.no_search_results_message') }}</p>
    </div>
    <pagination
      v-if="totalPages > 1"
      :activePage="selectedTemplatePage"
      :totalPages="totalPages"
      @selectPage="selectPage"
    ></pagination>
  </section>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import VueScrollTo from 'vue-scrollto';
  import _ from 'lodash';
  import TemplateHeader from '@/components/templates/TemplateHeader.vue';
  import TemplateCard from '@/components/templates/TemplateCard.vue';
  import Pagination from '@/components/base/Pagination.vue';
  import Loading from '@/components/base/Loading.vue';
  import TemplateListActions from '@/components/templates/TemplateListActions/TemplateListActions.vue';

  export default {
    name: 'Templates',
    components: {
      TemplateHeader,
      TemplateCard,
      Pagination,
      Loading,
      TemplateListActions,
    },
    data() {
      return {
        queries: {
          limit: 10,
          q: null,
          offset: null,
          clientId: 301,
          action: 'filter',
        },
        totalCount: null,
        defaultPageValue: 1,
      };
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('reflections', [
        'templatesPublic',
        'selectedTemplateType',
        'selectedTemplatePage',
        'selectedTemplateSort',
      ]),
      ...mapGetters('meta', ['templateSearch', 'loading']),
      ...mapGetters('methods', ['questionTypeList']),
      ...mapGetters('questionnaire', ['questionnaireFilterData']),
      totalPages() {
        return Math.ceil(this.totalCount / this.queries.limit) || 0;
      },
    },
    methods: {
      ...mapActions('reflections', ['getTemplates', 'setTemplatePage', 'setTemplateSort']),
      ...mapActions('methods', ['getQuestionList']),
      ...mapActions('meta', ['setLoading']),
      ...mapActions('questionnaire', ['getQuestionnaireFilterData']),
      async handlePageInit() {
        this.setLoading(true);
        if (!this.questionTypeList.length) await this.getQuestionList();

        this.queries.action = this.selectedTemplateType;
        this.queries.orderBy = this.selectedTemplateSort;
        this.queries.offset =
          this.selectedTemplatePage === 1
            ? 0
            : this.selectedTemplatePage * this.queries.limit - this.queries.limit;
        this.queries.q = this.templateSearch || null;
        await this.getTemplates(this.queries).then(this.handleRequest);

        if (!this.questionnaireFilterData) {
          await this.getQuestionnaireFilterData();
        }
      },
      handleRequest(response) {
        this.setLoading(false);
        const { totalCount, success } = response;
        if (totalCount && success) {
          this.totalCount = totalCount;
        }
      },
      selectPage(page) {
        this.setTemplatePage(page);
        this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
        this.getTemplates(this.queries).then(this.handleRequest);
      },
      handleTemplateSearch(value) {
        this.queries.q = value;
        this.queries.offset = null;
        this.totalCount = null;
        this.setTemplatePage(this.defaultPageValue);
        this.getTemplates(this.queries).then(this.handleRequest);
      },
      handleTypeSelect(type) {
        if (this.queries.action !== type) {
          this.queries.action = type;
          this.queries.q = null;
          this.queries.offset = null;
          this.totalCount = null;
          this.setTemplatePage(this.defaultPageValue);
          this.getTemplates(this.queries).then(this.handleRequest);
        }
      },
      handleFilterApplyScroll() {
        const resultContainer = document.getElementsByClassName('custom__container')[0];

        if (resultContainer) {
          setTimeout(() => {
            VueScrollTo.scrollTo(resultContainer, 1000);
          }, 500);
        }
      },
      handleDelete(success) {
        if (success) this.getTemplates(this.queries).then(this.handleRequest);
      },
      resetQueryPageValues() {
        this.queries.offset = null;
        this.totalCount = null;
        this.setTemplatePage(this.defaultPageValue);
      },
      handleApplyFilter(value) {
        this.resetQueryPageValues();
        this.queries = { ...this.queries, value };
        this.getTemplates(this.queries).then((res) => {
          this.handleRequest(res);
          if (res && res.success && value && !_.isEmpty(value)) {
            this.handleFilterApplyScroll();
          }
        });
      },
      handleApplySort(value) {
        this.resetQueryPageValues();
        this.queries = { ...this.queries, orderBy: value.orderBy };
        this.setTemplateSort(value.orderBy);
        this.getTemplates(this.queries).then(this.handleRequest);
      },
    },
  };
</script>
<style lang="scss" scoped>
  #create__custom {
    max-width: 940px;
    margin: 0 auto;
    .custom__container {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
    }
    .custom__list-container {
      width: 940px;
    }
  }
  .footer {
    position: relative;
  }
</style>
