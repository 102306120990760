<template>
  <div class="personal-licences">
    <p class="personal-licences__title">{{ $t('licences.my_licences') }}</p>
    <div v-if="licences && licences.length">
      <licence-card v-for="licence in computedLicences" :key="licence.id" :licence="licence" />
    </div>
    <div v-else class="personal-licences__empty">
      <p>{{ $t('licences.no_personal_licences_message') }}</p>
      <p class="small">{{ $t('licences.personal_licences_error_message') }}</p>
    </div>
  </div>
</template>
<script>
import LicenceCard from '@/components/user/licences/LicenceCard.vue';

export default {
  name: 'MyLicences',
  props: ['licences'],
  components: {
    LicenceCard,
  },
  computed: {
    computedLicences() {
      if (!this.licences || (this.licences && !this.licences.length)) {
        return [];
      };

      return this.licences.map(licence => {
        return {
          id: licence.relationId,
          title: licence.title,
          type: 2,
          durationType: licence.duration,
          customer: licence.organizationTitle,
          validUntil: this.moment(licence.validUntil).format('YYYY-MM-DD'),
          price: '-',
          nextPayment: '-',
        }
      })
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.personal-licences {
  color: $grey-9;

  &__empty {
    p {
      font-size: 14px;
      font-weight: 400;

      &.small {
        font-size: 12px;
        font-weight: 300;
        color: #888888;
      }
    }
  }
}
</style>
