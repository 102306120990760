<template>
  <div class="table-wrapper">
    <table role="table">
      <thead>
        <tr>
          <th v-for="column in columns" :key="`${column.key}`">
            <div :class="['head-title-wrapper', { 'right-align': column.rightAlign }]">
              <span class="title">{{ column.label }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody role="rowgroup">
        <tr v-if="!isDataPresent">
          <td class="no-data-row" :colspan="columnCount">{{ $t('messages.no_data_message') }}</td>
        </tr>
        <tr :class="{ 'row-clickable': isRowClickable, 'active-row': isRowActive(item.id) }" v-else
          v-for="(item, index) in data" :key="`${index}`" @click="handleRowAction(item)">
          <td v-for="column in columns" :key="`${column.key}`" :class="{ 'right-align': column.rightAlign }">
            <div v-if="column.rawHTML" v-html="item[column.key]"></div>
            <div v-else-if="column.isActionButton">
              <span class="action-button" @click="handleActionClick(item[column.key].action)">
                {{ item[column.key].actionTitle }}
              </span>
            </div>
            <div v-else>{{ item[column.key] }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'Table',
  props: ['data', 'columns', 'isRowClickable', 'isMultipleSelect', 'selectedItems'],
  components: {},
  data() {
    return {};
  },
  computed: {
    isDataPresent() {
      return this.data && this.data.length;
    },
    columnCount() {
      return this.columns ? this.columns.length : 0;
    },
  },
  methods: {
    handleRowAction(item) {
      this.$emit('rowAction', item.id);
    },
    handleActionClick(columnAction) {
      columnAction();
    },
    isRowActive(id) {
      return (
        this.isMultipleSelect && this.selectedItems && this.selectedItems === id
        // this.selectedItems.length &&
        // this.selectedItems.find((item) => item === id)
      );
    },
  },
};
</script>
<style lang="scss">
table {
  width: 100%;
  min-width: 977px;
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid #dedfe0;

    th {
      padding: 10px 5px;
      text-align: left;
    }

    .head-title-wrapper {
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #5f5f5f;
      }

      &.right-align {
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      &.row-clickable {
        cursor: pointer;

        &:hover {
          background-color: #eeedf7;
        }
      }

      &.active-row {
        background-color: #eeedf7;

        &:hover {
          background-color: #d6d5df;
        }
      }
    }

    td {

      padding: 14px 5px;
      font-size: 14px;
      font-weight: 400;
      color: $grey-9;

      &>div {}

      &.no-data-row {
        padding-top: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }

      &.right-align {
        &>div {
          text-align: right;
        }
      }

      span {
        &.small {
          font-size: 12px;
          color: #888;
        }

        &.action-button {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          color: $blue-5;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
