<template>
  <div :class="['licence-card flex-global', { active: isLicenceActive }]">
    <div class="licence-card__info flex-global">
      <div class="title-wrapper flex-global">
        <p>{{ licence.title }}</p>
        <div class="title-wrapper__duration">
          <span v-if="typeof (licence.durationType) === 'string'">{{ licence.durationType }}</span>
          <span v-if="licence.durationType === 0">{{ $t('licences.trial_licence') }}</span>
          <span v-if="licence.durationType === 1">{{ $t('licences.monthly_licence') }}</span>
          <span v-if="licence.durationType === 2">{{ $t('licences.yearly_licence') }}</span>
        </div>
        <div :class="['title-wrapper__type flex-global', { active: isLicenceActive }]">
          <span v-if="licence.type === 0">{{ $t('licences.licence_type_inactive') }}</span>
          <span v-if="licence.type === 1">{{ $t('licences.licence_type_active') }}</span>
          <span v-if="licence.type === 2">{{ $t('licences.licence_type_suspended') }}</span>
        </div>
      </div>
      <div v-if="isFromOrg" class="info-wrapper flex-global">
        <div class="info-wrapper__section">
          <p class="title">{{ $t('orders.number') }}</p>
          <p class="value">{{ licence.number }}</p>
        </div>
        <div class="info-wrapper__section">
          <p class="title">{{ $t('licences.licence_valid_until') }}</p>
          <p class="value">{{ licence.validUntil }}</p>
        </div>
        <div class="info-wrapper__section">
          <p class="title">{{ $t('licences.licence_price') }}</p>
          <p class="value">{{ licence.price }}</p>
        </div>
      </div>
      <div v-else class="info-wrapper flex-global">
        <div class="info-wrapper__section">
          <p class="title">{{ $t('licences.licence_customer') }}</p>
          <p class="value">{{ licence.customer }}</p>
        </div>
        <div class="info-wrapper__section">
          <p class="title">{{ $t('licences.licence_valid_until') }}</p>
          <p class="value">{{ licence.validUntil }}</p>
        </div>
        <!-- <div class="info-wrapper__section" v-if="isLicenceActive && !isTrialLicence">
          <p class="title">{{ $t('licences.licence_price') }}</p>
          <p class="value">{{ licence.price }}</p>
        </div> -->
        <!-- <div class="info-wrapper__section" v-if="isLicenceActive && !isTrialLicence">
          <p class="title">{{ $t('licences.licence_next_payment') }}</p>
          <p class="value">{{ licence.nextPayment }}</p>
        </div> -->
      </div>
    </div>
    <div v-if="isLicenceActive && !isTrialLicence" class="licence-card__actions flex-global">
      <div class="licence-action flex-global" @click="handleChange">
        <svgicon name="user-edit" :original="true" width="10px" height="9px" :fill="false"></svgicon>
        <p>{{ $t('licences.change_licence_action') }}</p>
      </div>
      <!-- <div class="licence-action flex-global" @click="handleSuspend">
        <svgicon
          name="user-suspend"
          :original="true"
          width="10px"
          height="11px"
          :fill="false"
        ></svgicon>
        <p>{{ $t('licences.suspend_licence_action') }}</p>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'LicenceCard',
  props: ['licence', 'isFromOrg'],
  components: {},
  data() {
    return {};
  },
  computed: {
    isLicenceActive() {
      return this.licence && this.licence.type === 1;
    },
    isTrialLicence() {
      return this.licence && this.licence.durationType === 0;
    },
  },
  methods: {
    ...mapActions('licences', ['resetLicencesForm']),
    handleChange() {
      this.resetLicencesForm();
      this.$router.push({ name: 'StepLicences' });
    },
    handleSuspend() {
      this.$emit('handleLicenceSuspend', this.licence.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.licence-card {
  margin-bottom: 10px;
  padding: 16px;
  max-width: 866px;
  min-width: 750px;
  height: 107px;
  justify-content: space-between;
  align-items: flex-start;
  border-left: 2px solid #e4e4e4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 4px 4px 0px;
  background: #ffffff;

  &.active {
    border-left: 2px solid #11ab99;
  }

  &__info {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    .title-wrapper {
      p {
        margin-right: 7px;
        font-size: 16px;
        font-weight: 600;
      }

      &__duration {
        margin-right: 7px;

        span {
          font-size: 14px;
          font-weight: 400;
          color: #969696;
        }
      }

      &__type {
        padding: 1px 10px;
        border-radius: 999px;
        background-color: #e4e4e4;

        span {
          font-size: 12px;
          font-weight: 400;
          text-transform: capitalize;
          color: #797979;
        }

        &.active {
          background-color: #d6ffdf;

          span {
            color: #48a952;
          }
        }
      }
    }

    .info-wrapper {
      &__section {
        &:not(:last-child) {
          margin-right: 45px;
        }

        p {
          &.title {
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            color: #888888;
          }

          &.value {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__actions {
    flex-direction: column;
    align-items: flex-start;

    .licence-action {
      justify-content: flex-start;
      padding: 2px 6px;
      width: 100%;
      border-radius: 3px;
      cursor: pointer;

      svg {
        margin-right: 12px;
        stroke: none;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: $blue-5;
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
</style>
