<template>
  <div class="member-answer__answer-sentences">
    <span class="answer-date">{{ displayDate }}</span>
    <span class="answer-sentence" v-html="incompleteSentence"></span>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'SentenceDisplay',
    props: ['date', 'answers', 'sentence'],
    computed: {
      incompleteSentence() {
        let sentence = this.sentence;
        const answerArray = this.answers;

        if (!Array.isArray(answerArray)) {
          return `<span class="no-reflection">${answerArray}</span>`;
        }

        answerArray.forEach((phrase) => {
          sentence = sentence.replace(
            '@',
            `<span class="answer-sentence answer-sentence--answer">${phrase || '_'}</span>`
          );
        });
        return sentence;
      },
      displayDate() {
        return moment(this.date).format('YYYY-MM-DD');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .member-answer {
    &__answer-sentences {
      .answer-date {
        margin-right: 5px;
        font-size: 14px;
        color: #5a5a5a;
      }
    }
  }
</style>
