<template>
  <div class="stat-value-in-time">
    <div
      class="stat-value-in-time__item"
      v-for="(label, index) in filteredQuestionInTime"
      :key="index"
    >
      <!-- rf-373 old checkbox -->
      <!-- <checkbox @checkStatus="handleCheck" :boxValue="label.value" :checked="true"></checkbox> -->

      <!-- rf-373 disable median and mode by default -->
      <checkbox
        @checkStatus="handleCheck"
        :boxValue="label.value"
        :checked="!(label.value === `${$t('median')}` || label.value === `${$t('mode')}`)"
      ></checkbox>
      <span
        class="stat-value-in-time__item__color"
        :style="{ 'background-color': label.color }"
      ></span>
      <span class="stat-value-in-time__item__name">
        {{ label.value }}
      </span>
    </div>
  </div>
</template>
<script>
  import Checkbox from '@/components/elements/Checkbox.vue';

  export default {
    name: 'StatValueInTime',
    props: ['filteredQuestionInTime'],
    components: {
      Checkbox,
    },
    methods: {
      handleCheck(status) {
        this.$emit('checkStats', status);
      },
    },
  };
</script>
<style lang="scss">
  .stat-value-in-time {
    display: grid;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 16px;

    &__item {
      display: flex;
      align-items: center;

      &__color {
        height: 3px;
        width: 22px;
      }

      &__name {
        margin-left: 4px;
        word-break: break-word;
      }

      .custom-checkbox {
        margin-right: 8px;
      }
    }
  }
</style>
