<template>
  <div class="calendar-chart">
    <vc-calendar is-expanded :locale="calendarLocale" :from-date="fromDate" :attributes='attributes'/>
  </div>
</template>

<script>

export default {
  name: 'CalendarChart',
  props: ['answers'],
  data(){
    return {
      calendarLocale: localStorage.getItem('locale') || 'lt'
    }
  },
  computed: {
    attributes() {
      const filteredData = this.answers.map((answer) => {
        const {
          date,
          color
        } = answer;
        return {
          dot: {
            style: { backgroundColor: color }
          },
          dates: date
        };
      });

      return filteredData
    },
    fromDate() {
      return ((this.answers && this.answers.length) && this.answers[0].date) ? new Date(this.answers[0].date) : new Date()
    }
  },
};
</script>

<style lang="scss">
@import 'mixins';

.calendar-chart {
  width: 490px;
  max-height: 422px;
  overflow-y: auto;
  margin-top: 20px;
  .vc-container{
    border: none;
    border-radius: 0;
    background-color: $grey-6;
    .vc-pane-container{
      .vc-arrows-container{
        padding: 8px 30px;
      }
    }
    .vc-weekday{
      color: $grey-5;
    }
  }
  .vc-dot{
    width: 26px;
    height: 26px;
    margin-bottom: 2px;
    z-index: -1;
    &:not(:last-child){
      margin-right: -15px;
    }
  }
  // decrease the margins of calendar dots if there are 8 or more present in a day
  .vc-dot:first-child:nth-last-child(n + 8),
  .vc-dot:first-child:nth-last-child(n + 8) ~ .vc-dot {
    &:not(:last-child){
      margin-right: -20px;
    }
  }
}
</style>
