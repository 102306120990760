import axios from 'axios';
import i18n from '../../plugins/i18n'

import { answers, memberAnswers } from './helpers/memberAnswers.data';
import { handleCommitResponse, handleResponseError } from './helpers/responseHelpers';

const getDefaultState = () => ({
  answers,
  mockAnswers: memberAnswers,
  currentView: 'list',
  currentTab: 1,
  answerType: 'individual',
  members: [],
  member: null,
  memberAnswers: [],
  memberReflections: [],
  memberGroups: [],
});

const state = getDefaultState();

const mutations = {
  TOGGLE_VIEW(state, view) {
    state.currentView = view;
  },
  SET_TAB(state, tab) {
    state.currentTab = tab;
  },
  SET_TYPE(state, type) {
    state.answerType = type;
  },
  GET_MEMBERS(state, members) {
    state.members = members;
  },
  GET_MEMBER(state, member) {
    state.member = member;
  },
  GET_MEMBER_REFLECTIONS(state, reflections) {
    state.memberReflections = reflections;
  },
  GET_MEMBER_GROUP_LIST(state, groups) {
    state.memberGroups = groups;
  },
  GET_MEMBER_ANSWERS(state, answers) {
    state.memberAnswers = answers;
  },
  GET_ALL_MEMBER_ANSWERS(state, reflections) {
    state.memberAnswers = [...state.memberAnswers, ...reflections];
  },
  REMOVE_MEMBER(state, memberIndex) {
    state.members.splice(memberIndex, 1);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  toggleView({ commit }, payload) {
    commit('TOGGLE_VIEW', payload);
  },
  setTab({ commit }, payload) {
    commit('SET_TAB', payload);
  },
  setType({ commit }, payload) {
    commit('SET_TYPE', payload);
  },
  getMembers({ commit }, payload) {
    return axios
      .get(`client/group/getMembers/${payload}`)
      .then((res) => handleCommitResponse(res, commit, 'GET_MEMBERS'))
      .catch(handleResponseError);
  },
  getMember({ commit, state }, payload) {
    const member = state.members.find((member) => member.id === payload * 1);
    if (member) {
      commit('GET_MEMBER', member);
      return Promise.resolve({ success: true, data: member });
    }
    return Promise.resolve({ success: false, msg: i18n.t("warnings.cant_get_member_message") });
  },
  getMemberReflections({ commit }, payload) {
    return axios({
      method: 'GET',
      url: '/reflection/reflection/list',
      params: {
        ...payload,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_MEMBER_REFLECTIONS'))
      .catch(handleResponseError);
  },
  getMemberAnswers({ commit }, payload) {
    return axios({
      method: 'GET',
      url: '/reflection/answer/list',
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_MEMBER_ANSWERS'))
      .catch(handleResponseError);
  },
  getAllMemberAnswers({ commit }, payload) {
    return axios({
      method: 'GET',
      url: '/reflection/answer/list',
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_ALL_MEMBER_ANSWERS'))
      .catch(handleResponseError);
  },
  // unused
  getAllMemberAnswersAllReflections({ commit }, payload) {
    return axios({
      method: 'GET',
      url: '/reflection/answer/list',
      params: { ...payload },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_ALL_MEMBER_ANSWERS_REFLECTIONS'))
      .catch(handleResponseError);
  },
  resetMemberAnswers({ commit }) {
    commit('GET_MEMBER_ANSWERS', []);
  },
  getMemberGroupList({ commit }, payload) {
    return axios({
      method: 'GET',
      url: 'client/group/list',
      params: {
        clientId: payload,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_MEMBER_GROUP_LIST'))
      .catch(handleResponseError);
  },
  removeMember({ commit, getters }, payload) {
    const { id: groupId } = payload.group
    const { id: memberId } = payload.member

    const members = getters.members;

    return axios
      .delete(`/client/group/removeMember/${groupId}/${memberId}`)
      .then((res) => {
        const { success } = res.data

        if (success) {
          if (members.length) {
            const memberIndex = members.findIndex((member) => member.id === memberId)
            commit('REMOVE_MEMBER', memberIndex)
          }
          return Promise.resolve({ success })
        }
        return Promise.resolve({ success: false, msg: i18n.t("warnings.cant_remove_member_message") })
      })
      .catch(handleResponseError)
  },
  resetMembersState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  answers(state) {
    return state.answers;
  },
  currentView(state) {
    return state.currentView;
  },
  memberAnswers(state) {
    return state.memberAnswers;
  },
  tabbedAnswers(state) {
    const tabbedAnswers = state.mockAnswers.map((answer, i) => ({
      ...answer,
      tab: i + 1,
    }));
    return tabbedAnswers;
  },
  selectedAnswer(state, getters) {
    return getters.tabbedAnswers.filter((answer) => answer.tab === state.currentTab)[0];
  },
  currentTab(state) {
    return state.currentTab;
  },
  answerType(state) {
    return state.answerType;
  },
  members(state) {
    return state.members;
  },
  member(state) {
    return state.member;
  },
  memberReflections(state) {
    return state.memberReflections;
  },
  memberGroups(state) {
    return state.memberGroups;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
