<template>
  <div :src="option.show" class="method-answer__image" v-tooltip="option.value">
    <img class="method-answer__image__img" :src="option.show" />
    <span class="method-answer__value">{{ option.value }}</span>
  </div>
</template>

<script>
export default {
  name: 'TemplateImage',
  props: ['option'],
};
</script>