<template>
  <div class="stat-components__header">
    <div class="stat-components__header__question">
      <header-h4 v-if="questionMethod.id === 5">{{ questionTitle }} {{ question.title }}</header-h4>
      <header-h4 v-else>{{ questionTitle }}</header-h4>
    </div>
    <div class="stat-components__header__title">
      <svgicon
        class="stat-components__header__icon"
        :name="methodIconName"
        :original="true"
        width="25px"
        height="25px"
      ></svgicon>
      <header-h2 class="stat-components__header__title__value">
        {{ questionMethod.title.toLowerCase() }}
      </header-h2>
    </div>
    <svgicon
      v-if="currentView === 'list' && answerType === 'inTime'"
      :class="[
        'member-answer-time__arrow group-stats',
        { 'member-answer-time__arrow--expanded': answerExpanded },
      ]"
      name="green-arrow"
      :original="true"
      height="16px"
      width="16px"
      color="#3B328B"
      :fill="false"
      @click="expandAnswer"
    ></svgicon>
  </div>
</template>
<script>
  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import HeaderH4 from '@/components/elements/typography/HeaderH4.vue';

  export default {
    name: 'StatComponentsHeader',
    components: {
      HeaderH2,
      HeaderH4,
    },
    props: [
      'questionTitle',
      'questionMethod',
      'question',
      'currentView',
      'answerExpanded',
      'answerType',
    ],
    computed: {
      methodIconName() {
        if (this.questionMethod.id === 4) {
          return `methods/question-text`;
        }
        if (this.questionMethod.id === 16) {
          return `methods/question-select`;
        }
        return `methods/${this.questionMethod.template}`;
      },
    },
    methods: {
      expandAnswer() {
        if (this.currentView === 'list' && this.answerType === 'inTime') {
          this.$emit('expandAnswer');
        }
      },
    },
  };
</script>
<style lang="scss">
  .stat-components {
    &__header {
      display: flex;

      &__title {
        color: $violet-2;
        margin-left: 7px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        margin-left: auto;

        &__value {
          margin-left: 10px;
        }
      }

      &__question {
        h4 {
          font-size: 16px;
          max-width: 580px;
        }
      }
    }
  }
</style>
