<template>
  <div class="list-chart-filter">
    <ul class="list-chart-filter__items">
      <li class="list-chart-filter__items__item" v-for="percent in percentage" :key="percent.value">
        <checkbox
          v-if="method !== 1"
          :checked="true"
          :boxValue="percent.value"
          @checkStatus="checkStatus"
        ></checkbox>
        <span
          class="list-chart-filter__items__item__color"
          :style="{ 'background-color': percent.color }"
        ></span>
        <span
          class="list-chart-filter__items__item__value"
        >{{ `${percent.value} (${percent.count})` }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Checkbox from '@/components/elements/Checkbox.vue';

export default {
  name: 'ListChartFilter',
  components: {
    Checkbox,
  },
  props: ['percentage', 'method'],
  methods: {
    checkStatus(value) {
      this.$emit('checkStatus', value);
    },
  },
};
</script>

<style lang="scss">
.list-chart-filter {
  &__items {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;

      &__color {
        min-height: 16px;
        min-width: 16px;
        border-radius: 50%;
        margin-left: 16px;
      }

      &__value {
        font-family: Roboto-Medium;
        font-size: 14px;
        margin-left: 8px;
        word-break: break-word;
      }

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
