import axios from 'axios';
import { handleCommitResponse, handleResponseError } from './helpers/responseHelpers';

const getDefaultState = () => ({
  servicesList: [],
  servicesCart: [],
  isLoadingPayment: false,
  serviceSearchQuery: null,
});

const state = getDefaultState();

const mutations = {
  GET_SERVICES(state, value) {
    state.servicesList = value;
  },
  ADD_SERVICE_TO_CART(state, service) {
    state.servicesCart.push(service);
  },
  REMOVE_SERVICE_FROM_CART(state, index) {
    state.servicesCart.splice(index, 1);
  },
  RESET_SERVICES_CART(state) {
    state.servicesCart = [];
  },
  SET_IS_LOADING_PAYMENT(state, value) {
    state.isLoadingPayment = value;
  },
  SET_SEARCH_QUERY(state, value) {
    state.serviceSearchQuery = value;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getServices({ commit }, payload) {
    const { q } = payload;
    return axios({
      method: 'GET',
      url: 'client/service/list',
      params: {
        q,
      },
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_SERVICES'))
      .catch(handleResponseError);
  },
  addServiceToCart({ commit, state }, serviceId) {
    const foundServiceInCart = state.servicesCart
      ? state.servicesCart.find((item) => item.id === serviceId)
      : false;
    if (!foundServiceInCart) {
      const foundServiceObject = state.servicesList.find((service) => service.id === serviceId);
      commit('ADD_SERVICE_TO_CART', foundServiceObject);
      return Promise.resolve({ success: true });
    }
    return Promise.resolve({ success: false });
  },
  removeServiceFromCart({ commit, state }, serviceId) {
    const index = state.servicesCart.findIndex((item) => item.id === serviceId);
    if (index || index === 0) {
      commit('REMOVE_SERVICE_FROM_CART', index);
      return Promise.resolve({ success: true });
    }
    return Promise.resolve({ success: false });
  },
  handlePayseraPurchaseForServices(context, payload) {
    return axios({
      method: 'post',
      url: '/client/payment/createServicePayment',
      data: {
        ...payload,
      },
    })
      .then(({ data: { success, data } }) => {
        if (!success) return Promise.resolve({ success: false });
        const { payseraRedirectUrl } = data;
        window.location.replace(payseraRedirectUrl);

        return Promise.resolve({ success: true, data });
      })
      .catch(handleResponseError);
  },
  setSearchQuery({ commit }, value) {
    commit('SET_SEARCH_QUERY', value);
  },
  setIsLoadingPayment({ commit }, value) {
    commit('SET_IS_LOADING_PAYMENT', value);
  },
  resetServicesCart({ commit }) {
    commit('RESET_SERVICES_CART');
  },
  resetServicesState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  servicesList(state) {
    return state.servicesList;
  },
  servicesCart(state) {
    return state.servicesCart;
  },
  isLoadingPayment(state) {
    return state.isLoadingPayment;
  },
  serviceSearchQuery(state) {
    return state.serviceSearchQuery;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
