<template>
  <div class="date-chip-wrapper flex-global">
    <p>{{dateDisplay}}</p>
     <svgicon
      @click="toggleDateRemove"
      color="white"
      :fill="false"
      width="10px"
      height="10px"
      name="multiply"
    ></svgicon>
  </div>
</template>

<script>
export default {
  name: 'DateChip',
  props: ['value', 'isDate'],
  computed: {
    dateDisplay(){
      return this.moment(this.value).format("YYYY-MM-DD");
    }
  },
  methods: {
    toggleDateRemove() {
      this.$emit('removeDate', this.dateDisplay);
    }
  },
};
</script>

<style lang="scss">
.date-chip-wrapper{
  width: 120px;
  height: 28px;
  border-radius: 14px;
  color: #fff;
  background-color: $red;
  p{
    font-size: 14px;
    &:first-child{
      margin-right: 10px;
    }
  }
  svg{
    cursor: pointer;
  }
}
</style>
