<template>
  <div class="modal" @click.native="toggleModal">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div v-if="!confirmationModal" class="modal__container">
          <div class="users-info">
            <div class="users-info__title">
              <span>{{ $t('profile.users') }}</span>
            </div>
            <!-- <div class="users-info__actions">
              some actions here
            </div> -->
            <div class="users-info__table">
              <Table :data="tableData" :columns="tableColumns" :isRowClickable="true" :isMultipleSelect="true"
                :selectedItems="selectedUserIds" @rowAction="handleRowClick" />
            </div>
            <div class="users-info__buttons flex-global">
              <button-3 class="cancel" @click.native="toggleModal">
                {{ $t('actions.cancel_action') }}
              </button-3>
              <button-4 class="save" :disabled="!selectedUserIds" @click.native="toggleConfirmationModal">
                {{ $t('actions.assign_licence_action') }}
              </button-4>
            </div>
          </div>
        </div>

        <div v-else class="modal__container confirmation-modal">
          <div class="users-info">
            <div class="users-info__confirmation-message">
              <p>
                <svgicon name="warning-small" width="24px" height="20px"></svgicon>
                {{ $t('warnings.assign_licence_to_user_message') }}
              </p>
              <p>
                {{ $t('warnings.assign_licence_to_user_subtitle_message') }}
              </p>
            </div>
            <div class="users-info__buttons flex-global">
              <button-3 class="cancel" @click.native="toggleConfirmationModal">
                {{ $t('actions.cancel_action') }}
              </button-3>
              <button-4 class="save" @click.native="handleAssign">
                {{ $t('actions.confirm_action') }}
              </button-4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/tables/Table.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'LicenceModal',
  props: ['licence'],
  components: {
    Table,
    Button3,
    Button4,
  },
  data() {
    return {
      confirmationModal: false,
      selectedUserIds: null,
    };
  },
  created() {
    this.handleModalInit();
  },
  computed: {
    ...mapGetters('user', ['organizationUserList']),
    orgId() {
      return this.$route.params.orgId && parseInt(this.$route.params.orgId, 10);
    },
    tableData() {
      return this.organizationUserList && this.organizationUserList.length
        ? this.organizationUserList.map((user) => {
          return {
            id: user.id,
            firstName: user.name,
            lastName: user.lastName,
            email: user.email,
            lastLoggedIn: user.lastLoginDate,
            role: user.roleTitle,
            // licence: '-',
          };
        })
        : [];
    },
    tableColumns() {
      return [
        {
          label: this.$t('inputs.first_name_cap'),
          key: 'firstName',
        },
        {
          label: this.$t('inputs.last_name_cap'),
          key: 'lastName',
        },
        {
          label: this.$t('inputs.email_address_cap'),
          key: 'email',
        },
        {
          label: this.$t('last_logged_in'),
          key: 'lastLoggedIn',
        },
        {
          label: this.$t('role'),
          key: 'role',
        },
        // {
        //   label: this.$t('licences.assigned_licence'),
        //   key: 'licence',
        // },
      ];
    },
  },
  methods: {
    ...mapActions('user', ['getOrganizationUserList']),
    toggleModal() {
      this.confirmationModal = false;
      this.$emit('handleModalToggle');
    },
    toggleConfirmationModal() {
      this.confirmationModal = !this.confirmationModal;
    },
    handleGetOrganizationUsers() {
      this.getOrganizationUserList(this.orgId);
    },
    handleRowClick(value) {
      if (value === this.selectedUserIds) {
        this.selectedUserIds = null;
      } else {
        this.selectedUserIds = value;
      }
      // array for multiple user assign. check table and OrganizationLicence components to roll back to it later
      // const index = this.selectedUserIds.indexOf(value);
      // if (index === -1) {
      //   this.selectedUserIds.push(value);
      // } else {
      //   this.selectedUserIds.splice(index, 1);
      // }
    },
    handleAssign() {
      this.$emit('handleAction', this.selectedUserIds);
    },
    handleModalInit() {
      this.handleGetOrganizationUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &__modal {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  }

  &__container {
    padding: 22px 27px;
    padding-bottom: 44px;
    width: 1166px;
    border-radius: 5px;

    &.confirmation-modal {
      padding: 30px 22px 25px 22px;
      width: 522px;

      button {
        &.save {
          width: 202px;
        }
      }
    }
  }

  .users-info {
    width: 100%;
    text-align: left;

    &__title {
      margin-bottom: 10px;
      text-align: left;

      span {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &__confirmation-message {
      margin-bottom: 20px;

      p {
        &:first-child {
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 700;
          color: $grey-9;
        }

        font-size: 12px;
        font-weight: 300;
        color: #888888;
      }
    }

    &__actions {
      margin-bottom: 25px;
    }

    &__table {
      margin-bottom: 30px;
    }

    &__buttons {
      justify-content: flex-end;

      button {
        height: 40px;

        &.cancel {
          width: 145px;
        }

        &.save {
          margin-left: 18px;
          background: $violet-3;

          &:hover {
            background: $violet-5;
          }

          &:disabled {
            background: $grey-2;
          }
        }
      }
    }
  }
}
</style>
