<template>
  <div class="group-member__filter">
    <div class="group-member__filter__field">
      <input
        class="group-member__filter__field__input"
        type="text"
        :placeholder='$t("reflection.enter_reflection_title")'
        v-model="q"
        v-debounce="handleMemberFilter"
      />
    </div>
    <div class="group-member__filter__field">
      <multi-select
        :width="377"
        :placeholder='`- ${$t("group.select_group_placeholder")} -`'
        :options="memberGroupsOptions"
        @selectedValue="handleGroupSelect"
      ></multi-select>
    </div>
    <div class="group-member__filter__field">
      <button-4 @click.native="handleMemberFilter">{{ $t("actions.show_action") }}</button-4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Button4 from '@/components/elements/buttons/Button4.vue';
import MultiSelect from '@/components/elements/select/MultiSelect.vue';

export default {
  name: 'MemberFilter',
  components: {
    Button4,
    MultiSelect,
  },
  data() {
    return {
      q: '',
    };
  },
  computed: {
    ...mapGetters('members', ['memberGroups']),
    memberGroupsOptions() {
      return this.memberGroups.map((group) => ({ ...group, value: group.title }));
    },
  },
  methods: {
    handleGroupSelect(group) {
      this.$emit('handleGroupSelect', group);
    },
    handleMemberFilter() {
      this.$emit('handleMemberFilter', this.q);
    },
  },
};
</script>

<style lang="scss">
.group-member {
  &__filter {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #fff;

    &__field {
      position: relative;
      display: flex;

      &__input {
        width: 377px;
        height: 30px;
        padding: 10px;
      }

      &__icon {
        position: absolute;
        right: 6px;
        top: 6px;
      }

      &__title {
        position: absolute;
        left: 0;
        top: -12px;
      }
    }
  }
}
</style>
