<template>
  <div v-if="!isCallback" class="callback-window">
    <div class="callback-window__logo">{{ content.logo }}</div>
    <div class="callback-window__title" :style="{ color: `${content.titleColor}` }">
      {{ content.title }}
    </div>
    <div class="callback-window__subtitle">
      {{ content.subtitle }}
      <a v-if="isCancel" href="mailto:info@reflectus.lt">info@reflectus.lt</a>
    </div>
    <div class="callback-window__button">
      <button-4 @click.native="content.actionHandler.action()">
        {{ content.actionHandler.actionTitle }}
      </button-4>
    </div>
  </div>
  <div v-else class="callback-window">
    <div class="callback-window__loading-wrapper">
      <img :src="loadingGif" alt="loading" />
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import loadingGif from '@/assets/gif/loading.gif';

  export default {
    name: 'CheckoutCallback',
    props: [],
    components: {
      Button4,
    },
    data() {
      return {
        loadingGif,
      };
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      typeOfCallback() {
        const { path } = this.$route;
        const pathValues = path.split('/');
        return pathValues[pathValues.length - 1];
      },
      isSuccess() {
        return this.typeOfCallback === 'accept';
      },
      isCancel() {
        return this.typeOfCallback === 'cancel';
      },
      isCallback() {
        return this.typeOfCallback === 'callback';
      },
      content() {
        switch (this.typeOfCallback) {
          case 'accept':
            return {
              logo: '🎉',
              title: this.$t('licences.success_payment_header'),
              titleColor: '#11900E',
              subtitle: this.$t('licences.success_payment_subtitle'),
              actionHandler: {
                action: () => this.handlePushAction('ProfileLicence'),
                actionTitle: this.$t('licences.my_licences_alt'),
              },
            };
          case 'cancel':
            return {
              logo: '❌',
              title: this.$t('licences.error_payment_header'),
              titleColor: '#ff3366',
              subtitle: this.$t('licences.error_payment_subtitle'),
              actionHandler: {
                action: () => this.handlePushAction('StepLicences'),
                actionTitle: this.$t('actions.try_again_action'),
              },
            };
          case 'callback':
            return {
              logo: '-',
              title: '-',
              subtitle: '-',
            };
          default:
            return null;
        }
      },
    },
    methods: {
      ...mapActions('licences', ['resetLicencesForm']),
      handlePushAction(name) {
        this.$router.push({ name });
      },
      handlePageInit() {
        this.resetLicencesForm();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .callback-window {
    margin-top: 245px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #000;
    &__title {
      margin-top: 5px;
      margin-bottom: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }
    &__subtitle {
      width: 560px;
      font-size: 16px;
      a {
        color: $green-3;
      }
    }
    &__button {
      margin-top: 30px;
      button {
        width: 280px;
        height: 39px;
        font-size: 14px;
        font-weight: 400;
        background-color: $violet-3;
        &:hover {
          background-color: $violet-5;
        }
      }
    }
    &__loading-wrapper {
      img {
        width: 185px;
        height: auto;
      }
    }
  }
</style>
