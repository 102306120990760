<template>
  <div
    class="reflection-options__option"
    :class="{ 'hide-option': !showScheduleOption || isScheduleCustomChild }"
  >
    <options-inner-once
      v-if="scheduleType === 'once'"
      :i="i"
      :currentScheduleType="currentScheduleType"
      :isScheduleChecked="isScheduleChecked"
      @handleScheduleRemove="handleScheduleRemove"
      @handleIsEditModal="handleIsEditModal"
    />
    <options-inner-custom
      v-else
      :i="i"
      :currentScheduleType="currentScheduleType"
      :isScheduleChecked="isScheduleChecked"
      @handleScheduleRemove="handleScheduleRemove"
      @handleIsEditModal="handleIsEditModal"
    />
    <warning-modal
      v-if="isEditModal"
      @handleToggleAction="toggleIsEditModal"
      :message="$t('modals.finish_option_message')"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import OptionsInnerOnce from '@/components/reflections/options/OptionsInnerOnce.vue';
  import OptionsInnerCustom from '@/components/reflections/options/OptionsInnerCustom.vue';
  import WarningModal from '@/components/base/WarningModal.vue';

  export default {
    name: 'OptionsSchedule',
    components: {
      OptionsInnerOnce,
      OptionsInnerCustom,
      WarningModal,
    },
    props: ['i', 'currentScheduleType', 'isScheduleChecked', 'isScheduleCustomChild'],
    data() {
      return {
        isEditModal: false,
      };
    },
    computed: {
      ...mapGetters('questionnaire', ['questionnaire']),
      schedule() {
        return this.questionnaire.schedules[this.i];
      },
      scheduleType() {
        return this.schedule.repeatType === 'custom' || this.schedule.repeatType === 'daily'
          ? 'recurrent'
          : 'once';
      },
      showScheduleOption() {
        return this.currentScheduleType === this.scheduleType;
      },
    },
    methods: {
      handleScheduleRemove(payload) {
        this.$emit('handleScheduleRemove', payload);
      },
      toggleIsEditModal() {
        this.isEditModal = !this.isEditModal;
      },
      handleIsEditModal() {
        this.toggleIsEditModal();
      },
    },
  };
</script>
<style lang="scss">
  .reflection-options__inputs {
    .vdp-datepicker {
      &.empty-date-input {
        input {
          border: 1px solid $red-4;
        }
      }
    }
    .input-error-active {
      border: 1px solid $red-4;
    }
  }
</style>
