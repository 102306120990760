<template>
  <div class="method-answer__color" v-tooltip="option.value">
    <div class="method-answer__color__bg" :style="{ 'background-color': option.show }"></div>
    <span class="method-answer__value">{{ option.value }}</span>
  </div>
</template>

<script>
export default {
  name: 'TemplateColor',
  props: ['option'],
};
</script>