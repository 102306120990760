<template>
  <div class="template__yes-no" v-if="question">
    <div class="template__input-field" v-for="(option, i) in options" :key="`question--${number}_answerOptions--${i}`">
      <svgicon
        v-if="type === 'color' && !option.show"
        :class="['template__color-toggle', {'template__color-toggle--error' : option.error}]"
        name="methods/color-pick-blank"
        :original="true"
        width="36px"
        height="18px"
        @click="toggleColorPicker(i)"
      ></svgicon>
      <svgicon
        v-if="type === 'img' && !option.show"
        :class="['template__color-toggle', {'template__color-toggle--error' : option.error}]"
        name="methods/image-pick-blank"
        :original="true"
        width="36px"
        height="18px"
        @click="toggleImageModal(i)"
      ></svgicon>
      <div class="template__color-show" v-if="type === 'img' && option.show" @click="toggleImageModal(i)">
        <img class="template__color-show-color" :src="option.show" />
        <svgicon
          class="template__color-show-icon"
          name="methods/color-pick-close"
          :original="true"
        ></svgicon>
      </div>
      <div class="template__color-show" v-if="type === 'color' && option.show">
        <div
          class="template__color-show-color"
          :style="{ 'background-color': option.show }"
          @click="toggleColorPicker(i)"
        ></div>
        <svgicon
          class="template__color-show-icon"
          name="methods/color-pick-close"
          :original="true"
        ></svgicon>
      </div>
      <input
        :class="[
          'template__input-text',
          { 'template__input-text--color': type === 'color' || type === 'img', 'template__input-text--error' : option.error },
        ]"
        type="text"
        @input="setQuestionPropertyHandler($event, {type: 'value', index: number, optionIndex: i})"
        :placeholder='option.error || $t("insert_value_placeholder")'
        :value="option.value"
        :disabled="true"
         maxlength="30"
      />
    </div>
    <div class="picker-modal" v-show="isVisible" @click="toggleColorPicker">
      <div class="picker-modal__bg">
        <div class="picker-modal__modal" @click.stop>
          <div class="picker-modal__container">
            <svgicon
              @click="toggleColorPicker"
              color="grey"
              :fill="false"
              name="multiply"
              class="picker-modal__close"
            ></svgicon>
            <sketch-color-picker v-model="colors[i]" @input="colorChange"></sketch-color-picker>
            <button-3 class="picker-modal__action" :disabled="!options[i].show" @click.native="toggleColorPicker">{{ $t("actions.select_action") }}</button-3>
          </div>
        </div>
      </div>
    </div>
    <question-image-picker
      :type="'question'"
      v-show="showImageModal"
      @click="toggleImageModal"
      @closeImageModal="toggleImageModal"
      @onImageSelect="onImageSelect"
      :key="images[i]"
      :label="`Image${images[i]}`"
      :selectedImages="selectedImages"
    ></question-image-picker>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Sketch } from 'vue-color';

import QuestionImagePicker from '@/components/reflections/question/QuestionImagePicker.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';


export default {
  name: 'TemplateYesNo',
  components: {
    'sketch-color-picker': Sketch,
    QuestionImagePicker,
    Button3,
  },
  props: ['number', 'question', 'edit'],
  data() {
    return {
      colors: ['#11ab99', '#ff3366'],
      images: [null, null],
      isVisible: false,
      showImageModal: false,
      i: 0,
      selectedImages: [],
    };
  },
  updated() {
    if (this.type === 'color') {
      this.options.forEach((option, i) => {
        if (option.show) {
          const isColor = option.show.split('')[0] === '#';
          if (isColor) return;
        }
        if (!option.show) {
          option.show = this.colors[i];
        }
      });
    }

    if (this.type === 'text') {
      this.options.forEach((option) => { option.show = option.value; });
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire']),
    options() {
      return this.question.answerOptions.options;
    },
    type() {
      return this.question.answerOptions.type;
    },
    view() {
      return this.question.answerOptions.view;
    },
  },
  methods: {
    ...mapActions('questionnaire', ['setQuestionProperty', 'setOptionError']),
    toggleColorPicker(i) {
      if (!this.edit) return;
      this.isVisible = !this.isVisible;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    colorChange(color) {
      this.options[this.i].show = color.hex;
    },
    toggleImageModal(i) {
      if (!this.edit) return;
      this.showImageModal = !this.showImageModal;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    onImageSelect(image) {
      this.images[this.i] = image.url;
      this.options[this.i].show = image.url;
      if (this.selectedImages[this.i]) this.selectedImages.splice(this.i, 1, image);
      else this.selectedImages.push(image);
    },
    setQuestionPropertyHandler($event, payload) {
      const { optionIndex } = payload;
      const { value } = $event.target;
      if (!value) {
        this.setOptionError({
          questionIndex: this.number,
          optionIndex,
          error: this.$t('warnings.finish_typing')
        });
      } else {
        this.setOptionError({
          questionIndex: this.number,
          optionIndex,
          error: null,
        });
      }
      payload.property = value;
      this.setQuestionProperty(payload);
    },
  },
};
</script>
<style lang="scss">
.template {
  &__yes-no {
    .template__input-field {
      position: relative;

      .template__color-toggle {
        top: 6px;
        position: absolute;

        &:hover {
          cursor: pointer;
        }

        &--error {
          border: 1px solid $red-4;
        }
      }
      .template__input-text {
        &--color {
          padding-left: 40px;
        }
      }
    }
  }

  &__color-show {
    display: flex;
    width: 36px;
    height: 18px;
    position: absolute;
    top: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &__color-show-color {
    width: 18px;
    height: 100%;
    border: 0.5px solid $violet-6;
    border-right: none;

  }

  &__color-show-icon {
    width: 18px;
    height: 100%;
  }
}
</style>
