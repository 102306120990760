<template>
  <section id="editReflection" class="editReflection" v-if="!loading">
    <div class="editReflection__container" v-if="templateFound">
      <create-reflection
        :type="'template'"
        :personal="templateIsPersonal"
        :editStatus="editStatus"
        :isPublic="questionnaire.public"
      ></create-reflection>
      <mobile v-if="selectedMethod"></mobile>
      <method-list v-else></method-list>
    </div>
    <div class="editReflection__container" v-else>
      <div class="editReflection__not-found">
        <svgicon
          class="editReflection__not-found__icon"
          name="broken-pen"
          :original="true"
        ></svgicon>
        <header-h2 class="editReflection__not-found__title">{{ $t("messages.template_not_found_message") }}</header-h2>
        <router-link class="editReflection__not-found__link" :to="{ name: 'CreateReflection' }">
          <button-4>{{ $t("actions.create_new_questionnaire_action") }}</button-4>
        </router-link>
      </div>
    </div>
    <navigation-modal v-show="questionnaireNavigation.modal"></navigation-modal>
  </section>
  <loading v-else></loading>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CreateReflection from '@/components/reflections/CreateReflection.vue';
import MethodList from '@/components/methods/MethodList.vue';
import Mobile from '@/components/mobile/Mobile.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import NavigationModal from '@/components/base/NavigationModal.vue';
import Loading from '@/components/base/Loading.vue';

export default {
  name: 'EditTemplate',
  components: {
    CreateReflection,
    MethodList,
    Mobile,
    Button4,
    HeaderH2,
    NavigationModal,
    Loading,
  },
  data() {
    return {
      templateFound: false,
    };
  },
  created() {
    this.handlePageInit();
  },
  beforeDestroy() {
    this.resetQuestionnaireState();
  },
  computed: {
    ...mapGetters('methods', ['selectedMethod']),
    ...mapGetters('reflections', ['editStatus']),
    ...mapGetters('questionnaire', ['questionnaire', 'questions', 'answerTemplate']),
    ...mapGetters('user', ['user']),
    ...mapGetters('meta', ['questionnaireNavigation', 'loading']),

    templateIsPersonal() {
      const userId = parseInt(this.user.id, 10);
      const { owner } = this.questionnaire;
      const templateIsPersonal = owner ? owner.id === userId : false;
      return templateIsPersonal;
    },
  },
  methods: {
    ...mapActions('questionnaire', [
      'getAnswerTemplate',
      'setCurrentQuestion',
      'addSchedule',
      'resetQuestionnaireState',
    ]),
    ...mapActions('reflections', ['getTemplateById', 'setEditStatus']),
    ...mapActions('meta', ['setLoading']),
    async handlePageInit() {
      this.setLoading(true);
      this.setEditStatus('view');
      try {
        await this.handleGetMethodsAndAnswerTypes();
        await this.handleGetTemplate();
      } catch {
        this.setLoading(false);
      } finally {
        this.setLoading(false);
      }
    },
    async handleGetMethodsAndAnswerTypes() {
      if (!this.answerTemplate.length) await this.getAnswerTemplate();
      return Promise.resolve();
    },
    async handleGetTemplate() {
      const templateId = this.$route.params.id * 1;
      const { id } = this.questionnaire;
      if (id && id === templateId) {
        this.templateFound = true;
        this.setCurrentQuestion(this.questions[0]);
        return Promise.resolve();
      }
      const { data } = await this.getTemplateById(templateId);
      this.templateFound = true;
      this.setCurrentQuestion(data.questions[0]);
      return Promise.resolve();
    },
  },
};
</script>
<style lang="scss">
.editReflection {
  &__container {
    display: flex;
    max-width: 940px;
    margin: 20px auto 0 auto;
    justify-content: space-between;
  }

  &__not-found {
    margin: 60px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      height: 72px;
      width: 80px;
    }

    &__title {
      margin-top: 16px;
    }

    &__link {
      button {
        margin-top: 16px;
      }
    }
  }
}
</style>
