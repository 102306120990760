<template>
  <button>
    <slot/>
  </button>
</template>
<script>
export default {
  name: 'Button5',
};
</script>
<style lang="scss" scoped>
button {
  padding: 0;
  font-family: Roboto-Medium;
  border: none;
  background: none;
  color: $violet-5;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    color: $grey-2;
  }
}
</style>
