<template>
  <transition name="modal__fade">
    <div class="modal__backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="calendarModal"
        aria-describedby="calendarModal"
        @click.stop
      >
        <div class="modal__confirm" v-if="register || passResetModal || emailSent">
          <svgicon
            @click="close"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__confirm__close"
          ></svgicon>
          <div class="confirm__container">
            <svgicon name="large-check" :original="true" height="80px"></svgicon>
            <header-h2 v-if="register">{{ $t("modals.register_modal_confirm_id") }}</header-h2>
            <p class="register-p" v-if="register">
              {{ $t("modals.register_modal_confirm_sent_message") }}
            </p>
            <div v-if="passResetModal">
              <header-h2>{{ $t("modals.pass_reset_modal_pass_changed_message") }}</header-h2>
              <p class="email-sent-message">{{ $t("modals.pass_reset_modal_use_new_pass_message") }}</p>
            </div>
            <div v-if="emailSent">
              <header-h2>{{ $t("modals.pass_reset_modal_pass_change_link_message") }}</header-h2>
              <p class="email-sent-message">{{ $t("modals.pass_reset_modal_check_email_message") }}</p>
            </div>
            <button-4 @click.native="closeAndRedirect" v-if="register || emailSent">{{ $t("actions.ok_action") }}</button-4>
            <button-4 @click.native="closeAndRedirect" v-if="(passResetModal && !isStudent) && !emailSent">
              {{ $t("actions.ok_action") }}
            </button-4>
          </div>
        </div>
        <div class="modal__confirm modal__confirm--pass" v-else>
          <svgicon
            @click="close"
            color="grey"
            :fill="false"
            name="multiply"
            class="modal__confirm__close"
          ></svgicon>
          <div class="confirm__container">
            <svgicon name="large-confirm" :original="true" height="80px"></svgicon>
            <header-h2>{{ $t("modals.email_modal_enter_your_email") }}</header-h2>
            <div class="info__field">
              <input
                type="text"
                v-model="resetEmail"
                @blur="$v.resetEmail.$touch()"
                @keydown.enter="sendPassResetRequest"
              />
              <svgicon
                v-if="$v.resetEmail.$error || error"
                name="warning"
                :original="true"
                height="16px"
                class="icon-danger"
              ></svgicon>
              <div class="info__error" v-if="$v.resetEmail.$error">
                <span class="info__error-message" v-if="!$v.resetEmail.required">
                  {{ $t("warnings.email_is_required") }}
                </span>
                <span class="info__error-message" v-if="!$v.resetEmail.email">
                  {{ $t("warnings.invalid_email") }}
                </span>
              </div>
              <div class="info__error" v-if="error">
                <span class="info__error-message">{{ error }}</span>
              </div>
            </div>
            <button-4 @click.native="sendPassResetRequest" :disabled="!emailIsValid">
              {{ $t("actions.ok_action") }}
            </button-4>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';

import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'Modal',
  components: {
    HeaderH2,
    Button4,
  },
  props: ['register', 'passResetModal', 'isStudent'],
  data() {
    return {
      resetEmail: null,
      emailSent: false,
    };
  },
  validations: {
    resetEmail: {
      email,
      required,
    },
  },
  computed: {
    ...mapGetters('user', ['error']),
    emailIsValid() {
      return !this.$v.resetEmail.$invalid;
    },
  },
  methods: {
    ...mapActions('user', ['resetPassword']),
    close() {
      this.emailSent = false;
      return this.$emit('close');
    },
    closeAndRedirect() {
      this.close();
      this.$router.push({ name: 'Login' });
    },
    sendPassResetRequest() {
      return this.resetPassword({ email: this.resetEmail }).then(() => {
        this.emailSent = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';
.modal {
  @include hardBoxShadow();
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  &__backdrop {
    margin: 0 !important;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__confirm {
    position: relative;
    padding: 20px;
    width: 260px;
    height: 230px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__close {
      position: absolute;
      height: 9px;
      width: 9px;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    &--pass {
      .confirm {
        &__container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .info {
            &__field {
              position: relative;
              display: flex;
              flex-direction: column;
            }

            &__error {
              display: flex;
              flex-direction: column;
            }

            &__error-message {
              margin-top: 3px;
              font-family: Roboto-Regular;
              font-size: 12px;
              color: $red-4;
            }

            input {
              width: 240px;
              margin-top: 6px;
              min-height: 28px;
              border: 1px solid $grey-1;
              padding: 0 10px;
              font-family: Roboto-Regular;
              font-size: 14px;
              color: $grey-5;
              &:hover,
              &:active {
                border: 1px solid $grey-2;
              }
              &::placeholder {
                color: $grey-2;
              }
            }
            svg {
              position: absolute;
              right: 6px;
              top: 14px;
            }
          }
        }
      }
    }

    h2 {
      margin-top: 10px;
    }

    button {
      margin-top: 20px;
    }
  }

  &__fade {
    &-enter,
    &-leave {
      opacity: 0;

      &-active {
        transition: opacity 0.5s ease-in-out;
      }
    }
  }
}

.register-p {
  font-family: Nunito-Regular;
  font-size: 14px;
  color: $grey-5;
  margin-top: 5px;
}

.email-sent-message {
  font-size: 12px;
  margin-top: 5px;
}
</style>
