<template>
  <div class="profile-inner">
    <p class="profile-inner__title">{{ $t('profile.order_history') }}</p>
    <Table v-if="!isLoading" :data="tableData" :columns="tableColumns" :isRowClickable="true"
      @rowAction="handleRowClick" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Table from '@/components/tables/Table.vue';

export default {
  name: 'ProfileHistory',
  components: {
    Table,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('licences', ['licenceOrdersHistory']),
    tableData() {
      return this.licenceOrdersHistory && this.licenceOrdersHistory.length
        ? this.licenceOrdersHistory.map((order) => {
          const orderTitle = order.items && order.items.length ? order.items.map(item => `<span>${item.title || '-'} <span class="small">${item.type}</span></span>`) : '-';
          return {
            id: order.id,
            nr: order.id,
            date: order.date,
            title: orderTitle.length ? `<div class="flex-global item-column">${orderTitle.join('')}</div>` : '-',
            paymentType: order.paymentType,
            status: `<span class="${order.status === 'paid' ? 'status-green' : ''}">${order.statusTitle}</span>`,
            total: `€ ${parseFloat(order.fullPrice, 10).toFixed(2)}`,
            // downloadAction: {
            //   action: () => this.handleOrderDownload(order.id),
            //   actionTitle: this.$t('actions.download_action'),
            // },
          };
        })
        : null;
    },
    tableColumns() {
      return [
        {
          label: this.$t('orders.number'),
          key: 'nr',
        },
        {
          label: this.$t('orders.date'),
          key: 'date',
        },
        {
          label: this.$t('orders.purchase_item'),
          key: 'title',
          rawHTML: true,
        },
        {
          label: this.$t('orders.payment'),
          key: 'paymentType',
        },
        {
          label: this.$t('orders.status'),
          key: 'status',
          rawHTML: true,
        },
        {
          label: this.$t('orders.total'),
          key: 'total',
          rightAlign: true,
        },
        // {
        //   label: '',
        //   key: 'downloadAction',
        //   isActionButton: true,
        // },
      ];
    },
  },
  methods: {
    ...mapActions('licences', ['getLicenceOrdersHistory']),
    handleGetOrdersHistory() {
      this.isLoading = true;
      this.getLicenceOrdersHistory().then(() => {
        this.isLoading = false;
      });
    },
    handleRowClick(value) {
      this.$router.push({ name: 'OrderInner', params: { orderId: value } });
    },
    handlePageInit() {
      this.handleGetOrdersHistory();
    },
    // handleOrderDownload(orderId) {
    //   console.log(`action for ${orderId} order`);
    // },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .item-column {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &>span {
    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }
}

/deep/ .status-green {
  color: #3cac47;
}
</style>
