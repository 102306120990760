<template>
  <div class="group-confirm__page" v-if="currentGroup">

    <section id="group-confirm" class="group-confirm">
      <div class="group-confirm__header">
        <svgicon name="certified" :original="true" height="2.2em"></svgicon>
        <header-h2>Grupė sėkmingai sukurta bei sugeneruotas jos kodas!</header-h2>
      </div>
      <div class="group-confirm__code">
        <h4>Grupės kodas</h4>
        <h1>{{currentGroup.token}}</h1>
        <p>
          Pasidalinkite kodu su žmonėmis, kad jie prisijungtų
          prie Jūsų „6f klasės mokiniai“ grupės
        </p>
      </div>
      <div class="group-confirm__expiry">
        <h4>Liko</h4>
        <vue-countdown :time="timeRemaining">
          <template slot-scope="props">{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</template>
        </vue-countdown>
        <p>
          Kodas bus aktyvus 24 val, vėliau galėsite pakartotinai aktyvuoti
          tą patį grupės kodą, kad prie jos prisijungtų nauji nariai.
        </p>
        <router-link :to="{name: 'GroupMembers', params: { id: currentGroup.id } }">
          <button-4>Grupės nariai</button-4>
        </router-link>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import VueCountdown from '@chenfengyuan/vue-countdown';


import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'GroupConfirm',
  components: {
    HeaderH2,
    Button4,
    VueCountdown,
  },
  computed: {
    ...mapGetters('groups', ['groupList', 'currentGroup']),
    timeRemaining() {
      const date = this.currentGroup.tokenValidationDate;
      const timeRemaining = moment(date).unix();
      return timeRemaining;
    },
  },
  created() {
    if (!this.groupList.length) {
      this.$router.push({ name: 'NewGroup' });
    }
  },
};
</script>
<style lang="scss">
.group-confirm {
  max-width: 940px;
  margin: 20px auto 0 auto;
  position: relative;
  text-align: center;
  .group-confirm__header {
    h2 {
      margin-top: 25px;
    }
  }
  &__code {
    margin: 30px auto 0 auto;
    width: 300px;
    height: 160px;
    background-color: #fff;
    padding: 15px;
    h4 {
      margin-top: 5px;
      font-family: Nunito-SemiBold;
    }
    h1 {
      font-family: Nunito-Bold;
      font-size: 30px;
      font-weight: inherit;
      padding: 0;
      margin: 10px 0 0 0;
      color: $grey-3;
    }
    p {
      margin-top: 20px;
    }
  }

  &__expiry {
    margin-top: 15px;
    h4 {
      font-family: Roboto-Medium;
    }
    span {
      font-family: Roboto-Black;
      font-size: 19px;
      color: $green-3;
      margin-top: 8px;
    }

    p {
      margin-top: 20px;
    }

    button {
      margin-top: 30px;
    }
  }

  h4 {
    margin: 0;
    padding: 0;
    color: $grey-5;
    font-size: 14px;
    font-weight: inherit;
  }

  p {
    max-width: 338px;
    margin: 0 auto;
    padding: 0;
    font-family: Nunito-Regular;
    font-size: 12px;
    color: $grey-5;
  }
}
</style>
