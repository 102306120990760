<template>
  <div>
    <sidebar-section :section="profileSection" />
    <sidebar-section v-for="section in orgSections" :key="section.title" :section="section" />
  </div>
</template>
<script>
  import SidebarSection from '@/components/Menu/SidebarSection.vue';

  export default {
    name: 'ProfileSidebar',
    props: ['user', 'organizations'],
    components: {
      SidebarSection,
    },
    data() {
      return {};
    },
    computed: {
      fullUserName() {
        const { name, lastName } = this.user;
        return this.user ? `${name} ${lastName}` : '-';
      },
      profileSection() {
        return {
          title: this.fullUserName,
          isProfile: true,
          links: [
            {
              name: this.$t('profile.account'),
              route: '/profile/view',
              icon: 'user-icon',
              iconWidth: '13px',
              iconHeight: '12px',
            },
            {
              name: this.$t('profile.licences'),
              route: '/profile/licence',
              icon: 'user-clock',
              iconWidth: '15px',
              iconHeight: '16px',
            },
            {
              name: this.$t('profile.services'),
              route: '/profile/services',
              icon: 'user-services',
              iconWidth: '15px',
              iconHeight: '16px',
            },
            {
              name: this.$t('profile.payment_history'),
              route: '/profile/history',
              icon: 'user-history',
              iconWidth: '15px',
              iconHeight: '14px',
            },
            {
              name: this.$t('profile.settings'),
              route: '/profile/settings',
              icon: 'user-settings',
              iconWidth: '15px',
              iconHeight: '16px',
            },
          ],
        };
      },
      orgSections() {
        return this.organizations && this.organizations.length
          ? this.organizations
              .filter((org) => org.role === 'admin')
              .map((org) => {
                return {
                  id: org.id,
                  title: org.title,
                  isOpen: org.isOpen,
                  isProfile: false,
                  links: [
                    {
                      name: this.$t('profile.account'),
                      route: `/profile/org/${org.id}/view`,
                      icon: 'user-icon',
                      iconWidth: '13px',
                      iconHeight: '12px',
                    },
                    {
                      name: this.$t('profile.licences'),
                      route: `/profile/org/${org.id}/licence`,
                      icon: 'user-clock',
                      iconWidth: '15px',
                      iconHeight: '16px',
                    },
                    {
                      name: this.$t('profile.services'),
                      route: `/profile/org/${org.id}/services`,
                      icon: 'user-services',
                      iconWidth: '15px',
                      iconHeight: '16px',
                    },
                    {
                      name: this.$t('profile.users'),
                      route: `/profile/org/${org.id}/users`,
                      icon: 'user-users',
                      iconWidth: '15px',
                      iconHeight: '10px',
                    },
                    {
                      name: this.$t('profile.payment_history'),
                      route: `/profile/org/${org.id}/history`,
                      icon: 'user-history',
                      iconWidth: '15px',
                      iconHeight: '14px',
                    },
                  ],
                };
              })
          : [];
      },
    },
    methods: {},
  };
</script>
<style lang="scss" scoped></style>
