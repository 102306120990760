<template>
  <div class="stat-components__list__toggle">
    <div class="list-expand" v-if="displayExpandCheckbox">
      <checkbox :checked="expanded" :boxValue="'list-view'" @checkStatus="handleExpand"></checkbox>
      <span class="uppercase-title">{{ $t('actions.expand_all_action') }}</span>
    </div>
    <div class="list-type-icons">
      <svgicon
        :name="currentView === 'list' ? 'list-view-on' : 'list-view-off'"
        height="16px"
        width="16px"
        :original="true"
        @click="toggleView('list')"
        v-tooltip="$t('list_view')"
      ></svgicon>
      <svgicon
        :name="currentView === 'tabs' ? 'tab-view-on' : 'tab-view-off'"
        height="16px"
        width="16px"
        :original="true"
        @click="toggleView('tabs')"
        v-tooltip="$t('tab_view')"
      ></svgicon>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import Checkbox from '@/components/elements/Checkbox.vue';

  export default {
    name: 'StatDisplayToggle',
    components: { Checkbox },
    props: ['expanded', 'answerType'],
    computed: {
      ...mapGetters('stats', ['currentView']),
      displayExpandCheckbox() {
        return this.currentView === 'list' && this.answerType === 'inTime';
      },
    },
    methods: {
      ...mapActions('stats', ['toggleView']),
      handleExpand(value) {
        this.$emit('statDisplayToggle', value);
      },
    },
  };
</script>
<style lang="scss">
  .stat-components {
    &__list {
      &__toggle {
        position: absolute;
        display: flex;
        align-items: center;
        top: -20px;
        right: 0;

        .uppercase-title {
          align-self: center;
          margin-left: 5px;
        }

        .list-expand {
          display: flex;
          margin-right: 10px;
        }

        .list-type-icons {
          svg {
            cursor: pointer;

            &:last-of-type {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
</style>
