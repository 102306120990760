<template>
  <div class="login__page">
    <section class="authentication">
      <div class="login__container teacher">
        <div class="login__header">
          <header-h1>{{ $t('login_page.login_teacher') }}</header-h1>
          <button-3 @click.native="handleFormSwitch">{{ $t('actions.register_action') }}</button-3>
        </div>
        <div class="info__field">
          <span class="uppercase-title">{{ $t('inputs.email_address') }}*</span>
          <input class="info__field__input" type="email" :placeholder="$t('your_email_placeholder')"
            v-model="loginForm.email" @blur="$v.loginForm.email.$touch()" @keydown.enter="loginSubmit" />
          <svgicon v-if="$v.loginForm.email.$error || error" name="warning" :original="true" height="16px"></svgicon>
          <div class="info__error" v-if="$v.loginForm.email.$error">
            <span class="info__error__message" v-if="!$v.loginForm.email.required">
              {{ $t('warnings.email_is_required') }}
            </span>
            <span class="info__error__message" v-if="!$v.loginForm.email.email">
              {{ $t('warnings.invalid_email') }}
            </span>
          </div>
          <div class="info__error" v-if="error">
            <span class="info__error__message" v-if="error.error === 'license_expired'">
              {{ $t('modals.license_modal_expired_license_header') }}
            </span>
            <span class="info__error__message" v-else>{{ error }}</span>
            <router-link tag="span" :to="{ name: 'EmailResend', params: { email: resendEmail } }"
              class="info__error__resend" v-if="resendEmail">
              {{ $t('warnings.resend_email') }}
            </router-link>
          </div>
        </div>
        <div class="info__field">
          <span class="uppercase-title">{{ $t('inputs.password') }}</span>
          <input class="info__field__input" type="password" v-model="loginForm.password"
            @blur="$v.loginForm.password.$touch()" @keydown.enter="loginSubmit" />
          <svgicon v-if="$v.loginForm.password.$error || error" name="warning" :original="true" height="16px"></svgicon>
          <div class="info__error" v-if="$v.loginForm.password.$error">
            <span class="info__error__message">{{ $t('warnings.password_is_required') }}</span>
            <span class="info__error__message" v-if="!$v.loginForm.password.minLength">
              {{ $t('warnings.password_length_warning') }}
            </span>
          </div>
        </div>
        <div class="login__password-reset">
          <button-5 @click.native="passwordReset">{{ $t('warnings.forgot_password') }}</button-5>
        </div>
        <div class="login__action">
          <button-4 @click.native="loginSubmit">{{ $t('actions.login_action') }}</button-4>
        </div>
      </div>
      <div class="login__container student">
        <header-h1>{{ $t('login_page.login_student') }}</header-h1>
        <p class="student-login-content">{{ $t('login_page.login_student_content') }}</p>
        <div class="content-icon-wrapper">
          <a class="google-play-icon" href="https://play.google.com/store/apps/details?id=com.reflectus"
            target="_blank">
            <img src="../../assets/google-play-badge.png" alt="Google Play badge" />
          </a>
          <a class="app-store-icon" href="https://apps.apple.com/lt/app/reflectus/id1492042735" target="_blank">
            <img src="../../assets/app-store-badge.png" alt="App Store badge" />
          </a>
        </div>
      </div>
      <modal v-show="isModalVisible" @close="toggleModal" :register="registerModal"></modal>
    </section>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';

import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import Button5 from '@/components/elements/buttons/Button5.vue';
import Modal from '@/components/base/Modal.vue';

export default {
  name: 'Login',
  components: {
    HeaderH1,
    Button3,
    Button4,
    Button5,
    Modal,
  },
  props: ['isLogin'],
  data() {
    return {
      registerModal: true,
      loginForm: {
        email: null,
        password: null,
      },
      isModalVisible: false,
      privacyError: null,
      resendEmail: null,
    };
  },
  validations: {
    loginForm: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  mounted() {
    this.setError(null);
  },
  computed: {
    ...mapGetters('user', ['error', 'privacyAgreed', 'loginMode', 'userRegisterForm']),
    loginIsValid() {
      return !this.$v.loginForm.$invalid;
    },
  },
  methods: {
    ...mapActions('user', [
      'login',
      'register',
      'togglePrivacy',
      'toggleLoginMode',
      'setError',
      'setIsTeacherData',
    ]),
    loginSubmit() {
      this.$v.loginForm.$touch();
      if (this.loginIsValid) {
        this.login({
          email: this.loginForm.email,
          password: this.loginForm.password,
        }).then((res) => {
          const { success, data } = res;
          if (!success && data.emailConfirmed === false) {
            const { email } = this.loginForm;
            if (email) this.resendEmail = email;
          }

          if (success && data && data.error === 'license_expired') {
            this.$router.push({ name: 'ProfileLicence' });
          }
        });
      }
    },
    passwordReset() {
      this.registerModal = false;
      this.setError(null);
      this.toggleModal();
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    checkedValues(value) {
      if (!value.checked) {
        this.privacyError = true;
        this.togglePrivacy(false);
      } else {
        this.privacyError = false;
        this.togglePrivacy(true);
      }
      this.userRegisterForm.acceptNewsletter = value.checked;
    },
    handleFormSwitch() {
      this.setError(null);
      this.setIsTeacherData(false);
      this.$router.push({ name: 'Register' });
    },
  },
};
</script>
<style lang="scss">
.authentication {
  max-width: 940px;
  margin: 60px auto 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login {
    &__header {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin-left: 30px;
      }

      &--register {
        width: 620px;
        justify-content: space-between;

        button {
          margin: 0;
        }
      }
    }

    &__container {
      margin-top: 20px;
      width: 300px;
      padding: 20px;
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.teacher,
      &.student {
        width: 441px;
      }

      &.teacher {
        .login__header {
          justify-content: space-between;

          h1 {
            letter-spacing: 1.44px;
            white-space: pre;
          }

          button {
            margin-left: 25px;
            padding: 5px 14px;
          }
        }

        .info__field {
          .info__field__input {
            width: auto;
          }
        }

        .login__action {
          display: flex;
          margin-top: 30px;
          margin-bottom: 10px;
        }
      }

      &.student {
        margin-top: 30px;

        .student-login-content {
          margin-top: 21px;
          font-family: Nunito-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: $grey-5;
        }

        .content-icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          margin-bottom: 13px;

          .google-play-icon {
            img {
              width: 157px;
              height: 48px;
            }
          }

          .app-store-icon {
            margin-left: 20px;

            img {
              width: 139px;
              height: 47px;
            }
          }
        }
      }

      .info {
        &__field {
          position: relative;
          display: flex;
          flex-direction: column;

          &:last-of-type {
            margin-top: 15px;
          }

          &__input {
            width: 260px;
            margin-top: 6px;
            min-height: 28px;
            border: 1px solid $grey-1;
            padding: 0 10px;
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $grey-5;

            &:hover,
            &:active {
              border: 1px solid $grey-2;
            }

            &::placeholder {
              color: $grey-2;
            }

            &[type='password'] {
              font-family: Roboto-Regular;
              font-size: 22px;
              color: $green-3;
              letter-spacing: 8px;
            }

            &.phone-number-input-registration {
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }

          svg {
            position: absolute;
            right: 6px;
            top: 26px;
            transform: rotatte(180deg);
          }

          h3 {
            color: red;
          }

          &:nth-of-type(2) {
            margin-top: 16px;
          }

          &:nth-of-type(3) {
            margin-top: 20px;
          }
        }

        &__error {
          display: flex;
          flex-direction: column;
          max-width: 260px;

          &__message {
            margin-top: 3px;
            font-family: Roboto-Regular;
            font-size: 12px;
            color: $red-4;
            text-transform: initial;
          }

          &__resend {
            font-family: Roboto-Regular;
            text-decoration: underline;
            font-size: 12px;
            opacity: 0.9;
            color: #3b328b;
            text-transform: initial;
            cursor: pointer;
            margin-top: 6px;
          }
        }
      }

      &--register {
        padding: 20px;
        width: 620px;
        min-height: 170px;
        height: 100%;

        .info__row {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          &:not(:first-of-type) {
            margin-top: 15px;
          }

          .info__field {
            height: 100%;

            input {
              width: 260px;
            }

            &:nth-of-type(2) {
              margin-top: 0;
            }

            &:nth-of-type(3) {
              margin-top: 0;
            }
          }
        }
      }

      &--pass {
        width: 620px;

        .info__row {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .info__field {
            input {
              width: 260px;
            }

            &:nth-of-type(2) {
              margin-top: 0;
            }

            &:nth-of-type(3) {
              margin-top: 0;
            }
          }
        }
      }
    }

    &__password-reset {
      display: flex;
      align-self: flex-start;
      margin-top: 20px;

      button {
        font-family: Roboto-Regular;
        text-decoration: underline;
        font-size: 12px;
        opacity: 0.9;
      }
    }

    &__action {
      margin-top: 35px;

      button {
        min-width: 102px;
        margin: 0 auto;
        padding: 5px 20px;
      }
    }
  }

  .privacy-toggle {
    margin: 15px 0 0 180px;
    align-self: flex-start;
    display: flex;
    align-items: center;

    &__field {
      display: flex;
      align-items: center;
      position: relative;

      &__text {
        margin-left: 10px;
        font-size: 14px;
      }

      &__link {
        color: inherit;
        text-decoration: underline;
      }

      &__error {
        font-size: 12px;
        color: $red-4;
        position: absolute;
        left: 0;
        bottom: -16px;
      }

      &:last-of-type {
        margin-left: 20px;
      }
    }
  }
}
</style>
