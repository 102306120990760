<template>
  <div class="my-licences">
    <p class="my-licences__title">{{ $t('licences.personal_licence') }}</p>
    <div v-if="isTrialLicence" class="my-licences__list">
      <licence-card v-if="trialLicence" :key="trialLicence.id" :licence="trialLicence"
        @handleLicenceSuspend="handleLicenceSuspend" />
      <div v-if="!isExpired" class="my-licences__trial">
        <div class="empty-licence-link flex-global">
          <span class="title-wrapper flex-global">
            <svgicon name="user-trial-info" :original="true" width="59px" height="49px" :fill="false"></svgicon>
            <span>{{ $t('licences.licence_valid_for_message') }}:</span>
            <span class="duration">
              {{ trialLicence.validDuration }} {{ $t('schedule.duration_days_alt') }}
            </span>
          </span>
          <div class="licence-link" @click="handleLicencePurchase">
            {{ $t('licences.select_licence_action') }}
          </div>
        </div>
      </div>
      <div v-else class="my-licences__empty">
        <p>{{ $t('licences.no_active_licence_message') }}</p>
        <div class="trial-licence-expired-wrapper flex-global">
          <svgicon name="user-warning-big" :original="true" width="142px" height="90px" :fill="false"></svgicon>
          <div class="info flex-global">
            <div>
              <p>{{ $t('licences.trial_expired_message') }}</p>
              <p class="small">
                {{ $t('licences.trial_expired_subtitle_message') }}
              </p>
            </div>
            <div class="licence-link" @click="handleLicencePurchase">
              {{ $t('licences.select_licence_action') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isPurchasedLicence" class="my-licences__list">
      <licence-card v-if="purchasedLicence" :key="purchasedLicence.id" :licence="purchasedLicence"
        @handleLicenceSuspend="handleLicenceSuspend" />
      <div v-if="isExpired" class="my-licences__empty">
        <p>{{ $t('licences.no_active_licence_message') }}</p>
        <div class="empty-licence-link flex-global">
          <span>{{ $t('licences.select_a_licence_message') }}</span>
          <div class="licence-link" @click="handleLicencePurchase">
            {{ $t('licences.select_licence_action') }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      -
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import LicenceCard from '@/components/user/licences/LicenceCard.vue';

export default {
  name: 'Licences',
  props: ['user', 'userOrganizations', 'licenceData'],
  components: {
    LicenceCard,
  },
  data() {
    return {
      today: this.moment(),
      trialLicenceActive: true,
      licences: [
        // // active monthly
        // {
        //   id: 0,
        //   title: 'Reflectus platforma',
        //   type: 1,
        //   durationType: 1,
        //   customer: 'Mindaugas Leskauskas',
        //   validUntil: '2022-10-15',
        //   price: 11.99,
        //   nextPayment: '2022-12-30',
        // },
        // // active trial
        // {
        //   id: 1,
        //   title: 'Bandomoji Reflectus licencija',
        //   type: 1,
        //   durationType: 0,
        //   customer: 'Mindaugas Leskauskas',
        //   validUntil: '2022-10-15',
        //   price: 11.99,
        //   nextPayment: '2022-12-30',
        // },
        // // active yearly
        // {
        //   id: 2,
        //   title: 'Reflectus + Mokymų platformos',
        //   type: 1,
        //   durationType: 2,
        //   customer: 'Mindaugas Leskauskas',
        //   validUntil: '2022-09-07',
        //   price: 49.99,
        //   nextPayment: '2022-12-30',
        // },
        // // suspended yearly
        // {
        //   id: 3,
        //   title: 'Mokymų platforma',
        //   type: 2,
        //   durationType: 2,
        //   customer: 'Mindaugas Leskauskas',
        //   validUntil: '2022-09-07',
        //   price: 49.99,
        //   nextPayment: '2022-12-30',
        // },
      ],
    };
  },
  computed: {
    userFullName() {
      return `${this.user.name} ${this.user.lastName}`;
    },
    isTrialLicence() {
      return (this.licenceData && this.licenceData.type === 'default') || false;
    },
    isPurchasedLicence() {
      return (this.licenceData && this.licenceData.type === 'purchased') || false;
    },
    isExpired() {
      return (
        (this.licenceData &&
          this.licenceData.error &&
          this.licenceData.error === 'license_expired') ||
        false
      );
    },
    trialLicence() {
      if (this.isTrialLicence && !this.isExpired) {
        return {
          id: 1,
          title: this.$t('licences.trial_reflectus_licence'),
          type: 1,
          durationType: 0,
          customer: this.userFullName,
          validUntil: this.moment(this.licenceData.date).format('YYYY-MM-DD'),
          validDuration: this.moment(this.licenceData.date).diff(this.today, 'days'),
        };
      }
      return null;
    },
    purchasedLicence() {
      if (this.isPurchasedLicence && !this.isExpired) {
        return {
          id: 2,
          title: this.$t('reflectus_platform'),
          type: 1,
          durationType: 2,
          customer: this.userFullName,
          validUntil: this.moment(this.licenceData.date).format('YYYY-MM-DD'),
          price: '-',
          nextPayment: '-',
        };
      }
      return null;
    },
    firstUserOrganization() {
      return this.userOrganizations && this.userOrganizations.length ? this.userOrganizations[0] : null;
    }
  },
  methods: {
    ...mapActions('licences', ['resetLicencesForm']),
    ...mapActions('user', ['resetCurrentActiveOrganization']),
    handleLicencePurchase() {
      this.resetLicencesForm();
      this.resetCurrentActiveOrganization();
      this.$router.push({ name: 'StepLicences' });
    },
    handleLicenceSuspend(value) {
      console.log(`handle suspend: ${value}`);
    },
  },
  // type: 0 - inactive, 1 - active, 2 - suspended
  // durationType: 0 - trial, 1 - monthly, 2 - yearly
};
</script>
<style lang="scss" scoped>
.my-licences {
  color: $grey-9;

  &__empty,
  &__trial {
    p {
      margin-bottom: 17px;
      font-size: 14px;
      font-weight: 400;
    }

    .empty-licence-link {
      justify-content: space-between;
      padding: 14px 24px;
      padding-left: 36px;
      max-width: 866px;
      height: 63px;
      border-radius: 999px;
      background-color: $grey-6;

      svg {
        stroke: none;
        margin-right: 14px;
      }

      span {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .licence-link {
      width: 180px;
      padding: 9px 31px;
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      border-radius: 999px;
      color: #fff;
      background-color: $green-3;
      cursor: pointer;

      &:hover {
        background-color: $green-4;
      }
    }

    .trial-licence-expired-wrapper {
      justify-content: flex-start;
      margin-top: 56px;
      height: 90px;

      .info {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 34px;
        height: 100%;

        p {
          margin: 0;
          margin-bottom: 3px;
          font-size: 16px;
          font-weight: 600;

          &.small {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            color: #b1b1b1;
          }
        }
      }
    }
  }

  &__trial {
    margin-top: 16px;

    .empty-licence-link {
      .title-wrapper {
        justify-content: flex-start;
        align-items: center;
      }

      .duration {
        margin-left: 14px;
        font-size: 16px;
        font-weight: 700;
        color: #ff9559;
      }
    }
  }
}
</style>
