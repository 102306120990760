<template>
  <div class="profile-inner services">
    <popup-cart
      :cartItems="servicesCart"
      :isLoading="isLoadingPayment"
      @handleCartClear="handleCartClear"
      @handlePayment="handlePayment"
      @handleRemoveCartItem="handleRemoveCartItem"
    />
    <p class="profile-inner__title">{{ $t('profile.services') }}</p>
    <!-- <p class="profile-inner__description">
      Informacija, kad paslaugos yra vienkartinio mokėjimo. Eget morbi porttitor ut sit odio nunc
      nunc
    </p> -->
    <div class="profile-inner__filter">
      <div class="input-wrapper">
        <svgicon name="search-grey" width="17px" height="18px"></svgicon>
        <input
          class="search"
          :value="serviceSearchQuery"
          v-debounce:1000="handleServiceSearch"
          :placeholder="$t('search')"
        />
      </div>
      <div class="category-list flex-global">
        <span :class="{ active: activeCategory === 'all' }" @click="changeCategory('all')">
          {{ $t('all_2') }}
        </span>
        <span
          :class="{ active: activeCategory === category }"
          v-for="(category, index) in serviceCategories"
          :key="index"
          @click="changeCategory(category)"
        >
          {{ category }}
        </span>
      </div>
    </div>
    <div class="empty-list-wrapper flex-global" v-if="!areServicesPresent">
      <p>{{ $t('messages.services_list_empty_message') }}</p>
    </div>
    <div
      v-for="(category, index) in activeServiceCategories"
      :key="index"
      class="content-container flex-global"
    >
      <p class="category-title">{{ category }}</p>
      <div class="content-list flex-global">
        <div
          class="content-list__wrapper"
          v-for="service in filteredServices(category)"
          :key="service.id"
        >
          <user-service :service="service" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import UserService from '@/components/user/services/UserService.vue';
  import PopupCart from '@/components/base/PopupCart/PopupCart.vue';

  export default {
    name: 'ProfileServices',
    components: {
      UserService,
      PopupCart,
    },
    data() {
      return {
        activeCategory: 'all',
        queries: {
          q: null,
        },
      };
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('services', [
        'servicesList',
        'servicesCart',
        'isLoadingPayment',
        'serviceSearchQuery',
      ]),
      orgId() {
        return this.$route.params.orgId && parseInt(this.$route.params.orgId, 10);
      },
      services() {
        return this.servicesList && this.servicesList.length
          ? this.servicesList.map((service) => {
              return {
                id: service.id,
                category: service.category,
                title: service.title,
                description: service.description,
                imageUrl: service.image,
                price: service.price,
                priceBeforeDiscount: service.priceBeforeDiscount,
                priceWithoutVAT: service.priceWithoutVAT,
                isOnSale: service.discountText && service.discountText !== '',
                salePrice: service.price,
                discountText: service.discountText,
                salePercentage: ' ',
                actionHandler: {
                  action: () => this.handleAddToCartAction(service.id),
                  actionTitle: this.$t('actions.add_to_cart_action'),
                },
              };
            })
          : [];
      },
      areServicesPresent() {
        return (this.services && this.services.length) || false;
      },
      serviceCategories() {
        const servicesList =
          this.servicesList && this.servicesList.length
            ? this.servicesList.map((service) => service.category)
            : [];

        return servicesList && servicesList.length
          ? servicesList.filter((v, i, a) => a.indexOf(v) === i)
          : [];
      },
      activeServiceCategories() {
        return this.activeCategory === 'all'
          ? this.serviceCategories
          : this.serviceCategories.filter((cat) => cat === this.activeCategory);
      },
      servicesCartIds() {
        return this.servicesCart && this.servicesCart.length
          ? this.servicesCart.map((item) => item.id)
          : [];
      },
    },
    methods: {
      ...mapActions('services', [
        'getServices',
        'addServiceToCart',
        'resetServicesCart',
        'removeServiceFromCart',
        'handlePayseraPurchaseForServices',
        'setIsLoadingPayment',
        'setSearchQuery',
      ]),
      handlePageInit() {
        this.setSearchQuery(null);
        this.getServices(this.queries);
      },
      handleServiceSearch(value) {
        this.setSearchQuery(value);
        this.queries.q = value;
        this.getServices(this.queries);
      },
      filteredServices(cat) {
        return this.services.filter((service) => service.category === cat);
      },
      handleAddToCartAction(id) {
        this.addServiceToCart(id).then((res) => {
          const { success } = res;
          if (success) {
            this.$toast.success(this.$t('warnings.add_to_cart_success_message'));
          } else {
            this.$toast.error(this.$t('warnings.item_already_in_cart_message'));
          }
        });
      },
      handlePayment() {
        this.setIsLoadingPayment(true);
        const formData = {
          serviceIds: this.servicesCartIds,
          ...(this.orgId && { organizationId: this.orgId }),
        };

        this.handlePayseraPurchaseForServices(formData).then(() => {
          this.setIsLoadingPayment(false);
        });
      },
      handleCartClear() {
        this.resetServicesCart();
        this.$toast.success(this.$t('warnings.cart_cleared_message'));
      },
      handleRemoveCartItem(id) {
        this.removeServiceFromCart(id);
      },
      changeCategory(value) {
        this.activeCategory = value;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .profile-inner {
    &.services {
      position: relative;
    }
    &__filter {
      .input-wrapper {
        position: relative;
        svg {
          position: absolute;
          left: 8px;
          top: 5px;
        }
        .search {
          margin-bottom: 24px;
          padding-left: 30px;
          width: 494px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid $grey-3;
          font-family: 'Nunito-Regular';
          font-size: 14px;
          font-weight: 400;
        }
      }
      .category-list {
        width: 100%;
        justify-content: flex-start;
        span {
          display: block;
          font-size: 16px;
          font-weight: 600;
          color: $grey-5;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 23px;
          }
          &:hover {
            text-decoration: underline;
          }
          &.active {
            text-decoration: underline;
            color: $violet-3;
          }
        }
      }
    }
  }
  .content-container {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 40px;
    .category-title {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 600;
      color: $grey-5;
    }
  }
  .content-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 810px;

    &__wrapper {
      margin: 0 23px 23px 0;
    }

    &__content {
      width: 310px;
      height: 392px;
      background: grey;
    }
  }
</style>
