<template>
  <div class="template__indicator" v-if="question">
    <div class="template__input-field">
      <div class="template__indicator__scales">
        <div class="template__indicator__scales__scale">
          <span class="uppercase-title">{{ $t('from_cap') }}</span>
          <input
            :class="[
              'input-box',
              'input-box--small',
              { 'input-box--error': errors.min || errors.range },
            ]"
            type="number"
            :placeholder="errors.min || $t('insert_value_placeholder')"
            v-model="options.min"
            min="0"
            :disabled="!edit"
            @keydown="handleNumberInput($event, 'min')"
            @input="handleScaleRange($event, 'min')"
          />
        </div>
        <div class="template__indicator__scales__scale">
          <span class="uppercase-title">{{ $t('to_cap') }}</span>
          <input
            :class="[
              'input-box',
              'input-box--small',
              { 'input-box--error': errors.max || errors.range },
            ]"
            type="number"
            :placeholder="errors.max || $t('insert_value_placeholder')"
            v-model="options.max"
            min="1"
            :disabled="!edit"
            @keydown="handleNumberInput($event, 'max')"
            @input="handleScaleRange($event, 'max')"
          />
        </div>
      </div>
      <p
        class="template__input-field__error indicator-method-error-message"
        v-if="errors && errors.range"
      >
        {{ errors.range }}
      </p>
    </div>
    <div class="template__input-field">
      <span class="uppercase-title">{{ $t('questionnaire.bottom_title') }}</span>
      <input
        :class="['input-box', { 'input-box--error': errors.minTitle }]"
        type="text"
        :placeholder="errors.minTitle || $t('insert_value_placeholder')"
        v-model="options.minTitle"
        :disabled="!edit"
      />
    </div>
    <div class="template__input-field">
      <span class="uppercase-title">{{ $t('questionnaire.top_title') }}</span>
      <input
        :class="['input-box', { 'input-box--error': errors.maxTitle }]"
        type="text"
        :placeholder="errors.maxTitle || $t('insert_value_placeholder')"
        v-model="options.maxTitle"
        :disabled="!edit"
      />
    </div>
    <div class="template__input-field numberTypeWrapper">
      <span class="uppercase-title">{{ $t('questionnaire.number_interval') }}</span>
        <select name="scale-numberType-select" class="input-box numberTypeSelect" v-model="options.numberType">
          <option v-for="size in intervalSizes" :key="size.value" :value="size.value">{{size.name}}</option>
        </select>
       <!-- <multi-select-indicator-size
        :options="intervalSizes"
        :id="'indicatorIntervalSelect'"
        :placeholder='$t("actions.select_action")'
        :selected="selectedIntervalSize"
      ></multi-select-indicator-size> -->
    </div>
    <!-- <div class="template__input-field template__input-field--column">
      <span class="uppercase-title">{{ $t('questionnaire.number_interval') }}</span>
      <input
        :class="[
          'input-box',
          'input-box--small',
          { 'input-box--error': errors.step || errors.stepError },
        ]"
        type="number"
        :placeholder="errors.step || $t('insert_value_placeholder')"
        v-model="options.step"
        min="1"
        @keydown="handleStep($event)"
        @input="handleStepErrors($event.target.value)"
        :disabled="!edit"
      />
    </div> -->
    <p
      class="template__input-field__error indicator-method-error-message"
      v-if="errors && errors.stepError"
    >
      {{ errors.stepError }}
    </p>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  // import MultiSelectIndicatorSize from '@/components/elements/select/MultiSelectIndicatorSize.vue';

  export default {
    name: 'TemplateIndicator',
    components: {
      // MultiSelectIndicatorSize,
    },
    props: ['number', 'question', 'edit'],
    data() {
      return {
        i: 0,
        intervalSizes: [
          { name: this.$t('methods.scale_method_number_type_integers'), value: 'integers'},
          { name: this.$t('methods.scale_method_number_type_tenth'), value: 'tenth'},
          { name: this.$t('methods.scale_method_number_type_hundredth'), value: 'hundredth'},
        ]
      };
    },
    created() {
      if (!this.options.min) this.options.min = 1;
      if (!this.options.max) this.options.max = 10;
      if (!this.options.step) this.options.step = 1;
      if (!this.options.numberType) this.options.numberType = this.intervalSizes[0].value;
      if (!this.options.minTitle)
        this.options.minTitle = this.$t('questionnaire.scale_bottom_placeholder');
      if (!this.options.maxTitle)
        this.options.maxTitle = this.$t('questionnaire.scale_top_placeholder');
    },
    computed: {
      ...mapGetters('questionnaire', ['questions']),
      options() {
        return this.question.answerOptions;
      },
      errors() {
        return this.question.answerOptions.errors || null;
      },
      type() {
        return this.question.answerOptions.type;
      },
      view() {
        return this.question.answerOptions.view;
      },
      selectedIntervalSize(){
        return this.intervalSizes.filter(obj => obj.value === 'hundredth')
      },
    },
    methods: {
      ...mapActions('questionnaire', ['setAnswerOptionError', 'selectedQuestionErrors']),
      handleLengthInput(event) {
        const { value } = event.target;
        this.options.maxLength = parseInt(value, 10);
      },
      handleNumberInput(event, type) {
        const { keyCode } = event;
        const { value } = event.target;
        if (!((keyCode > 95 && keyCode < 106) || (keyCode > 47 && keyCode < 58) || keyCode === 8)) {
          event.preventDefault();
        }
        if (!value) {
          switch (type) {
            case 'min':
              this.setAnswerOptionError({
                questionIndex: this.number,
                optionKey: 'min',
                error: this.$t('warnings.finish_2_typing'),
              });
              break;
            case 'max':
              this.setAnswerOptionError({
                questionIndex: this.number,
                optionKey: 'max',
                error: this.$t('warnings.finish_2_typing'),
              });
              break;
            default:
              break;
          }
        }
      },
      handleScaleRange(event) {
        const { value } = event.target;
        const { min, max, step } = this.options;

        this.handleStepErrors(step);
        if (value) {
          if (parseInt(min, 10) === parseInt(max, 10) || parseInt(min, 10) > parseInt(max, 10)) {
            this.setAnswerOptionError({
              questionIndex: this.number,
              optionKey: 'range',
              error: this.$t('warnings.indicator_method_warning_invalid_range'),
            });
          } else {
            this.setAnswerOptionError({
              questionIndex: this.number,
              optionKey: 'range',
              error: null,
            });
          }
        }
      },
      handleStep(event) {
        this.handleNumberInput(event);
      },
      handleStepErrors(value) {
        const { min, max } = this.options;
        const minValue = parseInt(min, 10);
        const maxValue = parseInt(max, 10);
        const maxRangeValue = minValue > maxValue ? minValue : maxValue;

        if (value) {
          if (parseInt(value, 10) > parseInt(maxRangeValue, 10) || parseInt(value, 10) === 0) {
            this.setAnswerOptionError({
              questionIndex: this.number,
              optionKey: 'stepError',
              error: this.$t('warnings.indicator_method_warning_invalid_interval'),
            });
          } else {
            this.setAnswerOptionError({
              questionIndex: this.number,
              optionKey: 'stepError',
              error: null,
            });
          }
        }
      },
    },
  };
</script>
<style lang="scss">
  .template {
    &__indicator {
      &__scales {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__scale {
          input {
            margin-left: 12px;
          }
        }
      }
      .template__input-field__error {
        &.indicator-method-error-message {
          position: static;
        }
      }
    }
    &__input-field{
      .numberTypeSelect{
        padding: 0;
        padding-left: 4px;
        option{
          font-family: Roboto-Regular, sans-serif;
        }
      }
    }
  }
</style>
