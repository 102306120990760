<template>
  <div class="modal" @click.native="toggleModal">
    <div class="modal__bg">
      <div class="modal__modal" @click.stop>
        <div v-if="!deleteModal" class="modal__container">
          <div class="user-info">
            <div class="user-info__credentials">
              <div class="name-wrapper flex-global">
                <span class="name">{{ fullName }}</span>
                <span class="delete-button" @click="toggleDeleteModal">
                  <svgicon name="bin-2" width="16px" height="16px" color="#f23333" />
                  {{ $t('actions.remove_action') }}
                </span>
              </div>
              <div class="info-wrapper flex-global">
                <div class="info-wrapper__box">
                  <p class="title">{{ $t('inputs.first_name_cap') }}</p>
                  <p>{{ user.name }}</p>
                </div>
                <div class="info-wrapper__box">
                  <p class="title">{{ $t('inputs.last_name_cap') }}</p>
                  <p>{{ user.lastName }}</p>
                </div>
                <div class="info-wrapper__box">
                  <p class="title">{{ $t('inputs.email_address_cap') }}</p>
                  <p>{{ user.email }}</p>
                </div>
                <div class="info-wrapper__box">
                  <p class="title">{{ $t('inputs.org_phone_alt_cap') }}</p>
                  <p>{{ user.phone || '-' }}</p>
                </div>
                <div class="info-wrapper__box">
                  <p class="title">{{ $t('last_logged_in') }}</p>
                  <p>{{ lastLoggedIn }}</p>
                </div>
              </div>
            </div>
            <div class="user-info__role flex-global">
              <p class="title">{{ $t('role') }}</p>
              <div class="toggle-wrapper">
                <div class="toggle-wrapper__element flex-global" @click="handleRoleChange(roles.teacher)">
                  <div class="toggle">
                    <div v-if="currentRole === roles.teacher"></div>
                  </div>
                  <span>{{ $t('teacher') }}</span>
                </div>
                <div class="toggle-wrapper__element flex-global" @click="handleRoleChange(roles.admin)">
                  <div class="toggle">
                    <div v-if="currentRole === roles.admin"></div>
                  </div>
                  <span>{{ $t('admin') }}</span>
                </div>
                <!-- <div
                  class="toggle-wrapper__element flex-global"
                  @click="handleRoleChange(roles.teacherAdmin)"
                >
                  <div class="toggle">
                    <div v-if="currentRole === roles.teacherAdmin"></div>
                  </div>
                  <span>{{ $t('teacher_admin') }}</span>
                </div> -->
                <p class="toggle-wrapper__info-text">{{ roleInfoText }}</p>
              </div>
            </div>
            <!-- <div class="user-info__licence flex-global">
              <p class="title">{{ $t('profile.licence') }}</p>
              <div class="licence-wrapper">
                <licence-card
                  :licence="userLicence"
                  :isFromOrg="true"
                  @handleLicenceSuspend="handleLicenceSuspend"
                />
              </div>
            </div> -->
            <div class="user-info__buttons flex-global">
              <button-3 class="cancel" @click.native="toggleModal">
                {{ $t('actions.cancel_action') }}
              </button-3>
              <button-4 class="save" @click.native="handleEditAction">
                {{ $t('actions.save_action_3') }}
              </button-4>
            </div>
          </div>
        </div>
        <div v-else class="modal__container delete-modal">
          <p class="header">{{ $t('warnings.remove_user_question') }}</p>
          <p class="subtitle">{{ $t('warnings.remove_user_question_subtitle') }}</p>
          <div class="user-info__buttons flex-global">
            <button-3 class="cancel" @click.native="toggleDeleteModal">
              {{ $t('actions.cancel_action') }}
            </button-3>
            <button-4 class="save" @click.native="handleUserRemove">
              {{ $t('actions.remove_action') }}
            </button-4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import LicenceCard from '@/components/user/licences/LicenceCard.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

import {
  USER_TYPE_MEMBER,
  USER_TYPE_ADMIN,
  USER_TYPE_TEACHER_ADMIN,
} from '@/helpers/constants/userTypes';

export default {
  name: 'UserModal',
  props: ['user', 'orgId'],
  components: {
    // LicenceCard,
    Button3,
    Button4,
  },
  data() {
    return {
      roles: {
        teacher: USER_TYPE_MEMBER,
        admin: USER_TYPE_ADMIN,
        teacherAdmin: USER_TYPE_TEACHER_ADMIN,
      },
      currentRole: USER_TYPE_MEMBER,
      userLicence: {
        id: 111,
        number: '#421G4VCEJX2E1',
        title: 'Reflectus platforma',
        type: 1,
        durationType: 1,
        customer: 'Mindaugas Leskauskas',
        validUntil: '2022-10-15',
        price: 11.99,
        nextPayment: '2022-12-30',
      },
      deleteModal: false,
    };
  },
  created() {
    this.currentRole = this.user ? this.user.role : this.roles.USER_TYPE_MEMBER;
  },
  computed: {
    fullName() {
      return `${this.user.name} ${this.user.lastName}`;
    },
    lastLoggedIn() {
      const isValidDate = this.moment(this.user.lastLoginDate).isValid();
      return isValidDate ? this.moment(this.user.lastLoginDate).format('YYYY-MM-DD') : '-';
    },
    roleInfoText() {
      switch (this.currentRole) {
        case this.roles.teacher:
          return this.$t('profile.user_type_teacher_info_message');
        case this.roles.admin:
          return this.$t('profile.user_type_admin_info_message');
        case this.roles.teacherAdmin:
          return 'text for teacherAdmin';
        default:
          return '-';
      }
    },
  },
  methods: {
    toggleModal() {
      this.$emit('handleModalToggle');
    },
    toggleDeleteModal() {
      this.deleteModal = !this.deleteModal;
    },
    handleRoleChange(value) {
      this.currentRole = value;
    },
    handleLicenceSuspend(value) {
      console.log(`handle suspend: ${value}`);
    },
    handleUserRemove() {
      this.deleteModal = false;
      this.$emit('handleAction', {
        organizationId: this.orgId,
        clientId: this.user.id,
      });
    },
    handleEditAction() {
      this.$emit('handleEditAction', { email: this.user.email, role: this.currentRole });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  &__modal {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  }

  &__container {
    padding: 22px 27px;
    padding-bottom: 44px;
    width: 1000px;
    border-radius: 5px;

    &.delete-modal {
      width: 522px;
      padding-bottom: 25px;

      p {
        width: 100%;
        text-align: left;

        &.header {
          margin-bottom: 6px;
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          color: $grey-9;
        }

        &.subtitle {
          margin-bottom: 40px;
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
          color: #888;
        }
      }

      .user-info {
        &__buttons {
          width: 100%;
          justify-content: flex-end;

          button {
            &.save {
              width: 145px;
            }
          }
        }
      }
    }
  }

  .user-info {
    width: 100%;
    color: $grey-9;

    &__credentials {
      margin-bottom: 30px;
      text-align: left;

      .name-wrapper {
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 100%;

        span {
          &.name {
            margin-right: 22px;
            font-size: 16px;
            font-weight: 600;
          }

          &.delete-button {
            margin-top: 1px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            color: #f23333;
          }
        }
      }

      .info-wrapper {
        width: 100%;
        justify-content: flex-start;

        &__box {
          &:not(:last-child) {
            margin-right: 45px;
          }

          p {
            font-size: 16px;
            font-weight: 400;

            &.title {
              font-size: 12px;
              font-weight: 300;
              color: #888;
            }
          }
        }
      }
    }

    &__role {
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 25px;
      padding-top: 25px;
      padding-bottom: 20px;
      width: 100%;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      .title {
        width: 150px;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }

      .toggle-wrapper {
        text-align: left;

        &__element {
          justify-content: flex-start;
          cursor: pointer;

          &:not(:last-of-type) {
            margin-bottom: 14px;
          }

          .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            width: 20px;
            height: 20px;
            border: 2px solid #000;
            border-radius: 999px;

            div {
              width: 10px;
              height: 10px;
              border-radius: 999px;
              background: #000;
            }
          }

          span {
            font-size: 14px;
            font-weight: 400;
            color: #666;
          }
        }

        &__info-text {
          margin-top: 22px;
          font-size: 12px;
          font-weight: 300;
          color: #888;
        }
      }
    }

    &__licence {
      margin-bottom: 43px;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .title {
        width: 150px;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }

      .licence-wrapper {
        .licence-card {
          min-width: 620px;
          max-width: 620px;
        }
      }
    }

    &__buttons {
      justify-content: flex-end;

      button {
        height: 40px;

        &.cancel {
          width: 145px;
        }

        &.save {
          margin-left: 18px;
          width: 202px;
          background: $violet-3;

          &:hover {
            background: $violet-5;
          }
        }
      }
    }
  }
}
</style>
