<template>
  <section id="my-groups" class="my-groups" v-if="!loading">
    <div class="my-groups__header">
      <div class="my-groups__search-wrapper">
        <input
          type="text"
          :placeholder="$t('questionnaire_search_placeholder')"
          :value="groupSearch"
          v-debounce:1000="handleGroupSearch"
        />
        <svgicon
          name="search"
          :original="true"
          height="1em"
          :fill="false"
          :color="'#0F9F8F'"
        ></svgicon>
      </div>
      <header-h1>{{ $t('my_groups') }}</header-h1>
      <router-link :to="{ name: 'NewGroup' }">
        <button-7>{{ $t('group.new_group') }}</button-7>
      </router-link>
    </div>
    <div class="my-groups__container">
      <div class="my-groups__list" v-if="groupList.length">
        <group-list-item
          v-for="(group, index) in groupList"
          :key="index"
          :group="group"
          :isFromList="true"
        />
      </div>
      <not-found
        v-else
        :text="$t('messages.no_groups_at_the_moment_message')"
        routeName="Home"
      ></not-found>
    </div>
  </section>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import { handleGetGroupList } from '@/helpers/groups.helpers';

  import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
  import Button7 from '@/components/elements/buttons/Button7.vue';
  import GroupListItem from '@/components/groups/GroupListItem.vue';
  import Loading from '@/components/base/Loading.vue';
  import NotFound from '@/components/base/NotFound.vue';

  export default {
    name: 'MyGroups',
    components: {
      HeaderH1,
      Button7,
      GroupListItem,
      Loading,
      NotFound,
    },
    data() {
      return {
        queries: {
          q: null,
        },
      };
    },
    created() {
      this.handlePageInit();
    },
    mounted() {
      this.setGroupSearch('');
    },
    computed: {
      ...mapGetters('groups', ['groupList']),
      ...mapGetters('meta', ['loading', 'groupSearch']),
    },
    methods: {
      ...mapActions('groups', ['getGroupList']),
      ...mapActions('meta', ['setLoading', 'setGroupSearch']),
      handleGetGroupList,
      async handlePageInit() {
        this.setLoading(true);
        try {
          this.handleGetGroupList(this.queries);
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      handleGroupSearch(value) {
        this.setLoading(true);
        this.setGroupSearch(value);

        this.queries.q = value;
        this.getGroupList(this.queries).then(this.setLoading(false));
      },
    },
  };
</script>
<style lang="scss">
  @import 'mixins';

  .my-groups {
    width: 100%;
    max-width: 940px;
    margin: 20px auto 0 auto;
    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;

      button {
        font-size: 14px;
      }

      &__spacer {
        flex: 1;
      }

      h1 {
        flex: 1;
        text-align: center;
      }
    }

    &__search-wrapper {
      position: relative;
      background-color: #fff;
      input {
        padding: 10px;
        height: 28px;
        width: 256px;
        border: none;

        &::placeholder {
          color: $grey-2;
        }
      }

      svg {
        position: absolute;
        top: 20%;
        right: 4%;
        cursor: pointer;
      }
    }

    &__container {
      margin-top: 15px;
    }

    &__list {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      // &-item {
      //   height: 50px;
      //   width: 420px;
      //   padding: 0 20px;
      //   background-color: #fff;
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   margin-top: 5px;

      //   @include handleHover();

      //   &:nth-of-type(n + 1) {
      //     margin-left: 10px;
      //   }

      //   h4 {
      //     font-size: 16px;
      //   }

      //   a {
      //     height: 1.2em;
      //   }
      // }
    }
  }
</style>
