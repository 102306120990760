<template>
  <div class="not-found__page">

    <section id="not-found" class="not-found">
      <div class="not-found__box">
        <p class="not-found__box__title">
          {{ $t("page_not_found_header") }}
        </p>
        <div class="not-found__box__actions">
          <router-link :to="{name: 'Home'}">
          <button-3>{{ $t("actions.back_to_start_action") }}</button-3>
          </router-link>
          <button-4 @click.native="$router.back()">{{ $t("actions.go_back_action") }}</button-4>
        </div>
        <div class="not-found__box__icons">
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--404"
            name="404/404"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--dotted-square"
            name="404/dotted-square"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--half-circle"
            name="404/half-circle"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--oval-1"
            name="404/oval"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--oval-2"
            name="404/oval"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--question-mark"
            name="404/question-mark"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--weave"
            name="404/weave"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--x"
            name="404/x"
            :original="true"
          ></svgicon>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'NotFound',
  components: {
    Button3,
    Button4,
  },
};
</script>
<style lang="scss">
.not-found {
  max-width: 940px;
  margin: 30px auto 0 auto;
  position: relative;

  &__box {
    position: relative;
    margin: 170px auto 0 auto;
    width: 620px;
    height: 186px;
    padding: 20px;
    border-radius: 2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-family: Roboto-Medium;
      font-size: 16px;
    }

    &__actions {
      margin-top: 40px;

      button {
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }

    &__icons {
      &__icon {
        position: absolute;

        &--404 {
          width: 212px;
          height: 64px;
          top: -40px;
          left: 50%;
          transform: translateX(-50%);
        }

        &--oval-1, &--oval-2 {
          height: 12px;
          width: 12px;
          top: -18px;
          transform: translateX(-25%);
        }

        &--oval-1 {
          left: 25%;
        }
        &--oval-2 {
          right: 25%;
        }

        &--weave {
          height: 22px;
          width: 54px;
          right: -20px;
          top: -10px;
        }

        &--x {
          height: 15px;
          width: 15px;
          right: -55px;
          top: 30%;
          transform: translateY(-30%);
        }

        &--question-mark {
          height: 14px;
          width: 8px;
          right: -45px;
          top: 50%;
          transform: translateY(-50%);
        }

        &--dotted-square {
          height: 52px;
          width: 52px;
          bottom: -23px;
          left: -23px;
          z-index: -1;
        }

        &--half-circle {
          height: 15px;
          width: 15px;
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
