<template>
  <div class="registration-switch flex-global">
    <div
      :class="[
        'registration-switch__button flex-global',
        { active: currentUserType === userTypes.teacher.id },
      ]"
      @click="handleTypeSwitch(userTypes.teacher.id)"
    >
      <svgicon
        name="user-type-teacher"
        :color="currentUserType === userTypes.teacher.id ? activeColor : inactiveColor"
        width="17px"
        height="17px"
      ></svgicon>
      <span>{{ userTypes.teacher.title }}</span>
    </div>
    <div
      :class="[
        'registration-switch__button flex-global',
        { active: currentUserType === userTypes.org.id },
      ]"
      @click="handleTypeSwitch(userTypes.org.id)"
    >
      <svgicon
        name="user-type-org"
        :color="currentUserType === userTypes.org.id ? activeColor : inactiveColor"
        width="17px"
        height="17px"
      ></svgicon>
      <span>{{ userTypes.org.title }}</span>
    </div>
  </div>
</template>
<script>
  import { USER_TYPE_TEACHER, USER_TYPE_ORG } from '@/helpers/constants/userTypes';

  export default {
    name: 'RegistrationTypeSwitch',
    components: {},
    data() {
      return {
        activeColor: '#5147A7',
        inactiveColor: '#B2B4B6',
        currentUserType: USER_TYPE_TEACHER,
        userTypes: {
          teacher: {
            id: USER_TYPE_TEACHER,
            title: this.$t('register_page.for_teacher'),
          },
          org: {
            id: USER_TYPE_ORG,
            title: this.$t('register_page.for_organization'),
          },
        },
      };
    },
    methods: {
      handleTypeSwitch(value) {
        this.currentUserType = value;
        this.$emit('handleTypeSwitch', value);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .registration-switch {
    width: 385px;
    height: 51px;
    padding: 5px;
    border: 1.5px solid $grey-10;
    border-radius: 25.5px;
    &__button {
      flex: 1;
      height: 100%;
      border: 1.5px solid #fff;
      border-radius: 999px;
      cursor: pointer;
      span {
        font-family: Nunito-Regular;
        margin-left: 12px;
        font-size: 16px;
        font-weight: 500;
        color: $grey-11;
      }
      &.active {
        border: 1.5px solid $violet-3;
        span {
          color: $violet-3;
        }
      }
    }
  }
</style>
