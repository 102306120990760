<template>
  <section id="main">
    
    <div class="main__container">
      <create-reflection></create-reflection>
      <Mobile></Mobile>
    </div>
  </section>
</template>

<script>

import CreateReflection from '@/components/reflections/CreateReflection.vue';
import Mobile from '@/components/mobile/Mobile.vue';

export default {
  name: 'NewReflection',
  components: {
    CreateReflection,
    Mobile,
  },
};
</script>
<style lang="scss" scoped>
#main {
  .main__container {
    display: flex;
    max-width: 940px;
    margin: 30px auto 0 auto;
    justify-content: space-between;
  }
}
</style>
