<template>
  <section id="reflection__create" class="reflection__create">
    <div class="reflection__container">
      <div class="reflection__info-box">
        <div class="reflection__header">
          <header-h1 v-if="type === 'new'">{{ $t("questionnaire.new_questionnaire_header") }}</header-h1>
          <header-h1 v-if="type === 'template'">{{ $t("questionnaire.questionnaire_template_header") }}</header-h1>
          <header-h1 v-if="type === 'draft'">{{ $t("questionnaire.questionnaire_header") }}</header-h1>
          <div class="reflection__delete" v-if="editStatus === 'edit' || type === 'new'">
            <button-4
              v-if="type ==='draft'"
              class="reflections__buttons__button reflections__buttons__button--share above-questionnaire"
              @click.native="goToOptions"
            >
              <svgicon
                class="reflections__buttons__button__icon"
                name="share_template"
                :original="true"
              ></svgicon>{{ $t("actions.send_action") }}
            </button-4>
            <svgicon
              v-if="type === 'new'"
              class="reflection__delete__icon"
              name="copy_template"
              :original="true"
              v-tooltip='$t("questionnaire.questionnaire_copy_to_templates_message")'
              @click="handleTemplateCopy"
            ></svgicon>
            <svgicon
              class="reflection__delete__icon"
              name="delete"
              :original="true"
              v-tooltip='$t("questionnaire.questionnaire_delete_message")'
              v-if="!questionnaire.clientGroupId"
              @click="toggleModal('delete')"
            ></svgicon>
          </div>
          <div class="reflection__delete" v-if="editStatus === 'view' && type === 'template'">
            <button-4
              v-if="type ==='template'"
              class="reflections__buttons__button reflections__buttons__button above-questionnaire"
              @click.native="useTemplate"
            >{{ $t("actions.use_this_template_action") }}
            </button-4>
          </div>
          <div
            class="questionnaire-delete-modal"
            @click="toggleModal"
            v-if="modalVisible && modalType === 'delete'"
          >
            <div class="questionnaire-delete-modal__bg">
              <div class="questionnaire-delete-modal__modal" @click.stop>
                <div class="questionnaire-delete-modal__container">
                  <svgicon
                    @click="toggleModal"
                    color="grey"
                    :fill="false"
                    name="multiply"
                    class="questionnaire-delete-modal__close"
                  ></svgicon>
                  <svgicon
                    class="questionnaire-delete-modal__container__icon"
                    name="large-question-mark"
                    :original="true"
                  ></svgicon>
                  <h4
                    class="questionnaire-delete-modal__container__heading"
                    v-if="type === 'template'"
                  >{{ $t("questionnaire.delete_template_question") }}</h4>
                  <h4
                    class="questionnaire-delete-modal__container__heading"
                    v-else
                  >{{ $t("questionnaire.delete_questionnaire_question") }}</h4>
                  <p
                    v-if="questionnaire.schedules.length"
                    class="questionnaire-delete-modal__container__warning"
                  >{{ $t("questionnaire.questionnaire_no_send_message") }}</p>
                  <div class="questionnaire-delete-modal__container__actions">
                    <button-3 @click.native="toggleModal">{{ $t("no_cap") }}</button-3>
                    <button-4 @click.native="handleQuestionnaireDelete">{{ $t("yes_cap") }}</button-4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="questionnaire-delete-modal"
            @click="toggleModal"
            v-if="modalVisible && modalType === 'success'"
          >
            <div class="questionnaire-delete-modal__bg">
              <div class="questionnaire-delete-modal__modal" @click.stop>
                <div class="questionnaire-delete-modal__container">
                  <svgicon
                    @click="toggleModal"
                    color="grey"
                    :fill="false"
                    name="multiply"
                    class="questionnaire-delete-modal__close"
                  ></svgicon>
                  <svgicon
                    class="questionnaire-delete-modal__container__icon"
                    name="large-check"
                    :original="true"
                  ></svgicon>
                  <h4
                    class="questionnaire-delete-modal__container__heading"
                  >{{ $t("questionnaire.template_creation_success_message") }}</h4>
                  <p
                    v-if="questionnaire.schedules"
                    class="questionnaire-delete-modal__container__warning"
                  >{{ $t("questionnaire.questionnaire_find_message") }}</p>
                  <div class="questionnaire-delete-modal__container__actions">
                    <button-4 @click.native="toggleModal">{{ $t("actions.ok_action") }}</button-4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="questionnaire-delete-modal"
            @click="toggleModal"
            v-if="modalVisible && modalType === 'errors'"
          >
            <div class="questionnaire-delete-modal__bg">
              <div class="questionnaire-delete-modal__modal" @click.stop>
                <div class="questionnaire-delete-modal__container">
                  <svgicon
                    @click="toggleModal"
                    color="grey"
                    :fill="false"
                    name="multiply"
                    class="options-modal__close"
                  ></svgicon>
                  <svgicon
                    class="options-modal__container__icon"
                    name="large-remove"
                    :original="true"
                  ></svgicon>
                  <h4
                    class="options-modal__container__heading"
                  >{{ $t("questionnaire.questionnaire_finish_message") }}</h4>
                  <div class="options-modal__container__items" v-if="errors">
                    <div
                      class="options-modal__schedule"
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      <p
                        class="options-modal__schedule__title options-modal__schedule__title--error"
                      >{{ error.msg }}</p>
                    </div>
                  </div>
                  <div class="options-modal__container__actions">
                    <button-3 @click.native="toggleModal">{{ $t("actions.ok_action") }}</button-3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="questionnaire-delete-modal"
            @click="toggleModal"
            v-if="modalVisible && modalType === 'noId'"
          >
            <div class="questionnaire-delete-modal__bg">
              <div class="questionnaire-delete-modal__modal" @click.stop>
                <div class="questionnaire-delete-modal__container">
                  <svgicon
                    @click="toggleModal"
                    color="grey"
                    :fill="false"
                    name="multiply"
                    class="options-modal__close"
                  ></svgicon>
                  <svgicon
                    class="options-modal__container__icon"
                    name="large-remove"
                    :original="true"
                  ></svgicon>
                  <h4 class="options-modal__container__heading">{{ $t("questionnaire.questionnaire_save_first_message") }}</h4>
                  <div class="options-modal__container__actions">
                    <button-3 @click.native="toggleModal">{{ $t("actions.ok_action") }}</button-3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <create-reflection-details :type="type" :editStatus="editStatus"></create-reflection-details>
      </div>
      <Reflections :type="type" :editStatus="editStatus" :personal="personal" ref="reflectionsRef"></Reflections>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Reflections from './Reflections.vue';
import CreateReflectionDetails from './CreateReflectionDetails.vue';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'CreateReflection',
  components: {
    Reflections,
    CreateReflectionDetails,
    HeaderH1,
    Button3,
    Button4,
  },
  props: ['type', 'editStatus', 'personal', 'isPublic'],
  data() {
    return {
      modalVisible: false,
      modalType: null,
    };
  },
  computed: {
    ...mapGetters('questionnaire', ['questionnaire', 'errors']),
    hasSchedules() {
      const { schedules } = this.questionnaire;
      return schedules ? schedules.length > 0 : false;
    },
  },
  methods: {
    ...mapActions('questionnaire', [
      'unsetQuestionnaire',
      'deleteQuestionnaire',
      'handleQuestionnaireErrors',
      'createTemplate',
    ]),
    ...mapActions('reflections', ['createFromQuestionnaire', 'deleteTemplate']),
    toggleModal(type) {
      this.modalType = type || null;
      this.modalVisible = !this.modalVisible;
    },
    handleQuestionnaireDelete() {
      const { id } = this.questionnaire;
      if (id) {
        if (this.type === 'draft' || this.type === 'new') {
          this.deleteQuestionnaire(id).then(() => this.handleDeleteReroute());
        } else this.deleteTemplate(id).then(() => this.handleDeleteReroute());
      } else this.handleDeleteReroute();
    },
    handleDeleteReroute() {
      this.unsetQuestionnaire();
      this.toggleModal();
      this.$router.push({ name: 'Home' });
    },
    handleTemplateCopy() {
      const { id } = this.questionnaire;
      if (!id) {
        this.toggleModal('noId');
        return;
      }
      this.handleQuestionnaireErrors().then((res) => {
        const { success } = res;
        if (success) {
          this.createFromQuestionnaire(id).then((res) => {
            const { success } = res;
            if (success) {
              this.toggleModal('success');
            }
          });
        } else {
          this.toggleModal('errors');
        }
      });
    },
    goToOptions() {
      this.$refs.reflectionsRef.callCreateButtonsRef();
    },
    useTemplate() {
      this.$refs.reflectionsRef.callUseTemplateRef();
    }
  },
};
</script>
<style lang="scss">
@import 'mixins';
#reflection__create {
  min-height: 660px;
  .reflection__container {
    max-width: 640px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .reflection__info-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .reflection__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        h3 {
          text-transform: uppercase;
          color: #3b328d;
          font-family: Nunito;
          font-size: 15px;
          font-weight: 700;
        }

        .reflection__delete {
          display: flex;
          &__icon {
            width: 27px;
            height: 27px;
            transition: all 0.3s;

            &:hover {
              cursor: pointer;
              transform: translateY(-3px) scale(1.25);
            }

            &:not(:first-of-type) {
              margin-left: 8px;
            }
          }
        }
      }
    }
    textarea {
      resize: none;
      color: #505e67;
    }
  }
}
.questionnaire-delete-modal {
  position: absolute;
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  &__container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__icon {
      width: 120px;
      height: 82px;
    }

    &__heading {
      margin-top: 20px;
      font-family: Nunito-Bold;
      font-size: 16px;
      color: $violet-5;
    }

    &__warning {
      font-family: Nunito-Regular;
      font-size: 16px;
      color: $violet-5;
      max-width: 260px;
    }

    &__actions {
      margin-top: 20px;

      button {
        width: 102px;
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}
.picker-modal {
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
  &__container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &__action {
    margin-top: 20px;
  }
}
</style>
