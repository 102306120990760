<template>
  <div class="method__modal">
    <svgicon
      @click="close"
      color="grey"
      :fill="false"
      name="multiply"
      class="method__modal-close"
      height="9px"
      width="9px"
    ></svgicon>
    <div class="method__container">
      <div class="method__header">
        <div class="method__title">
          <div>
            <svgicon :name="`methods/${method.template}`" :original="true" height="2.2em"></svgicon>
            <span>{{ method.title }}</span>
          </div>
          <router-link :to="{ name: 'CreateReflection' }">
            <button-7>{{ $t("actions.new_questionnaire_action") }}</button-7>
          </router-link>
        </div>
        <div class="method__type">
          <paragraph>
            {{ $t("questionnaire.answer_type") }} -
            <span>{{ $t("questionnaire.answer_type_closed") }}</span>
            (())
          </paragraph>
          <paragraph>{{ $t("duration") }} - {{ method.duration }} {{ $t("schedule.duration_seconds") }}</paragraph>
        </div>
      </div>
      <div class="method__description">
        <header-h2>{{ $t("methods.about_method") }}</header-h2>
        <paragraph>{{ method.description }}</paragraph>
      </div>
      <div class="method__creation">
        <header-h2>{{ $t("creation") }}</header-h2>
        <paragraph>
          {{ $t("questionnaire.question_creation_content") }}
        </paragraph>
      </div>
    </div>
    <div class="mobile__container">
      <mobile :modalView="true"></mobile>
      <span class="mobile__example">{{ $t("questionnaire.mobile_user_screen_example") }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Mobile from '@/components/mobile/Mobile.vue';
import Button7 from '@/components/elements/buttons/Button7.vue';
import Paragraph from '@/components/elements/typography/Paragraph.vue';
import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';

export default {
  name: 'MethodModal',
  components: {
    Button7,
    Paragraph,
    HeaderH2,
    Mobile,
  },
  props: ['method'],
  computed: {
    ...mapGetters('methods', ['selectedMethod']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.method__modal {
  position: relative;
  width: 780px;
  max-height: 545px;
  overflow-y: hidden;
  display: flex;
  .method__container {
    flex: 66%;
    display: flex;
    flex-direction: column;
    height: 545px;
    background-color: #fff;
    margin-right: 5px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      margin-right: 5px;
      border: none;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 45px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    .method__header {
      display: flex;
      padding: 20px;
      flex-direction: column;

      .method__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          span {
            margin-left: 10px;
            font-family: Nunito-Bold;
            font-size: 16px;
            text-transform: uppercase;
            color: $violet-5;
          }
        }
      }
      .method__type {
        align-items: baseline;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
      }
    }
    .method__description {
      padding: 0 20px;
      text-align: left;
    }
    .method__creation {
      text-align: left;
      padding: 0 20px;
      margin-top: 20px;
    }
    .method__example {
      padding: 20px;
    }
  }
  .mobile__container {
    flex: 36%;
    background-color: #fff;

    section {
      margin: 25px auto 0 auto;
    }
    .mobile__example {
      font-family: Nunito-Regular;
      font-size: 12px;
      color: $grey-5;
      display: block;
      text-align: center;
    }
  }
  .method__modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
