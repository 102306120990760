<template>
  <section id="group-member" class="group-member" v-if="!loading">
    <member-header></member-header>
    <member-filter @handleGroupSelect="handleGroupSelect" @handleMemberFilter="handleMemberFilter"></member-filter>
    <div class="group-member__reflections" v-if="filteredQuestionnaire.length">
      <member-reflection
        v-for="questionnaire in filteredQuestionnaire"
        :key="questionnaire.id"
        :member="member"
        :questionnaire="questionnaire"
      ></member-reflection>
    </div>
    <div v-else class="group-member__no-reflections-message">
      <p>{{ $t("messages.no_reflections_message") }}</p>
    </div>
    <pagination
      v-if="totalPages > 1"
      :activePage="activePage"
      :totalPages="totalPages"
      @selectPage="selectPage"
    ></pagination>
  </section>
  <not-found text="Narys nerastas" routeName="GroupMembers" v-else-if="loading && memberNotFound"></not-found>
  <loading v-else></loading>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import {
  handleGetGroup,
  handleGetMembers,
  handleGetMember,
  handleGetQuestionnaires,
} from '@/helpers/groups.helpers';

import MemberHeader from '@/components/groups/member/MemberHeader.vue';
import MemberFilter from '@/components/groups/member/MemberFilter.vue';
import MemberReflection from '@/components/groups/member/MemberReflection.vue';
import Pagination from '@/components/base/Pagination.vue';
import Loading from '@/components/base/Loading.vue';
import NotFound from '@/components/base/NotFound.vue';

export default {
  name: 'GroupMember',
  components: {
    MemberHeader,
    MemberFilter,
    MemberReflection,
    Loading,
    NotFound,
    Pagination,
  },
  data() {
    return {
      groupId: null,
      memberNotFound: false,
      queries: {
        limit: 10,
        q: null,
        offset: null,
        clientId: null,
        clientGroupId: null,
      },
      totalCount: null,
      activePage: 1,
      filterGroupId: null,
    };
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('members', ['member', 'members', 'memberGroups']),
    ...mapGetters('questionnaire', ['groupQuestionnaireList']),
    ...mapGetters('meta', ['loading']),
    totalPages() {
      return Math.ceil(this.totalCount / this.queries.limit) || 0;
    },
    filteredQuestionnaire() {
      if (!this.filterGroupId) return this.groupQuestionnaireList.items;
      return this.groupQuestionnaireList.items.filter(
        (group) => group.clientGroupId === this.filterGroupId
      );
    },
  },
  methods: {
    ...mapActions('groups', ['getGroup']),
    ...mapActions('members', ['getMembers', 'getMember', 'getMemberGroupList']),
    ...mapActions('questionnaire', ['getQuestionnairesByGroup']),
    ...mapActions('meta', ['setLoading']),
    handleGetGroup,
    handleGetMembers,
    handleGetMember,
    handleGetQuestionnaires,
    async handlePageInit() {
      const {
        params: { memberId, id },
      } = this.$route;
      this.groupId = +id;
      this.queries.clientId = +memberId;
      this.setLoading(true);
      try {
        await this.handleGetGroup();
        await this.handleGetMembers();
        const { success } = await this.handleGetMember();
        const questionnaires = await this.handleGetQuestionnaires(success);
        this.handleQuestionnairePagination(questionnaires);
      } catch (error) {
        this.setLoading(false);
      } finally {
        this.setLoading(false);
      }
    },
    handleNotFound() {
      this.setLoading(false);
      this.memberNotFound = true;
    },
    selectPage(page) {
      this.queries.offset = page === 1 ? 0 : page * this.queries.limit - this.queries.limit;
      this.getQuestionnairesByGroup(this.queries);
      this.activePage = page;
    },
    handleQuestionnairePagination(res) {
      const {
        payload: { totalCount },
      } = res;
      this.totalCount = totalCount;
    },
    handleGroupSelect(group) {
      const { id } = group;
      this.filterGroupId = id || null;
    },
    handleMemberFilter(q) {
      this.queries = {
        ...this.queries,
        q: q || null,
        clientGroupId: this.filterGroupId || this.groupId,
        offset: null,
      };
      this.getQuestionnairesByGroup(this.queries).then((res) => {
        this.handleQuestionnairePagination(res);
      });
    },
  },
};
</script>
<style lang="scss">
.group-member {
  max-width: 940px;
  margin: 20px auto 0 auto;
  position: relative;

  &__reflections {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  &__no-reflections-message {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    p {
      width: 940px;
      text-align: center;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    .loading {
      width: 400px;
      height: 400px;
    }
  }
}
</style>
