<template>
  <section id="reflection__options" class="reflection__options" v-if="!loading">
    <div class="options-header flex-global">
      <header-h1>{{ $t('schedule.reflection_schedule_settings') }}</header-h1>
      <create-reflection-buttons :submit="true" :type="type"></create-reflection-buttons>
    </div>
    <div class="options__container">
      <options-form
        :key="`options-form--${scheduleLength}`"
        :questionnaire="questionnaire"
        :scheduleLength="scheduleLength"
        :groupList="groupList"
      ></options-form>
      <div v-if="!scheduleIsEdit" class="options__add">
        <button-5
          @click.native="addScheduleHandler"
          :class="{
            'schedule-button-error':
              this.questionnaire.schedules.length === 0 && scheduleErrorCheck,
          }"
        >
          + {{ addTimeButtonTitle }}
        </button-5>
        <p
          class="schedule-error-message"
          v-if="this.questionnaire.schedules.length === 0 && scheduleErrorCheck"
        >
          ({{ $t('warnings.no_schedule_options_warning_message') }})
        </p>
      </div>
      <!-- <div class="options__actions">
        <create-reflection-buttons :submit="true" :type="type"></create-reflection-buttons>
      </div> -->
    </div>
    <div class="modal" @click="handleModalToggle" v-if="showModal">
      <div class="modal__bg">
        <div class="modal__modal" @click.stop>
          <div class="modal__container">
            <svgicon
              @click="handleModalToggle"
              color="grey"
              :fill="false"
              name="multiply"
              class="modal__close"
            ></svgicon>
            <svgicon class="modal__container__icon" name="large-remove" :original="true"></svgicon>
            <h4 class="modal__container__heading">{{ $t('attention_cap') }}!</h4>
            <p class="modal__container__warning">
              {{ $t('schedule.create_group_for_schedule_message_one') }}
            </p>
            <p class="modal__container__warning">
              {{ $t('schedule.create_group_for_schedule_message_two') }}
            </p>
            <div class="modal__container__actions">
              <button-3 @click.native="handleModalToggle">{{ $t('actions.ok_action') }}</button-3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <navigation-modal v-show="questionnaireNavigation.modal"></navigation-modal>
  </section>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
  import CreateReflectionButtons from '@/components/reflections/CreateReflectionButtons.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button5 from '@/components/elements/buttons/Button5.vue';
  import OptionsForm from '@/components/reflections/options/OptionsForm.vue';
  import Loading from '@/components/base/Loading.vue';
  import NavigationModal from '@/components/base/NavigationModal.vue';

  export default {
    name: 'ReflectionOptions',
    components: {
      HeaderH1,
      CreateReflectionButtons,
      Button3,
      Button5,
      OptionsForm,
      Loading,
      NavigationModal,
    },
    data() {
      return {
        type: null,
        scheduleLength: 0,
        showModal: false,
        groupListQuery: {
          q: null,
        },
      };
    },
    created() {
      this.handlePageInit();
    },
    beforeDestroy() {
      this.resetQuestionnaireState();
      this.setScheduleIsEdit(false);
    },
    computed: {
      ...mapGetters('questionnaire', [
        'questionnaire',
        'scheduleErrorCheck',
        'currentScheduleType',
        'scheduleIsEdit',
      ]),
      ...mapGetters('groups', ['groupList']),
      ...mapGetters('meta', ['questionnaireNavigation', 'loading']),
      addTimeButtonTitle() {
        return this.currentScheduleType === 'once'
          ? this.$t('schedule.add_one_time_type_option')
          : this.$t('schedule.add_recurrent_type_option');
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'addSchedule',
        'getQuestionnaireSchedule',
        'getQuestionnaire',
        'resetQuestionnaireState',
        'setScheduleIsEdit',
      ]),
      ...mapActions('reflections', ['getTemplateById', 'setEditStatus']),
      ...mapActions('groups', ['getGroupList']),
      ...mapActions('meta', ['setLoading', 'setOptionsNavigation']),
      async handlePageInit() {
        this.setLoading(true);
        this.setOptionsNavigation(false);
        this.setScheduleIsEdit(false);
        this.setEditStatus('edit');
        const {
          params: { type },
        } = this.$route;
        this.type = type;

        try {
          await this.handleGetGroup();
          await this.handleGetQuestionnaire();
        } catch {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      async handleGetGroup() {
        const { success, data } = await this.getGroupList(this.groupListQuery);
        if (success && !data.length) {
          this.showModal = true;
        }
        return Promise.resolve();
      },
      async handleGetQuestionnaire() {
        const {
          params: { id },
        } = this.$route;
        await this.getQuestionnaire({ questionnaireId: id });
        const { schedules } = await this.getQuestionnaireSchedule(id);
        this.scheduleLength = schedules ? schedules.length : 0;

        return Promise.resolve();
      },
      addScheduleHandler() {
        this.addSchedule(this.currentScheduleType);
        this.scheduleLength = this.questionnaire.schedules.length;
        this.setScheduleIsEdit(true);
      },
      handleModalToggle() {
        this.showModal = false;
        this.$router.push({ name: 'MyGroups' });
      },
    },
  };
</script>
<style lang="scss">
  #reflection__options {
    width: 940px;
    margin: 20px auto 0 auto;
    position: relative;

    h1 {
      width: 270px;
      text-align: center;
    }

    .options-header {
      position: relative;
      & > div {
        position: absolute;
        right: 0;
      }
      .reflections__buttons {
        margin-top: 0;
      }
    }

    .options__container {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      .options__add {
        padding: 20px;
        width: 940px;
        height: 46x;
        display: flex;
        align-items: center;
        background-color: #fff;
        margin: 5px auto 0 auto;
        border-left: 2px solid $grey-2;

        button {
          &.schedule-button-error {
            color: $red-4;
          }
        }

        span {
          font-family: Nunito-SemiBold;
          font-size: 14px;
          color: $grey-2;
          margin-right: 20px;
        }
        .schedule-error-message {
          margin-left: 5px;
          font-size: 14px;
          color: $red-4;
        }
      }

      .options__actions {
        width: 620px;
        padding: 20px;
        margin: 0 auto;
      }
    }
  }
  .options__errors {
    margin-top: 20px;
    padding: 20px;
    width: 620px;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin: 0 auto;
    opacity: 0.5;
    justify-content: center;

    .options__error {
      color: red;
      font-family: Roboto-Regular;
      font-size: 14px;

      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }

  .options__check {
    width: 620px;
    margin: 20px auto;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      font-family: Nunito-Bold;
      text-transform: uppercase;
      font-size: 10px;
      color: $green-5;
    }
  }
</style>
