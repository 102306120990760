import axios from 'axios';
import i18n from '../../plugins/i18n'

import { handleCommitResponse, handleResponseError } from './helpers/responseHelpers';

const getDefaultState = () => ({
  selectedMethod: null,
  methodArray: [],
  currentTab: 0,
  questionTypeList: [],
  filteredMethods: [],
  methodAnswerType: null,
  iconPulse: false,
});

const state = getDefaultState();

const mutations = {
  SET_METHOD_ANSWER_TYPE(state, type) {
    state.currentMethodAnswerType = type;
  },
  UNSET_COMPONENT(state) {
    state.selectedMethod = null;
  },
  ADD_METHOD(state, method) {
    state.methodArray.push(method);
  },
  REMOVE_METHOD(state, index) {
    state.methodArray.splice(index, 1);
  },
  UPDATE_METHOD(state, method) {
    state.methodArray.splice(method.index, 1, method);
  },
  SWITCH_TAB(state, tab) {
    state.currentTab = tab;
  },
  GET_QUESTION_LIST(state, questionList) {
    // @TEMP_ONE temporary removal of question template answer templates
    const tempQuestionList = questionList.map(question =>
      question.template === 'question'
        ? { ...question, availableAnswerTemplates: [{ value: "select", name: i18n.t("methods.question_method_select") }, { value: "text", name: i18n.t("methods.question_method_text") }] }
        : question
    );
    state.questionTypeList = tempQuestionList;
  },
  RESET_QUESTION_LIST(state) {
    state.questionTypeList = [];
  },
  SET_METHOD(state, method) {
    state.selectedMethod = method;
  },
  SET_FILTERED_METHODS(state, methods) {
    state.filteredMethods = methods;
  },
  SET_ICON_PULSE(state, status) {
    state.iconPulse = status;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};
const actions = {
  setMethodAnswerType({ commit }, payload) {
    commit('SET_METHOD_ANSWER_TYPE', payload);
  },
  onMethodSelect({ commit }, payload) {
    commit('SET_METHOD', payload);
  },
  onMethodUnset({ commit, state }) {
    if (state.selectedMethod) {
      commit('UNSET_COMPONENT');
    } else {
      commit('SET_ICON_PULSE', true);
      setTimeout(() => commit('SET_ICON_PULSE', false), 3000);
    }
  },
  addMethod({ commit, dispatch, rootGetters }, payload) {
    commit('ADD_METHOD', payload);
    dispatch('questionnaire/getCurrentAnswerTemplates', payload, { root: true });
    dispatch('questionnaire/addQuestion', payload, { root: true });
    const questions = rootGetters['questionnaire/questions'];
    dispatch('questionnaire/setCurrentTab', questions.length - 1, { root: true });
    dispatch('questionnaire/setCurrentQuestion', null, { root: true });
  },
  updateMethod({ commit }, payload) {
    commit('UPDATE_METHOD', payload);
  },
  removeMethod({ commit }, payload) {
    commit('REMOVE_METHOD', payload);
  },
  switchTab({ commit }, payload) {
    commit('SWITCH_TAB', payload);
  },
  getQuestionList({ commit, dispatch }) {
    dispatch('meta/setLoading', true, { root: true });
    return axios({
      method: 'get',
      url: '/reflection/method/list',
    })
      .then((res) => handleCommitResponse(res, commit, 'GET_QUESTION_LIST'))
      .catch(handleResponseError);
  },
  resetQuestionList({ commit }) {
    commit('RESET_QUESTION_LIST');
  },
  setFilteredMethods({ state, commit }, payload) {
    if (!payload) commit('SET_FILTERED_METHODS', state.questionTypeList);
    else {
      const { type, value } = payload;
      if (type === 'type') {
        if (value === 'all') {
          commit('SET_FILTERED_METHODS', state.questionTypeList);
        } else {
          const filteredMethods = state.questionTypeList.filter(
            (method) => method.allowUnlimitedPartCount === value,
          );
          commit('SET_FILTERED_METHODS', filteredMethods);
        }
      } else if (type === 'time') {
        if (value === 'all') {
          commit('SET_FILTERED_METHODS', state.questionTypeList);
        } else {
          const filteredMethods = state.questionTypeList.filter(
            (method) => method.duration <= value,
          );
          commit('SET_FILTERED_METHODS', filteredMethods);
        }
      }
    }
  },
  getMethodById({ getters, commit }, payload) {
    const method = getters.questionTypeList.find((item) => item.id === payload);
    commit('SET_METHOD', method);
  },
  resetMethodsState({ commit }) {
    commit('RESET_STATE');
  },
};

const getters = {
  methodAnswerType(state) {
    return state.methodAnswerType;
  },
  currentMethods(state) {
    return state.methodArray;
  },
  currentTab(state) {
    return state.currentTab;
  },
  questionTypeList(state) {
    return state.questionTypeList;
  },
  selectedMethod(state) {
    return state.selectedMethod;
  },
  filteredMethods(state) {
    return state.filteredMethods;
  },
  iconPulse(state) {
    return state.iconPulse;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
