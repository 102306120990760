<template>
  <div class="not-found__page">
    <section id="not-found" class="not-found">
      <div class="not-found__box">
        <header-h1 v-if="!error">
          {{ $t("modals.email_confirm_modal_header") }}
        </header-h1>
        <header-h1 v-else>
          {{ error }}
        </header-h1>
        <div class="not-found__box__actions">
          <button-3 @click.native="handleRouteHome()">{{ $t("next") }}</button-3>
        </div>
        <div class="not-found__box__icons">
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--dotted-square"
            name="404/dotted-square"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--half-circle"
            name="404/half-circle"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--oval-1"
            name="404/oval"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--oval-2"
            name="404/oval"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--question-mark"
            name="404/question-mark"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--weave"
            name="404/weave"
            :original="true"
          ></svgicon>
          <svgicon
            class="not-found__box__icons__icon not-found__box__icons__icon--x"
            name="404/x"
            :original="true"
          ></svgicon>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';


import Button3 from '@/components/elements/buttons/Button3.vue';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';

export default {
  name: 'EmailConfirm',
  components: {
    Button3,
    HeaderH1,
  },
  data() {
    return {
      error: null,
    };
  },
  created() {
    const { secret } = this.$route.query;
    this.confirmEmail(secret).then((res) => {
      const { success, data } = res;
      if (success) {
        const user = data.data;
        this.refreshUser(user);
        this.handleRegisterModal();
        setTimeout(() => {
          this.$router.push({ name: 'Home' });
        }, 3000);
      } else {
        this.error = data.data.message;
      }
    });
  },
  methods: {
    ...mapActions('user', ['confirmEmail', 'refreshUser', 'toggleModal']),
    handleRegisterModal() {
      this.toggleModal({ type: 'register', status: false });
    },
    handleRouteHome() {
      this.handleRegisterModal();
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>
