<template>
  <div class="member-answer__answer-3-2-1">
    <div class="answer-block" v-for="part in method.parts" :key="part.answerCount">
      <div class="answer-list">
        <p>{{ part.title }}</p>
        <div class="answer-list-item" v-for="(answer, index) in part.answers" :key="index">
          <span class="answer-list-item__number">{{ index + 1 }}.</span>
          <span class="answer-list-item__answer" v-if="answer.value">{{ answer.value }}</span>
          <span
            :class="['answer-list-item__answer', 'answer-list-item__answer--none']"
            v-if="answer.value === ''"
            >Nėra atsakymo</span
          >
          <span
            :class="['answer-list-item__answer', 'answer-list-item__answer--none']"
            v-if="answer.value === null"
            >Nereflektavo</span
          >
        </div>
      </div>
      <div class="answer-input">
        <input type="text" class="member-answer__input" :placeholder='$t("insert_value_placeholder_2")' />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Template321',
  props: ['method'],
};
</script>
<style lang="scss">
.member-answer {
  &__answer-3-2-1 {
    margin-top: 16px;

    .answer-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-of-type) {
        margin-top: 16px;
      }
      .answer-list {
        p {
          font-family: Roboto-Regular;
          font-size: 14px;
          color: $grey-5;
        }

        .answer-list-item {
          margin-left: 20px;

          &__number {
            font-family: Roboto-Bold;
            font-size: 14px;
            color: $violet-2;
          }

          &__answer {
            margin-left: 5px;
            font-family: Roboto-Regular;
            font-size: 14px;
            color: $green-3;
          }

          &__answer--none {
            color: $grey-4;
          }

          &:first-of-type {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
