<template>
  <section v-if="!loading" id="terms" class="terms">
    <h1>{{ $t("terms_and_conditions_header") }}</h1>
    <div class="terms__container" v-if="termsAndConditions" v-html="termsAndConditions.content"></div>
    <!-- <div class="terms__container">
      <div class="terms__header">
        <h2 class="h2">Privatumo politika</h2>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="h2">I. SĄVOKOS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                <span>Svetainė</span> - REFLECTUS svetainė, pasiekiama internetiniu adresu
                www.reflectus.lt.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>REFLECTUS</span> – UAB “Refleksija“, Lietuvos Respublikoje įsteigta uždaroji
                akcinė bendrovė, juridinio asmens kodas 304992411, registruotos buveinės ir biuro
                adresas – Žalgirio g. 92, LT-09300 Vilnius, duomenys kaupiami ir tvarkomi VĮ
                „Registrų centras“ Juridinių asmenų registre, el. pašto adresas – info@reflectus.lt,
                telefono numeris +370 5 2620494.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Vartotojas</span> - bet kuris fizinis ir juridinis asmuo, kuris naudojasi
                Svetaine.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Slapukas</span> - nedidelis failas, kuris siunčiamas į įrenginį, bet kuriam
                asmeniui besilankant Svetainėje.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Naršyklė</span> - programa, skirta atvaizduoti internetinius puslapius
                (tinklalapius) žiniatinklyje ar asmeniniame kompiuteryje.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Asmens duomenys</span> - bet kuri informacija, susijusi su fiziniu asmeniu –
                duomenų subjektu, kurio tapatybė yra žinoma arba gali būti tiesiogiai ar
                netiesiogiai nustatyta pasinaudojant tokiais duomenimis kaip asmens kodas, vienas
                arba keli asmeniui būdingi fizinio, fiziologinio, psichologinio, ekonominio,
                kultūrinio ar socialinio pobūdžio požymiai.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Asmens duomenų gavėjas</span> - juridinis ar fizinis asmuo, kuriam teikiami
                asmens duomenys, įskaitant Partnerius.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Privatumo politika</span> - šis dokumentas, kuriame yra numatytos pagrindinės
                asmens duomenų ir kitos svarbios informacijos rinkimo, kaupimo, tvarkymo ir laikymo
                taisyklės, naudojantis Svetaine.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Įstatymas</span> - LR asmens duomenų teisinės apsaugos įstatymas.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Bendrieji reikalavimai</span> - 2018 m. gegužės 25 dienos Europos Sąjungos
                Bendrasis duomenų apsaugos reglamentas (BDAR, angl. GDPR).
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>IP adresas</span> - kiekvienam prie interneto prijungtam kompiuteriui
                priskirtas unikalus numeris, kuris žinomas kaip interneto protokolo (IP) adresas.
                Kadangi šie numeriai paprastai priskiriami pagal šalies blokus, IP adresas gali būti
                dažnai naudojamas šaliai, kurioje kompiuteris prijungiamas prie interneto,
                identifikuoti.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">II. BENDROSIOS NUOSTATOS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                Svetainės Privatumo politikoje yra numatytos pagrindinės asmens duomenų ir kitos
                asmeniui svarbios informacijos rinkimo, kaupimo ir jų tvarkymo taisyklės Vartotojui
                naudojantis Svetaine bei užsakant Svetainėje teikiamas Paslaugas.
              </p>
            </li>
            <li class="content__item">
              <p>
                Privatumo politika skirta apsaugoti ir ginti Svetainės Vartotojų ir/ar Naudotojų
                asmens ir kitus duomenis nuo neteisėto naudojimo.
              </p>
            </li>
            <li class="content__item">
              <p>
                Vartotojai laikomi susipažinę su šia Privatumo politika, kai jie gauna patvirtinimą
                elektroniniu paštu užsiprenumeravę naujienlaiškį. Su šiuo Svetainės dokumentu galite
                susipažinti bet kuriuo metu Svetainėje.
              </p>
            </li>
            <li class="content__item">
              <p>
                <span>Slapukas</span> – nedidelis failas, kuris siunčiamas į įrenginį, bet kuriam
                asmeniui besilankant Svetainėje.
              </p>
            </li>
            <li class="content__item">
              <p>
                Ši Privatumo politika taikoma visais atvejais, kai REFLECTUS gauna asmens duomenis
                dėl to, kad Vartotojas užsako per Svetainę naujienlaiškį.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">III. ASMENS DUOMENŲ RINKIMAS IR SAUGOJIMAS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                Renkami ir saugomi dalis arba visi šie Asmens duomenys: vardas, pavardė, gyvenamoji
                vieta, darbovietė, telefono numeris, elektroninio pašto adresas, IP adresas.
              </p>
            </li>
            <li class="content__item">
              <p>
                Naudojantis Svetaine į Vartotojo kompiuterį yra įrašomi Slapukai, skirti rinkti
                statistinei lankomumo informacijai, užtikrinti Paslaugų kokybę ir efektyvų
                naudojimąsi Svetaine. Detali informacija apie Slapukus: Slapuko
                pavadinimas,Paskirtis, Sukūrimo momentas, Galiojimo laikas.
              </p>
            </li>
            <li class="content__item">
              <p>
                Vartotojas išreiškia savo išankstinį laisvanorišką sutikimą leisti REFLECTUS įrašyti
                slapukus spausdamas mygtuką „SUTINKU“ žemiau nurodytu būdu: „Mes naudojame slapukus,
                kad pagerintų jūsų patirtį naudojantis šia svetaine. Spauskite „Patvirtinti“, jog
                perskaitėte ir priimate mūsų slapukų politiką. Galite blokuoti slapukų naudojimą
                arba juos išjungti nustatymuose.”
              </p>
            </li>
            <li class="content__item">
              <p>
                Naršyklės nustatymais jūs galite ištrinti arba blokuoti visus šiuos Slapukus, bet
                tokiu atveju dalis Svetainės gali neveikti.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">IV. PASKYROS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                REFLECTUS Paskyros yra nuorodos tam tikruose internetiniuose puslapiuose, kuriuose
                gali būti talpinama ta pati informacija, kaip ir Svetainėje, nes REFLECTUS yra tiek
                Svetainės, tiek Paskyrų valdytojas. Svetainės Paskyrų naudotojams taikoma to
                internetinio puslapio, kuriame yra REFLECTUS Paskyra, Privatumo politika ir to
                puslapio taisyklės, taip pat ši Privatumo politika ir kitos Svetainėje publikuojamos
                REFLECTUS taikytinos taisyklės.
              </p>
            </li>
            <li class="content__item">
              <p>
                REFLECTUS turi Paskyras šiuose internetiniuose puslapiuose: @reflectus.lt –
                (socialinis tinklas Facebook; socialinis tinklas Linkedin).
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">V. ASMENS DUOMENŲ TEIKIMAS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                REFLECTUS informuoja, jog neperduoda nei atlygintinai, nei neatlygintinai ar kitokiu
                būdu neatskleidžia Asmens duomenų tretiesiems asmenims esantiems tiek Lietuvos
                Respublikoje, tiek Europos Sąjungos šalyse ir kitose užsienio valstybėse, išskyrus
                žemiau nurodytus atvejus: jei yra Vartotojo ir/ar Naudotojo sutikimas; teisėsaugos
                institucijoms pagal Lietuvos Respublikos teisės aktų numatytą tvarką.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">VI. ASMENS DUOMENŲ KOREGAVIMAS, PAPILDYMAS IR IŠTRYNIMAS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                Vartotojas suteikia teisę REFLECTUS rinkti, valdyti, tvarkyti ir saugoti Vartotojo
                asmens duomenis tokia apimtimi ir tokiais tikslais, kaip numatyta Privatumo
                politikoje ir kituose Svetainės dokumentuose. Vartotojas turi teisę atšaukti savo
                leidimą REFLECTUS rinkti, valdyti ir tvarkyti jo Asmens duomenis, reikalauti
                panaikinti asmens duomenis bet kuriuo metu. Sutikimo rinkti, valdyti ir tvarkyti
                Vartotojo asmens duomenis davimas ar atšaukimas galioja tik į priekį. REFLECTUS, iš
                Vartotojo gavus tokį pranešimą, nedelsiant sustabdo to asmens duomenų tvarkymą,
                tačiau tai nereiškia, kad REFLECTUS turi ištrinti/sunaikinti Asmens duomenis, jeigu
                turi pagrindą juos saugoti.
              </p>
            </li>
            <li class="content__item">
              <p>
                Vartotojas turi teisę susipažinti su savo asmens duomenimis. Siekdamas sužinoti,
                kokius asmens duomenis REFLECTUS surinko ir iš kokių šaltinių, kokiais tikslais jie
                tvarkomi, kokiems gavėjams jie teikiami ir buvo teikti, Vartotojas turi iš anksto
                kreiptis į REFLECTUS el. pašto adresu, nurodytu šioje Privatumo politikoje.
                REFLECTUS įgyvendina Vartotojo teisę susipažinti su tvarkomais asmens duomenimis per
                30 kalendorinių dienų nuo Vartotojo prašymo gavimo.
              </p>
            </li>
            <li class="content__item">
              <p>
                Jeigu susipažinęs su savo asmens duomenimis Vartotojas nustato, kad asmens duomenys
                yra neteisingi, neišsamūs ar netikslūs, turi teisę kreiptis į REFLECTUS elektroniniu
                pašto adresu prašydamas ištaisyti neteisingus, neišsamius, netikslius asmens
                duomenis ir (arba) sustabdyti tokių asmens duomenų tvarkymo veiksmus. Asmens
                duomenys taisomi ir naikinami arba jų tvarkymo veiksmai sustabdomi pagal duomenų
                subjekto tapatybę ir jo asmens duomenis patvirtinančius dokumentus. REFLECTUS
                įgyvendina Vartotojo teisę sustabdyti asmens duomenų tvarkymą (arba asmens duomenis
                sunaikinti) per 5 darbo dienas nuo Vartotojo prašymo gavimo.
              </p>
            </li>
            <li class="content__item">
              <p>
                REFLECTUS asmens duomenis saugo 5 (penkerius) metus nuo jų pateikimo, išskyrus
                atvejus, kai šioje Privatumo politikoje yra numatytas kitoks saugojimo terminas.
              </p>
            </li>
            <li class="content__item">
              <p>
                Vartotojas ir/ar Naudotojas iš anksto sutinka, kad jo duomenis REFLECTUS turi teisę
                laikyti neterminuotai, jeigu jo duomenimis buvo pasinaudota vykdant neteisėtą veiką
                ar tapatybės vagystei ar kitam pažeidimui, dėl kurio buvo ar bus atliekamas
                atitinkamų teisėsaugos institucijų tyrimas, jeigu REFLECTUS yra gavęs skundų,
                susijusių su atitinkamu Vartotoju, ar jeigu REFLECTUS yra pastebėjęs atitinkamo
                Vartotojo padarytus Privatumo politikos ir/ar Svetainėje esančių taisyklių
                pažeidimus ar esant kitiems teisėtiems tikslams saugoti asmens duomenis.
              </p>
            </li>
            <li class="content__item">
              <p>
                Įgyvendindami aukščiau minėtas teises Vartotojas ir/ar Naudotojas gali kreiptis į
                REFLECTUS el. paštu info@reflectus.lt .
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">VII. ASMENS DUOMENŲ APSAUGA</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                REFLECTUS užtikrina, jog Vartotojo pateikiami asmens duomenys Svetainėje būtų
                apsaugoti nuo bet kokių neteisėtų veiksmų: neteisėto asmens duomenų pakeitimo,
                atkleidimo ar sunaikinimo, asmens tapatybės vagystės, sukčiavimo bei asmens duomenų
                apsaugos lygis atitiktų Lietuvos Respublikos teisės aktų reikalavimus.
              </p>
            </li>
            <li class="content__item">
              <p>
                Asmens duomenys yra saugomi nuo praradimo, neleistino naudojimo ir pakeitimų.
                Patalpa, kurioje laikomi surinkti duomenys, yra fiziškai apsaugota nuo pašalinių
                asmenų prieigos. Svetainės Vartotojų duomenis sauganti duomenų bazė yra apsaugota
                nuo neleistinos prieigos per kompiuterinius tinklus.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="terms__article">
        <div class="article__title">
          <h2 class="header-h2">VIII. BAIGIAMOSIOS NUOSTATOS</h2>
        </div>
        <div class="article__content">
          <ul class="content__items">
            <li class="content__item">
              <p>
                Ši Privatumo politika galioja nuo 2019 m. balandžio 8 dienos. Apie bet kokius
                Privatumo politikos pasikeitimus Vartotojai bus informuoti Svetainėje ir
                elektroniniu paštu.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>-->
    <div class="terms__actions">
      <button-4 class="terms__actions__btn" @click.native="() => $router.back()">{{ $t("actions.back_action") }}</button-4>
      <button-4
        class="terms__actions__btn"
        @click.native="onPrivacySubmit"
        v-if="!user.name"
      >{{ $t("actions.i_agree_action") }}</button-4>
    </div>
  </section>
  <loading v-else/>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import Loading from '@/components/base/Loading.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';

export default {
  name: 'Terms',
  components: {
    Loading,
    Button4
  },
  created() {
    this.handleGetTerms();
  },
  computed: {
    ...mapGetters('user', ['privacyAgreed', 'user']),
    ...mapGetters('meta', ['termsAndConditions', 'loading']),
  },
  methods: {
    ...mapActions('user', ['togglePrivacy']),
    ...mapActions('meta', ['getTermsAndConditions']),
    onPrivacySubmit() {
      if (this.privacyAgreed) {
        this.$router.push({ name: 'Register' });
      } else {
        this.togglePrivacy(true);
        this.$router.push({ name: 'Register' });
      }
    },
    handleGetTerms() {
      if (!this.termsAndConditions) this.getTermsAndConditions();
    },
  },
};
</script>
<style lang="scss">
.terms {
  max-width: 940px;
  margin: 30px auto 0 auto;
  position: relative;

  h1 {
    text-align: center;
  }

  &__header {
    font-size: 16px;
  }

  &__article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    .content {
      &__items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__container {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 30px 20px 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: flex-start;

    p {
      text-align: left;
      margin-top: 10px;

      span {
        font-family: Roboto-Medium;
      }
    }
  }

  &__actions {
    max-width: 800px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    &__btn {
      display: block;
      margin: 0 auto;

      &:last-of-type {
        margin-left: auto;
      }
    }
  }
}
</style>
