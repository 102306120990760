<template>
  <div class="new-group__page">
    <section id="new-group" class="new-group">
      <div class="new-group__header">
        <header-h1>{{ $t('group.new_group_creation') }}</header-h1>
      </div>
      <div class="new-group__container">
        <div class="new-group__form">
          <div class="new-group__form__name">
            <div>
              <header-h3>{{ $t('group.group_title_self') }}</header-h3>
            </div>
            <input type="text" v-model="title" />
          </div>
          <div class="new-group__form__title">
            <div>
              <header-h3>{{ $t('group.group_title_user') }}</header-h3>
            </div>
            <input type="text" v-model="memberTitle" />
          </div>
        </div>
        <div class="new-group__error" v-if="error">
          <span>{{ error }}</span>
        </div>
        <div class="new-group__buttons">
          <router-link to="/my-groups">
            <button-3>{{ $t('actions.cancel_action') }}</button-3>
          </router-link>
          <button-4 @click.native="addGroupHandler" :disabled="disableButton">
            {{ $t('actions.group_create_action') }}
          </button-4>
        </div>

        <div class="new-group__warning">
          <p>{{ $t('group.group_note_header') }}</p>
          <p>{{ $t('group.group_note_content') }}</p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
  import HeaderH3 from '@/components/elements/typography/HeaderH3.vue';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  export default {
    name: 'NewGroup',
    components: {
      HeaderH1,
      HeaderH3,
      Button3,
      Button4,
    },
    data() {
      return {
        title: null,
        memberTitle: null,
        error: null,
        disableButton: false,
      };
    },
    methods: {
      ...mapActions('groups', ['addGroup']),
      addGroupHandler() {
        if (!this.title || !this.memberTitle) {
          this.error = this.$t('warnings.lack_of_info_message');
        } else {
          this.disableButton = true;
          this.error = null;
          const payload = { title: this.title, memberTitle: this.memberTitle };
          this.addGroup(payload).then(() => {
            this.disableButton = false;
          });
        }
      },
    },
  };
</script>
<style lang="scss">
  .new-group {
    max-width: 940px;
    margin: 20px auto 0 auto;
    position: relative;

    &__container {
      margin-top: 15px;
    }

    &__header {
      text-align: center;
    }

    &__form {
      height: 85px;
      width: 100%;
      background-color: #fff;
      padding: 20px;
      padding-top: 18px;
      display: flex;
      justify-content: space-between;

      &__name,
      &__title {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          align-items: center;

          svg {
            margin-left: 10px;
          }
        }

        h3 {
          font-family: Nunito-Bold;
          font-size: 10px;
          text-align: left;
          color: $grey-3;
        }

        input {
          margin-top: 5px;
          width: 420px;
          height: 28px;
          border: 1px solid $grey-1;
          padding: 0 10px;
          font-family: Roboto-Medium;
          font-size: 14px;
          color: $grey-5;
        }
      }
    }

    &__buttons {
      margin-top: 25px;
      text-align: center;

      button {
        margin-left: 10px;
      }
    }

    &__error {
      margin: 20px auto 0 auto;
      background-color: #fff;
      border-radius: 5px;
      color: $red-3;
      font-family: Roboto-Regular;
      padding: 20px;
      text-align: center;
      opacity: 0.8;
      max-width: 640px;
    }

    &__warning {
      margin: 40px auto 0 auto;
      text-align: center;
      max-width: 565px;
      p {
        font-family: Nunito-SemiBold;
        font-size: 12px;
        color: $grey-5;

        &:last-of-type {
          font-family: Nunito-Regular;
          font-size: 12px;
        }
      }
    }
  }
</style>
