<template>
  <div>
    <div class="reflection-options__option-header">
      <h3 class="reflection-options__option-date">
        {{ $t('schedule.repeat_type_once_title_two') }}
      </h3>
      <div class="reflection-options__option-events flex-global">
        <div
          class="reflection-options__edit"
          v-if="(!schedule.active || (schedule.active && allowToDelete)) && !editStatus"
          @click="handleScheduleEdit"
        >
          <svgicon
            class="reflection-options__edit__icon"
            name="edit-button"
            :fill="false"
            :original="true"
            v-tooltip="$t('actions.edit_schedule_time_action')"
          ></svgicon>
        </div>
        <div
          class="reflection-options__delete"
          v-if="!schedule.active || (schedule.active && allowToDelete)"
          @click="handleScheduleRemove(i, schedule)"
        >
          <svgicon
            class="reflection-options__delete__icon"
            name="delete"
            :original="true"
            v-tooltip="$t('actions.remove_schedule_time_action')"
          ></svgicon>
        </div>
      </div>
    </div>
    <options-schedule-steps v-if="editStatus" :steps="editSteps" :currentStep="currentEditStep" />
    <div v-if="editStatus">
      <div v-if="currentEditStep === 0">
        <span class="grey-explanation-text">{{ $t('schedule.select_date_and_time_exp') }}</span>
        <div class="reflection-options__row">
          <div class="reflection-options__col">
            <div class="reflection-options__inputs">
              <div class="step-one-date">
                <span class="reflection-options__option-title">{{ $t('date') }}</span>
                <vue-datepicker
                  :monday-first="true"
                  :bootstrap-styling="true"
                  :full-month-name="true"
                  :language="currentCalendarLocale.locale"
                  :placeholder="'2019-05-29'"
                  :format="customFormatter"
                  @selected="handleDate($event, i, 'fromDate')"
                  :value="schedule.fromDate"
                  :disabled-dates="disabledFromDates"
                ></vue-datepicker>
              </div>
              <div class="step-one-time">
                <span class="reflection-options__option-title">{{ $t('time') }}</span>
                <date-time-picker
                  class="reflection-options__input reflection-options__input--time-picker"
                  v-model="schedule.time"
                  :format="'HH:mm'"
                  :only-time="true"
                  :no-value-to-custom-elem="true"
                  :color="'#11ac9a'"
                  :overlay="false"
                  :class="{ 'input-error-active': !isValidDateTime && isScheduleChecked }"
                >
                  <input
                    type="time"
                    class="reflection-options__input__time-picker"
                    v-model="schedule.time"
                    @input="handleTimeInput"
                    placeholder="HH:mm"
                    @click="(e) => e.preventDefault()"
                  />
                </date-time-picker>
              </div>
            </div>
            <p class="schedule-error-message" v-if="!isValidDateTime && isScheduleChecked">
              {{ $t('modals.options_modal_start_time_past_warning') }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="currentEditStep === 1">
        <span class="grey-explanation-text">{{ $t('schedule.select_duration_exp') }}</span>
        <div class="reflection-options__row">
          <div class="reflection-options__col">
            <span class="reflection-options__option-title">{{ $t('duration') }}</span>
            <div class="reflection-options__inputs">
              <input
                class="reflection-options__input reflection-options__input--small"
                type="number"
                :value="schedule.duration"
                :id="`duration${i}`"
                :name="`duration${i}`"
                @input="handleDuration($event, i)"
                min="1"
              />
              <multi-select
                :id="`durationTypeSelect${i}`"
                :key="`durationTypeSelect${i}`"
                :options="reflectionDuration"
                :placeholder="`- ${$t('select_option_placeholder')} -`"
                :width="123"
                :selected="durationType"
                @selectedValue="handleDurationSelect"
              ></multi-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reflection-options__step-buttons flex-global" v-if="editStatus">
      <button-3 v-if="currentEditStep > 0" @click.native="handleStepChange(-1)">
        {{ $t('back') }}
      </button-3>
      <button-4
        v-if="currentEditStep > 0 && currentEditStep === editSteps.length - 1"
        @click.native="handleScheduleConfirm"
      >
        {{ $t('actions.create_schedule_action') }}
      </button-4>
      <button-4 v-else @click.native="handleStepChange(1)">
        {{ $t('next') }}
      </button-4>
    </div>
    <options-data-display
      v-else
      :type="'once'"
      :schedule="schedule"
      :durationValues="reflectionDuration"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { lt, en } from 'vuejs-datepicker/dist/locale';
  import VueDatepicker from 'vuejs-datepicker';
  import DateTimePicker from 'vue-ctk-date-time-picker';
  import Button3 from '@/components/elements/buttons/Button3.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';

  import MultiSelect from '@/components/elements/select/MultiSelect.vue';
  import OptionsDataDisplay from '@/components/reflections/options/OptionsDataDisplay.vue';
  import OptionsScheduleSteps from '@/components/reflections/options/OptionsScheduleSteps.vue';

  export default {
    name: 'OptionsInnerOnce',
    components: {
      VueDatepicker,
      MultiSelect,
      DateTimePicker,
      OptionsDataDisplay,
      OptionsScheduleSteps,
      Button3,
      Button4,
    },
    props: ['i', 'currentScheduleType', 'isScheduleChecked'],
    created() {
      const { skip, repeatType, weekDays } = this.schedule;
      skip.forEach((date) => this.disabledSkipDates.dates.push(this.moment(date).toDate()));

      if (repeatType === 'once') {
        this.disabledToDates.days = [];
        this.disabledSkipDates.days = [];
        this.disabledToDates.from = null;
        this.disabledToDates.to = null;
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = null;
      } else if (this.schedule.repeatType === 'daily') {
        this.disabledToDates.days = [];
        this.disabledSkipDates.days = [];
        this.disabledToDates.from = null;
        this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
      } else if (this.schedule.repeatType === 'custom') {
        weekDays.forEach((day) => {
          if (day === 7) day = 0;
          const dayIndex = this.disabledToDates.days.findIndex((i) => i === day);
          this.disabledToDates.days.splice(dayIndex, 1);
          this.disabledSkipDates.days.splice(dayIndex, 1);
        });
        this.disabledToDates.from = null;
        this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
        this.disabledSkipDates.from = null;
        this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
      }
    },
    data() {
      return {
        time: null,
        reflectionDuration: [
          { value: this.$t('schedule.duration_minutes'), type: 'minutes' },
          { value: this.$t('schedule.duration_hours'), type: 'hours' },
          { value: this.$t('schedule.duration_days'), type: 'days' },
        ],
        reflectionRepeat: [
          { value: this.$t('schedule.repeat_type_once'), type: 'once' },
          { value: this.$t('schedule.repeat_type_daily'), type: 'daily' },
          { value: this.$t('schedule.repeat_type_custom'), type: 'custom' },
        ],
        weekStep: [1, 2, 3, 4],
        weekDays: [
          { value: 1, type: 'days', display: this.$t('date_and_time.day_monday_abb') },
          { value: 2, type: 'days', display: this.$t('date_and_time.day_tuesday_abb') },
          { value: 3, type: 'days', display: this.$t('date_and_time.day_wednesday_abb') },
          { value: 4, type: 'days', display: this.$t('date_and_time.day_thursday_abb') },
          { value: 5, type: 'days', display: this.$t('date_and_time.day_friday_abb') },
          { value: 6, type: 'days', display: this.$t('date_and_time.day_saturday_abb') },
          { value: 7, type: 'days', display: this.$t('date_and_time.day_sunday_abb') },
        ],
        durationToDisplay: null,
        disabledFromDates: {
          to: new Date(this.moment().subtract(1, 'day')),
          from: null,
        },
        disabledToDates: {
          to: null,
          from: null,
          days: [0, 1, 2, 3, 4, 5, 6],
          dates: [],
        },
        placeholderDate: null,
        disabledSkipDates: {
          to: null,
          from: null,
          days: [0, 1, 2, 3, 4, 5, 6],
          dates: [],
        },
        hello: null,
        calendarLocales: [
          { key: 'lt', locale: lt },
          { key: 'en', locale: en },
        ],
        currentLocale: localStorage.getItem('locale'),
        editMode: false,
        editSteps: [
          this.$t('schedule.sending_date'),
          this.$t('schedule.duration_of_reflection_two'),
        ],
        currentEditStep: 0,
      };
    },
    computed: {
      ...mapGetters('questionnaire', ['questionnaire', 'scheduleIsEdit']),
      schedule() {
        return this.questionnaire.schedules[this.i];
      },
      editStatus() {
        return this.schedule.initialEditStatus && this.schedule.initialEditStatus !== undefined
          ? this.schedule.initialEditStatus
          : this.editMode;
      },
      durationType() {
        const durationType = { type: this.schedule.durationType || 'minutes', value: null };
        switch (durationType.type) {
          case 'minutes':
            durationType.value = this.$t('schedule.duration_minutes');
            break;
          case 'hours':
            durationType.value = this.$t('schedule.duration_hours');
            break;
          case 'days':
            durationType.value = this.$t('schedule.duration_days');
            break;
          default:
            break;
        }

        return durationType;
      },
      repeatType() {
        const repeatType = { type: this.schedule.repeatType || 'once', value: null };
        switch (repeatType.type) {
          case 'once':
            repeatType.value = this.$t('schedule.repeat_type_once');
            break;
          case 'daily':
            repeatType.value = this.$t('schedule.repeat_type_daily');
            break;
          case 'custom':
            repeatType.value = this.$t('schedule.repeat_type_custom');
            break;
          default:
            break;
        }

        return repeatType;
      },
      activeDay() {
        const { weekDays } = this.schedule;
        if (weekDays) {
          return weekDays;
        }
        return [];
      },
      currentCalendarLocale() {
        return this.calendarLocales.find((locale) => locale.key === this.currentLocale);
      },
      allowToDelete() {
        const dateAndTime = this.moment(
          this.schedule.fromDate + this.schedule.time,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY-MM-DD HH:mm');
        return dateAndTime > this.moment().format('YYYY-MM-DD HH:mm');
      },
      isValidDateTime() {
        let isValid = 0;
        if (!this.schedule.active) {
          const scheduleDateTime = this.moment(
            this.schedule.fromDate + this.schedule.time,
            'YYYY-MM-DD HH:mm'
          ).format('YYYY-MM-DD HH:mm');
          if (scheduleDateTime <= this.moment().format('YYYY-MM-DD HH:mm')) {
            isValid = 1;
          }
        } else {
          const scheduleDateTime = this.moment(
            this.schedule.fromDate + this.schedule.time,
            'YYYY-MM-DD HH:mm'
          ).format('YYYY-MM-DD HH:mm');
          if (scheduleDateTime === this.moment().format('YYYY-MM-DD HH:mm')) {
            isValid = 1;
          }
        }
        return isValid === 0;
      },
      noWeekDaysSelected() {
        return this.disabledToDates.days.length === 7 || false;
      },
      scheduleType() {
        return this.schedule.repeatType === 'custom' || this.schedule.repeatType === 'daily'
          ? 'recurrent'
          : 'once';
      },
      showScheduleOption() {
        return this.currentScheduleType === this.scheduleType;
      },
    },
    methods: {
      ...mapActions('questionnaire', [
        'removeSchedule',
        'setQuestionnaireProperty',
        'setScheduleProperty',
        'setScheduleIsEdit',
      ]),
      customFormatter(value) {
        return this.moment(value).format('YYYY-MM-DD');
      },
      handleScheduleRemove(i, schedule) {
        if (this.scheduleIsEdit && !this.editStatus) {
          this.handleIsEditModal();
        } else {
          this.$emit('handleScheduleRemove', { i, schedule });
        }
      },
      handleIsEditModal() {
        this.$emit('handleIsEditModal');
      },
      handleScheduleEdit() {
        if (this.scheduleIsEdit) {
          this.handleIsEditModal();
        } else {
          this.editMode = !this.editMode;
          this.setScheduleIsEdit(true);
        }
      },
      timeChangeHandler(value, i) {
        const property = value;
        const payload = {
          type: 'time',
          index: i,
          property,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleDate(value, i, type, skipIndex) {
        const selectedDate = this.moment(value).format('YYYY-MM-DD');
        const payload = {
          type,
          index: i,
          property: selectedDate,
        };
        if (skipIndex > -1) {
          payload.skipIndex = skipIndex;
        }
        if (type !== 'skip') {
          this.setQuestionnaireProperty(payload);
        } else {
          this.setScheduleProperty(payload);
        }

        if (type === 'toDate') {
          // this.disabledToDates.from = this.moment(value).toDate();
          this.disabledSkipDates.from = this.moment(value).toDate();
        }
        if (type === 'fromDate') {
          this.disabledSkipDates.to = this.moment(value).toDate();
          this.disabledToDates.to = this.moment(value).toDate();
        }
      },
      handleSkip(value, skipIndex, index) {
        const selectedDate = this.moment(value).format('YYYY-MM-DD');
        this.setScheduleProperty({
          type: 'skip',
          index,
          skipIndex,
          property: selectedDate,
        });
        this.disabledSkipDates.dates.push(new Date(selectedDate));
        this.placeholderDate = null;
      },
      handleSkipDelete(skipIndex) {
        this.schedule.skip.splice(skipIndex, 1);
        this.disabledSkipDates.dates.splice(skipIndex, 1);
      },
      handleDuration(e, i) {
        const property = parseInt(e.target.value, 10);
        const payload = {
          type: 'duration',
          index: i,
          property,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleTimeInput(value) {
        console.log(value);
      },
      handleDurationSelect(value) {
        const {
          params: { type: routeType },
        } = this.$route;

        const payload = {
          type: 'durationType',
          index: this.i,
          property: value.type,
          routeType,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleRepeatSelect(value) {
        const type = value.type;
        const payload = {
          type: 'repeatType',
          index: this.i,
          property: type,
        };
        this.setQuestionnaireProperty(payload);
        if (type === 'once') {
          this.disabledToDates.days = [];
          this.disabledSkipDates.days = [];
          this.disabledToDates.from = null;
          this.disabledToDates.to = null;
          this.disabledSkipDates.from = null;
          this.disabledSkipDates.to = null;
        } else if (type === 'daily') {
          this.disabledToDates.days = [];
          this.disabledSkipDates.days = [];
          this.disabledToDates.from = null;
          this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
          this.disabledSkipDates.from = null;
          this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
        } else if (type === 'custom') {
          this.disabledToDates.days = [0, 1, 2, 3, 4, 5, 6];
          this.disabledSkipDates.days = [0, 1, 2, 3, 4, 5, 6];
          this.disabledToDates.from = null;
          this.disabledToDates.to = this.moment(this.schedule.fromDate).toDate();
          this.disabledSkipDates.from = null;
          this.disabledSkipDates.to = this.moment(this.schedule.fromDate).toDate();
        }
      },
      handleDaySelect(day) {
        const payload = {
          type: 'weekDays',
          index: this.i,
          property: day,
        };
        this.setQuestionnaireProperty(payload);
        if (day === 7) day = 0;
        const skipDays = this.disabledSkipDates.days;
        const toDays = this.disabledToDates.days;
        const dayIndex = skipDays.findIndex((skipDay) => skipDay === day);
        if (dayIndex > -1) {
          skipDays.splice(dayIndex, 1);
          toDays.splice(dayIndex, 1);
        } else {
          skipDays.push(day);
          toDays.push(day);
        }
      },
      handleWeekStepSelect(value) {
        const weekStep = value.value;
        const payload = {
          type: 'weekStep',
          index: this.i,
          property: weekStep,
        };
        this.setQuestionnaireProperty(payload);
      },
      handleStepChange(value) {
        this.currentEditStep += value;
      },
      handleInitialEditStatus() {
        const payload = {
          type: 'editStatus',
          index: this.i,
        };
        this.setScheduleProperty(payload);
      },
      handleScheduleConfirm() {
        this.handleInitialEditStatus();
        this.editMode = false;
        this.currentEditStep = 0;
        this.setScheduleIsEdit(false);
      },
    },
  };
</script>
<style lang="scss">
  .reflection-options__inputs {
    .step-one-date {
      margin-right: 20px;
    }
    .step-one-date,
    .step-one-time {
      .reflection-options__option-title {
        display: block;
        margin-bottom: 5px;
      }
    }
    .vdp-datepicker {
      &.empty-date-input {
        input {
          border: 1px solid $red-4;
        }
      }
    }
    .input-error-active {
      border: 1px solid $red-4;
    }
  }
</style>
