<template>
  <section id="method-list" class="method-list" ref="methodList">
    <header-h2>{{ $t("methods.select_method") }}</header-h2>
    <div class="method-list__container">
      <ul :class="['method-list__list', {'method-list__list--sticky' : sticky}]">
        <method-list-item
          v-for="method in activeQuestionList"
          :key="method.id"
          :method="method"
        >
          <template v-slot:name>{{method.title.toLowerCase()}}</template>
          <template v-slot:time>{{method.duration}}s</template>
        </method-list-item>
      </ul>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';

import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import MethodListItem from '@/components/methods/MethodListItem.vue';

export default {
  name: 'MethodList',
  components: {
    HeaderH2,
    MethodListItem,
  },
  computed: {
    ...mapGetters('methods', ['questionTypeList']),
    ...mapGetters('questionnaire', ['questions', 'currentView']),
    sticky() {
      if (this.currentView === 'list') {
        if (this.questions.length >= 2) {
          return true;
        }
      }
      return false;
    },
    activeQuestionList() {
      return this.questionTypeList.filter(item => item.active);
    },
  },
};
</script>
<style lang="scss">
#method-list {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 40px;
  max-height: 725px;

  .method-list__container {
    width: 280px;
    margin-top: 10px;
    background-color: $grey-6;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 0px;
    flex-basis: 0px;

    &::-webkit-scrollbar {
      border: none;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 45px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    .method-list__list {
      padding: 0;
      margin: 0 0 0 0;
      display: flex;
      flex-direction: column;
      list-style: none;
    }
  }
}
</style>
