<template>
  <div class="payment-methods">
    <div :class="['payment-methods__method flex-global', { active: isPaysera }]">
      <div class="header flex-global">
        <svgicon name="paysera-logo" width="19" height="25"></svgicon>
        <p>Paysera</p>
      </div>
      <div class="content" v-if="isPaysera">
        <div class="switch-wrapper flex-global">
          <div class="switch flex-global">
            <div v-if="isPaysera"></div>
          </div>
          <p>{{ $t('licences.payment_method_paysera') }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="payment-methods__method flex-global">
      <div class="header flex-global">
        <svgicon name="paysera-logo" width="19" height="25"></svgicon>
        <p>Kortele</p>
      </div>
    </div>
    <div class="payment-methods__method flex-global">
      <div class="header flex-global">
        <svgicon name="paysera-logo" width="19" height="25"></svgicon>
        <p>Pavedimu</p>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { PAYMENT_METHOD_PAYSERA } from '@/helpers/constants/licence';

  export default {
    name: 'PaymentMethodSwitch',
    props: [],
    components: {},
    data() {
      return {
        paymentMethods: {
          paysera: PAYMENT_METHOD_PAYSERA,
        },
      };
    },
    computed: {
      ...mapGetters('licences', ['paymentMethod']),
      isPaysera() {
        return this.paymentMethod === this.paymentMethods.paysera;
      },
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .payment-methods {
    width: 610px;
    &__method {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 34px 26px;
      border: 1px solid #d2d2d2;
      background-color: $grey-6;
      color: #333;
      cursor: pointer;
      &.active {
        padding: 16px 24px;
        border: 1px solid #fff;
        border-left: 1px solid $violet-3;
        background-color: #fff;
        .header {
          margin-bottom: 15px;
        }
      }
      .header {
        svg {
          margin-right: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .content {
        padding: 15px 0 19px 20px;
        .switch-wrapper {
          .switch {
            margin-right: 10px;
            width: 12px;
            height: 12px;
            border: 1px solid $blue-5;
            border-radius: 50%;
            > div {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $blue-5;
            }
          }
          p {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
</style>
