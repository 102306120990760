<template>
  <div class="answer-component__indicator">
    <!-- <div class="stat-data">
      <div class="stat-bar">
        <div class="stat-bar__bar" :style="{ width: `${calcPercentage.yes}%` }">
          <div class="bar-percentage">{{ calcPercentage.yes }}%</div>
          <div class="bar-bar"></div>
        </div>
        <div class="stat-bar__bar" :style="{ width: `${calcPercentage.no}%` }">
          <div class="bar-percentage">{{ calcPercentage.no }}%</div>
          <div class="bar-bar"></div>
        </div>
        <div class="stat-bar__bar" :style="{ width: `${calcPercentage.null}%` }" v-if="calcPercentage.null">
          <div class="bar-percentage">{{ calcPercentage.null }}%</div>
          <div class="bar-bar"></div>
        </div>
      </div>
      <div class="stat-labels">
        <div class="stat-labels__label">
          <div class="label-icon"></div>
          <div class="label-value">Taip ({{ calcPercentage.yesCount }})</div>
        </div>
        <div class="stat-labels__label">
          <div class="label-icon"></div>
          <div class="label-value">Ne ({{ calcPercentage.noCount }})</div>
        </div>
        <div class="stat-labels__label">
          <div class="label-icon"></div>
          <div class="label-value">Nereflektavo ({{ calcPercentage.nullCount }})</div>
        </div>
      </div>
    </div>-->
    <div class="stat-chart">
      <!-- <span class="stat-chart__labelX">Data / Tema</span> -->
      <!-- <span class="stat-chart__labelY">Skalė</span> -->
      <chart-arith-values :average="average" :median="median" :mode="mode" />
      <line-chart
        :chartdata="chartData"
        :options="chartOptions"
        class="stat-chart__chart"
        :height="660"
        :width="980"
      ></line-chart>
    </div>
  </div>
</template>
<script>
  import LineChart from '@/components/charts/LineChart.vue';
  import ChartArithValues from '@/components/charts/ChartArithValues.vue';

  export default {
    name: 'StatsIndicator',
    components: {
      LineChart,
      ChartArithValues,
    },
    props: ['question', 'method'],
    computed: {
      calcPercentage() {
        const total = this.chartDataValues.length;
        let yesCount = 0;
        let noCount = 0;
        let nullCount = 0;
        this.chartDataValues.forEach((value) => {
          if (value === 1) yesCount += 1;
          if (value === -1) noCount += 1;
          if (value === 0) nullCount += 1;
        });
        const yesPerc = (yesCount / total) * 100;
        const noPerc = (noCount / total) * 100;
        const nullPerc = (nullCount / total) * 100;

        return {
          yes: Math.floor(yesPerc),
          no: Math.floor(noPerc),
          null: Math.floor(nullPerc),
          yesCount,
          noCount,
          nullCount,
        };
      },
      dataAnswers() {
        return this.method.map((answer) => {
          return {
            ...answer,
            value: typeof answer.value === 'string' ? answer.value.replace(',', '.') : answer.value,
          };
        });
      },
      chartLabels() {
        return this.dataAnswers.map((item) => item.date);
      },
      chartDataValues() {
        return this.dataAnswers.map((item) => item.value);
      },
      numericValues() {
        return this.dataAnswers.filter((i) => +i.value).map((item) => +item.value);
      },
      average() {
        return this.numericValues.length
          ? Math.round(this.numericValues.reduce((a, b) => a + b, 0) / this.numericValues.length)
          : '-';
      },
      median() {
        if (this.numericValues.length === 0) return '-';

        const arr = this.numericValues.slice().sort((a, b) => a - b);

        const half = Math.floor(arr.length / 2);

        if (arr.length % 2) return Math.round(arr[half] * 100) / 100;

        return Math.round(((arr[half - 1] + arr[half]) / 2.0) * 100) / 100;
      },
      mode() {
        const modes = [];
        const count = [];
        let i;
        let number;
        let maxIndex = 0;

        for (i = 0; i < this.numericValues.length; i += 1) {
          number = this.numericValues[i];
          count[number] = (count[number] || 0) + 1;
          if (count[number] > maxIndex) {
            maxIndex = count[number];
          }
        }

        for (i in count)
          if (Object.prototype.hasOwnProperty.call(count, i)) {
            if (count[i] === maxIndex) {
              modes.push(Number(i));
            }
          }

        if (modes.length === this.numericValues.length) {
          if (this.numericValues.length === 1) {
            return modes;
          }
          return '-';
        }
        return modes;
      },
      chartData() {
        return {
          labels: this.chartLabels,
          datasets: [
            {
              label: 'Skalė',
              data: this.chartDataValues,
              borderColor: '#48cfae',
              backgroundColor: 'transparent',
              pointBackgroundColor: '#48cfae',
              spanGaps: false,
              lineTension: 0,
              borderWidth: 2,
            },
          ],
        };
      },
      chartOptions() {
        const { min, max, step } = this.question.answerOptions;
        return {
          responsive: true,
          maintainAspectRatio: false,
          height: 200,
          legend: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                  offsetGridLines: true,
                  drawBorder: false,
                },
                ticks: {
                  autoSkip: true,
                  minRotation: 45,
                },
                offset: true,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  color: 'transparent',
                  zeroLineColor: '#707070',
                  zeroLineWidth: 1,
                },
                ticks: {
                  beginAtZero: parseInt(min, 10) === 0,
                  steps: parseInt(step, 10),
                  suggestedMax: parseInt(max, 10),
                  // max: parseInt(max, 10),
                  min: parseInt(min, 10),
                },
              },
            ],
          },
        };
      },
    },
  };
</script>
<style lang="scss">
  .answer-component {
    &__indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .stat-data {
        display: flex;
        justify-content: center;
        align-items: center;
        .stat-bar {
          display: flex;

          width: 300px;
          &__bar {
            display: flex;
            flex-direction: column;

            .bar-percentage {
              text-align: center;
              color: $green-7;
              font-family: Roboto-Medium;
              font-size: 14px;
            }

            .bar-bar {
              margin-top: 8px;
              height: 15px;
              width: 100%;

              &:first-of-type {
                background-color: $green-7;
              }

              &:nth-of-type(2) {
                background-color: $red-5;
              }

              &:nth-of-type(3) {
                background-color: $blue-4;
              }
            }

            &:first-of-type {
              .bar-bar {
                background-color: $green-7;
              }
              .bar-percentage {
                color: $green-7;
              }
            }

            &:nth-of-type(2) {
              .bar-bar {
                background-color: $red-5;
                border-left: 3px solid #fff;
              }
              .bar-percentage {
                color: $red-5;
              }
            }

            &:nth-of-type(3) {
              .bar-bar {
                background-color: $blue-4;
                border-left: 3px solid #fff;
              }
              .bar-percentage {
                color: $blue-4;
              }
            }
          }
        }
        .stat-labels {
          margin-left: 60px;
          display: flex;
          flex-direction: column;

          &__label {
            display: flex;
            align-items: center;

            .label-icon {
              width: 16px;
              height: 16px;
              border-radius: 50%;
            }

            .label-value {
              font-family: Roboto-Regular;
              font-size: 14px;
              color: $grey-5;
              margin-left: 10px;
            }

            &:not(:first-of-type) {
              margin-top: 10px;
            }

            &:first-of-type {
              .label-icon {
                background-color: $green-7;
              }
            }

            &:nth-of-type(2) {
              .label-icon {
                background-color: $red-5;
              }
            }
            &:nth-of-type(3) {
              .label-icon {
                background-color: $blue-4;
              }
            }
          }
        }
      }
      .stat-chart {
        position: relative;
        width: 100%;

        padding: 20px;

        &__labelY,
        &__labelX {
          position: absolute;
          font-family: Roboto-Medium;
          color: $grey-5;
          font-size: 12px;
        }

        &__labelY {
          left: 0;
          top: 10px;
        }

        &__labelX {
          right: 5px;
          top: 51%;
        }

        &__chart {
          max-height: 250px;
          max-width: 685px;
          margin: 20px auto 0 0;
        }
      }
    }
  }
</style>
