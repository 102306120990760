<template>
  <div class="method-answer__text">
    <span class="method-answer__value">{{ option.value }}</span>
  </div>
</template>

<script>
export default {
  name: 'TemplateText',
  props: ['option'],
};
</script>
