<template>
  <div class="profile-inner">
    <p class="profile-inner__title">{{ $t('profile.licences') }}</p>
    <licences :user="user" :userOrganizations="userOrganizations" :licenceData="userLicenceData" />
    <my-licences :licences="myAssignedLicences" />
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import Licences from '@/components/user/licences/Licences.vue';
  import MyLicences from '@/components/user/licences/MyLicences.vue';

  export default {
    name: 'ProfileLicence',
    components: {
      Licences,
      MyLicences,
    },
    data() {
      return {};
    },
    created() {
      this.handlePageInit();
    },
    computed: {
      ...mapGetters('user', ['user', 'userOrganizations']),
      ...mapGetters('licences', ['myAssignedLicences']),
      userLicenceData() {
        return (this.user && this.user.licenseData) || false;
      },
    },
    methods: {
      ...mapActions('licences', ['getMyAssignedLicences']),
      ...mapActions('user', ['getCurrentUser']),
      handleGetMyAssignedLicences() {
        this.getMyAssignedLicences();
      },
      handlePageInit() {
        this.handleGetMyAssignedLicences();
        this.getCurrentUser();
      },
    },
  };
</script>
<style lang="scss">
  .my-licences,
  .personal-licences {
    margin-bottom: 35px;

    &__title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 600;
      color: $grey-9;
    }
  }
</style>
