<template>
  <div class="home__callendar-day" v-show="!empty && !showDay">
    <div
      class="day__header"
      :class="{ 'day__header--active': day === moment().format('dddd DD'), 'day__header--weekend': weekend}"
    >
      <span>
        {{day}}
      </span>
    </div>
    <ul class="day__reflections">
      <slot name="reflection"/>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'CalendarDay',
  props: ['currentDay', 'weekend', 'day', 'empty', 'mockReflections', 'reflections'],
  computed: {
    showDay() {
      const reflections = this.reflections;
      const weekend = this.weekend;
      return weekend && !reflections;
    },
  },
};
</script>
<style lang="scss">
.home__callendar-day {
  display: flex;
  flex-direction: column;
  min-width: 175px;

  &:not(:first-of-type) {
    margin-left: 15px;
  }

  .day__header {
    width: 100%;
    height: 25px;
    background-color: $blue-1;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: Roboto-Medium;
      font-size: 11px;
      color: $grey-5;
    }

    &--weekend {
      background-color: $red-2;
    }

     &--active {
      background-color: #5ABDFF;

      span {
      color: #fff;
      }
    }
  }

  .day__reflections {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px 0 0 0;
    padding: 0;

    &--empty {
      .empty-reflection {
      background-color: $grey-1;
      width: 174px;
      height: 85px;

      &:not(:first-of-type) {
        margin-top: 5px;
      }
      }
    }
  }
}
</style>
