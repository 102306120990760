<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer-content-wrapper">
        <p class="content-header">{{ $t("footer.help_content_1") }}</p>
        <a class="content-link violet" href="https://share.hsforms.com/1z55MNM4ESEyCY13gMNJgHw4iwze" target="_blank">{{ $t("actions.get_offer_action") }}</a>
      </div>
      <div class="footer-content-wrapper">
        <p class="content-header">{{ $t("footer.help_content_2") }}</p>
        <a class="content-link orange" href="https://share.hsforms.com/1RDNoAj65RCyeJ-spae3KeA4iwze" target="_blank">{{ $t("actions.contact_action") }}</a>
      </div>
      <div class="footer-content-wrapper">
        <p class="content-header">{{ $t("footer.help_content_3") }}</p>
        <a class="content-link orange" href="https://share.hsforms.com/1RDNoAj65RCyeJ-spae3KeA4iwze" target="_blank">{{ $t("actions.contact_action") }}</a>
      </div>
    </div>
    <div class="footer__copyright">
      <div class="copyright-content-wrapper">
        <div>&copy; 2021 Reflectus. {{ $t("all_rights_reserved") }}</div>
        <div>{{ $t("company_info.full_company_info") }}</div>
      </div>
      <a href="https://www.facebook.com/reflectus.lt" target="_blank" class="copyright-content-fb-link"><svgicon name="facebook" width="16px" height="16px" :original="true"></svgicon></a>
    </div>
  </footer>
</template>

<script>
import '../icons';

export default {
  name: 'Footer',
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 0;
  padding-top: 16px;
  margin-top: auto;
  width: 100%;
  background-color: $green-3;
  color: #fff;
  max-height: 500px;
  font-family: Roboto-Regular;
  font-size: 12px;
  .footer__container {
    display: flex;
    max-width: 944px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    .footer-content-wrapper{
      .content-header{
        margin-bottom: 6px;
        font-family: 'Nunito-SemiBold';
        font-size: 14px;
        font-weight: 600;
      }
      .content-link{
        display: block;
        width: fit-content;
        padding: 7px 24px;
        font-family: 'Roboto-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        border-radius: 100px;
        color: #fff;
        &.violet{
          background: #5147A7;
          &:hover {
            background: #5d52be;
          }
          &:active {
            color: $grey-2;
          }
        }
        &.orange{
          background: $orange-2;
          &:hover {
            background: $orange-1;
          }
          &:active {
            color: $grey-2;
          }
        }
      }
      .content-phone-number{
        padding: 5px 24px;
        width: fit-content;
        border: 2px solid #fff;
        border-radius: 100px;
        a{
          color: #fff;
          text-decoration: none;
          font-family: 'Roboto-Medium';
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .footer__copyright {
    margin: 0 auto;
    margin-top: 16px;
    max-width: 944px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #fff;
    .copyright-content-wrapper{
      width: 659px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .copyright-content-fb-link{
      svg{
        fill: #fff;
      }
      &:hover{
        svg{
          fill: rgb(235, 235, 235);
        }
      }
    }
  }
}
</style>
