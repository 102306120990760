<template>
  <div class="profile-inner">
    <p class="profile-inner__title">{{ $t('profile.account_data') }}</p>
    <!-- first name -->
    <advanced-field
      :title="$t('inputs.first_name_cap')"
      :valueTitle="'name'"
      :value="user.name"
      :valueToDisplay="valuesToDisplay.name"
      :isError="$v.user.name.$error"
      :ref="`advancedField-name`"
      @handleEdit="handleUserEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.user.name.$error">
          <span class="error-message" v-if="!$v.user.name.required">
            {{ $t('warnings.first_name_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.user.name.maxLength">
            {{ $t('warnings.first_name_length_warning') }}
          </span>
          <span class="error-message" v-if="!$v.user.name.minLength">
            {{ $t('warnings.first_name_length_warning') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- last name -->
    <advanced-field
      :title="$t('inputs.last_name_cap')"
      :valueTitle="'lastName'"
      :value="user.lastName"
      :valueToDisplay="valuesToDisplay.lastName"
      :isError="$v.user.lastName.$error"
      ref="advancedField-lastName"
      @handleEdit="handleUserEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.user.lastName.$error">
          <span class="error-message" v-if="!$v.user.lastName.required">
            {{ $t('warnings.last_name_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.user.lastName.maxLength">
            {{ $t('warnings.last_name_length_warning') }}
          </span>
          <span class="error-message" v-if="!$v.user.lastName.minLength">
            {{ $t('warnings.last_name_length_warning') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- email address -->
    <advanced-field
      :title="$t('inputs.email_address_cap')"
      :valueTitle="'email'"
      :value="user.email"
      :valueToDisplay="valuesToDisplay.email"
      :isError="$v.user.email.$error"
      ref="advancedField-email"
      @handleEdit="handleUserEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.user.email.$error">
          <span class="error-message" v-if="!$v.user.email.required">
            {{ $t('warnings.email_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.user.email.email">
            {{ $t('warnings.invalid_email') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- mobile number -->
    <advanced-field
      :title="$t('inputs.mobile_number_cap')"
      :valueTitle="'phone'"
      :value="user.phone"
      :valueToDisplay="valuesToDisplay.phone"
      :isError="$v.user.phone.$error"
      ref="advancedField-phone"
      @handleEdit="handleUserEdit"
    >
      <template v-slot:input-error>
        <div v-if="$v.user.phone.$error">
          <span class="error-message" v-if="!$v.user.phone.required">
            {{ $t('warnings.phone_is_required') }}
          </span>
          <span class="error-message" v-if="!$v.user.phone.minLength">
            {{ $t('warnings.invalid_phone') }}
          </span>
        </div>
      </template>
    </advanced-field>
    <!-- school/org -->
    <advanced-field
      :title="$t('inputs.school_org')"
      :valueTitle="'organization'"
      :value="user.organization"
      :organizations="organizations"
      :readOnly="true"
      ref="advancedField-organization"
    />
    <!-- password -->
    <advanced-field :title="$t('inputs.password')" :isPasswordField="true" />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
  import AdvancedField from '@/components/elements/fields/AdvancedField.vue';

  export default {
    name: 'ProfileInfo',
    components: {
      AdvancedField,
    },
    data() {
      return {
        valuesToDisplay: {
          name: null,
          lastName: null,
          email: null,
          phone: null,
        },
      };
    },
    validations: {
      user: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(32),
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
          numeric,
          minLength: minLength(9),
        },
        organization: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(120),
        },
      },
    },
    mounted() {
      if (this.user) {
        this.handleTitleChange('name');
        this.handleTitleChange('lastName');
        this.handleTitleChange('email');
        this.handleTitleChange('phone');
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'userOrganizations']),
      organizations() {
        return this.userOrganizations && this.userOrganizations.length
          ? this.userOrganizations.map((org) => org.title)
          : '-';
      },
    },
    methods: {
      ...mapActions('user', ['updateUser']),
      handleUserEdit(newValue) {
        this.user[newValue.name] = newValue.value;
        this.$v.user[newValue.name].$touch();

        if (!this.$v.user[newValue.name].$error) {
          this.handleUserUpdate(newValue);
        }
      },
      handleUserUpdate(value) {
        const payload = { [value.name]: value.value };

        this.updateUser(payload).then((res) => {
          const { success, msg } = res;
          if (success) {
            this.$refs[`advancedField-${value.name}`].closeEditModal();
            this.$toast.success(this.$t('profile.successful_profile_update'));
            this.handleTitleChange(value.name);
          }
          if (msg) {
            this.$toast.error(msg);
          }
        });
      },
      handleTitleChange(value) {
        const nameValue = this.user[value];
        this.valuesToDisplay[value] = nameValue;
      },
    },
  };
</script>
<style lang="scss" scoped></style>
