<template>
  <div class="group-reflection-stats" v-if="!loading">
    <group-reflection-header
      :questionHasParts="questionHasParts"
      :questionLoading="questionLoading"
      @handleReflectionChange="handleReflectionChange"
      @handleDeleteQuestionnaireModal="handleDeleteQuestionnaireModal"
    ></group-reflection-header>
    <member-reflection-comment v-if="currentReflection"></member-reflection-comment>
    <div
      class="group-reflection-stats__container"
      v-if="questionnaireReflectionCount > 0 && questionnaireQuestions.length > 0"
    >
      <stat-types
        :questionLoading="questionLoading"
        @expandAnswer="setDefaultExpandValue"
      ></stat-types>
      <div class="stat-components__list" v-if="answerType === 'group'">
        <div class="stat-components__list__tabs" v-if="currentView === 'tabs'">
          <stats-tab
            v-for="(question, key) in questions"
            :key="key"
            :number="key"
            @click.native="handleTabChange(key)"
          ></stats-tab>
        </div>
        <stat-display-toggle
          :expanded="expanded"
          :answerType="answerType"
          @statDisplayToggle="handleExpand"
        ></stat-display-toggle>
        <div class="stat-components__list__container" v-if="currentView === 'list'">
          <stat-components
            v-for="(question, index) in questionnaire.questions"
            :key="index"
            :question="question"
            :number="index + 1"
            :expanded="expanded"
            :groupStatQuestion="groupStatQuestion[index]"
            :groupIndividualStats="groupIndividualStats[index]"
            :loading="questionLoading"
            :memberCount="currentReflectionMembersCount"
          ></stat-components>
        </div>
        <div class="stat-components__list__container" v-else>
          <stat-components
            :question="questions[currentTab]"
            :number="currentTab"
            :expanded="true"
            :groupStatQuestion="groupStatQuestion[currentTab]"
            :groupIndividualStats="groupIndividualStats[currentTab]"
            :loading="questionLoading"
            :memberCount="currentReflectionMembersCount"
          ></stat-components>
        </div>
      </div>
      <div class="stat-components__list" v-else-if="answerType === 'individual'">
        <div class="stat-components__list__tabs" v-if="currentView === 'tabs'">
          <stats-tab
            v-for="(question, key) in questions"
            :key="key"
            :number="key"
            @click.native="handleTabChange(key)"
          ></stats-tab>
        </div>
        <stat-display-toggle
          :expanded="expanded"
          :answerType="answerType"
          @statDisplayToggle="handleExpand"
        ></stat-display-toggle>
        <div class="stat-components__list__container" v-if="currentView === 'list'">
          <stat-components
            v-for="(question, index) in questionnaire.questions"
            :key="index"
            :question="question"
            :number="index + 1"
            :expanded="expanded"
            :groupIndividualStats="groupIndividualStats[index]"
            :distinctAnswers="distinctAnswers"
            :loading="questionLoading"
            :memberCount="currentReflectionMembersCount"
          ></stat-components>
        </div>
        <div class="stat-components__list__container" v-else>
          <stat-components
            :question="questions[currentTab]"
            :number="currentTab"
            :expanded="true"
            :groupIndividualStats="groupIndividualStats[currentTab]"
            :distinctAnswers="distinctAnswers"
            :loading="questionLoading"
            :memberCount="currentReflectionMembersCount"
          ></stat-components>
        </div>
      </div>
      <div class="stat-components__list" v-else-if="answerType === 'inTime'">
        <div class="stat-components__list__tabs" v-if="currentView === 'tabs'">
          <stats-tab
            v-for="(question, key) in questions"
            :key="key"
            :number="key"
            @click.native="handleTabChange(key)"
          ></stats-tab>
        </div>
        <stat-display-toggle
          :expanded="expanded"
          :answerType="answerType"
          @statDisplayToggle="handleExpand"
        ></stat-display-toggle>
        <group-reflection-filter
          :disabledDates="disabledDates"
          @handleReflectionFilter="handleFilterDates"
          :currentReflectionDates="currentReflectionDates"
        ></group-reflection-filter>
        <div class="stat-components__list__container" v-if="currentView === 'list'">
          <stat-components
            v-for="(question, index) in questionnaire.questions"
            :key="index"
            :question="question"
            :number="index + 1"
            :expanded="expanded"
            :groupStatInTime="groupStatInTime[index]"
            :loading="questionLoading"
            :memberCount="currentReflectionMembersCount"
          ></stat-components>
        </div>
        <div class="stat-components__list__container" v-else>
          <stat-components
            :hasParts="questionHasParts"
            :question="questions[currentTab]"
            :number="currentTab"
            :expanded="true"
            :groupStatInTime="groupStatInTime[currentTab]"
            :loading="questionLoading"
            :memberCount="currentReflectionMembersCount"
          ></stat-components>
        </div>
      </div>
      <div class="stat-components__list" v-else>
        <div class="stat-components__list__container">
          <stat-messages
            :questionnaireId="questionnaireId"
            :messages="groupMessages"
            :defaultQueries="defaultQueries"
            :totalCount="totalCount"
            :activePage="activePage"
            @updateTotalCount="updateTotalCount"
            @updateActivePage="updateActivePage"
          />
        </div>
      </div>
    </div>
    <div v-else class="group-reflection-stats__container">
      <h3 class="no-data-message">{{ $t('messages.no_data_message') }}</h3>
    </div>
    <delete-modal
      v-if="deleteModal"
      :type="deleteModalType"
      @handleDeleteAction="handleDeleteActionWrapper"
    />
  </div>
  <loading v-else></loading>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  import {
    handleGetMethods,
    handleGetReflections,
    handleSetReflection,
    handleGetGroup,
  } from '@/helpers/groups.helpers';

  import GroupReflectionHeader from '@/components/groups/GroupReflectionHeader.vue';
  import StatComponents from '@/components/stats/group-stats/StatComponents.vue';
  import MemberReflectionComment from '@/components/groups/member/MemberReflectionComment.vue';
  import StatTypes from '@/components/stats/group-stats/StatTypes.vue';
  import Loading from '@/components/base/Loading.vue';
  import StatsTab from '@/components/stats/StatsTab.vue';
  import GroupReflectionFilter from '@/components/groups/GroupReflectionFilter.vue';
  import StatDisplayToggle from '@/components/stats/stat-components/StatDisplayToggle.vue';
  import StatMessages from '@/components/stats/stat-components/StatMessages.vue';
  import DeleteModal from '@/components/base/DeleteModal.vue';

  export default {
    name: 'GroupReflectionStats',
    components: {
      GroupReflectionHeader,
      MemberReflectionComment,
      StatComponents,
      StatTypes,
      Loading,
      StatsTab,
      GroupReflectionFilter,
      StatDisplayToggle,
      StatMessages,
      DeleteModal,
    },
    data() {
      return {
        expanded: true,
        questionLoading: false,
        disabledDates: {
          from: this.moment()
            .add(1, 'days')
            .toDate(),
          to: null,
        },
        inTimeDates: {
          from: this.moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          to: this.moment().format('YYYY-MM-DD'),
        },
        defaultQueries: {
          limit: 10,
          offset: null,
        },
        totalCount: null,
        activePage: 1,
        deleteModalType: null,
      };
    },
    created() {
      this.handlePageInit();
    },
    destroyed() {
      this.resetStateStat();
    },
    watch: {
      answerType() {
        this.handleTypeSwitch();
      },
    },
    computed: {
      ...mapGetters('meta', ['loading', 'deleteModal']),
      ...mapGetters('groups', ['currentGroup']),
      ...mapGetters('methods', ['questionTypeList']),
      ...mapGetters('questionnaire', ['questions', 'questionnaire']),
      ...mapGetters('reflections', ['currentReflection', 'groupReflectionList']),
      ...mapGetters('stats', [
        'currentView',
        'answerType',
        'currentTab',
        'groupStatQuestion',
        'groupIndividualStats',
        'groupStatInTime',
        'distinctAnswers',
        'groupMessages',
      ]),
      groupId() {
        const {
          params: { groupId },
        } = this.$route;
        const { id } = this.currentGroup;
        return id || +groupId;
      },
      reflectionId() {
        const {
          params: { reflectionId },
        } = this.$route;
        const firstReflection = this.groupReflectionList[0].id;

        return this.currentReflection.id || +reflectionId || +firstReflection;
      },
      questionnaireId() {
        const {
          params: { questionnaireId },
        } = this.$route;
        const { id } = this.questionnaire;

        return id || +questionnaireId;
      },
      statQuestionPayload() {
        const question = this.questions[this.currentTab];
        return {
          reflectionId: this.reflectionId,
          questionId: +question.id,
        };
      },
      individualStatPayload() {
        const question = this.questions[this.currentTab];
        return {
          reflectionId: this.reflectionId,
          questionId: +question.id,
          groupId: this.groupId,
        };
      },
      inTimeStatPayload() {
        return {
          questionId: this.question.id,
          questionnaireId: this.questionnaire.id,
          ...this.inTimeDates,
        };
      },
      question() {
        if (!this.questions.length) return false;
        return this.questions[this.currentTab];
      },
      questionHasParts() {
        if (!this.question) return false;
        return !!this.question.parts.length;
      },
      distinctAnswersMethodPresent() {
        return (
          this.questions.find((question) => question.methodId === 5 || question.methodId === 4) ||
          false
        );
      },
      questionnaireReflectionCount() {
        return this.questionnaire.reflectionCount;
      },
      questionnaireQuestions() {
        return this.questionnaire.questions;
      },
      currentReflectionMembersCount() {
        const { membersCount } = this.currentReflection;
        return membersCount;
      },
      currentReflectionDates() {
        const { start, end } = this.currentReflection;
        return {
          start: this.moment(start).format('YYYY-MM-DD'),
          end: this.moment(end).format('YYYY-MM-DD'),
        };
      },
      currentReflectionFrom() {
        const { start } = this.currentReflection;
        return start
          ? this.moment(start)
              .subtract(1, 'months')
              .format('YYYY-MM-DD')
          : this.moment()
              .subtract(1, 'months')
              .format('YYYY-MM-DD');
      },
      currentReflectionTo() {
        const { start } = this.currentReflection;
        return start
          ? this.moment(start).format('YYYY-MM-DD')
          : this.moment()
              .add(1, 'days')
              .format('YYYY-MM-DD');
      },
    },
    methods: {
      ...mapActions('meta', ['setLoading', 'toggleDeleteModal']),
      ...mapActions('groups', ['getGroup']),
      ...mapActions('methods', ['getQuestionList']),
      ...mapActions('questionnaire', ['getQuestionnaire', 'deleteGroupQuestionnaire']),
      ...mapActions('reflections', ['getReflectionsByGroup', 'setCurrentReflection']),
      ...mapActions('stats', [
        'setType',
        'setTab',
        'toggleView',
        'setGroupStatQuestionParts',
        'setAllGroupStatQuestion',
        'setAllIndividualStats',
        'setIndividualStatParts',
        'setAllGroupStatsInTime',
        'setGroupStatInTimeParts',
        'setDistinctAnswers',
        'getAllMessages',
        'resetGroupStats',
        'resetGroupIndividual',
        'resetGroupInTime',
        'resetDistinctAnswers',
        'resetState',
        'resetStateStat',
        'resetGroupState',
        'resetIndividualState',
      ]),
      handleGetMethods,
      handleGetReflections,
      handleSetReflection,
      handleGetGroup,
      async handlePageInit() {
        this.setLoading(true);
        try {
          await this.handleGetMethods();
          await this.handleGetGroup();
          await this.handleGetReflections().then(this.handleSetReflection);
          // await this.handleSetReflection()
          await this.handleSetCurrentDates();
          await this.handleGetQuestionnaire();
          await this.handleGetGroupMessages();
          await this.handleTypeSwitch();
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      setDefaultExpandValue() {
        this.expanded = true;
      },
      handleExpand(value) {
        if (this.currentView === 'tabs') {
          this.expanded = true;
        } else this.expanded = value.checked;
      },
      updateTotalCount(count) {
        this.totalCount = +count;
      },
      updateActivePage(page) {
        this.activePage = page;
      },
      async handleReflectionChange() {
        this.resetGroupStats();
        this.resetGroupIndividual();
        this.resetGroupInTime();

        this.setQuestionLoading(true);
        await setTimeout(() => {
          this.setQuestionLoading(false);
          this.handleTypeSwitch();
          this.handleSetCurrentDates();
        }, 500);
      },
      async handleGetQuestionnaire() {
        const { start } = this.currentReflection;
        const {
          params: { questionnaireId },
        } = this.$route;
        const { success } = await this.getQuestionnaire({ questionnaireId, reflectionDate: start });
        return Promise.resolve(success);
      },
      async handleGetGroupMessages() {
        const {
          params: { questionnaireId },
        } = this.$route;
        const { success, totalCount } = await this.getAllMessages({
          questionnaireId,
          ...this.defaultQueries,
        });
        this.updateTotalCount(totalCount);
        return Promise.resolve(success);
      },
      async handleGetDistinctAnswers() {
        const {
          params: { questionnaireId },
        } = this.$route;

        const { success } = await this.setDistinctAnswers({ id: questionnaireId });
        return Promise.resolve(success);
      },
      async handleGroupStatQuestions() {
        if (!this.questions.length) return Promise.resolve();
        if (this.groupStatQuestion.length) return Promise.resolve();
        this.setQuestionLoading(true);

        for (const [index, question] of this.questions.entries()) {
          const payload = {
            ...this.statQuestionPayload,
            questionId: question.id,
          };
          const hasParts = !!question.parts && !!question.parts.length;

          if (hasParts) {
            await this.handleGroupStatQuestionParts(question.parts, index, payload);
          } else await this.setAllGroupStatQuestion(payload);
        }
        this.setQuestionLoading(false);
        return Promise.resolve();
      },
      async handleGroupStatQuestionParts(parts, questionIndex, payload) {
        for (const part of parts) {
          await this.setGroupStatQuestionParts({
            ...payload,
            questionPartId: part.id,
            questionIndex,
          });
        }
        return Promise.resolve();
      },
      async handleGroupStatInTimeParts(parts, questionIndex, payload) {
        for (const part of parts) {
          await this.setGroupStatInTimeParts({
            ...payload,
            questionPartId: part.id,
            questionIndex,
          });
        }
        return Promise.resolve();
      },
      async handleIndividualStatParts(parts, questionIndex, payload) {
        for (const part of parts) {
          await this.setIndividualStatParts({
            ...payload,
            questionPartId: part.id,
            questionIndex,
          });
        }
        return Promise.resolve();
      },
      async handleGroupInTimeStats() {
        if (!this.questions.length) return Promise.resolve();
        if (this.groupStatInTime.length) return Promise.resolve();

        this.setQuestionLoading(true);

        for (const [index, question] of this.questions.entries()) {
          const payload = {
            ...this.inTimeStatPayload,
            questionId: question.id,
          };
          const hasParts = !!question.parts && !!question.parts.length;

          if (hasParts) await this.handleGroupStatInTimeParts(question.parts, index, payload);
          else await this.setAllGroupStatsInTime(payload);
        }

        this.setQuestionLoading(false);
        return Promise.resolve();
      },
      async handleGroupIndividualStats() {
        if (!this.questions.length) return Promise.resolve();
        if (this.groupIndividualStats.length) return Promise.resolve();

        this.setQuestionLoading(true);

        for (const [index, question] of this.questions.entries()) {
          const payload = {
            ...this.individualStatPayload,
            questionId: question.id,
          };
          const hasParts = question.parts && question.parts.length;

          if (hasParts) await this.handleIndividualStatParts(question.parts, index, payload);
          else await this.setAllIndividualStats(payload);
        }

        this.setQuestionLoading(false);
        return Promise.resolve();
      },
      handleTabChange(key) {
        this.setTab(key);
      },
      async handleTypeSwitch() {
        if (this.currentTab >= this.questions.length) {
          this.handleTabChange(0);
        }
        if (this.answerType === 'group') {
          await this.resetGroupState();
          await this.resetIndividualState();
          await this.handleGroupStatQuestions();
          await this.handleGroupIndividualStats();
          return Promise.resolve();
        }
        if (this.answerType === 'individual') {
          await this.resetIndividualState();
          await this.resetDistinctAnswers();
          if (this.distinctAnswersMethodPresent) {
            await this.handleGetDistinctAnswers();
          }
          await this.handleGroupIndividualStats();
        }
        await this.resetGroupInTime();
        await this.handleGroupInTimeStats();
        return Promise.resolve();
      },
      async handleFilterDates({ fromDate, toDate }) {
        this.inTimeDates = {
          from: this.moment(fromDate).format('YYYY-MM-DD'),
          to: this.moment(toDate).format('YYYY-MM-DD'),
        };
        this.resetGroupInTime();
        this.handleGroupInTimeStats();
      },
      async handleSetCurrentDates() {
        this.inTimeDates.from = this.currentReflectionFrom;
        this.inTimeDates.to = this.currentReflectionTo;
        return Promise.resolve();
      },
      async handleQuestionnaireDelete() {
        const {
          params: { questionnaireId },
        } = this.$route;
        this.toggleDeleteModal();
        this.setLoading(true);
        const result = await this.deleteGroupQuestionnaire(questionnaireId);

        if (result) {
          setTimeout(() => {
            this.$router.push({ name: 'Group' });
            this.setLoading(false);
          }, 1000);
        } else {
          this.setLoading(false);
        }
        return Promise.resolve();
      },
      setQuestionLoading(status) {
        this.questionLoading = status;
      },
      handleDeleteQuestionnaireModal() {
        this.deleteModalType = 'questionnaire';
        this.toggleDeleteModal();
      },
      handleDeleteActionWrapper() {
        this.handleQuestionnaireDelete();
      },
    },
  };
</script>
<style lang="scss">
  .group-reflection-stats {
    max-width: 940px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    &__container {
      display: flex;
      margin-top: 40px;
      position: relative;
      .no-data-message {
        margin: 0 auto;
      }
    }

    .stat-components {
      &__list {
        display: flex;
        flex-direction: column;
        max-width: 840px;
        width: 100%;
        margin-left: 5px;
        position: relative;

        &__tabs {
          top: -24px;
          left: 10px;
          position: absolute;
          display: flex;
          align-items: center;
        }

        &__container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &--test {
            margin-top: 50px;
            padding: 20px;
            background-color: #d1e2f1;
          }
        }

        .group-reflection {
          &__filter {
            margin-bottom: 3px;
          }
        }
      }
    }
  }
</style>
