<template>
  <div class="comment__container">
    <div class="comment__comments" :class="{ 'comment__comments--expanded': expanded }">
      <div class="comment__box">
        <textarea
          class="comment__input"
          :class="{ 'comment__input--expanded': expanded }"
          :placeholder='$t("comment_placeholder")'
          :readonly="!editStatus"
          v-model="currentReflection.comments"
        ></textarea>
        <svgicon
          class="comment__input__icon"
          name="expand"
          :original="true"
          v-if="!expanded"
          @click="expand"
          v-tooltip='$t("actions.expand_action")'
        ></svgicon>
        <svgicon
          class="comment__input__icon"
          name="collapse"
          :original="true"
          v-else
          @click="expand"
          v-tooltip='$t("actions.collapse_action")'
        ></svgicon>
        <svgicon
          class="comment__input__icon"
          :name="editStatus ? 'save' : 'pen'"
          :original="true"
          @click="handleEdit"
          v-tooltip='editStatus ? $t("actions.save_action_2") : $t("actions.edit_action")'
        ></svgicon>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MemberReflectionComment',
  data() {
    return {
      expanded: false,
      editStatus: false,
    };
  },
  computed: {
    ...mapGetters('reflections', ['currentReflection']),
  },
  methods: {
    ...mapActions('reflections', ['updateReflection']),
    expand() {
      this.expanded = !this.expanded;
    },
    handleEdit() {
      const { id, comments } = this.currentReflection;
      if (!this.editStatus) {
        this.editStatus = !this.editStatus;
        return;
      }
      const payload = {
        id,
        comments,
      };
      this.updateReflection(payload);
      this.editStatus = !this.editStatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.comment {
  &__container {
    display: flex;
  }

  &__comments {
    padding: 15px 20px;
    width: 820px;
    background-color: #fff;
    align-items: flex-start;
    height: 60px;
    transition: all 0.3s;

    &--expanded {
      height: 140px;

      .comment__box {
        align-items: flex-end;
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;

    svg {
      height: 22px;
      width: 22px;
      margin-left: 20px;
    }
  }

  &__input {
    width: 700px;
    height: 28px;
    padding: 6px 10px;
    border: 1px solid $grey-2;
    resize: none;
    font-family: Roboto-Regular;
    overflow: hidden;

    &::placeholder {
      font-family: Roboto-Regular;
      font-size: 14px;
      color: $grey-4;
    }

    &--expanded {
      height: 94px;
    }

    &__icon {
      cursor: pointer;
      outline: none;
    }
  }
}
</style>
