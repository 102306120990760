<template>
  <div class="purchase flex-global">
    <step-viewer v-if="showStepViewer" />
    <div class="purchase__step-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  import StepViewer from '@/components/user/licences/StepViewer.vue';

  export default {
    name: 'PurchaseLicence',
    components: {
      StepViewer,
    },
    data() {
      return {};
    },
    computed: {
      showStepViewer() {
        return this.$route.name !== 'CheckoutCallback';
      },
    },
    methods: {},
  };
</script>
<style lang="scss">
  .purchase {
    margin-top: -10px;
    padding-top: 68px;
    padding-bottom: 60px;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Nunito-Regular;
    color: $grey-9;
    background-color: #fff;
  }
</style>
