<template>
  <div class="faq__page">
    <section id="faq" class="faq">
      <faq-header></faq-header>
      <div class="faq__container">
        <div class="faq__questions">
          <div class="accordion" v-for="(question, key) in questions" :key="key">
            <div class="accordion__header" @click="handleCollapse('main', key)">
              <svgicon
                class="accordion__header__icon accordion__header__icon--plus"
                :name="open === key ? 'minus' : 'add'"
                :original="true"
              ></svgicon>
              <h3 class="accordion__header__title">{{ question.title }}</h3>
            </div>
            <div
              :class="['accordion__content', { 'accordion__content--open': open === key }]"
              v-for="(details, detailsKey) in question.details"
              :key="detailsKey"
              @click.stop
            >
              <div class="accordion__content__header" @click="handleCollapse('nested', detailsKey)">
                <svgicon
                  class="accordion__content__header__icon"
                  :name="openNested === detailsKey ? 'minus' : 'add'"
                  :original="true"
                ></svgicon>
                <p class="accordion__content__header__title">{{ details.title }}</p>
              </div>
              <div
                :class="[
                  'accordion__content__body',
                  { 'accordion__content__body--open': openNested === detailsKey },
                ]"
              >
                <p class="accordion__content__body__p" v-html="details.content"></p>
              </div>
            </div>
          </div>
        </div>
        <faq-contact :user="user"></faq-contact>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FaqHeader from '@/components/faq/FaqHeader.vue';
import FaqContact from '@/components/faq/FaqContact.vue';

export default {
  name: 'FAQ',
  components: {
    FaqHeader,
    FaqContact,
  },
  data() {
    return {
      open: -1,
      openNested: -1,
    };
  },
  created() {
    this.handleGetFaq();
  },
  computed: {
    ...mapGetters('meta', ['faq']),
    ...mapGetters('user', ['user']),
    categories() {
      const categories = [];
      this.faq.forEach((element) => {
        categories.push(element.group);
      });
      return categories.filter(
        (category, index, self) => self.findIndex((c) => c === category) === index
      );
    },
    questions() {
      let entries = [];
      const array = [];
      this.categories.forEach((category) => {
        this.faq.forEach((element) => {
          if (category === element.group) {
            entries.push({
              title: element.question,
              content: element.answer,
              position: element.position,
            });
          }
        });
        array.push({ title: category, details: entries.sort((a, b) => a.position - b.position) });
        entries = [];
      });
      return array;
    },
  },
  methods: {
    ...mapActions('meta', ['getFaq']),
    handleGetFaq() {
      this.getFaq();
    },
    switchIcon() {
      if (this.iconName === 'add') {
        this.iconName = 'multiply';
      } else if (this.iconName === 'multiply') {
        this.iconName = 'add';
      }
    },
    handleCollapse(type, index) {
      if (type === 'main' && index !== this.open) {
        this.open = index;
      } else if (type === 'main') this.open = -1;
      if (type === 'nested' && index !== this.openNested) {
        this.openNested = index;
      } else this.openNested = -1;
    },
  },
};
</script>
<style lang="scss">
#faq {
  max-width: 940px;
  margin: 20px auto 0 auto;
  position: relative;

  .faq__container {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    .faq__questions {
      display: flex;
      flex-direction: column;
    }
    .faq__questions {
      width: 580px;

      .faq__question {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        height: 10px;
        padding: 20px;
        .collapsed-true {
          display: block;
        }
        .collapsed-false {
          display: none;
        }

        &.collapsed-true__container {
          .collapsed-true {
            display: none;
          }
          .collapsed-false {
            display: block;
          }
        }

        h2 {
          margin-left: 20px;
        }
      }

      .faq__question-inner {
        padding: 20px 20px 40px 20px;
      }

      .faq__question-nested {
        display: flex;
        padding: 20px;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        .collapsed-true {
          display: block;
        }
        .collapsed-false {
          display: none;
        }

        &.collapsed-true__container {
          .collapsed-true {
            display: none;
          }
          .collapsed-false {
            display: block;
          }
        }
      }
      .nested__content {
        margin-left: 40px;
        text-align: left;
      }
    }
  }
}

.accordion {
  width: 620px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;

  &__header {
    display: flex;
    align-items: center;
    margin-right: auto;
    width: 100%;

    &__icon {
      width: 14px;
      height: 14px;
    }

    &__title {
      font-family: Nunito-Bold;
      font-size: 16px;
      color: $violet-5;
      margin-left: 20px;
    }

    &:hover,
    &:active {
      cursor: pointer;
    }
  }

  &__content {
    margin-top: 10px;
    margin-left: 34px;
    display: none;
    flex-direction: column;
    width: 100%;

    &--open {
      display: flex;
    }

    &__header {
      display: flex;
      align-items: center;

      &__icon {
        width: 10px;
        height: 10px;
      }

      &__title {
        font-family: Roboto-Medium;
        font-size: 14px;
        color: $grey-5;
        margin-left: 10px;
      }

      &:hover,
      &:active {
        cursor: pointer;
      }
    }

    &__body {
      display: none;
      flex-direction: column;
      margin-left: 20px;
      margin-top: 10px;

      &--open {
        display: flex;
      }

      &__p {
        font-size: 14px;
      }
    }
  }
}
</style>
