<template>
  <div class="list-chart">
    <ul class="list-chart__list">
      <list-chart-item
        v-for="(answer, index) in answers"
        :key="index"
        :value="answer.value"
        :color="isIndicator ? '' : answer.color"
        :date="answer.date"
        :name="individual ? answer.name : null"
        :lastName="individual ? answer.lastName : null"
        :memberId="answer.clientId"
        :index="index + 1"
        :handleNavigation="handleNavigation"
      ></list-chart-item>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ListChartItem from '@/components/stats/chart-templates/ListChartItem.vue';

export default {
  name: 'ListChart',
  props: ['answers', 'individual', 'isIndicator'],
  components: {
    ListChartItem,
  },
  computed:{
    ...mapGetters('reflections', ['currentReflection']),
    routeParams() {
      return this.$route.params;
    }
  },
  methods: {
    ...mapActions('stats', ['setType', 'toggleView']),
    ...mapActions('meta', ['setLoading']),
    handleNavigation(memberId) {
      const params = {
        groupId: this.routeParams.groupId,
        memberId: parseInt(memberId, 10),
        questionnaireId: this.routeParams.questionnaireId,
        reflectionId: this.currentReflection.id
      }
      this.toggleView('list');
      this.setType('inTime');
      this.$router.push({ name: "GroupMemberStats", params }, this.handleOnComplete);
    },
    handleOnComplete(){
      this.setLoading(true);
    }
  }
};
</script>

<style lang="scss">
@import 'mixins';

.list-chart {
  width: 490px;
  padding-right: 12px;
  max-height: 422px;
  overflow-y: auto;
  margin-top: 20px;

  @include handleListChartScrollbar();

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__item {
      padding: 10px 15px;
      background-color: #fff;
      display: flex;
      align-items: center;
      .list-chart-member-index {
        min-width: 25px;
        font-size: 14px;
      }

      &:nth-of-type(2n) {
        background-color: $grey-1;
      }

      &__value,
      &__date,
      &__name {
        font-family: Roboto-Regular;
        font-size: 14px;
      }

      &__value {
        margin-left: auto;
        text-align: right;
        word-break: break-word;
      }

      &__name {
        margin-left: 10px;
        min-width: 40px;
        cursor: pointer;
        &:hover{
          font-weight: bold;
          text-decoration: underline;
          color: $green-4;
        }
      }

      &__color {
        margin-left: 20px;
        min-height: 16px;
        min-width: 16px;
        border-radius: 50%;
        background-color: '#AC92ED';
      }

      &__name-container {
        display: flex;
        align-items: center;
        width: auto;

        &__icon {
          min-height: 22px;
          min-width: 22px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $grey-2;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
