<template>
  <section id="editReflection" class="editReflection" v-if="!loading">
    <div class="editReflection__container" v-if="questionnaireFound">
      <create-reflection
        :type="'draft'"
        :editStatus="editStatus"
        :personal="true"
      ></create-reflection>
      <mobile v-if="selectedMethod"></mobile>
      <method-list v-else></method-list>
    </div>
    <div class="editReflection__container" v-else>
      <div class="editReflection__not-found">
        <svgicon
          class="editReflection__not-found__icon"
          name="broken-pen"
          :original="true"
        ></svgicon>
        <header-h2 class="editReflection__not-found__title">
          {{ $t('messages.template_not_found_message') }}
        </header-h2>
        <router-link class="editReflection__not-found__link" :to="{ name: 'CreateReflection' }">
          <button-4>{{ $t('actions.create_new_questionnaire_action') }}</button-4>
        </router-link>
      </div>
    </div>
    <navigation-modal v-show="questionnaireNavigation.modal"></navigation-modal>
  </section>
  <loading v-else></loading>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import CreateReflection from '@/components/reflections/CreateReflection.vue';
  import MethodList from '@/components/methods/MethodList.vue';
  import Mobile from '@/components/mobile/Mobile.vue';
  import Button4 from '@/components/elements/buttons/Button4.vue';
  import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
  import NavigationModal from '@/components/base/NavigationModal.vue';
  import Loading from '@/components/base/Loading.vue';

  export default {
    name: 'EditReflection',
    components: {
      CreateReflection,
      MethodList,
      Mobile,
      Button4,
      HeaderH2,
      NavigationModal,
      Loading,
    },
    data() {
      return {
        questionnaireFound: false,
        type: 'draft',
      };
    },
    created() {
      this.handlePageInit();
    },
    beforeDestroy() {
      this.handlePageDestroy();
    },
    computed: {
      ...mapGetters('user', ['user']),
      ...mapGetters('methods', ['selectedMethod', 'questionTypeList']),
      ...mapGetters('reflections', ['editStatus']),
      ...mapGetters('questionnaire', ['questionnaire', 'answerTemplate', 'questionExamples']),
      ...mapGetters('meta', ['questionnaireNavigation', 'loading']),
    },
    methods: {
      ...mapActions('reflections', ['setEditStatus']),
      ...mapActions('methods', ['getQuestionList', 'getMethodById']),
      ...mapActions('questionnaire', [
        'getAnswerTemplate',
        'getQuestionnaire',
        'setCurrentQuestion',
        'getQuestionnaireSchedule',
        'resetQuestionnaireState',
        'getQuestionExamples',
      ]),
      ...mapActions('meta', ['setLoading']),
      async handlePageInit() {
        this.setLoading(true);
        this.setEditStatus('edit');
        try {
          await this.handleGetMethodsAndAnswerTypes();
          await this.handleGetQuestionnaire();
        } catch (error) {
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      },
      async handleGetMethodsAndAnswerTypes() {
        if (!this.questionTypeList.length) await this.getQuestionList();
        if (!this.answerTemplate.length) await this.getAnswerTemplate();
        if (!this.questionExamples.length) await this.getQuestionExamples();
        return Promise.resolve();
      },
      async handleGetQuestionnaire() {
        const {
          params: { id },
        } = this.$route;
        const { data } = await this.getQuestionnaire({ questionnaireId: id });
        if (data.id) this.questionnaireFound = true;
        this.setCurrentQuestion(data.questions[data.questions.length - 1]);
        await this.getQuestionnaireSchedule(id);
        return Promise.resolve();
      },
      handlePageDestroy() {
        this.resetQuestionnaireState();
        this.setEditStatus('view');
      },
    },
  };
</script>
<style lang="scss">
  .editReflection {
    &__container {
      display: flex;
      max-width: 940px;
      margin: 20px auto 0 auto;
      justify-content: space-between;
    }

    &__not-found {
      margin: 60px auto 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__icon {
        height: 72px;
        width: 80px;
      }

      &__title {
        margin-top: 16px;
      }

      &__link {
        button {
          margin-top: 16px;
        }
      }
    }
  }
</style>
