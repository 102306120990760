<template>
  <div class="template__thermometer">
    <div v-for="(option, j) in options" :key="j">
      <div class="template__input-field">
        <svgicon
          v-if="!option.show || option.show === '#26619c'"
          class="template__color-toggle"
          name="methods/color-pick-blank"
          :original="true"
          width="36px"
          height="18px"
          @click="toggleColorPicker(j)"
        ></svgicon>
        <div
          class="template__color-show"
          v-if="option.show && option.show !== '#26619c'"
          @click="toggleColorPicker(j)"
        >
          <div
            class="template__color-show-color"
            :style="{ 'background-color': option.show }"
          ></div>
          <svgicon
            v-if="edit"
            class="template__color-show-icon"
            name="methods/pick-plus"
            :original="true"
          ></svgicon>
        </div>
        <auto-resize-input>
          <textarea
            :class="[
              'template__input-text',
              'template__input-text--color',
              { 'template__input-text--error': option.error },
            ]"
            rows="1"
            type="text"
            :placeholder='`- ${$t("actions.select_2_action")} -`'
            v-model="option.value"
            :title="option.value"
            @input="
              setQuestionPropertyHandler($event, { type: 'value', optionIndex: j, index: number })
            "
            :disabled="!edit"
            :maxlength="inputLength"
            ref="answerValueRef"
          />
        </auto-resize-input>
        <p class="max-input-error" v-if="maxReached[j]">{{ $t("warnings.max_char_count_reached_warning") }}</p>
      </div>
    </div>
    <div class="picker-modal" v-show="isVisible" v-if="edit" @click="toggleColorPicker(i)">
      <div class="picker-modal__bg">
        <div class="picker-modal__modal" @click.stop>
          <div class="picker-modal__container">
            <svgicon
              @click="toggleColorPicker"
              color="grey"
              :fill="false"
              name="multiply"
              class="picker-modal__close"
            ></svgicon>
            <sketch-color-picker v-model="colors[i]" @input="colorChange"></sketch-color-picker>
            <button-3
              class="picker-modal__action"
              :disabled="!options[i].show || options[i].show === '#26619c'"
              @click.native="toggleColorPicker"
            >
            {{ $t("actions.select_action") }}
            </button-3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Sketch } from 'vue-color';

import Button3 from '@/components/elements/buttons/Button3.vue';
import AutoResizeInput from '@/helpers/autoResizeInput';

export default {
  name: 'TemplateThermometer',
  components: {
    'sketch-color-picker': Sketch,
    Button3,
    AutoResizeInput
  },
  props: ['type', 'number', 'edit', 'questionExpanded'],
  data() {
    return {
      colors: ['#C1272D', '#E86100', '#FDB913', '#006A44', '#146EB4'],
      isVisible: false,
      i: 0,
      imageActive: null,
      inputLength: 120,
      maxReached: [false, false, false, false, false]
    };
  },
  created() {
    if (this.selectedMethod) {
      const { answerOptions: { defaults: { select: { options } }, defaults } } = this.selectedMethod;
      if (!defaults) return;
      this.colors = options.map((option) => option.show);
    }
  },
  watch: {
    questionExpanded(newVal){
      if(newVal === true){
        this.$refs.answerValueRef.forEach(element => {
          element.style.height = `auto`
          element.style.height = `${element.scrollHeight}px`
        });
      }
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['questions']),
    ...mapGetters('methods', ['selectedMethod']),
    question() {
      return this.questions[this.number];
    },
    options() {
      return this.question.answerOptions.options;
    },
  },
  methods: {
    ...mapActions('questionnaire', ['setQuestionProperty', 'setOptionError']),
    toggleColorPicker(i) {
      this.isVisible = !this.isVisible;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    colorChange(color) {
      this.options[this.i].show = color.hex;
    },
    setQuestionPropertyHandler($event, payload) {
      const { optionIndex } = payload;
      const { value } = $event.target;

      if(value.length === this.inputLength){
        this.maxReached[optionIndex] = true
      }else{
        this.maxReached[optionIndex] = false
      }

      if (!value) {
        this.setOptionError({
          questionIndex: this.number,
          optionIndex,
          error: this.$t('warnings.finish_typing')
        });
      } else {
        this.setOptionError({
          questionIndex: this.number,
          optionIndex,
          error: null,
        });
      }
      payload.property = value;
      this.setQuestionProperty(payload);
    },
  },
};
</script>
<style lang="scss">
@import 'mixins';
.template {
  &__thermometer {
    .template__input-field {
      position: relative;

      .template__color-toggle {
        top: 6px;
        position: absolute;

        &:hover {
          cursor: pointer;
        }
      }
      .template__input-text {
        height: initial;
        min-height: 30px;
        &--color {
          padding-left: 40px;
        }
      }
      .template__color-show{
        top: 0px;
      }
    }
  }

  &__color-show {
    display: flex;
    width: 36px;
    height: 18px;
    position: absolute;
    top: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &__color-show-color {
    width: 18px;
    height: 100%;
    border: 0.5px solid $violet-6;
    border-right: none;
  }

  &__color-show-icon {
    width: 18px;
    height: 100%;
  }
  .max-input-error{
    color: #bc0000;
    font-size: 14px;
  }
}

.image-modal {
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
  &__container {
    position: relative;
    padding: 20px;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    padding: 5px;
    margin: 5px;

    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      border: 1px solid $violet-6;
      transform: translateY(-3px) scale(1.02);
    }

    &--active {
      border: 1px solid $violet-6;
    }
  }

  &__actions {
    margin-top: 70px;
    display: flex;
    align-self: flex-start;

    button:last-of-type {
      margin-left: 10px;
    }
  }
}
</style>
