<template>
  <multiselect
    :id="id"
    :key="id"
    v-model="value"
    :options="options"
    :maxHeight="200"
    :searchable="false"
    :showLabels="false"
    :placeholder="placeholder"
    :style="{ width: `${width}px` || 'inherit' }"
    :allow-empty="false"
    track-by="value"
    label="value"
    @select="selectedValue"
    :disabled="disabled"
  >
    <template v-slot:caret>
      <svgicon
        class="arrow-icon"
        name="green-arrow"
        width="12px"
        height="12px"
        color="#BABABA"
        :stroke="true"
        :fill="false"
      ></svgicon>
    </template>
  </multiselect>
</template>

<script>
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'MultiSelect',
    components: {
      Multiselect,
    },
    props: ['options', 'placeholder', 'width', 'id', 'selected', 'disabled'],
    data() {
      return {
        value: this.selected || null,
      };
    },
    created() {
      if (this.selected) {
        this.value = this.selected;
      }
    },
    watch: {
      selected(newVal) {
        this.value = newVal;
      },
    },
    computed: {
      typeOfProperty() {
        return typeof this.options[0];
      },
    },
    methods: {
      selectedValue(value) {
        if (this.typeOfProperty !== 'object') {
          this.$emit('selectedValue', { value, id: this.id });
        } else {
          this.$emit('selectedValue', value);
        }
      },
    },
  };
</script>

<style lang="scss"></style>
