<template>
  <div class="line-template__container">
    <line-chart v-if="filteredQuestionInTime.length" :chart-data="dataSet" :options="chartOptions"></line-chart>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import chartOptions from './line-chart.options';

import LineChart from '@/components/stats/chart-templates/LineChart.vue';

export default {
  name: 'LineTemplate',
  components: {
    LineChart,
  },
  props: ['questionInTimeDates', 'filteredQuestionInTime', 'specificChartOptions'],
  computed: {
    ...mapGetters('stats', ['colors']),
    total() {
      return this.filteredQuestionInTime.map((question) => question.value).length;
    },
    chartOptions() {
      return this.specificChartOptions || chartOptions;
    },
    dataSet() {
      if (!this.filteredQuestionInTime.length) return false;
      return {
        labels: this.questionInTimeDates,
        datasets: this.filteredQuestionInTime.map((groupStat) => ({
          label: groupStat.value,
          data: groupStat.percentage,
          lineTension: 0.1,
          borderColor: groupStat.color,
          pointBackgroundColor: groupStat.color,
          backgroundColor: 'transparent',
        })),
      };
    },
  },
};
</script>

<style lang="scss">
.line-template {
  &__container {
    max-height: 500px;
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
  }
}
</style>
