<template>
  <div class="template__traffic-lights">
    <div class="template__input-field" v-for="(option, j) in options" :key="j">
      <svgicon
        v-if="!option.show && type !== 'img'"
        class="template__color-toggle"
        name="methods/color-pick-blank"
        :original="true"
        width="36px"
        height="18px"
        @click="toggleColorPicker(j)"
      ></svgicon>
      <div class="template__color-show" v-if="option.show && type === 'color'">
        <div
          class="template__color-show-color"
          :style="{ 'background-color': option.show }"
          @click="toggleColorPicker(j)"
        ></div>
      </div>
      <div class="template__color-show template__color-show--img" v-if="type === 'img'">
        <svgicon
          v-if="!option.show"
          class="template__color-toggle"
          name="methods/image-pick-blank"
          :original="true"
          width="36px"
          height="18px"
          @click="toggleImageModal(j)"
        ></svgicon>
        <img v-else class="template__color-show-color" :src="option.show" />
      </div>
      <input
        :class="[
          'template__input-text',
          'template__input-text--color',
          { 'template__input-text--error': option.error },
        ]"
        :disabled="!edit"
        type="text"
        v-model="option.value"
        :placeholder='option.error || $t("insert_value_placeholder")'
        @input="
          setQuestionPropertyHandler($event, { type: 'value', index: number, optionIndex: j })
        "
      />
    </div>
    <div class="picker-modal" v-show="isVisible" @click="toggleColorPicker(i)">
      <div class="picker-modal__bg">
        <div class="picker-modal__modal" @click.stop>
          <div class="picker-modal__container">
            <svgicon
              @click="toggleColorPicker"
              color="grey"
              :fill="false"
              name="multiply"
              class="picker-modal__close"
            ></svgicon>
            <sketch-color-picker v-model="colors[i]" @input="colorChange"></sketch-color-picker>
            <button-3 class="picker-modal__action" @click.native="toggleColorPicker">
            {{ $t("actions.select_action") }}
            </button-3>
          </div>
        </div>
      </div>
    </div>
    <question-image-picker
      :type="'question'"
      v-show="showImageModal"
      @click="toggleImageModal"
      @closeImageModal="toggleImageModal"
      @onImageSelect="onImageSelect"
      :key="`image--${images[i]}`"
      :label="`Image${images[i]}`"
      :selectedImages="selectedImages"
    ></question-image-picker>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Sketch } from 'vue-color';

import Button3 from '@/components/elements/buttons/Button3.vue';
import QuestionImagePicker from '@/components/reflections/question/QuestionImagePicker.vue';

export default {
  name: 'TemplateTrafficLights',
  components: {
    'sketch-color-picker': Sketch,
    QuestionImagePicker,
    Button3,
  },
  props: ['type', 'number', 'edit'],
  data() {
    return {
      colors: ['#C1272D', '#E86100', '#13A85B'],
      images: [null, null, null],
      isVisible: false,
      i: 0,
      imageActive: null,
      showImageModal: false,
      selectedImages: [],
    };
  },
  updated() {
    if (this.type === 'color') {
      this.options.forEach((option, i) => {
        if (option.show) {
          const isColor = option.show.split('')[0] === '#';
          if (isColor) return;
        }
        if (!option.show) {
          option.show = this.colors[i];
        }
      });
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['questions']),
    question() {
      return this.questions[this.number];
    },
    options() {
      return this.question.answerOptions.options;
    },
  },
  methods: {
    ...mapActions('questionnaire', ['setQuestionProperty', 'setOptionError']),
    toggleColorPicker(i) {
      if (!this.edit) return;
      this.isVisible = !this.isVisible;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    colorChange(color) {
      this.question.answerOptions.options[this.i].show = color.hex;
    },
    toggleImageModal(i) {
      this.showImageModal = !this.showImageModal;
      if (typeof i === 'number') {
        this.i = i;
      }
    },
    onImageSelect(image) {
      if (!this.edit) return;
      this.images[this.i] = image.url;
      this.options[this.i].show = image.url;
      if (this.selectedImages[this.i]) this.selectedImages.splice(this.i, 1, image);
      else this.selectedImages.push(image);
    },
    setQuestionPropertyHandler($event, payload) {
      const { optionIndex } = payload;
      const { value } = $event.target;
      if (!value) {
        this.setOptionError({
          questionIndex: this.number,
          optionIndex,
          error: this.$t('warnings.finish_typing')
        });
      } else this.setOptionError({ questionIndex: this.number, optionIndex, error: null });
      payload.property = value;
      this.setQuestionProperty(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';
.template {
  &__traffic-lights {
    .template__input-field {
      position: relative;

      .template__color-toggle {
        top: 6px;
        position: absolute;

        &:hover {
          cursor: pointer;
        }
      }
      .template__input-text {
        &--color {
          padding-left: 40px;
        }
      }

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }

  &__color-show {
    display: flex;
    width: 36px;
    height: 18px;
    position: absolute;
    top: 6px;

    &--img {
      top: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__color-show-color {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: none;
  }

  &__color-show-icon {
    width: 18px;
    height: 100%;
  }
}

.image-modal {
  &__bg {
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    @include hardBoxShadow();
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
  &__container {
    position: relative;
    padding: 20px;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__close {
    position: absolute;
    height: 10px;
    width: 10px;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    padding: 5px;
    margin: 5px;

    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      border: 1px solid $violet-6;
      transform: translateY(-3px) scale(1.02);
    }

    &--active {
      border: 1px solid $violet-6;
    }
  }

  &__actions {
    margin-top: 70px;
    display: flex;
    align-self: flex-start;

    button:last-of-type {
      margin-left: 10px;
    }
  }
}
</style>
