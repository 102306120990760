<template>
  <div :class="['popup-cart flex-global', { open: isOpen }]">
    <div :class="['popup-cart__lip flex-global', { open: isOpen }]" @click="toggleCart">
      <div class="count flex-global" v-if="!isOpen">
        <svgicon class="cart-icon" name="shopping-cart" width="23" height="23"></svgicon>
        <span>({{ cartItemCount }})</span>
      </div>
      <svgicon v-else class="chevron-icon" name="chevron-big" :original="true" width="8px" height="12px" :fill="true"
        color="#5147A7"></svgicon>
    </div>
    <div :class="['popup-cart__content', { open: isOpen }]">
      <div class="content__wrapper flex-global">
        <div class="content__header flex-global">
          <div class="title flex-global">
            <svgicon name="shopping-cart" width="20" height="20"></svgicon>
            <p>{{ $t('cart') }}</p>
          </div>
          <span @click="toggleCart">{{ $t('actions.close_action') }}</span>
        </div>
        <div v-if="!isCartEmpty" class="content__table">
          <div class="header-row flex-global">
            <span>{{ $t('profile.service') }}</span>
            <span>{{ $t('licences.licence_price') }}</span>
          </div>
          <div class="overflow-wrapper">
            <div v-for="item in cartItemsForDisplay" :key="item.id" class="item-row service">
              <div class="item-row__content flex-global">
                <div class="title">
                  <p>{{ item.title }}</p>
                  <p v-if="item.description" class="small">{{ item.description }}</p>
                </div>
                <div class="price">
                  <span>€</span>
                  {{ item.price }}
                </div>
              </div>
              <div class="item-row__actions">
                <span class="action" @click="removeCartItem(item.id)">
                  {{ $t('actions.remove_action') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="content__empty" v-else>
          {{ $t('warnings.your_cart_is_empty_message') }}
        </div>
        <div v-if="!isCartEmpty" class="content__total flex-global">
          <span class="label">{{ $t('orders.total') }}</span>
          <span class="pricetag">
            <span>€</span>
            {{ cartTotal() }}
          </span>
        </div>
        <div class="content__checkbox">
          <custom-checkbox :checked="agreement" :boxValue="'purchase-agreement-services'"
            @checkStatus="handleAgreement">
            <template #label>
              <span class="checkbox-label">
                {{ $t('licences.rule_agreement_label') }}
                <a href="https://reflectus.lt/pirkimo-taisykles/" target="_blank">
                  {{ $t('licences.rule_agreement_link_label') }}
                </a>
              </span>
            </template>
          </custom-checkbox>
        </div>
        <div class="content__buttons flex-global">
          <button-3 class="cancel" @click.native="handleCartCancel">
            {{ $t('actions.cancel_action') }}
          </button-3>
          <button-4 class="pay" :disabled="isPurchaseDisabled" @click.native="handlePaymentAction">
            {{ $t('actions.purchase_action') }}
          </button-4>
        </div>
      </div>
    </div>
    <global-modal v-if="cancelModal" @handleModalToggle="toggleCancelModal">
      <template v-slot:modal-header>{{ $t('warnings.are_you_sure_cancel_cart_message') }}</template>
      <template v-slot:modal-actions>
        <button-4 @click.native="toggleCancelModal">
          {{ $t('no_cap') }}
        </button-4>
        <button-4 @click.native="handleCancel">{{ $t('yes_cap') }}</button-4>
      </template>
    </global-modal>
  </div>
</template>
<script>
import Button3 from '@/components/elements/buttons/Button3.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import GlobalModal from '@/components/base/GlobalModal.vue';
import CustomCheckbox from '@/components/elements/CustomCheckbox.vue';

export default {
  name: 'PopupCart',
  props: ['cartItems', 'isLoading'],
  components: {
    Button3,
    Button4,
    GlobalModal,
    CustomCheckbox,
  },
  data() {
    return {
      isOpen: false,
      cancelModal: false,
      agreement: false,
    };
  },
  computed: {
    cartItemCount() {
      return this.cartItems ? this.cartItems.length : 0;
    },
    cartItemsForDisplay() {
      return this.cartItems && this.cartItems.length
        ? this.cartItems.map((item) => {
          return {
            id: item.id,
            title: item.title,
            description: item.description,
            price: item.price || item.priceBeforeDiscount,
          };
        })
        : [];
    },
    isCartEmpty() {
      return this.cartItemCount <= 0;
    },
    isPurchaseDisabled() {
      return this.isCartEmpty || this.isLoading || !this.agreement;
    },
  },
  methods: {
    toggleCart() {
      this.isOpen = !this.isOpen;
    },
    removeCartItem(id) {
      this.$emit('handleRemoveCartItem', id);
    },
    handlePaymentAction() {
      this.$emit('handlePayment');
    },
    handleCartCancel() {
      if (this.isCartEmpty) {
        this.isOpen = false;
      } else {
        this.toggleCancelModal();
      }
    },
    handleCancel() {
      this.toggleCart();
      this.toggleCancelModal();
      this.$emit('handleCartClear');
    },
    handleAgreement() {
      this.agreement = !this.agreement;
    },
    toggleCancelModal() {
      this.cancelModal = !this.cancelModal;
    },
    cartTotal() {
      let total = 0;
      if (this.cartItems && this.cartItems.length) {
        this.cartItems.forEach((item) => {
          const actualPrice = item.price || item.actualPrice;
          total += parseFloat(actualPrice);
        });
        return parseFloat(total).toFixed(2);
      }
      return total;
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-cart {
  position: fixed;
  right: 0;
  align-items: flex-start;
  z-index: 1;
  color: #000;

  &.open {
    right: -1px;
  }

  &__lip {
    position: relative;
    margin-top: 35px;
    padding: 13px 19px;
    width: 63px;
    height: 73px;
    border: 1px solid $grey-12;
    border-radius: 5px 0 0 5px;
    background: #fff;
    cursor: pointer;

    .count {
      flex-direction: column;
    }

    svg {
      &.cart-icon {
        margin-bottom: 3px;
      }
    }

    span {
      font-size: 14px;
    }

    &.open {
      right: -1px;
      border-right: 1px solid #fff;
    }
  }

  &__content {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    padding: 40px 30px;
    width: 338px;
    border: 1px solid $grey-12;
    background: #fff;

    &.open {
      position: static;
      visibility: visible;
      opacity: 1;
    }

    .content {
      &__wrapper {
        flex-direction: column;
        width: 100%;
      }

      &__header {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;

        .title {
          p {
            font-family: 'Open Sans SemiBold';
            margin-left: 8px;
            font-size: 16px;
          }
        }

        span {
          font-family: 'Open Sans Regular';
          font-size: 12px;
          text-decoration: underline;
          color: #5f5f5f;
          cursor: pointer;

          &:hover {
            color: $grey-3;
          }
        }
      }

      &__table {
        width: 100%;
        margin-bottom: 27px;

        .header-row {
          justify-content: space-between;
          border-bottom: 1px solid #e5e5e5;

          span {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 300;
            color: #5f5f5f;
          }
        }

        .overflow-wrapper {
          height: 238px;
          overflow-y: auto;
        }

        .item-row {
          padding: 10px 6px 20px 0;
          border-bottom: 1px dashed #cccccc;

          &__content {
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 10px;

            .title {
              width: 207px;
              font-size: 14px;
              font-weight: 600;

              .small {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                width: 215px;
                height: 37px;
                text-transform: lowercase;
                font-size: 14px;
                font-weight: 400;
                color: #5a5a5a;
              }
            }

            .price {
              font-size: 14px;
              font-weight: 600;

              .span {
                margin-right: 4px;
              }
            }
          }

          &__actions {
            .action {
              font-size: 14px;
              font-weight: 700;
              text-decoration-line: underline;
              cursor: pointer;
              color: $blue-5;
            }
          }
        }
      }

      &__empty {
        margin-top: 20px;
        margin-bottom: 80px;
        color: #5a5a5a;
      }

      &__total {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 36px;

        span {
          font-size: 16px;
          font-weight: 600;

          &.label {
            color: $grey-9;
          }
        }
      }

      &__checkbox {
        width: 100%;
        margin-bottom: 26px;

        .checkbox-label {
          top: -3px;
          width: 250px;
          font-size: 14px;
        }
      }

      &__buttons {
        width: 100%;
        flex-direction: flex-end;

        button {
          height: 39px;

          &.cancel {
            height: 39px;
            border: none;

            &:hover {
              background-color: #fff;
              color: $violet-5;
            }
          }

          &.pay {
            height: 39px;
            width: 156px;
            margin-left: 18px;
            background-color: $violet-3;

            &:hover {
              background-color: $violet-5;
            }

            &:disabled {
              background-color: $grey-2;
            }
          }
        }
      }
    }
  }

  @media screen and (max-height: 745px) {
    top: 65px;
  }
}
</style>
