<template>
  <div class="login__page">
    <section class="authentication">
      <div class="registration-wrapper flex-global">
        <header-h1>{{ $t('register_page.registration') }}</header-h1>
        <registration-type-switch @handleTypeSwitch="handleTypeSwitch" />
        <div class="registration-form-wrapper">
          <p class="registration-form-wrapper__header">{{ formHeaderTitle }}</p>
          <div v-if="isOrganization" class="organization-form">
            <div class="info__row">
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.org_name') }}</span>
                <input class="info__field__input" type="text" v-model="userRegisterFormMain.organization"
                  @blur="$v.userRegisterFormMain.organization.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.organization.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.organization.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.organization.required">
                    {{ $t('warnings.org_name_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.organization.maxLength">
                    {{ $t('warnings.org_name_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.organization.minLength">
                    {{ $t('warnings.org_name_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.organization.alpha">
                    {{ $t('warnings.org_name_no_symbols_warning') }}
                  </span>
                </div>
              </div>
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.org_code') }}</span>
                <input class="info__field__input" type="text" v-model="userRegisterFormMain.orgCode"
                  @blur="$v.userRegisterFormMain.orgCode.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.orgCode.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.orgCode.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgCode.required">
                    {{ $t('warnings.org_code_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgCode.maxLength">
                    {{ $t('warnings.org_code_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgCode.numeric">
                    {{ $t('warnings.org_code_numeric_warning') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="info__row">
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.email_address') }}</span>
                <input class="info__field__input" type="text" v-model="userRegisterFormMain.orgEmail"
                  @blur="$v.userRegisterFormMain.orgEmail.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.orgEmail.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.orgEmail.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgEmail.required">
                    {{ $t('warnings.email_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgEmail.email">
                    {{ $t('warnings.invalid_email') }}
                  </span>
                </div>
              </div>
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.org_phone') }}</span>
                <input class="info__field__input" type="text" v-model="userRegisterFormMain.orgPhone"
                  @blur="$v.userRegisterFormMain.orgPhone.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.orgPhone.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.orgPhone.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgPhone.required">
                    {{ $t('warnings.phone_is_required') }}
                  </span>

                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.orgPhone.phone">
                    {{ $t('warnings.invalid_phone') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="teacher-info-box">
              <p>{{ $t('register_page.teacher_credentials') }}</p>
              <p>{{ $t('register_page.organization_help_text_one') }}</p>
              <p>{{ $t('register_page.organization_help_text_two') }}</p>
              <p>{{ $t('register_page.organization_help_text_three') }}</p>

              <div class="privacy-toggle__field registration-toggle">
                <toggle :checked="isTeacherData" :boxValue="'isTeacherData'" @checkStatus="checkedValues"></toggle>
                <span class="privacy-toggle__field__text">
                  {{ $t('register_page.teacher_credentials_available') }}
                </span>
              </div>
            </div>
          </div>
          <div class="teacher-form">
            <div class="info__row" v-if="!isTeacherData">
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.first_name') }}</span>
                <input class="info__field__input" type="text" v-model="userRegisterFormMain.name"
                  @blur="$v.userRegisterFormMain.name.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.name.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.name.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.name.required">
                    {{ $t('warnings.first_name_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.name.maxLength">
                    {{ $t('warnings.first_name_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.name.minLength">
                    {{ $t('warnings.first_name_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.name.alpha">
                    {{ $t('warnings.first_name_no_symbols_warning') }}
                  </span>
                </div>
              </div>
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.last_name') }}</span>
                <input class="info__field__input" type="text" v-model="userRegisterFormMain.lastName"
                  @blur="$v.userRegisterFormMain.lastName.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.lastName.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.lastName.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.lastName.required">
                    {{ $t('warnings.last_name_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.lastName.maxLength">
                    {{ $t('warnings.last_name_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.lastName.minLength">
                    {{ $t('warnings.last_name_length_warning') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.lastName.alpha">
                    {{ $t('warnings.last_name_no_symbols_warning') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="info__row">
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.email_address') }}</span>
                <input class="info__field__input" type="email" v-model="userRegisterFormMain.email"
                  @blur="$v.userRegisterFormMain.email.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.email.$error || error" name="warning" :original="true"
                  width="16px" height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.email.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.email.required">
                    {{ $t('warnings.email_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.email.email">
                    {{ $t('warnings.invalid_email') }}
                  </span>
                </div>
                <div class="info__error" v-if="error">
                  <span class="info__error__message">{{ error }}</span>
                  <router-link tag="span" :to="{ name: 'EmailResend', params: { email: resendEmail } }"
                    class="info__error__resend" v-if="resendEmail">
                    {{ $t('warnings.resend_email') }}
                  </router-link>
                </div>
              </div>
              <div class="info__field" v-if="isTeacherData">
                <span class="uppercase-title">{{ $t('inputs.password') }}</span>
                <input class="info__field__input" type="password" v-model="userRegisterFormMain.standalonePassword"
                  @blur="$v.userRegisterFormMain.standalonePassword.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.standalonePassword.$error" name="warning" :original="true"
                  width="16px" height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.standalonePassword.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.standalonePassword.required">
                    {{ $t('warnings.password_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.standalonePassword.minLength">
                    {{ $t('warnings.password_length_warning') }}
                  </span>
                </div>
              </div>
              <div class="info__field" v-if="!isTeacherData">
                <span class="uppercase-title">{{ $t('inputs.mobile_number') }}</span>
                <input class="info__field__input phone-number-input-registration" type="number"
                  v-model="userRegisterFormMain.phone" @blur="$v.userRegisterFormMain.phone.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.phone.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.phone.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.phone.required">
                    {{ $t('warnings.phone_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.phone.phone">
                    {{ $t('warnings.invalid_phone') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="info__row" v-if="!isTeacherData">
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.password') }}</span>
                <input class="info__field__input" type="password" v-model="userRegisterFormMain.password"
                  @blur="$v.userRegisterFormMain.password.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.password.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.password.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.password.required">
                    {{ $t('warnings.password_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.password.minLength">
                    {{ $t('warnings.password_length_warning') }}
                  </span>
                </div>
              </div>
              <div class="info__field">
                <span class="uppercase-title">{{ $t('inputs.password_repeat') }}</span>
                <input class="info__field__input" type="password" v-model="userRegisterFormMain.password2"
                  @blur="$v.userRegisterFormMain.password2.$touch()" />
                <svgicon v-if="$v.userRegisterFormMain.password2.$error" name="warning" :original="true" width="16px"
                  height="16px"></svgicon>
                <div class="info__error" v-if="$v.userRegisterFormMain.password2.$error">
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.password2.required">
                    {{ $t('warnings.password_repeat_is_required') }}
                  </span>
                  <span class="info__error__message" v-if="!$v.userRegisterFormMain.password2.sameAs">
                    {{ $t('warnings.password_mismatch') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isTeacherData" class="registration-privacy-toggle">
            <div class="privacy-toggle__field registration-toggle">
              <toggle :checked="privacyAgreed" :boxValue="'acceptPrivacy'" @checkStatus="checkedValues"></toggle>
              <span class="privacy-toggle__field__text">
                {{ $t('i_agree') }}
                <router-link class="privacy-toggle__field__link" :to="{ name: 'Terms' }">
                  {{ $t('terms_and_conditions') }}
                </router-link>
              </span>
              <div class="privacy-toggle__field__error" v-if="privacyError">
                {{ $t('warnings.terms_agreement_warning') }}
              </div>
            </div>
            <div class="privacy-toggle__field registration-toggle">
              <toggle :checked="acceptNewsletter" :boxValue="'acceptNewsletter'" @checkStatus="checkedValues"></toggle>
              <span class="privacy-toggle__field__text">{{ $t('email_news') }}</span>
            </div>
          </div>
        </div>
        <div class="registration-actions flex-global">
          <button-4 @click.native="handleUserRegistration">
            {{ $t('actions.register_action') }}
          </button-4>
          <router-link tag="a" :to="{ name: 'Login' }">
            <span>{{ $t('actions.login_action') }}</span>
          </router-link>
        </div>
        <modal v-show="isModalVisible" @close="toggleModal" :register="registerModal"></modal>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric,
  email,
  sameAs,
} from 'vuelidate/lib/validators';
import { USER_TYPE_TEACHER, USER_TYPE_ORG } from '@/helpers/constants/userTypes';
import HeaderH1 from '@/components/elements/typography/HeaderH1.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import Modal from '@/components/base/Modal.vue';
import Toggle from '@/components/elements/Toggle.vue';
import RegistrationTypeSwitch from '@/components/registration/RegistrationTypeSwitch.vue';

export default {
  name: 'Registration',
  components: {
    HeaderH1,
    Button4,
    Modal,
    Toggle,
    RegistrationTypeSwitch,
  },
  data() {
    return {
      currentUserType: USER_TYPE_TEACHER,
      privacyError: null,
      acceptNewsletter: false,
      isModalVisible: false,
      registerModal: true,
      resendEmail: null,
    };
  },
  validations: {
    userRegisterFormMain: {
      name: {
        required: requiredIf((form) => {
          return (
            form.currentUserType === USER_TYPE_TEACHER ||
            (form.currentUserType === USER_TYPE_ORG && form.isTeacherData === false)
          );
        }),
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      lastName: {
        required: requiredIf((form) => {
          return (
            form.currentUserType === USER_TYPE_TEACHER ||
            (form.currentUserType === USER_TYPE_ORG && form.isTeacherData === false)
          );
        }),
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      email: {
        required,
        email,
      },
      phone: {
        required: requiredIf((form) => {
          return (
            form.currentUserType === USER_TYPE_TEACHER ||
            (form.currentUserType === USER_TYPE_ORG && form.isTeacherData === false)
          );
        }),
        numeric,
        minLength: minLength(9),
      },
      organization: {
        required: requiredIf((form) => {
          return form.currentUserType === USER_TYPE_ORG;
        }),
        minLength: minLength(2),
        maxLength: maxLength(120),
      },
      orgCode: {
        required: requiredIf((form) => {
          return form.currentUserType === USER_TYPE_ORG;
        }),
        numeric,
        maxLength: maxLength(9),
      },
      orgEmail: {
        required: requiredIf((form) => {
          return form.currentUserType === USER_TYPE_ORG;
        }),
        email,
      },
      orgPhone: {
        required: requiredIf((form) => {
          return form.currentUserType === USER_TYPE_ORG;
        }),
        numeric,
        minLength: minLength(9),
      },
      password: {
        required: requiredIf((form) => {
          return (
            form.currentUserType === USER_TYPE_TEACHER ||
            (form.currentUserType === USER_TYPE_ORG && form.isTeacherData === false)
          );
        }),
        minLength: minLength(8),
        maxLength: maxLength(32),
      },
      password2: {
        required: requiredIf((form) => {
          return (
            form.currentUserType === USER_TYPE_TEACHER ||
            (form.currentUserType === USER_TYPE_ORG && form.isTeacherData === false)
          );
        }),
        sameAs: sameAs('password'),
      },
      standalonePassword: {
        required: requiredIf((form) => {
          return form.currentUserType === USER_TYPE_ORG && form.isTeacherData === true;
        }),
        minLength: minLength(8),
        maxLength: maxLength(32),
      },
    },
  },
  created() {
    this.userRegisterFormMain.termsAgreed = this.privacyAgreed;
    this.setRegistrationUserType(USER_TYPE_TEACHER);
  },
  computed: {
    ...mapGetters('user', ['error', 'privacyAgreed', 'userRegisterFormMain', 'isTeacherData']),
    formHeaderTitle() {
      return this.currentUserType === USER_TYPE_TEACHER
        ? this.$t('register_page.teacher_credentials')
        : this.$t('register_page.organization_credentials');
    },
    registerIsValid() {
      return !this.$v.userRegisterFormMain.$invalid && this.privacyAgreed;
    },
    orgRegisterIsValid() {
      return !this.$v.userRegisterFormMain.$invalid;
    },
    isOrganization() {
      return this.currentUserType === USER_TYPE_ORG;
    },
  },
  methods: {
    ...mapActions('user', [
      'login',
      'register',
      'togglePrivacy',
      'toggleLoginMode',
      'setError',
      'setRegistrationUserType',
      'setIsTeacherData',
      'login',
    ]),
    clearPasswordFields() {
      this.userRegisterFormMain.password = null;
      this.userRegisterFormMain.password2 = null;
      this.userRegisterFormMain.standalonePassword = null;
    },
    handleTypeSwitch(value) {
      this.currentUserType = value;
      this.setRegistrationUserType(value);
      this.setIsTeacherData(false);
      if (value === USER_TYPE_ORG) {
        this.clearPasswordFields();
      }
      this.$v.userRegisterFormMain.$reset();
    },
    registerUser() {
      this.$v.userRegisterFormMain.$touch();
      if (!this.privacyAgreed) {
        this.privacyError = true;
      } else {
        this.privacyError = false;
      }

      if (this.registerIsValid && !this.privacyError) {
        const formData = {
          ...(!this.userRegisterFormMain.isTeacherData && {
            name: this.userRegisterFormMain.name,
          }),
          ...(!this.userRegisterFormMain.isTeacherData && {
            lastName: this.userRegisterFormMain.lastName,
          }),
          email: this.userRegisterFormMain.email,
          ...(!this.userRegisterFormMain.isTeacherData && {
            phone: this.userRegisterFormMain.phone,
          }),
          password:
            this.userRegisterFormMain.currentUserType === USER_TYPE_TEACHER ||
              (this.userRegisterFormMain.currentUserType === USER_TYPE_ORG &&
                !this.userRegisterFormMain.isTeacherData)
              ? this.userRegisterFormMain.password
              : this.userRegisterFormMain.standalonePassword,
          termsAgreed: this.userRegisterFormMain.termsAgreed,
          acceptNewsletter: this.userRegisterFormMain.acceptNewsletter,
          role: USER_TYPE_TEACHER,
          ...(this.userRegisterFormMain.currentUserType === USER_TYPE_ORG && {
            organizationObject: {
              title: this.userRegisterFormMain.organization,
              code: this.userRegisterFormMain.orgCode,
              email: this.userRegisterFormMain.orgEmail,
              phone: this.userRegisterFormMain.orgPhone,
            },
          }),
        };

        this.register(formData).then((res) => {
          if (res.success) {
            this.registerModal = true;
            this.toggleModal();
          }
        });
      }
    },
    handleOrganizationLogin() {
      this.$v.userRegisterFormMain.$touch();

      if (this.orgRegisterIsValid) {
        const formData = {
          email: this.userRegisterFormMain.email,
          password: this.userRegisterFormMain.standalonePassword,
          isFromRegistration: true,
          organizationObject: {
            title: this.userRegisterFormMain.organization,
            code: this.userRegisterFormMain.orgCode,
            email: this.userRegisterFormMain.orgEmail,
            phone: this.userRegisterFormMain.orgPhone,
          },
        };

        this.login(formData).then((res) => {
          const { success, data } = res;
          if (!success && data.emailConfirmed === false) {
            const { email } = this.userRegisterFormMain;
            if (email) this.resendEmail = email;
          }

          if (success && data && data.error === 'license_expired') {
            this.$router.push({ name: 'ProfileLicence' });
          }
        });
      }
    },
    handleUserRegistration() {
      if (this.isOrganization && this.isTeacherData) {
        this.handleOrganizationLogin();
      } else {
        this.registerUser();
      }
    },
    checkedValues(value) {
      if (value.value === 'acceptPrivacy') {
        if (!value.checked) {
          if (this.isTeacherData) {
            this.privacyError = false;
          } else {
            this.privacyError = true;
          }

          this.togglePrivacy(false);
        } else {
          this.privacyError = false;
          this.togglePrivacy(true);
        }
        this.userRegisterFormMain.termsAgreed = value.checked;
      } else if (value.value === 'isTeacherData') {
        this.setIsTeacherData(value.checked);
        this.clearPasswordFields();
        this.$v.userRegisterFormMain.$reset();
      } else this.userRegisterFormMain.acceptNewsletter = value.checked;
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.registration-wrapper {
  font-family: Nunito-Regular;
  flex-direction: column;
  width: 100%;
  padding: 48px 122px;
  border-radius: 2px;
  background-color: #fff;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .registration-form-wrapper {
    margin-top: 40px;
    width: 100%;

    &__header {
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 600;
      color: $grey-5;
    }

    .teacher-form,
    .organization-form {
      margin-bottom: 24px;
    }

    .organization-form {
      .teacher-info-box {
        margin-top: 24px;

        p {
          &:first-of-type {
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 600;
            color: $grey-9;
          }

          &:not(:first-of-type) {
            font-size: 14px;
            font-weight: 400;
            color: #8e8e8e;
          }
        }

        .privacy-toggle {
          &__field {
            margin-top: 20px;
            margin-left: 0;

            &__text {
              font-size: 14px;
              font-weight: 600;
              color: $grey-9;
            }
          }
        }
      }
    }

    .info {
      &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      &__field {
        position: relative;
        display: flex;
        flex-direction: column;

        &__input {
          width: 315px;
          margin-top: 6px;
          min-height: 33px;
          border: 1px solid $grey-3;
          border-radius: 2px;
          padding: 0 10px;
          font-family: Roboto-Regular;
          font-size: 14px;
          color: $grey-5;

          &:hover,
          &:active {
            border: 1px solid $grey-2;
          }

          &::placeholder {
            color: $grey-2;
          }

          &[type='password'] {
            font-family: Roboto-Regular;
            font-size: 22px;
            color: $green-3;
            letter-spacing: 8px;
          }

          &.phone-number-input-registration {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        svg {
          position: absolute;
          right: 6px;
          top: 28px;
          transform: rotatte(180deg);
        }

        h3 {
          color: red;
        }
      }

      &__error {
        display: flex;
        flex-direction: column;
        max-width: 260px;

        &__message {
          margin-top: 3px;
          font-family: Roboto-Regular;
          font-size: 12px;
          color: $red-4;
          text-transform: initial;
        }

        &__resend {
          font-family: Roboto-Regular;
          text-decoration: underline;
          font-size: 12px;
          opacity: 0.9;
          color: #3b328b;
          text-transform: initial;
          cursor: pointer;
          margin-top: 6px;
        }
      }
    }

    .registration-privacy-toggle {
      .privacy-toggle {
        &__field {
          &__text {
            font-size: 12px;
            font-weight: 400;
            color: $grey-3;

            a {
              color: $green-3;
            }
          }

          &:last-of-type {
            margin-left: 0;
            margin-top: 18px;
          }
        }
      }
    }
  }

  .registration-actions {
    flex-direction: column;
    margin-top: 40px;

    button {
      width: 202px;
      height: 40px;
      font-size: 14px;
      margin-bottom: 24px;
      background: $violet-3;
    }

    a {
      font-size: 14px;
      font-weight: 600;
      color: $violet-3;
    }
  }
}
</style>
