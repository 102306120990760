<template>
  <section id="group" class="group" v-if="!loading">
    <group-upper-header :currentGroup="currentGroup" />
    <group-list-item :group="currentGroup" :isFromList="false" />
    <group-header :currentGroup="currentGroup"></group-header>
    <div class="group__reflections">
      <group-member
        v-for="(member, index) in sortedMembers"
        :key="index"
        :member="member"
        :group="currentGroup"
        :index="index + 1"
      ></group-member>
    </div>
  </section>
  <section class="group" v-else-if="!noGroup">
    <not-found text="Grupė nerasta" routeName="MyGroups"></not-found>
  </section>
  <loading v-else></loading>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import { handleGetGroup, handleGetMembers } from '@/helpers/groups.helpers';

import GroupHeader from '@/components/groups/GroupHeader.vue';
import GroupUpperHeader from '@/components/groups/GroupUpperHeader.vue';
import GroupMember from '@/components/groups/GroupMember.vue';
import GroupListItem from '@/components/groups/GroupListItem.vue';
import Loading from '@/components/base/Loading.vue';
import NotFound from '@/components/base/NotFound.vue';

export default {
  name: 'GroupMembers',
  components: {
    GroupHeader,
    GroupUpperHeader,
    GroupMember,
    GroupListItem,
    Loading,
    NotFound,
  },
  created() {
    this.handlePageInit();
  },
  computed: {
    ...mapGetters('groups', ['membersList', 'currentGroup']),
    ...mapGetters('members', ['members']),
    ...mapGetters('meta', ['loading']),
    noGroup() {
      return !!this.currentGroup;
    },
    sortedMembers() {
      // return this.members.slice().sort((a, b) => a.name.localeCompare(b.name));
      return this.members;
    },
  },
  methods: {
    ...mapActions('members', ['getMembers']),
    ...mapActions('groups', ['getGroup']),
    ...mapActions('meta', ['setLoading']),
    handleGetGroup,
    handleGetMembers,
    async handlePageInit() {
      this.setLoading(true);

      try {
        await this.handleGetGroup();
        await this.handleGetMembers();
      } catch (error) {
        this.setLoading(false);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.group {
  max-width: 940px;
  margin: 20px auto 0 auto;
  position: relative;
  // display: flex;
  // flex-direction: column;
  align-items: center;
  width: 100%;

  &__total {
    display: flex;
    span {
      padding-left: 20px;
      font-family: Nunito-SemiBold;
      font-size: 13px;
      color: $grey-5;
      text-align: left;
    }
  }
  &__reflections {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 15px;
  }
}
</style>
