<template>
  <div class="method-answer__container">
    <div
      :class="['method-answer', { 'method-answer--selected': option.value === memberAnswer }]"
      v-for="(option, key) in options"
      :key="key"
    >
      <template-color :option="option" v-if="option.show"></template-color>
      <template-text :option="option" v-else></template-text>
    </div>
  </div>
</template>

<script>
import TemplateText from '@/components/groups/member/answer-templates/TemplateText.vue';
import TemplateColor from '@/components/groups/member/answer-templates/TemplateColor.vue';

export default {
  name: 'TemplateThermometer',
  props: ['question', 'memberAnswer'],
  components: {
    TemplateText,
    TemplateColor,
  },
  computed: {
    options() {
      const {
        answerOptions: { options },
      } = this.question;
      const addition = [
        {
          show: null,
          value: this.$t("member_did_not_reflect"),
        },
        {
          show: null,
          value: this.$t("member_skipped_alt"),
        },
      ];
      return [...options, ...addition];
    },
  },
};
</script>
