<template>
  <div class="welcome__container" v-if="!noReflections && !loading">
    <div class="welcome__logo">
      <img :src="logoGif" alt="logo animation" />
    </div>
    <div class="welcome__title">
      <header-h2>{{ $t("reflectus_welcome_message") }}</header-h2>
      <p>{{ $t("reflectus_welcome_message_content") }}</p>
    </div>
    <div class="welcome__actions">
        <button-4 @click.native="handleLessons">{{ $t("tutorials") }}</button-4>
    </div>
  </div>
  <div class="welcome__container welcome__container--none" v-else-if="!loading">
    <div class="welcome__logo">
      <img :src="logoGif" alt="logo animation" />
    </div>
    <div class="welcome__title">
      <header-h2>{{ $t("reflection.no_active_reflections_this_week_message") }}</header-h2>
    </div>
  </div>
  <div class="welcome__container welcome__container--none" v-else>
    <div class="welcome__logo">
      <img :src="loadingGif" alt="logo animation" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import HeaderH2 from '@/components/elements/typography/HeaderH2.vue';
import Button4 from '@/components/elements/buttons/Button4.vue';
import logoGif from '@/assets/gif/animation3-3sec.gif';
import loadingGif from '@/assets/gif/loading.gif';

export default {
  name: 'Welcome',
  components: {
    HeaderH2,
    Button4,
  },
  props: ['noReflections', 'loading'],
  data() {
    return {
      logoGif,
      loadingGif,
    };
  },
  methods: {
    ...mapActions('user', ['setWelcome']),
    handleLessons() {
      this.setWelcome(false);
      this.$router.push({ name: 'Lessons' });
    },
  },
};
</script>
<style lang="scss">
.welcome__container {
  width: 320px;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .welcome__title {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    h2 {
      font-size: 24px;
      color: $violet-5;
      margin-top: 40px;
    }

    p {
      font-family: Roboto-Regular;
      font-size: 14px;
      color: $grey-5;
      margin-top: 15px;
    }
  }
  &--none {
    width: 380px;
  }

  .welcome__actions {
    margin-top: 20px;
    button {
      width: 180px;
      padding: 5px 10px;
    }
  }

  .welcome__logo {
    height: 80px;
    width: 160px;

    img {
      width: 100%;
    }
  }
}
</style>
