<template>
  <div class="reflection-list__item">
    <div class="item__number">
      <span>{{ number }}.</span>
    </div>
    <div class="item__details">
      <div class="item__date">
        <span>{{ moment(reflection.start).format('LLL') }}</span>
        <input
          class="item__input"
          type="text"
          :placeholder='$t("reflection.reflection_theme_placeholder")'
          v-model="reflection.name"
          v-debounce:1000="handleReflectionName"
        />
      </div>
      <span>
        <svgicon :name="reflectionStatus" :original="true" height="1em"></svgicon>
      </span>
    </div>
    <div @click="handleNavigation" class="item__action smaller-padding">
      <svgicon name="stats" :original="true" height="21px" width="15px"></svgicon>
      <span>{{ $t("member_statistics.member_statistics") }}</span>
    </div>
    <div @click="handleRefDelete" class="item__action reflection-delete-action">
      <svgicon name="bin" :original="true" height="21px" width="18px" :fill="false" color="#3B328B"></svgicon>
      <span>{{ $t("actions.delete_reflection_action") }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'MemberReflectionDate',
  props: ['reflection', 'number'],
  data() {
    return {
      reflectionValue: null,
    };
  },
  created() {
    this.reflectionValue = this.reflection.title || null;
  },
  computed: {
    reflectionStatus() {
      const { answered, status } = this.reflection;
      let response = null;
      switch (answered) {
        case 0:
          if (status === 'closed') response = 'check-no';
          if (status === 'pending' || status === 'ongoing') response = 'clock-green';
          break;
        case 1:
          response = 'check-yes';
          break;
        default:
          break;
      }
      return response;
    },
  },
  methods: {
    ...mapActions('reflections', ['updateReflection', 'setCurrentReflection']),
    ...mapActions('stats', ['setType']),
    handleNavigation() {
      const { groupId, memberId } = this.$route.params;
      const { questionnaireId, id } = this.reflection;
      this.$router.push({
        name: 'GroupMemberStats',
        params: { groupId, memberId, questionnaireId, reflectionId: id },
      }, this.handleOnComplete);
    },
    handleReflectionName() {
      const { id, name } = this.reflection;
      const payload = {
        id,
        name,
      };
      this.updateReflection(payload);
    },
    handleOnComplete(){
      this.setCurrentReflection(this.reflection);
      this.setType('group');
    },
    handleRefDelete(){
      this.$emit('handleDeleteModal', this.reflection.id);
    }
  },
};
</script>
