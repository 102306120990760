<template>
  <div class="reflection-list__item">
    <div class="item__number">
      <span><slot name="index" /></span>
    </div>
    <div class="item__details">
      <span class="item__date">
        {{ moment(reflection.start).format('LLL') }}
        <input
          class="item__input"
          type="text"
          :placeholder="$t('reflection.reflection_theme_placeholder')"
          v-model="reflection.name"
          v-debounce:1000="handleReflectionName"
        />
      </span>
      <span class="item__status">
        {{ $t('group.group_member_reflected') }}
        <strong>
          {{ reflection.activeMembersCount }}
          <slot name="status" />
        </strong>
        ({{ membersCompleted.toFixed(0) }}%)
      </span>
      <span class="item__completion">
        <span v-if="reflection.status === 'ongoing'">
          <svgicon
            name="clock"
            :original="true"
            height="1em"
            :fill="false"
            color="#13A85B"
          ></svgicon>
          {{ moment(reflection.end).format('YYYY MM DD, HH:mm') }}
        </span>
      </span>
    </div>
    <div class="item__action">
      <span @click="handleSendReflectionAgain">
        <svgicon
          name="send-again"
          class="send-again-icon"
          :fill="false"
          :original="true"
          height="25px"
        ></svgicon>
        {{ $t('group.group_reflection_send_again') }}
      </span>
    </div>
    <div class="item__action">
      <span @click="handleStatNavigation">
        <svgicon name="stats" class="margin-bottom-icon" :original="true" height="21px"></svgicon>
        {{ $t('group_statistics.group_statistics') }}
      </span>
    </div>
    <div class="item__action">
      <span @click="handleRefDelete">
        <svgicon
          name="bin"
          class="margin-bottom-icon"
          :original="true"
          height="21px"
          :fill="false"
          color="#3B328B"
        ></svgicon>
        {{ $t('actions.delete_reflection_action') }}
      </span>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'GroupReflectionDate',
    props: ['reflection'],
    computed: {
      membersCompleted() {
        const { activeMembersCount, membersCount } = this.reflection;
        const percentage = (activeMembersCount * 100) / membersCount;
        return percentage || 0;
      },
      // reflectionIsToday() {
      //   return this.moment(this.reflection.start).isSame(this.moment(), 'day');
      // },
    },
    methods: {
      ...mapActions('reflections', ['updateReflection', 'setCurrentSelectedReflection']),
      ...mapActions('stats', ['setType']),
      handleReflectionName() {
        const { id, name } = this.reflection;
        const payload = {
          id,
          name,
        };
        this.updateReflection(payload);
      },
      handleStatNavigation() {
        const params = {
          groupId: this.reflection.clientGroupId,
          reflectionId: this.reflection.id,
          questionnaireId: this.reflection.questionnaireId,
        };
        this.setType('group');
        this.$router.push({ name: 'GroupReflectionStats', params });
      },
      handleRefDelete() {
        this.$emit('handleDeleteModal', this.reflection.id);
      },
      handleSendReflectionAgain() {
        this.setCurrentSelectedReflection(this.reflection);
        this.$emit('handleSendReflectionModal');
      },
    },
  };
</script>
<style lang="scss">
  .reflection-list {
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;

      &:not(:first-of-type) {
        margin-top: 10px;
      }

      .item {
        color: $grey-5;
        height: 75px;

        &__details {
          width: 780px;
          justify-content: space-between;
        }

        &__details,
        &__action {
          padding: 10px;
          display: flex;
          align-items: center;
          background-color: #fff;
          height: 100%;
          text-align: center;
          &.smaller-padding {
            padding: 20px 15px;
          }
        }

        &__action {
          width: 157px;
          margin-left: 5px;
          span {
            font-family: Roboto-Medium;
            font-size: 12px;
            color: $violet-5;
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            &:hover {
              cursor: pointer;
              color: $violet-4;
            }
            svg {
              &.margin-bottom-icon {
                margin-bottom: 7px;
              }
              // margin-right: 5px;
            }
          }
          &.reflection-delete-action {
            padding: 20px 10px;
            width: 160px;
          }
          .send-again-icon {
            stroke: none;
          }
        }

        &__number {
          height: 75px;
          background-color: #fff;
          display: flex;
          span {
            font-size: 16px;
            font-family: Roboto-Regular;
            align-self: center;
            margin-left: 10px;
          }
        }

        &__date {
          display: flex;
          flex-direction: column;
          text-align: left;
          font-size: 14px;
          font-family: Roboto-Medium;
        }

        &__status,
        &__completion {
          font-family: Roboto-Regular;
          font-size: 14px;
          display: flex;
          align-items: center;
          min-width: 140px;

          svg {
            margin-right: 5px;
          }
          strong {
            margin: 0 5px;
          }
        }

        &__input {
          border: none;
          font-family: Roboto-Medium;
          font-size: 16px;
          color: $grey-5;

          &::placeholder {
            color: $grey-2;
          }
        }
      }
    }
  }
</style>
